import { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import Select from "../../../components/select/Select";
import UserModel from "../../../models/User";
import UserPop from "./UserPop";
import UserTable from "./UserTable";

interface FilterData {
    inputType: string;
    inputName: string;
    word: string;
}

const UserCard = ({ users, typeOptions, onRefresh } : { users: Array<UserModel>, typeOptions: Array<any>, onRefresh: any }) => {
    const [statusModal, setStatusModal] = useState<boolean>(false);
    const [arrUsers, setArrUsers] = useState<Array<UserModel>>([]);
    const [usersUniv, setUsersUniv] = useState<Array<UserModel>>([]);

    const filter = (type: FilterData) => {
        if (type.inputType === "input") {
            const filter1 = document.getElementById("filter-auser") as HTMLSelectElement | null;
            if (filter1 !== null) filter1.value = "default";

            let palabra = new RegExp(`${type.word}.*`, "i");
            let newUsers = usersUniv.filter((userElement) => palabra.test(userElement.userName));
            setArrUsers(newUsers);
        } else {
            if (type.inputName === "userType") {
                const filter1 = document.getElementById("filter-atext") as HTMLSelectElement | null;
                if (filter1 !== null) filter1.value = "";

                const usersFound = usersUniv.filter((userElement) => (userElement.userType === type.word));
                setArrUsers(usersFound);
            }
        }
    }

    useEffect(() => {
        setArrUsers(users);
        setUsersUniv(users);
    }, [users]);

    return (
        <>
            {
                statusModal ?
                    <UserPop onRefresh={onRefresh} userElement={null} arrTypes={typeOptions} onResponse={() => {setStatusModal(false) }} />
                : null
            }

            <div className="block">
                <div className="bg-arena-white m-[20px] md:m-[25px] space-y-[25px] px-[15px] py-[15px] md:space-y-[35px] md:px-[35px] md:py-[30px] rounded">
                    <div className="text-left font-bold">
                        <h3 className="text-[20px]">Usuarios</h3>
                    </div>
                    <div className="flex space-x-[10px]">
                        <button className="flex items-center bg-arena-orange py-[5px] px-[15px] space-x-[40px] rounded-sm" type="button" onClick={() => { setStatusModal(true) }}>
                            <div className="text-left txt-arena-white font-bold">
                                <span className="text-[14px]">Añadir Usuario</span>
                            </div>
                            <div className="txt-arena-white font-bold">
                                <span className="text-[20px]"><AiOutlinePlus /></span>
                            </div>
                        </button>
                    </div>
                    <div>
                        <div className="space-y-[20px] lg:flex lg:space-x-[10px] lg:space-y-0">
                            <div className="relative">
                                <input
                                    className="bg-transparent border-b border-zinc-500 py-[8px] px-0 md:px-[10px] w-full lg:w-[250px] txt-arena-gray text-[14px]"
                                    type="text"
                                    id="filter-atext"
                                    placeholder="Buscar Usuario"
                                    onChange={(wrd) => { filter({ inputType: "input", inputName: "userName", word: `${wrd.target.value}` }) }}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-[8px] flex items-center">
                                    <span className="txt-arena-gray text-[20px]"><BiSearch /></span>
                                </div>
                            </div>
                            <div>
                                <Select
                                    className="bg-transparent border-b border-zinc-500 py-[10px] px-0 md:px-[10px] w-full lg:w-[250px] txt-arena-gray text-[14px]"
                                    options={typeOptions}
                                    onResponse={(wrd: any) => { filter({ inputType: "select", inputName: "userType", word: `${typeOptions[wrd.target.value].value}` }) }}
                                    name="filter-auser"
                                    id="filter-auser"
                                    placeholder="Filtrar por tipo de usuario"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <UserTable onRefresh={onRefresh} arrTypes={typeOptions} users={arrUsers} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserCard;