import { useEffect, useState, useContext } from "react";
import { getRealeses } from "../../services/Realese";
import { getNews } from "../../services/News";
import { getAllDropsTournaments } from "../../services/TournamentDrop";
import { getCompanyById } from "../../services/Company";
import { IDCompany } from "../../global/global";
import { getPopups } from "../../services/Popup";
import { Fade } from "react-awesome-reveal";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import ImageTop from "../../assets/images/01_img_section_1.png";
import ImageSixDesktop from "../../assets/images/6KwebsiteArena.png";
import IconBg from "../../assets/icons/06_hexagon.svg";
import NewsModel from "../../models/News";
import RealeseModel from "../../models/Realese";
import TournamentDropModel from "../../models/TournamentDrop";
import HomepageHeader from "./HomepageHeader";
import HomepageRealeses from "./HomeRealeses";
import HomepageNews from "./HomepageNews";
import HomepageChannels from "./HomepageChannels";
import HomepageDrops from "./HomepageDrops";
import HomepageLogIn from "./HomepageLogIn";
import HomepageSponsor from "./HomepageSponsor";
import Loading from "../../components/loading/Loading";
import HomepagePop from "./popup/HomepagePop";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import TournamentPopDrop from "../tournaments/TournamentPopDrop";
import RealesesPop from "../realeses/RealesesPop";
import Rellax from "rellax";
import "./Homepage.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import "./styles.css";
// import required modules
import { Pagination } from "swiper";
import PopupBanner from "./popup/PopupBanner";
import ReactPlayer from "react-player";
import { getUrlVideos } from "../../services/Carrousel";
import { GlobalContext } from "../../context/global_context";


interface PopupInfo {
    status: boolean;
    images: Array<string>;
    video: string,
    urlExterna:string
}

interface ModalData {
    status: boolean;
    info: TournamentDropModel;
}

interface ModalData1 {
    status: boolean;
    info: RealeseModel;
}

interface RetargetlyProps {
    pid: number;
    src: number;
  }

const Homepage = () => {
    const RetargetlyScript = ({ pid, src }: RetargetlyProps) => {
        return (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
                var _rl_cn = _rl_cn || 0,_rl_ptc = ("https:" == window.location.protocol ? "https" : "http"); 
                window._rl_ids = window._rl_ids || []; 
                window._rely = window._rely   
       || []; 
                _rl_ids.push({pid:${pid},src:${src}});   
       
                _rely.send = _rely.send?_rely.send:function() {}; 
                (function() { 
                  var rl = document.createElement("script"); 
                  rl.type = "text/javascript"; 
                  rl.async = true; 
                  rl.src = _rl_ptc + "://api.retargetly.com/loader?id=" + _rl_ids[_rl_ids.length-1].pid; 
                  rl.id = "rely-api-"+(_rl_cn++); 
                  var s = document.getElementsByTagName("script")[0]; 
                  s.parentNode.insertBefore(rl, s);   
       
                })(); 
              `,
            }}
          />
        );
      };

    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [news, setNews] = useState<Array<NewsModel>>([]);
    const [realeses, setRealeses] = useState<Array<RealeseModel>>([]);
    const [dropsTournaments, setDropsTournaments] = useState<Array<TournamentDropModel>>([]);
    const [statusModal, setStatusModal] = useState<ModalData1>({
        status: false,
        info: {
            companyID: "",
            gameID: "",
            gameTitle: "",
            gameDeveloper: "",
            gameBody: "",
            gameGenre: [],
            gameESRB: "",
            gamePlatform: "",
            gameReleaseDate: "",
            gameScore: 0,
            gameCreatedBy: "",
            gameImageURL: "",
            dropVideoURL: ""
        }
    });

    const { globalState, setGlobalState } = useContext(GlobalContext)
    // console.log(globalState)


    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    const [popupData, setPopupData] = useState<PopupInfo>({
        status: false,
        images: [],
        video: "",
        urlExterna: ""
    });

    const [popupBanner, setPopupBanner] = useState({
        status: false,
        name: ''
    });

    const [statusModalD, setStatusModalD] = useState<ModalData>({
        status: false,
        info: {
            companyID: "",
            dropID: "",
            dropName: "",
            dropGameTitle: "",
            dropType: "",
            dropReward: "",
            dropGroupMode: "",
            dropDescription: "",
            drpoTerms: "",
            dropConsole: "",
            dropDate: "",
            dropLocation: "",
            dropCreatedBy: "",
            dropImageURL: "",
            dropVideoURL: "",
            dropExternalURL: ""
        }
    });

    const showRoom = (type: any) => {
        setPopupBanner({ ...popupBanner, status: true, name: type })
    }

    useEffect(() => {
        new Rellax(".animatev0", { speed: 3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev1", { speed: -1, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev2", { speed: -2, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev3", { speed: -3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev4", { speed: -4, center: false, round: true, vertical: true, horizontal: false });

        new Promise<void>((resolve, reject) => {
            // Get company
            getCompanyById({ companyID: `${IDCompany}` }).then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    if (datares.companyActivePopUps) {
                        getPopups().then(resPops => {
                            if (resPops && resPops.data) {
                                // console.log(resPops, "response")
                                const dataresPops: any = resPops.data;
                                const nbrRandom = Math.floor(Math.random() * dataresPops.length)
                                setPopupData({
                                    ...popupData,
                                    status: datares.companyActivePopUps,
                                    images: dataresPops[nbrRandom].popupImageURL,
                                    video: dataresPops[nbrRandom].popupVideoURL,
                                    urlExterna:dataresPops[nbrRandom].popupExternalURL
                                });
                            }
                        })
                    }
                }
            });

            getUrlVideos({ companyID: `${IDCompany}` }).then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    setGlobalState({...globalState, videosURL:datares[0]})
                }
            });

            // Get all News
            getNews().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        let diasDif = new Date().getTime() - new Date(element.noteCreatedAt).getTime();
                        element.noteCreatedAt = Math.round(diasDif / (1000 * 60 * 60 * 24));
                        newArr.push(element);
                    }

                    // Se ordena las noticias por episodio
                    newArr.sort((a: NewsModel, b: NewsModel) => {
                        return Number(String(b.noteEpisode).replace("Episodio ", "")).valueOf() - Number(String(a.noteEpisode).replace("Episodio ", "")).valueOf();
                    });

                    setNews(newArr);
                }
            });

            // Get all realeses
            getRealeses().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setRealeses(newArr);
                }
            });

            // Get all drops and tournaments...
            getAllDropsTournaments().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setDropsTournaments(newArr);
                }
            });

            setTimeout(function () {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    }, []);

    return (
        <>
            {/* <Loading /> */}
            {loadingStatus ? <Loading /> : null}

            {
                popupData.status ?
                    <HomepagePop
                        images={popupData.images}
                        urlExterna={popupData.urlExterna}
                        video={popupData.video}
                        closeModal={(sts: boolean) => { setPopupData({ ...popupData, status: sts }) }}
                    />
                    : null
            }

            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({ ...popupTP, status: false, documentStr: "" }) }}
                    />
                    : null
            }

            {
                statusModalD.status ?
                    <TournamentPopDrop data={statusModalD.info} onResponse={() => { setStatusModalD({ ...statusModalD, status: false }) }} />
                    : null
            }

            {
                statusModal.status ?
                    <RealesesPop realese={statusModal.info} closeModal={() => { setStatusModal({ ...statusModal, status: false }) }} />
                    : null
            }
            {
                popupBanner.status ?

                    <PopupBanner
                        images={popupData.images}
                        video={popupData.video}
                        videoSelected={popupBanner.name}
                        closeModal={(sts: boolean) => { setPopupBanner({ ...popupBanner, status: sts }) }}
                    />
                    :
                    null
            }

            <div id="animatev0" style={{ scrollBehavior: 'auto' }} className="bg-arena-gray">


                <div>
                    <div className="flex flex-row">
                        <div className="w-full bg-no-repeat bg-cover fixed z-40">
                            <Navbar />
                        </div>
                    </div>

                    <div className="space-y-[20px]">
                        <div className="relative bg-arena-grayo w-full top-0 md:absolute" >
                            <div>
                                <img className="animatev1 absolute w-[60vw] top-[66vw] left-[-27vw] md:w-[250px] md:top-[360px] md:left-[-120px] z-0" src={IconBg} />
                                <img className="animatev3 absolute w-[60vw] top-[55vw] right-[-25vw] md:w-[250px] md:top-[170px] md:left-[500px] z-0" src={IconBg} />
                            </div>
                            {/* <img className="w-[92vw] h-auto top-0 m-auto md:w-auto md:h-[750px] lg:h-[800px]" src={ ImageTop } /> */}
                            <div className="videoCarrousel">
                                <Swiper
                                    direction={"vertical"}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    {globalState.videosURL.carouselLinksURL?.map((item: any) => {
                                        // console.log(item)
                                        return (
                                            <SwiperSlide>
                                                <div className="container1">
                                                    <ReactPlayer
                                                        url={item}
                                                        className='responsive-iframe'
                                                        playing
                                                        width={'100%'}
                                                        height={'100%'}
                                                        controls={false}
                                                        loop={true}
                                                        muted={true}
                                                    />
                                                </div>

                                            </SwiperSlide>
                                        )
                                    })}
                                    {/* <SwiperSlide>
                                        <div className="container1">
                                            <ReactPlayer
                                                url={'https://firebasestorage.googleapis.com/v0/b/arenaweb-144819.appspot.com/o/videos%2Fvideos_carrusel%2FWeb%20Next%20Level.mp4?alt=media&token=56dcdd68-9026-4e3a-8367-c42c63eadc5a'}
                                                className='responsive-iframe'
                                                playing
                                                width={'100%'}
                                                height={'100%'}
                                                controls={false}
                                                loop={true}
                                                muted={true}
                                            />
                                        </div>

                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="container1">
                                            <ReactPlayer
                                                url={'https://firebasestorage.googleapis.com/v0/b/arenaweb-144819.appspot.com/o/videos%2Fvideos_carrusel%2FWeb%20Sala%20Platino.mp4?alt=media&token=f72ad0d7-fd98-44aa-9fb8-8bf7661dcebb'}
                                                className='responsive-iframe'
                                                playing
                                                width={'100%'}
                                                height={'100%'}
                                                controls={false}
                                                loop={true}
                                                muted={true}
                                            />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="container1">

                                            <ReactPlayer
                                                url={'https://firebasestorage.googleapis.com/v0/b/arenaweb-144819.appspot.com/o/videos%2Fvideos_carrusel%2FWeb%20VR.mp4?alt=media&token=2dae1a49-df83-4c08-80ab-23d56b9d3046'}
                                                className='responsive-iframe'
                                                playing
                                                width={'100%'}
                                                height={'100%'}
                                                controls={false}
                                                loop={true}
                                                muted={true}
                                            />
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="container1">
                                            <ReactPlayer
                                                url={'https://firebasestorage.googleapis.com/v0/b/arenaweb-144819.appspot.com/o/videos%2Fvideos_carrusel%2FWeb%20Versus%20Zone.mp4?alt=media&token=d6201941-5ec1-473c-9214-8b92742d2f46'}
                                                className='responsive-iframe'
                                                playing
                                                width={'100%'}
                                                height={'100%'}
                                                controls={false}
                                                loop={true}
                                                muted={true}
                                            />
                                        </div>


                                    </SwiperSlide> */}


                                </Swiper>
                            </div>

                        </div>
                        <div className="relative top-[-70vw] md:top-0 w-[90vw] m-auto md:w-[85%] z-30">
                            <HomepageHeader type={(e: any) => {
                                // console.log(e)
                                showRoom(e)
                            }} />
                        </div>
                        <div className="relative lg:flex lg:justify-center lg:space-x-[200px] w-[100%] md:w-[93%] top-10 md:top-0 z-30 m-auto">
                            <div className="relative">
                                <HomepageDrops
                                    drops={dropsTournaments}
                                    onModalDrop={(sts: boolean, info: TournamentDropModel) => {
                                        setStatusModalD({ ...statusModalD, status: sts, info: info })
                                    }}
                                />
                            </div>
                        </div>
                        <div className="relative w-[95vw] space-y-[2vw] m-auto md:w-[70%]">
                            <div>
                                <img className="animatev1 absolute w-[60vw] top-[-20vw] left-[-30vw] md:w-[250px] md:top-[50px] md:left-[20px] z-0" src={IconBg} />
                                <img className="animatev2 hidden md:block absolute w-[60vw] top-[120vw] right-[-30vw] md:w-[250px] md:top-[-30px] md:right-[-60px] z-0" src={IconBg} />
                            </div>
                            <HomepageLogIn />
                            <div>
                                <Fade>
                                    <a href="https://www.sixkarma.mx/" target="_blank">
                                        <img className="w-[100vw] lg:w-[50vw] m-auto z-30" src={ImageSixDesktop} />
                                    </a>
                                    <div className="md:hidden txt-arena-white px-[10vw] mb-[5vw]">
                                        <p className="text-[4vw]">
                                            Six Karma Esports. Somos la ola verde arrasando con las escenas de esport latina.
                                        </p>
                                    </div>
                                    <div className="w-[70vw] py-[10px] m-auto md:w-[250px] lg:w-[400px]">
                                        <a href="https://www.sixkarma.mx/" target="_blank">
                                            <button className="btn-arena-sixkarma" type="button">
                                                <span className="tracking-[3px] hover:tracking-[5px] ease-in duration-150">CONOCE MÁS</span>
                                            </button>
                                        </a>
                                    </div>
                                </Fade>
                            </div>
                            <div className="hidden md:block">
                                <img className="absolute w-[250px] bottom-[-200px] left-[-200px] z-30" src={IconBg} />
                            </div>
                        </div>
                        <div className="relative home-arena-style bg-arena-black">
                            <div className="w-[85vw] m-auto pb-[8vw] md:mt-[0px] md:pt-[200px] md:pb-[50px] md:w-[70%]">
                                <HomepageChannels />
                                <div>
                                    <img className="absolute w-[60vw] top-[210vw] right-[-15vw] md:w-[250px] md:top-[150px] md:right-[15px] z-[-1]" src={IconBg} />
                                    <img className="hidden lg:block absolute w-[250px] top-[550px] left-[-150px] z-30" src={IconBg} />
                                </div>
                            </div>
                            <div className="relative home-arena-style bg-arena-white">
                                <div className="w-[87vw] m-auto pt-[30px] md:w-[85%] md:h-[1150px] lg:h-[800px]">
                                    <HomepageNews news={news} />
                                    <div className="hidden lg:block">
                                        <img className="absolute w-[250px] top-[700px] right-[5vw] z-30" src={IconBg} />
                                    </div>
                                </div>
                                <div className="relative home-arena-style bg-arena-gray pb-[15px]">
                                    <div className="w-[85vw] pt-[20vw] m-auto md:pt-[80px] lg:pt-[150px] md:w-[70%]">
                                        <HomepageSponsor />
                                    </div>
                                    <div className="hidden md:block">
                                        <img className="animatev1 absolute w-[60vw] top-[150vw] right-[-10vw] md:w-[250px] md:top-[0px] md:left-[-50px] z-[-1]" src={IconBg} />
                                    </div>
                                    <div className="w-[90%] md:w-[95%] lg:w-[80%] m-auto">
                                        <HomepageRealeses
                                            realeses={realeses}
                                            onModalDetail={(relModel: RealeseModel, sts: boolean) => {
                                                setStatusModal({ ...statusModal, status: sts, info: relModel });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="relative mt-[150px]">
                        <div className="hidden md:block">
                            <img className="absolute w-[250px] top-[-220px] left-[-100px] lg:top-[-150px] lg:left-[80px] z-30" src={IconBg} />
                        </div>
                        <Fade>
                            <Footer
                                statusPop={(opt: any) => {
                                    setPopupTP({ ...popupTP, status: opt.status, documentStr: opt.documentStr })
                                }}
                            />
                        </Fade>
                    </div>



                </div>
            </div>
        </>
    )
}

export default Homepage;