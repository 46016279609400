import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import RealeseModel from "../../models/Realese";
import RealesesCard from "../realeses/RealesesCard";

const HomepageRealeses = ({ realeses, onModalDetail } : { realeses: Array<RealeseModel>, onModalDetail: any }) => {
    const onClickLeft = () => {
        let strContainer: string = "more-realeses-container";
        if (window.innerWidth >= 300 && window.innerWidth <= 400) {
            strContainer = "more-realeses-containerm";
        }

        const carrousel = document.getElementById(strContainer);
        if (carrousel !== null) {
            carrousel.scrollLeft -= 220;
        }
    }

    const onClickRight = () => {
        let strContainer: string = "more-realeses-container";
        if (window.innerWidth >= 300 && window.innerWidth <= 400) {
            strContainer = "more-realeses-containerm";
        }

        const carrousel = document.getElementById(strContainer);
        if (carrousel !== null) {
            carrousel.scrollLeft += 220;
        }
    }

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="text-left txt-arena-white font-bold px-[70px] py-[70px]">
                    <Fade><h3 className="text-[25px]">Nuevos lanzamientos</h3></Fade>
                </div>
                <div id="more-realeses-body">
                    <div id="more-realeses-container" className="w-[75%] lg:w-[85%] flex overflow-hidden space-x-[20px] m-auto">
                        <div className="flex items-center">
                            <button
                                className="scale-100 hover:scale-110 ease-in duration-150 absolute bg-arena-white txt-arena-gray left-[80px] lg:left-[180px] px-[20px] py-[20px] rounded-full z-40"
                                type="button"
                                onClick={onClickLeft}
                            >
                                <span className="text-[20px]"><AiOutlineArrowLeft /></span>
                            </button>
                            <button
                                className="scale-100 hover:scale-110 ease-in duration-150 absolute bg-arena-white txt-arena-gray right-[80px] lg:right-[180px] px-[20px] py-[20px] rounded-full z-40"
                                type="button"
                                onClick={onClickRight}
                            >
                                <span className="text-[20px]"><AiOutlineArrowRight /></span>
                            </button>
                        </div>
                        {
                            realeses.map((newElement: RealeseModel, i: number) => {
                                if (i < 10) {
                                    return(
                                        <div key={i} className="flex-shrink-0">
                                            <Fade>
                                                <RealesesCard
                                                    realeses={newElement}
                                                    onResponseModal={(relReponse: RealeseModel) => {
                                                        onModalDetail(relReponse, true)
                                                    }}
                                                />
                                            </Fade>
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                </div>
                <div className="md:w-[40%] lg:w-[30%] py-[10px] m-auto scale-100 hover:scale-110 ease-in duration-150">
                    <Link to="/realeses">
                        <Fade>
                            <button className="btn-arena-primary" type="button">
                                <span className="text-[14px] tracking-[2px]">VER TODOS</span>
                            </button>
                        </Fade>
                    </Link>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div>
                    <div className="text-left txt-arena-white font-bold px-[5vw] py-[7vw]">
                        <Fade><h3 className="text-[5vw]">Nuevos lanzamientos</h3></Fade>
                    </div>
                    <div className="body-carrousel-rel">
                        <div className="slider-carrousel-rel">
                            <div className="slides-carrousel-rel">
                                {
                                    realeses.map((realeseElement: RealeseModel, i: number) => {
                                        if (i < 10) {
                                            return(
                                                <div key={i} className="flex-shrink-0">
                                                    <Fade>
                                                        <RealesesCard
                                                            realeses={realeseElement}
                                                            onResponseModal={(relReponse: RealeseModel) => {
                                                                onModalDetail(relReponse, true)
                                                            }}
                                                        />
                                                    </Fade>
                                                </div>
                                            );
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-[85vw] m-auto my-[8vw]">
                    <Link to="/realeses">
                        <Fade>
                            <button className="btn-arena-primary" type="button">
                                <span className="text-[4vw]">VER TODOS</span>
                            </button>
                        </Fade>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default HomepageRealeses;