import { useEffect, useState } from "react";
import { getRealeses } from "../../../services/Realese";
import Loading from "../../../components/loading/Loading";
import NavbarAdmin from "../../../components/navbar-admin/NavbarAdmin";
import SidebarAdmin from "../../../components/sidebar-admin/SidebarAdmin";
import RealeseModel from "../../../models/Realese";
import RealesesCard from "./RealesesCard";

const AdminRealeses = () => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [realeses, setRealeses] = useState<Array<RealeseModel>>([]);
    const plataformsOptions = [
        { label: "Multiplataforma", value: "Multiplataforma" },
        { label: "Nintendo Switch", value: "Nintendo Switch" },
        { label: "Windows PC", value: "Windows PC" },
        { label: "Steam", value: "Steam" },
        { label: "Playstation 4/Pro", value: "Playstation 4/Pro" },
        { label: "Playstation 5", value: "Playstation 5" },
        { label: "Xbox One", value: "Xbox One" },
        { label: "Xbox Series X/S", value: "Xbox Series X/S" },
        { label: "Android", value: "Android" },
        { label: "iOS", value: "iOS" }
    ];

    const esrbOptions = [
        { label: "Todos", value: "E" },
        { label: "Todos +10", value: "E-10+" },
        { label: "Adolescentes", value: "T" },
        { label: "Maduro +17", value: "M" },
        { label: "Adultos únicamente +18", value: "A" },
        { label: "Aún sin calificar", value: "RP" },
    ];

    const starsOptions = [
        { label: "1 estrella", value: 1 },
        { label: "2 estrellas", value: 2 },
        { label: "3 estrellas", value: 3 },
        { label: "4 estrellas", value: 4 },
        { label: "5 estrellas", value: 5 },
    ];

    const getRealesesData = () => {
        new Promise<void>((resolve, reject) => {
            // Get all realeses
            getRealeses().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setRealeses(newArr);
                }
            });

            // Resolve promise
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    }

    useEffect(() => {
        getRealesesData();
    }, []);

    return (
        <div className="flex flex-row w-full h-screen">
            { loadingStatus ? <Loading /> : null }
            <div className="w-[65px] md:w-[250px] lg:w-[370px]">
                <SidebarAdmin />
            </div>
            <div className="w-full bg-zinc-300 flex-grow flex-shrink flex-auto overflow-y-scroll">
                <div>
                    <NavbarAdmin />
                </div>
                <div>
                    <RealesesCard
                        onRefresh={getRealesesData}
                        esrb={esrbOptions}
                        plataformas={plataformsOptions}
                        stars={starsOptions}
                        realeses={realeses}
                    />
                </div>
            </div>
        </div>
    );
}

export default AdminRealeses;