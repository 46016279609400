import { useEffect, useState } from "react";
import { getDrops, getTournaments } from "../../services/TournamentDrop";
import { Fade } from "react-awesome-reveal";
import IconBg from "../../assets/icons/06_hexagon.svg";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import TournamentDropModel from "../../models/TournamentDrop";
import TournamentsCard from "./TournamentsCard";
import TournamentsDrops from "./TournamentsDrops";
import Loading from "../../components/loading/Loading";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import TournamentPopDrop from "./TournamentPopDrop";
import TournamentPopCard from "./TournamentPopCard";
import TournamentsPopJoin from "./TournamentsPopJoin";
import Rellax from "rellax";
import "./Tournament.css";

interface ModalData {
    status: boolean;
    info: TournamentDropModel;
}

const Tournaments = () => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [drop, setDrops] = useState<Array<TournamentDropModel>>([]);
    const [tournament, setTournaments] = useState<Array<TournamentDropModel>>([]);
    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    const [statusModalD, setStatusModalD] = useState<ModalData>({
        status: false,
        info: {
            companyID: "",
            dropID: "",
            dropName: "",
            dropGameTitle: "",
            dropType: "",
            dropReward: "",
            dropGroupMode: "",
            dropDescription: "",
            drpoTerms: "",
            dropConsole: "",
            dropDate: "",
            dropLocation: "",
            dropCreatedBy: "",
            dropImageURL: "",
            dropVideoURL: "",
            dropExternalURL: ""
        }
    });

    const [statusModalT, setStatusModalT] = useState<ModalData>({
        status: false,
        info: {
            companyID: "",
            dropID: "",
            dropName: "",
            dropGameTitle: "",
            dropType: "",
            dropReward: "",
            dropGroupMode: "",
            dropDescription: "",
            drpoTerms: "",
            dropConsole: "",
            dropDate: "",
            dropLocation: "",
            dropCreatedBy: "",
            dropImageURL: "",
            dropVideoURL: "",
            dropExternalURL: ""
        }
    });
    
    const [statusModalJ, setStatusModalJ] = useState<ModalData>({
        status: false,
        info: {
            companyID: "",
            dropID: "",
            dropName: "",
            dropGameTitle: "",
            dropType: "",
            dropReward: "",
            dropGroupMode: "",
            dropDescription: "",
            drpoTerms: "",
            dropConsole: "",
            dropDate: "",
            dropLocation: "",
            dropCreatedBy: "",
            dropImageURL: "",
            dropVideoURL: "",
            dropExternalURL: ""
        }
    });
    
    const getData = () => {
        new Promise<void>((resolve, reject) => {
            // Get all drops...
            getDrops().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setDrops(newArr);
                }
            });

            // Get all tournaments
            getTournaments().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setTournaments(newArr);
                }
            });

            // Resolve promise
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    }

    useEffect(() => {
        new Rellax(".animatev0", { speed: 3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev1", { speed: -1, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev2", { speed: -2, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev3", { speed: -3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev4", { speed: -4, center: false, round: true, vertical: true, horizontal: false });
        
        getData();
    }, []);

    return (
        <>
            { loadingStatus ? <Loading /> : null }

            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({...popupTP, status: false, documentStr: ""}) }}
                    />
                : null
            }

            {
                statusModalD.status ?
                    <TournamentPopDrop data={statusModalD.info} onResponse={() => {setStatusModalD({...statusModalD, status: false})}} />
                : null
            }

            {
                statusModalT.status ?
                    <TournamentPopCard data={statusModalT.info} onResponse={() => {setStatusModalT({...statusModalT, status: false})}} />
                : null
            }

            {
                statusModalJ.status ?
                    <TournamentsPopJoin tournament={statusModalJ.info} onCloseModal={() => setStatusModalJ({...statusModalJ, status: false})} />
                : null
            }

            <div id="animatev0" style={{ scrollBehavior: 'auto' }} className="bg-arena-gray">
                <div className="w-full bg-no-repeat bg-cover fixed z-40">
                    <Navbar />
                </div>
                <div className="relative pt-[80px] w-[85%] md:pt-[100px] md:w-[650px] lg:w-[900px] m-auto">
                    <img className="animatev4 absolute w-[60vw] top-[-35vw] left-[25vw] md:w-[250px] md:top-[65px] md:left-[430px] lg:top-[90px] lg:left-[680px] z-0" src={IconBg} />
                    <img className="animatev2 absolute w-[60vw] top-[65vw] left-[-40vw] md:w-[250px] md:top-[700px] md:left-[-170px] lg:top-[900px] lg:left-[-180px] z-0" src={IconBg} />
                    <img className="animatev3 absolute w-[60vw] top-[250vw] left-[28vw] md:w-[250px] md:top-[900px] md:left-[440px] lg:top-[1100px] lg:left-[700px] z-0" src={IconBg} />
                    
                    <div>
                        <TournamentsDrops
                            drops={drop}
                            onModalDrop={(sts: boolean, info: TournamentDropModel) => {
                                setStatusModalD({...statusModalD, status: sts, info: info})
                            }}
                        />
                    </div>

                    <div className="py-[30vw] md:py-[150px] lg:py-[250px]">
                        <TournamentsCard
                            tournaments={tournament}
                            onModalDrop={(sts: boolean, info: TournamentDropModel) => {
                                setStatusModalT({...statusModalT, status: sts, info: info})
                            }}
                            onModalJoin={(sts: boolean, info: TournamentDropModel) => {
                                setStatusModalJ({...statusModalJ, status: sts, info: info})
                            }}
                        />
                    </div>
                </div>
                <div className="relative pt-10">
                    <img className="absolute w-[60vw] top-[-40vw] left-[-50vw] md:w-[250px] md:top-[-80px] md:left-[-140px] lg:top-[-100px] lg:left-0 z-0 md:z-10" src={IconBg} />
                    <Fade>
                        <Footer
                            statusPop={(opt: any) => {
                                setPopupTP({...popupTP, status: opt.status, documentStr: opt.documentStr })
                            }}
                        />
                    </Fade>
                </div>
            </div>
        </>
    );
}

export default Tournaments;