function validateMail(mail: string): boolean {
    if ((/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail))){
        return true;
    }
    return false;
}

function validateString(str: string): boolean {
    if ((/^[a-zA-ZáéíóúÁÉÍÓÚñÑ /s]*$/.test(str))) {
        return true;
    }
    return false;
}

function validatePhone(nbr: string): boolean {
    if((/^\d{10}$/.test(nbr)) ) {
        return true;
    }
    return false;
}

export {
    validateMail,
    validateString,
    validatePhone
};