function getCurrentStringDate(): string {
    let currentMonth: string = "";
    let currentDay: string = "";

    if (new Date().getMonth()+1 <= 9) {
        currentMonth = `0${new Date().getMonth()+1}`;
    } else {
        currentMonth = `${new Date().getMonth()+1}`;
    }

    if (new Date().getDate() <= 9) {
        currentDay = `0${new Date().getDate()}`;
    } else {
        currentDay = `${new Date().getDate()}`;
    }

    return `${new Date().getFullYear()}-${currentMonth}-${currentDay}`;
}

export {
    getCurrentStringDate
}