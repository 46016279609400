import { useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { IoIosKey, IoMdMail } from "react-icons/io";
import { AiFillGift } from "react-icons/ai";
import { Fade } from "react-awesome-reveal";
import IconControl from "../../assets/icons/realeses/02_icon_lanzamientos.png";
import axios from "axios";
import PopWarning from "../../components/Pop/PopWarning";
import PopSuccess from "../../components/Pop/PopSuccess";
import UserModel from "../../models/User";
import { addUser } from "../../services/Users";

interface PlayerModel {
    name: string;
    lastName: string;
    birthday: string;
    email: string;
    nick: string;
    password: string;
    gender: string;
    avatarId: number;
    phone: string;
}

interface PopInfo {
    status: boolean,
    type: string
}

const RegisterForm = () => {
    const [popWarningStatus, setPopWarningStatus] = useState<PopInfo>({ status: false, type: "" });
    const [popSuccessStatus, setPopSuccessStatus] = useState<PopInfo>({ status: false, type: "" });
    const [userData, setUserData] = useState<PlayerModel>({
        name: "",
        lastName: "",
        birthday: "",
        email: "",
        nick: "",
        password: "",
        gender: "0",
        avatarId: 1,
        phone: ""
    });

    const [userDataAux, setUserDataAux] = useState<UserModel>({
        userName: "",
        userUID: "",
        userLastName: "",
        userType: "",
        userLoginEmail: "",
        userPassword: "",
        userIsActive: false,
    });

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setUserData({...userData, [evt.currentTarget.name]: evt.currentTarget.value});
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (userData.name && userData.lastName && userData.birthday && userData.email && userData.nick && userData.password) {
            axios.post("https://offsite.gamersarena.com.mx/players", userData).then((res: any) => {
                if (res.status === 201) {
                    setPopSuccessStatus({...popWarningStatus, status: true, type: "¡El usuario se ha creado con éxito!"});
                    setUserDataAux({...userDataAux,
                        userName: userData.name,
                        userLastName: userData.lastName,
                        userType: "USER",
                        userLoginEmail: userData.email,
                        userPassword: userData.password,
                        userIsActive: true
                    });
                    new Promise<void>((resolve, reject) => {
                        addUser(userDataAux).then((res) => {});
                    });
                } else {
                    setPopWarningStatus({...popWarningStatus, status: true, type: "¡Ha ocurrido un problema, favor de volver a intentarlo!"});
                }
            }).catch((error) => {
                if (error.response.data.error.message) {
                    setPopWarningStatus({...popWarningStatus, status: true, type: error.response.data.error.message});
                } else {
                    setPopWarningStatus({...popWarningStatus, status: true, type: "¡Ha ocurrido un problema, favor de volver a intentarlo!"});
                }
            });
        } else {
            setPopWarningStatus({...popWarningStatus, status: true, type: "Favor de llenar todos los campos del formulario"});
        }
    }

    return (
        <>
            {
                popWarningStatus.status ?
                    <PopWarning body={popWarningStatus.type} onClose={() => { setPopWarningStatus({...popWarningStatus, status: false, type: ""}) }} />
                : null
            }

            {
                popSuccessStatus.status ?
                    <PopSuccess body={popSuccessStatus.type} onClose={() => { setPopSuccessStatus({...popSuccessStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="block">
                <form onSubmit={handleSubmit}>
                    <div className="w-[90vw] md:w-[600px] my-[50px] p-[10px] space-y-[10px] m-auto">
                        <Fade cascade damping={0.1}>
                            <div>
                                <img className="w-[70px]" src={IconControl} />
                            </div>
                            <div className="text-left txt-arena-white">
                                <div className="font-bold">
                                    <h3 className="text-[20px]">Registrate</h3>
                                </div>
                                <div>
                                    <p className="text-[16px]">Forma parte de nuestra comunidad de Gamers Arena</p>
                                </div>
                            </div>
                            <div className="space-y-[10px]">
                                <div className="text-left txt-arena-orange font-bold">
                                    <h3 className="text-[14px]">Nombre(s)</h3>
                                </div>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="name"
                                        className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[16px] py-[10px]"
                                        onChange={handleChange}
                                    />
                                    <div className="flex absolute inset-y-0 right-0 items-center pr-[20px] pointer-events-none txt-arena-white">
                                        <span className="text-[25px]"><FaUserAlt /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-[10px]">
                                <div className="text-left txt-arena-orange font-bold">
                                    <h3 className="text-[14px]">Apellido(s)</h3>
                                </div>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="lastName"
                                        className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[16px] py-[10px]"
                                        onChange={handleChange}
                                    />
                                    <div className="flex absolute inset-y-0 right-0 items-center pr-[20px] pointer-events-none txt-arena-white">
                                        <span className="text-[25px]"><FaUserAlt /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-[10px]">
                                <div className="text-left txt-arena-orange font-bold">
                                    <h3 className="text-[14px]">Correo electrónico</h3>
                                </div>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="email"
                                        className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[16px] py-[10px]"
                                        onChange={handleChange}
                                    />
                                    <div className="flex absolute inset-y-0 right-0 items-center pr-[20px] pointer-events-none txt-arena-white">
                                        <span className="text-[25px]"><IoMdMail /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-[10px]">
                                <div className="text-left txt-arena-orange font-bold">
                                    <h3 className="text-[14px]">Nickname</h3>
                                </div>
                                <div className="relative">
                                    <input
                                        type="text"
                                        name="nick"
                                        className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[16px] py-[10px]"
                                        onChange={handleChange}
                                    />
                                    <div className="flex absolute inset-y-0 right-0 items-center pr-[20px] pointer-events-none txt-arena-white">
                                        <span className="text-[25px]"><FaUserAlt /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-[10px]">
                                <div className="text-left txt-arena-orange font-bold">
                                    <h3 className="text-[14px]">Fecha de nacimiento</h3>
                                </div>
                                <div className="relative">
                                    <input
                                        type="date"
                                        name="birthday"
                                        className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[16px] py-[10px]"
                                        onChange={handleChange}
                                    />
                                    <div className="flex absolute inset-y-0 right-0 items-center pr-[20px] pointer-events-none txt-arena-white">
                                        <span className="text-[25px]"><AiFillGift /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-[10px]">
                                <div className="text-left txt-arena-orange font-bold">
                                    <h3 className="text-[14px]">Contraseña</h3>
                                </div>
                                <div className="relative">
                                    <input
                                        type="password"
                                        name="password"
                                        className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[16px] py-[10px]"
                                        onChange={handleChange}
                                    />
                                    <div className="flex absolute inset-y-0 right-0 items-center pr-[20px] pointer-events-none txt-arena-white">
                                        <span className="text-[25px]"><IoIosKey /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="pt-[20px] scale-100 hover:scale-110 ease-in duration-150">
                                <button className="btn-arena-primary" type="submit">ACEPTAR</button>
                            </div>
                        </Fade>
                    </div>
                </form>
            </div>
        </>
    );
}

export default RegisterForm;