import Select from "../../components/select/Select";

const VenuesFilter = ({ arrStates, onResponse } : { arrStates: Array<any>, onResponse: any }) => {
    return (
        <div className="block">
            <div className="flex justify-center md:justify-end">
                <Select
                    className="w-[90vw] md:w-[250px] glassmorphism p-[10px] text-[14px] txt-arena-white rounded-md"
                    id="venueState-filter"
                    name="venueState-filter"
                    options={arrStates}
                    placeholder="Estado..."
                    onResponse={(opt: any) => { onResponse(arrStates[opt.target.value], "select"); }}
                />
            </div>
        </div>
    );
}

export default VenuesFilter;