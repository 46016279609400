import React, { useState, useEffect } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { HiPhotograph } from "react-icons/hi";
import { uploadImages } from "../../../firebase";
import { IDCompany } from "../../../global/global";
import { addVenue, updateVenue } from "../../../services/Venues";
import VenuesModel from "../../../models/Venues";
import PopNotify from "../../../components/Pop/PopNotify";
import Select from "../../../components/select/Select";
import Loading from "../../../components/loading/Loading";
import { BsPlusCircleFill } from "react-icons/bs";

interface StepData {
    step: string;
    status: boolean;
}

interface ImageData {
    name: string;
    file: any;
}

interface PopInfo {
    status: boolean,
    type: string
}

const VenuesForm = ({ states, cities, venElement, onRefresh } : { states: Array<any>, cities: any, venElement: any, onRefresh: any }) => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [citiesCurrent, setCitiesCurrent] = useState<string>("");
    const [imageUpload, setImageUpload] = useState<Array<ImageData>>([
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null }
    ]);

    const [steps, setSteps] = useState<Array<StepData>>([
        { step: "Step 1", status: true },
        { step: "Step 2", status: false },
        { step: "Step 3", status: false }
    ]);

    const [venues, setVenues] = useState({
        companyID: { value: `${IDCompany}`, isCorrect: true },
        venueID: { value: "", isCorrect: false },
        venueName: { value: "", isCorrect: false },
        street: { value: "", isCorrect: false },
        number: { value: "", isCorrect: false },
        interiorNumber: { value: "", isCorrect: false },
        neighborhood: { value: "", isCorrect: false },
        city: { value: "", isCorrect: false },
        state: { value: "", isCorrect: false },
        country: { value: "", isCorrect: false },
        zipCode: { value: "", isCorrect: false },
        venueContactMail: { value: "", isCorrect: false },
        venueWorkingHours: { value: "", isCorrect: false },
        venueContactPhone: { value: "", isCorrect: false },
        venueMapURL: { value: "", isCorrect: false },
        venueImagesURL: { value: "", isCorrect: false },
        venueVideosURL: { value: "", isCorrect: false }
    });

    const nextStep = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        if ((evt.currentTarget.name === "Step 2" &&
            (
                !venues.venueName.isCorrect || !venues.state.isCorrect || !venues.city.isCorrect ||
                !venues.state.isCorrect || !venues.neighborhood.isCorrect || !venues.number.isCorrect
            ))
            ||
            (evt.currentTarget.name === "Step 3" &&
            (
                !venues.venueContactMail.isCorrect || !venues.venueContactPhone.isCorrect || !venues.venueWorkingHours.isCorrect
            ))
        ) {
            return 0;
        }

        const newStep = steps.map((stepElement, i) => {
            if (stepElement.step === evt.currentTarget.name) {
                return { ...stepElement, status: true };
            } else {
                return { ...stepElement, status: false };
            }
        });
        setSteps(newStep);
    }

    const deleteArrImage = (index: number) => {
        const newImageUpload = imageUpload.map((imgEle: ImageData, i: number) => {
            if (index === i) {
                return { name: "", file: null }
            } else {
                return { name: imgEle.name, file: imgEle.file }
            }
        });
        setImageUpload(newImageUpload);
    }

    const onResponseSelect = (opt: any) => {
        let arrCurrent: Array<Object> = [];
        
        if (opt.target.name === "state") {
            arrCurrent = states;
            setCitiesCurrent(states[opt.target.value].value);
        } else if (opt.target.name === "city") {
            arrCurrent = cities[citiesCurrent];
        }
        setVenues({...venues, [opt.target.name]: { value: Object(arrCurrent[opt.target.value]).value, isCorrect: true }});
    }

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setVenues({...venues, [evt.target.name]: { value: evt.target.value, isCorrect: true }});
    }

    const handleUpload = (evt: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = evt.currentTarget.files;
        if (file !== null) {
            const newImageUpload = imageUpload.map((imgEle: ImageData, i: number) => {
                if (index === i) {
                    return { name: file[0].name, file: file[0] }
                } else {
                    return { name: imgEle.name, file: imgEle.file }
                }
            });
            setImageUpload(newImageUpload);
        }
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (
            venues.venueName.isCorrect && venues.street.isCorrect && venues.neighborhood.isCorrect && venues.city.isCorrect &&
            venues.number.isCorrect && venues.state.isCorrect && venues.venueContactMail.isCorrect && venues.venueMapURL.isCorrect &&
            venues.venueContactPhone.isCorrect && venues.venueVideosURL.isCorrect && venues.venueWorkingHours.isCorrect
        ) {
            setLoadingStatus(true);
            let newImgUplAux: Array<string> = [];
            for (let i = 0; i < imageUpload.length; i++) {
                let imageUrlStr: any = "";
                if (imageUpload[i].file !== null) {
                    imageUrlStr = await uploadImages(imageUpload[i].file, "venues");
                } else if (imageUpload[i].name !== "") {
                    imageUrlStr = imageUpload[i].name;
                }
                newImgUplAux.push(imageUrlStr);
            }

            const venuesData: VenuesModel = {
                companyID: `${IDCompany}`,
                venueID: venElement ? venElement.venueID : "",
                venueName: venues.venueName.value,
                venueAddress : {
                    street: venues.street.value,
                    number: venues.number.value,
                    interiorNumber: venues.interiorNumber.value,
                    neighborhood: venues.neighborhood.value,
                    city: venues.city.value,
                    state: venues.state.value,
                    country: "México",
                    zipCode: venues.zipCode.value
                },
                venueContactMail: venues.venueContactMail.value,
                venueWorkingHours: venues.venueWorkingHours.value,
                venueContactPhone: venues.venueContactPhone.value,
                venueMapURL: venues.venueMapURL.value,
                venueImagesURL: newImgUplAux,
                venueVideosURL: venElement ? venElement.venueVideosURL : [venues.venueVideosURL.value],
            }
            new Promise<void>((resolve, reject) => {
                if (venElement !== null) {
                    updateVenue(venuesData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data) {
                            setPopStatus({...popStatus, status: true, type: "success"});
                            onRefresh(true);
                        } else {
                            setPopStatus({...popStatus, status: true, type: "error"});
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                } else {
                    addVenue(venuesData).then(res => {
                        setLoadingStatus(false);
                        if (res && !res.data) {
                            onRefresh(true);
                            setPopStatus({...popStatus, status: true, type: "success"});
                            setImageUpload([
                                { name: "", file: null },
                                { name: "", file: null },
                                { name: "", file: null },
                                { name: "", file: null }
                            ]);
                            setVenues({...venues,
                                companyID: { value: `${IDCompany}`, isCorrect: true },
                                venueID: { value: "", isCorrect: false },
                                venueName: { value: "", isCorrect: false },
                                street: { value: "", isCorrect: false },
                                number: { value: "", isCorrect: false },
                                interiorNumber: { value: "", isCorrect: false },
                                neighborhood: { value: "", isCorrect: false },
                                city: { value: "", isCorrect: false },
                                state: { value: "", isCorrect: false },
                                country: { value: "", isCorrect: false },
                                zipCode: { value: "", isCorrect: false },
                                venueContactMail: { value: "", isCorrect: false },
                                venueWorkingHours: { value: "", isCorrect: false },
                                venueContactPhone: { value: "", isCorrect: false },
                                venueMapURL: { value: "", isCorrect: false },
                                venueImagesURL: { value: "", isCorrect: false },
                                venueVideosURL: { value: "", isCorrect: false }
                            });
                        } else {
                            setPopStatus({...popStatus, status: true, type: "error"});
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                }
            });
        } else {
            setPopStatus({...popStatus, status: true, type: "warning"});
        }
    }

    useEffect(() => {
        if (venElement !== null) {
            const newRealese = {
                companyID: { value: `${IDCompany}`, isCorrect: true },
                venueName: { value: venElement.venueName, isCorrect: true },
                venueID: { value: venElement.venueID, isCorrect: true },
                street: { value: venElement.venueAddress.street, isCorrect: true },
                number: { value: venElement.venueAddress.number, isCorrect: true },
                interiorNumber: { value: venElement.venueAddress.interiorNumber, isCorrect: true },
                neighborhood: { value: venElement.venueAddress.neighborhood, isCorrect: true },
                city: { value: venElement.venueAddress.city, isCorrect: true },
                state: { value: venElement.venueAddress.state, isCorrect: true },
                country: { value: venElement.venueAddress.country, isCorrect: true },
                zipCode: { value: venElement.venueAddress.zipCode, isCorrect: true },
                venueContactMail: { value: venElement.venueContactMail, isCorrect: true },
                venueWorkingHours: { value: venElement.venueWorkingHours, isCorrect: true },
                venueContactPhone: { value: venElement.venueContactPhone, isCorrect: true },
                venueMapURL: { value: venElement.venueMapURL, isCorrect: true },
                venueImagesURL: { value: venElement.venueImagesURL, isCorrect: true },
                venueVideosURL: { value: venElement.venueVideosURL, isCorrect: true }
            }
            setVenues(newRealese);
            setCitiesCurrent(venElement.venueAddress.state);
            
            // Se almacenan las imagenes en un estado para manipular en caso que se desee editar
            let arrImgInput: Array<ImageData> = []; 
            for (let i = 0; i < 4; i++) {
                if (venElement.venueImagesURL[i]) {
                    arrImgInput.push({ name: venElement.venueImagesURL[i], file: null });
                } else {
                    arrImgInput.push({ name: "", file: null });
                }
            }
            setImageUpload(arrImgInput);

            // Se identifican los campos
            let venueStateInput = document.getElementById("stateInput") as HTMLSelectElement | null;
            let venueCityInput = document.getElementById("cityInput") as HTMLSelectElement | null;
            
            // Se ingresan los valores
            if (venueStateInput !== null) venueStateInput.value = `${states.findIndex((el, i) => el.value === venElement.venueAddress.state)}`;
            if (venueCityInput !== null) venueCityInput.value = `${cities[venElement.venueAddress.state].findIndex((el: any, i: number) => el.value === venElement.venueAddress.city)}`;
        }
    }, [venElement]);

    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="block">
                <form onSubmit={handleSubmit}>
                    <div className="px-2 text-left">
                        {/* Primera parte del formulario */}
                        {
                            steps[0].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[290px] bg-zinc-300 border border-zinc-300"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-zinc-300 rounded-full">
                                                <span className="text-[18px] text-zinc-300 font-bold">2</span>
                                            </div>
                                            <div className="w-[290px] bg-zinc-300 border border-zinc-300"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-zinc-300 rounded-full">
                                                <span className="text-[18px] text-zinc-300 font-bold">3</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-zinc-300 font-bold">Contacto</span>
                                            <span className="text-[12px] text-zinc-300 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="space-y-5">
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Nombre
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="venueNameInput"
                                                type="text"
                                                placeholder="Nombre..."
                                                name="venueName"
                                                onChange={handleChange}
                                                value={venues.venueName.value}
                                            />
                                        </div>
                                        <div className="md:grid md:grid-cols-2 md:gap-4">
                                            <div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="locationLabel">
                                                        Estado
                                                    </label>
                                                    <Select
                                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                        options={states}
                                                        onResponse={onResponseSelect}
                                                        name="state"
                                                        id="stateInput"
                                                        placeholder="Estado..."
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                        Calle
                                                    </label>
                                                    <input
                                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                        id="streetInput"
                                                        type="text"
                                                        placeholder="Calle..."
                                                        name="street"
                                                        onChange={handleChange}
                                                        value={venues.street.value}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                        Colonia
                                                    </label>
                                                    <input
                                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                        id="neighborhoodInput"
                                                        type="text"
                                                        placeholder="Colonia..."
                                                        name="neighborhood"
                                                        onChange={handleChange}
                                                        value={venues.neighborhood.value}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                        Delegación / municipio
                                                    </label>
                                                    <Select
                                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                        options={(citiesCurrent === "" || !cities[citiesCurrent]) ? [] : cities[citiesCurrent]}
                                                        onResponse={onResponseSelect}
                                                        name="city"
                                                        id="cityInput"
                                                        placeholder="Delegación o municipio..."
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                        Número
                                                    </label>
                                                    <input
                                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                        id="numberInput"
                                                        type="text"
                                                        placeholder="Número..."
                                                        name="number"
                                                        onChange={handleChange}
                                                        value={venues.number.value}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                        Código Postal
                                                    </label>
                                                    <input
                                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                        id="zipCodeInput"
                                                        type="text"
                                                        placeholder="Código postal..."
                                                        name="zipCode"
                                                        onChange={handleChange}
                                                        value={venues.zipCode.value}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-end pt-[5px]">
                                            <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" name="Step 2" type="button" onClick={nextStep}>
                                                Siguiente
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }

                        {/* Segunda parte del formulario */}
                        {
                            steps[1].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[290px] bg-orange-600 border border-orange-600"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">2</span>
                                            </div>
                                            <div className="w-[290px] bg-zinc-300 border border-zinc-300"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-zinc-300 rounded-full">
                                                <span className="text-[18px] text-zinc-300 font-bold">3</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-orange-600 font-bold">Contacto</span>
                                            <span className="text-[12px] text-zinc-300 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="space-y-5">
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Correo electronico del encargado
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="venueContactMailInput"
                                                type="text"
                                                placeholder="Correo electronico..."
                                                name="venueContactMail"
                                                onChange={handleChange}
                                                value={venues.venueContactMail.value}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Número de telefono
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="venueContactPhoneInput"
                                                type="text"
                                                placeholder="Telefono..."
                                                name="venueContactPhone"
                                                onChange={handleChange}
                                                value={venues.venueContactPhone.value}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Horario sucursal <span className="text-zinc-400">{'(E.g.: L-J 12 A 22 | V-S 12 - 23 | D 12 -22)'}</span>
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="venueWorkingHoursInput"
                                                type="text"
                                                placeholder="Horario..."
                                                name="venueWorkingHours"
                                                onChange={handleChange}
                                                value={venues.venueWorkingHours.value}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex justify-end pt-[5px]">
                                        <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" name="Step 3" type="button" onClick={nextStep}>
                                            Siguiente
                                        </button>
                                    </div>
                                </div>
                            : null
                        }

                        {/* Tercera parte del formulario */}
                        {
                            steps[2].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[290px] bg-orange-600 border border-orange-600"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">2</span>
                                            </div>
                                            <div className="w-[290px] bg-orange-600 border border-orange-600"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">3</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-orange-600 font-bold">Contacto</span>
                                            <span className="text-[12px] text-orange-600 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="mb-4 space-y-2">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Imagen <span className="text-zinc-400">{'Resolución recomendada 760x385 (máximo 4 imagenes)'}</span>
                                            </label>
                                            <div className="flex items-center justify-center w-full">
                                                <label className="w-full h-12 border-4 border-dashed">
                                                    <div className="flex space-x-2 p-2">
                                                        <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                        <div>
                                                            { imageUpload[0].name ?
                                                                <div className="flex">
                                                                    <span
                                                                        className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                                    >
                                                                        {imageUpload[0].name}
                                                                    </span>
                                                                    <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteArrImage(0)}>
                                                                        <AiFillCloseCircle />
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="flex">
                                                                    <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                        Cargar imagen...
                                                                    </span>
                                                                    <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <input type="file" className="opacity-0" name="venueImagesURL" onChange={(evt: any) => handleUpload(evt, 0)} />
                                                </label>
                                            </div>
                                            <div className="flex items-center justify-center w-full">
                                                <label className="w-full h-12 border-4 border-dashed">
                                                    <div className="flex space-x-2 p-2">
                                                        <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                        <div>
                                                            { imageUpload[1].name ?
                                                                <div className="flex">
                                                                    <span
                                                                        className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                                    >
                                                                        {imageUpload[1].name}
                                                                    </span>
                                                                    <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteArrImage(1)}>
                                                                        <AiFillCloseCircle />
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="flex">
                                                                    <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                        Cargar imagen...
                                                                    </span>
                                                                    <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <input type="file" className="opacity-0" name="venueImagesURL" onChange={(evt: any) => handleUpload(evt, 1)} />
                                                </label>
                                            </div>
                                            <div className="flex items-center justify-center w-full">
                                                <label className="w-full h-12 border-4 border-dashed">
                                                    <div className="flex space-x-2 p-2">
                                                        <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                        <div>
                                                            { imageUpload[2].name ?
                                                                <div className="flex">
                                                                    <span
                                                                        className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                                    >
                                                                        {imageUpload[2].name}
                                                                    </span>
                                                                    <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteArrImage(2)}>
                                                                        <AiFillCloseCircle />
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="flex">
                                                                    <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                        Cargar imagen...
                                                                    </span>
                                                                    <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <input type="file" className="opacity-0" name="venueImagesURL" onChange={(evt: any) => handleUpload(evt, 2)} />
                                                </label>
                                            </div>
                                            <div className="flex items-center justify-center w-full">
                                                <label className="w-full h-12 border-4 border-dashed">
                                                    <div className="flex space-x-2 p-2">
                                                        <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                        <div>
                                                            { imageUpload[3].name ?
                                                                <div className="flex">
                                                                    <span
                                                                        className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                                    >
                                                                        {imageUpload[3].name}
                                                                    </span>
                                                                    <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteArrImage(3)}>
                                                                        <AiFillCloseCircle />
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="flex">
                                                                    <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                        Cargar imagen...
                                                                    </span>
                                                                    <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <input type="file" className="opacity-0" name="venueImagesURL" onChange={(evt: any) => handleUpload(evt, 3)} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                URL mapa sucursal
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="venueMapURLInput"
                                                type="text"
                                                placeholder="URL Mapa..."
                                                name="venueMapURL"
                                                onChange={handleChange}
                                                value={venues.venueMapURL.value}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                URL video sucursal
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="venueVideosURLInput"
                                                type="text"
                                                placeholder="URL Video..."
                                                name="venueVideosURL"
                                                onChange={handleChange}
                                                value={venues.venueVideosURL.value}
                                            />
                                        </div>
                                        <div className="flex justify-end pt-[5px]">
                                            <button className="bg-arena-orange px-[60px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" type="submit">
                                                { venElement ? "Guardar cambios" : "Añadir sucursal" }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </form>
            </div>
        </>
    );
}

export default VenuesForm;