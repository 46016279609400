import { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { Fade } from "react-awesome-reveal";
import IconThrophie from "../../assets/icons/tournaments/02_icon_drops.png";
import TournamentDropModel from "../../models/TournamentDrop";

interface PageInfo {
    page: number,
    elements: number,
    firstIndex: number,
    lastIndex: number
}

const TournamentsDrops = ({ drops, onModalDrop } : { drops: Array<TournamentDropModel>, onModalDrop: any }) => {
    const [pagination, setPagination] = useState<PageInfo>({
        page: 1,
        elements: 3,
        firstIndex: 0,
        lastIndex: 2
    });

    const onChangePage = (place: string) => {
        if (place === "right" && (drops.length > (pagination.page * pagination.elements))) {
            setPagination({...pagination,
                page: pagination.page + 1,
                elements: pagination.elements,
                firstIndex: pagination.firstIndex + pagination.elements,
                lastIndex: pagination.lastIndex + pagination.elements
            });
        } else if (place === "left" && pagination.page > 1 ) {
            setPagination({...pagination,
                page: pagination.page - 1,
                elements: pagination.elements,
                firstIndex: pagination.firstIndex - pagination.elements,
                lastIndex: pagination.lastIndex - pagination.elements
            });
        }
    }

    return (
        <>
            {/* Desktop */}
            <div className="relative hidden md:block">
                <div className="glassmorphism pb-10">
                    <Fade cascade damping={0.2}>
                        <div className="px-8 py-5">
                            <span className="input-group-addon"><img className="w-[55px] lg:w-[65px]" src={IconThrophie} /></span>
                        </div>
                        <div className="text-left text-white px-10">
                            <h3 className="text-[20px] font-bold pb-2">Drops by Arena Movistar</h3>
                            <div className="w-[80%] lg:w-[50%]">
                                <p className="text-[16px]">
                                    Forma parte de la comunidad Arena Movistar siguiéndonos en todos nuestros canales, participa en las 
                                    diferentes dinámicas y gana grandes premios.
                                </p>
                            </div>
                        </div>
                    </Fade>
                </div>

                <div className="pt-7 pb-[80px]">
                    <div>
                        <button
                            className="scale-100 hover:scale-110 ease-in duration-150 absolute bg-transparent top-[400px] left-[-50px] txt-arena-white z-30"
                            type="button"
                            onClick={() => onChangePage("left")}
                        >
                            <span className="text-[50px]"><MdOutlineArrowBackIosNew /></span>
                        </button>
                        <button
                            className="scale-100 hover:scale-110 ease-in duration-150 absolute bg-transparent top-[400px] right-[-50px] txt-arena-white z-30"
                            type="button"
                            onClick={() => onChangePage("right")}
                        >
                            <span className="text-[50px]"><MdOutlineArrowForwardIos /></span>
                        </button>
                    </div>
                    <div className="grid grid-cols-3 space-x-3">
                        {
                            drops.map((dropElement: TournamentDropModel, i: number) => {
                                if (i >= pagination.firstIndex && i <= pagination.lastIndex) {
                                    return (
                                        <div key={i}>
                                            <Fade>
                                                <button
                                                    className="w-[215px] h-[215px] lg:w-[300px] lg:h-[300px] scale-95 hover:scale-100 ease-in duration-150"
                                                    type="button"
                                                    onClick={() => {
                                                        if (dropElement.dropExternalURL && dropElement.dropExternalURL !== "") {
                                                            let win = window.open(dropElement.dropExternalURL, '_blank');
                                                            if (win !== null) {
                                                                win.focus();
                                                            }
                                                        } else {
                                                            onModalDrop(true, dropElement)
                                                        }
                                                    }}
                                                >
                                                    <img className="w-[215px] h-auto lg:w-[300px] img-boxs rounded" src={dropElement.dropImageURL} />
                                                    <div className="flex justify-between items-center bg-arena-black txt-arena-white text-left">
                                                        <p className="text-[14px] pl-3">Reglamento y premios</p>
                                                        <span className="text-[30px] py-2 pr-3"><AiOutlineArrowRight /></span>
                                                    </div>
                                                </button>
                                            </Fade>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div className="glassmorphism pb-[5vw] px-[5vw]">
                    <Fade cascade damping={0.2}>
                        <div className="py-[5vw]">
                            <span className="input-group-addon"><img className="w-[15vw]" src={IconThrophie} /></span>
                        </div>
                        <div className="text-left text-white">
                            <h3 className="text-[5vw] font-bold pb-[2vw]">Drops by Arena Movistar</h3>
                            <p className="text-[4vw]">
                                Forma parte de la comunidad Arena Movistar siguiéndonos en todos nuestros canales, participa en las 
                                diferentes dinámicas y gana grandes premios.
                            </p>
                        </div>
                    </Fade>
                    <div className="mt-[5vw]">
                        <div className="body-carrousel-dt">
                            <div className="slider-carrousel-dt">
                                <div className="slides-carrousel-dt">
                                    {
                                        drops.map((dropElement: TournamentDropModel, i: number) => {
                                            return (
                                                <div key={i}>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (dropElement.dropExternalURL && dropElement.dropExternalURL !== "") {
                                                                let win = window.open(dropElement.dropExternalURL, '_blank');
                                                                if (win !== null) {
                                                                    win.focus();
                                                                }
                                                            } else {
                                                                onModalDrop(true, dropElement)
                                                            }
                                                        }}
                                                    >
                                                        <div className="w-full grid content-center bg-arena-black rounded-lg">
                                                            <Fade>
                                                                <img className="w-[70vw] h-[75vw] rounded-lg" src={dropElement.dropImageURL} />
                                                            </Fade>
                                                        </div>
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center space-x-1">
                            <button type="button" className="w-2 h-2 bg-arena-white rounded-full"></button>
                            <button type="button" className="w-2 h-2 bg-arena-white rounded-full"></button>
                            <button type="button" className="w-2 h-2 bg-arena-white rounded-full"></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TournamentsDrops;