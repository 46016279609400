import { useState, useEffect } from "react";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Fade } from "react-awesome-reveal";
import TournamentDropModel from "../../models/TournamentDrop";

const HomepageDrops = ({ drops, onModalDrop } : { drops: Array<TournamentDropModel>, onModalDrop: any }) => {
    const onClickLeft = () => {
        let strContainer: string = "more-drops-container";
        if (window.innerWidth >= 300 && window.innerWidth <= 400) {
            strContainer = "more-drops-containerm";
        }

        const carrousel = document.getElementById(strContainer);
        if (carrousel !== null) {
            carrousel.scrollLeft -= 370;
        }
    }

    const onClickRight = () => {
        let strContainer: string = "more-drops-container";
        if (window.innerWidth >= 300 && window.innerWidth <= 400) {
            strContainer = "more-drops-containerm";
        }

        const carrousel = document.getElementById(strContainer);
        if (carrousel !== null) {
            carrousel.scrollLeft += 370;
        }
    }

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="w-full text-left txt-arena-white font-bold px-[50px] py-[20px] m-auto">
                    <Fade><h3 className="text-[20px]">Últimas promociones y torneos</h3></Fade>
                </div>
                <div id="more-drops-body">
                    <div id="more-drops-container" className="w-[740px] flex overflow-hidden space-x-[20px] m-auto">
                        <div className="flex items-center">
                            <button
                                className="scale-100 hover:scale-110 ease-in duration-150 absolute bg-arena-white txt-arena-gray left-[5%] lg:left-[-20px] px-[20px] py-[20px] rounded-full z-30"
                                type="button"
                                onClick={onClickLeft}
                            >
                                <span className="text-[20px]"><AiOutlineArrowLeft /></span>
                            </button>
                            <button
                                className="scale-100 hover:scale-110 ease-in duration-150 absolute bg-arena-white txt-arena-gray right-[5%] lg:right-[-20px] px-[20px] py-[20px] rounded-full z-30"
                                type="button"
                                onClick={onClickRight}
                            >
                                <span className="text-[20px]"><AiOutlineArrowRight /></span>
                            </button>
                        </div>
                        {
                            drops.map((dropElement: TournamentDropModel, i: number) => {
                                return(
                                    <div className="flex-shrink-0 px-0" key={i}>
                                        <Fade>
                                            <button
                                                className="w-[350px] h-[350px] bg-arena-black scale-95 hover:scale-100 ease-in duration-150 rounded-md"
                                                type="button"
                                                onClick={() => {
                                                    if (dropElement.dropExternalURL && dropElement.dropExternalURL !== "") {
                                                        let win = window.open(dropElement.dropExternalURL, '_blank');
                                                        if (win !== null) {
                                                            win.focus();
                                                        }
                                                    } else {
                                                        onModalDrop(true, dropElement)
                                                    }
                                                }}
                                            >
                                                <div className="absolute top-0 bg-arena-primary ml-[15px] mt-[10px] rounded-md">
                                                    <span className="px-[15px] py-[5px] txt-arena-white text-bold text-[14px]">{dropElement.dropType}</span>
                                                </div>
                                                <img className="w-[350px] h-auto rounded-md" src={dropElement.dropImageURL} />
                                            </button>
                                        </Fade>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden marginNegative400px">
                <div className="my-[25vw] px-[10vw] space-y-[8vw]">
                    <div className="text-left txt-arena-white font-bold">
                        <Fade><h3 className="text-[5vw]">Últimas promociones</h3></Fade>
                    </div>
                    <div>
                        <div className="body-carrousel-dt">
                            <div className="slider-carrousel-dt">
                                <div className="slides-carrousel-dt">
                                    {
                                        drops.map((dropElement: TournamentDropModel, i: number) => {
                                            return (
                                                <div className="relative" key={i}>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            if (dropElement.dropExternalURL && dropElement.dropExternalURL !== "") {
                                                                let win = window.open(dropElement.dropExternalURL, '_blank');
                                                                if (win !== null) {
                                                                    win.focus();
                                                                }
                                                            } else {
                                                                onModalDrop(true, dropElement)
                                                            }
                                                        }}
                                                    >
                                                        <div className="w-[70vw] h-[75vw] grid content-center bg-arena-black rounded-lg">
                                                            <Fade>
                                                                <img className="w-[70vw] auto rounded-lg" src={dropElement.dropImageURL} />
                                                            </Fade>
                                                        </div>
                                                    </button>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center space-x-1">
                            <button type="button" className="w-2 h-2 bg-arena-white rounded-full"></button>
                            <button type="button" className="w-2 h-2 bg-arena-white rounded-full"></button>
                            <button type="button" className="w-2 h-2 bg-arena-white rounded-full"></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomepageDrops;