import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import Select from "../../../components/select/Select";
import VenuesModel from "../../../models/Venues";
import VenuesPop from "./VenuesPop";
import VenuesTable from "./VenuesTable";

interface FilterData {
    inputType: string;
    inputName: string;
    word: string;
}

const VenuesCard = ({
    venues,
    states,
    cities,
    status,
    onRefresh
} : {
    venues: Array<VenuesModel>,
    states: Array<any>,
    cities: any,
    status: Array<any>,
    onRefresh: any
}) => {
    const [statusModal, setStatusModal] = useState<boolean>(false);
    const [arrVenues, setArrVenues] = useState<Array<VenuesModel>>([]);
    const [venuesUniv, setVenuesUniv] = useState<Array<VenuesModel>>([]);

    const filter = (type: FilterData) => {
        if (type.inputType === "input") {
            const filter1 = document.getElementById("filter-avstates") as HTMLSelectElement | null;

            if (filter1 !== null) filter1.value = "default";

            let palabra = new RegExp(`${type.word}.*`, "i");
            let newVenues = venuesUniv.filter((venuesElement) => palabra.test(venuesElement.venueName));
            setArrVenues(newVenues);
        } else {
            if (type.inputName === "state") {
                const filter1 = document.getElementById("filter-avstates") as HTMLInputElement | null;

                if (filter1 !== null) filter1.value = "";

                const venuesFound = venuesUniv.filter((venuesElement) => (venuesElement.venueAddress.state === type.word));
                setArrVenues(venuesFound);
            }
        }
    }

    useEffect(() => {
        setArrVenues(venues);
        setVenuesUniv(venues);
    }, [venues]);

    return (
        <>
            {
                statusModal ?
                    <VenuesPop
                        onRefresh={onRefresh}
                        states={states}
                        cities={cities}
                        venElement={null}
                        onResponse={() => {setStatusModal(false) }}
                    />
                : null
            }

            <div className="block">
                <div className="bg-arena-white m-[20px] md:m-[25px] space-y-[25px] px-[15px] py-[15px] md:space-y-[35px] md:px-[35px] md:py-[30px] rounded">
                    <div className="text-left font-bold">
                        <h3 className="text-[20px]">Sucursales</h3>
                    </div>
                    <div className="flex space-x-[10px]">
                        <button className="flex items-center bg-arena-orange py-[5px] px-[15px] space-x-[40px] rounded-sm" type="button" onClick={() => { setStatusModal(true) }}>
                            <div className="text-left txt-arena-white font-bold">
                                <span className="text-[14px]">Añadir sucursal</span>
                            </div>
                            <div className="txt-arena-white font-bold">
                                <span className="text-[20px]"><AiOutlinePlus /></span>
                            </div>
                        </button>
                    </div>
                    <div>
                        <div className="space-y-[20px] lg:flex lg:space-x-[10px] lg:space-y-0">
                            <div className="relative">
                                <input
                                    className="bg-transparent border-b border-zinc-500 py-[8px] px-0 md:px-[10px] w-full lg:w-[250px] txt-arena-gray text-[14px]"
                                    type="text"
                                    id="filter-vtext"
                                    placeholder="Buscar sucursal"
                                    onChange={(wrd) => { filter({ inputType: "input", inputName: "venueName", word: `${wrd.target.value}` }) }}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-[8px] flex items-center">
                                    <span className="txt-arena-gray text-[20px]"><BiSearch /></span>
                                </div>
                            </div>
                            <div>
                                <Select
                                    className="bg-transparent border-b border-zinc-500 py-[10px] px-0 md:px-[10px] w-full lg:w-[250px] txt-arena-gray text-[14px]"
                                    options={states}
                                    onResponse={(wrd: any) => { filter({ inputType: "select", inputName: "state", word: `${states[wrd.target.value].value}` }) }}
                                    name="filter-avstates"
                                    id="filter-avstates"
                                    placeholder="Filtrar por Estado"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <VenuesTable onRefresh={onRefresh} states={states} cities={cities} venues={arrVenues} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default VenuesCard;