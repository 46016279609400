export function effectButton() {
    // Buttons Desktop
    const boton1 = document.getElementById("btn-cb-1");
    const boton2 = document.getElementById("btn-cb-2");
    const boton3 = document.getElementById("btn-cb-3");
    const boton4 = document.getElementById("btn-cb-4");
    const boton5 = document.getElementById("btn-cb-5");

    if (boton1 !== null && boton2 !== null && boton3 !== null && boton4 !== null && boton5 !== null) {
        boton1.addEventListener("click", function(){
            if (boton2.classList.contains("active")) boton2.classList.remove("active");
            if (boton3.classList.contains("active")) boton3.classList.remove("active");
            if (boton4.classList.contains("active")) boton4.classList.remove("active");
            if (boton5.classList.contains("active")) boton5.classList.remove("active");
            if(!boton1.classList.contains("active")) boton1.classList.toggle("active");
        });

        boton2.addEventListener("click", function(){
            if(boton1.classList.contains("active")) boton1.classList.remove("active");
            if(boton3.classList.contains("active")) boton3.classList.remove("active");
            if(boton4.classList.contains("active")) boton4.classList.remove("active");
            if(boton5.classList.contains("active")) boton5.classList.remove("active");
            if(!boton2.classList.contains("active")) boton2.classList.toggle("active");
        });

        boton3.addEventListener("click", function(){
            if(boton1.classList.contains("active")) boton1.classList.remove("active");
            if(boton2.classList.contains("active")) boton2.classList.remove("active");
            if(boton4.classList.contains("active")) boton4.classList.remove("active");
            if(boton5.classList.contains("active")) boton5.classList.remove("active");
            if(!boton3.classList.contains("active")) boton3.classList.toggle("active");
        });

        boton4.addEventListener("click", function(){
            if(boton1.classList.contains("active")) boton1.classList.remove("active");
            if(boton2.classList.contains("active")) boton2.classList.remove("active");
            if(boton3.classList.contains("active")) boton3.classList.remove("active");
            if(boton5.classList.contains("active")) boton5.classList.remove("active");
            if(!boton4.classList.contains("active")) boton4.classList.toggle("active");
        });

        boton5.addEventListener("click", function(){
            if(boton1.classList.contains("active")) boton1.classList.remove("active");
            if(boton2.classList.contains("active")) boton2.classList.remove("active");
            if(boton3.classList.contains("active")) boton3.classList.remove("active");
            if(boton4.classList.contains("active")) boton4.classList.remove("active");
            if(!boton5.classList.contains("active")) boton5.classList.toggle("active");
        });
    }


    // Buttons mobile
    const boton1m = document.getElementById("btn-cbm-1");
    const boton2m = document.getElementById("btn-cbm-2");
    const boton3m = document.getElementById("btn-cbm-3");
    const boton4m = document.getElementById("btn-cbm-4");
    const boton5m = document.getElementById("btn-cbm-5");

    if (boton1m !== null && boton2m !== null && boton3m !== null && boton4m !== null && boton5m !== null) {
        boton1m.addEventListener("click", function(){
            if (boton2m.classList.contains("active")) boton2m.classList.remove("active");
            if (boton3m.classList.contains("active")) boton3m.classList.remove("active");
            if (boton4m.classList.contains("active")) boton4m.classList.remove("active");
            if (boton5m.classList.contains("active")) boton5m.classList.remove("active");
            if(!boton1m.classList.contains("active")) boton1m.classList.toggle("active");
        });

        boton2m.addEventListener("click", function(){
            if(boton1m.classList.contains("active")) boton1m.classList.remove("active");
            if(boton3m.classList.contains("active")) boton3m.classList.remove("active");
            if(boton4m.classList.contains("active")) boton4m.classList.remove("active");
            if(boton5m.classList.contains("active")) boton5m.classList.remove("active");
            if(!boton2m.classList.contains("active")) boton2m.classList.toggle("active");
        });

        boton3m.addEventListener("click", function(){
            if(boton1m.classList.contains("active")) boton1m.classList.remove("active");
            if(boton2m.classList.contains("active")) boton2m.classList.remove("active");
            if(boton4m.classList.contains("active")) boton4m.classList.remove("active");
            if(boton5m.classList.contains("active")) boton5m.classList.remove("active");
            if(!boton3m.classList.contains("active")) boton3m.classList.toggle("active");
        });

        boton4m.addEventListener("click", function(){
            if(boton1m.classList.contains("active")) boton1m.classList.remove("active");
            if(boton2m.classList.contains("active")) boton2m.classList.remove("active");
            if(boton3m.classList.contains("active")) boton3m.classList.remove("active");
            if(boton5m.classList.contains("active")) boton5m.classList.remove("active");
            if(!boton4m.classList.contains("active")) boton4m.classList.toggle("active");
        });

        boton5m.addEventListener("click", function(){
            if(boton1m.classList.contains("active")) boton1m.classList.remove("active");
            if(boton2m.classList.contains("active")) boton2m.classList.remove("active");
            if(boton3m.classList.contains("active")) boton3m.classList.remove("active");
            if(boton4m.classList.contains("active")) boton4m.classList.remove("active");
            if(!boton5m.classList.contains("active")) boton5m.classList.toggle("active");
        });
    }
}