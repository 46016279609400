import { AiOutlineArrowRight } from "react-icons/ai";
import { Fade } from "react-awesome-reveal";
import IconTwitter from "../../assets/icons/13_twitter_arena.svg";
import IconInstagram from "../../assets/icons/14_instagram_arena.svg";
import IconFacebook from "../../assets/icons/15_facebook_arena.svg";

const HomepageChannels = () => {
    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="w-[400px] text-left py-[30px] txt-arena-white font-bold">
                    <Fade>
                        <h3 className="text-[25px]">Búscanos en Nuestros diferentes canales</h3>
                    </Fade>
                </div>
                <div className="space-y-[10px] lg:space-y-0 lg:flex lg:space-x-[15px]">
                    <div className="glassmorphism p-[10px] w-[500px] lg:w-[350px] scale-100 hover:scale-105 ease-in duration-150">
                        <a className="text-left txt-arena-white font-bold" href="https://facebook.com/ArenaThePlaceToPlay" target="_blank">
                            <div className="flex justify-between items-center w-[100%] m-auto">
                                <Fade>
                                    <div className="w-[90%] flex items-center space-x-[15px]">
                                        <img className="w-[35px]" src={IconTwitter} />
                                        <span className="text-[14px] font-bold">@arenaplace2play</span>
                                    </div>
                                    <span className="w-[10%] text-[25px]"><AiOutlineArrowRight /></span>
                                </Fade>
                            </div>
                        </a>
                    </div>

                    <div className="glassmorphism p-[10px] w-[500px] lg:w-[350px] scale-100 hover:scale-105 ease-in duration-150">
                        <a className="text-left txt-arena-white font-bold" href="https://instagram.com/arenatheplacetoplay" target="_blank">
                            <div className="flex justify-between items-center w-[100%] m-auto">
                                <Fade>
                                    <div className="w-[90%] flex items-center space-x-[15px]">
                                        <img className="w-[35px]" src={IconInstagram} />
                                        <span className="text-[14px] font-bold">@arenatheplacetoplay</span>
                                    </div>
                                    <span className="w-[10%] text-[25px]"><AiOutlineArrowRight /></span>
                                </Fade>
                            </div>
                        </a>
                    </div>

                    <div className="glassmorphism p-[10px] w-[500px] lg:w-[350px] scale-100 hover:scale-105 ease-in duration-150">
                        <a className="text-left txt-arena-white font-bold" href="https://twitter.com/arenaplacetplay" target="_blank">
                            <div className="flex justify-between items-center w-[100%] m-auto">
                                <Fade>
                                    <div className="w-[90%] flex items-center space-x-[15px]">
                                        <img className="w-[35px]" src={IconFacebook} />
                                        <span className="text-[14px] font-bold">@ArenaThePlaceToPlay</span>
                                    </div>
                                    <span className="w-[10%] text-[25px]"><AiOutlineArrowRight /></span>
                                </Fade>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden pt-[15vw]">
                <div className="space-y-[2vw]">
                    <div className="w-[60vw] mt-[8vw] text-left py-[2vw] txt-arena-white font-bold">
                        <Fade>
                            <h3 className="text-[4vw]">Búscanos en nuestros diferentes canales</h3>
                        </Fade>
                    </div>
                    <div className="glassmorphism p-[2vw] w-full">
                        <a className="text-left txt-arena-white font-bold" href="https://facebook.com/ArenaThePlaceToPlay" target="_blank">
                            <div className="flex items-center w-[95%] m-auto">
                                <Fade>
                                    <div className="w-[90%] flex items-center space-x-[3vw]">
                                        <img className="w-[10vw]" src={IconTwitter} />
                                        <span className="text-[4vw] font-bold">@arenaplace2play</span>
                                    </div>
                                    <span className="w-[10%] text-[8vw]"><AiOutlineArrowRight /></span>
                                </Fade>
                            </div>
                        </a>
                    </div>
                    <div className="glassmorphism p-[2vw] w-full">
                        <a className="text-left txt-arena-white font-bold" href="https://instagram.com/arenatheplacetoplay" target="_blank">
                            <div className="flex items-center w-[95%] m-auto">
                                <Fade>
                                    <div className="w-[90%] flex items-center space-x-[3vw]">
                                        <img className="w-[10vw]" src={IconInstagram} />
                                        <span className="text-[4vw] font-bold">@arenatheplacetoplay</span>
                                    </div>
                                    <span className="w-[10%] text-[8vw]"><AiOutlineArrowRight /></span>
                                </Fade>
                            </div>
                        </a>
                    </div>
                    <div className="glassmorphism p-[2vw] w-full">
                        <a className="text-left txt-arena-white font-bold" href="https://twitter.com/arenaplacetplay" target="_blank">
                            <div className="flex items-center w-[95%] m-auto">
                                <Fade>
                                    <div className="w-[90%] flex items-center space-x-[3vw]">
                                        <img className="w-[10vw]" src={IconFacebook} />
                                        <span className="text-[4vw] font-bold">@ArenaThePlaceToPlay</span>
                                    </div>
                                    <span className="w-[10%] text-[8vw]"><AiOutlineArrowRight /></span>
                                </Fade>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HomepageChannels;