import ImageSponsor1 from "../../assets/images/movistar.jpg";
import ImageSponsor2 from "../../assets/images/18_img_partners_2.png";
import { Fade } from "react-awesome-reveal";

const HomepageSponsor = () => {
    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block m-auto">
                <div className="md:w-[80%] lg:w-[55%] space-y-[15px]">
                    <Fade cascade damping={0.1}>
                        <div className="text-left txt-arena-white font-bold">
                            <h3 className="text-[25px]">Nuestros patrocinadores</h3>
                        </div>
                        <div className="text-left text-gray-400">
                            <p className="text-[20px]">
                                Hacemos alianzas con los mejores partners para traerte experiencias únicas y divertidas
                            </p>
                        </div>
                    </Fade>
                </div>
                <div className="space-y-[15px] lg:space-y-0 lg:flex lg:space-x-[35px] pt-[18px]">
                    <Fade cascade damping={0.1}>
                        <div className="relative scale-100 hover:scale-105 ease-in duration-150">
                            <a href="https://www.movistar.com.mx/" target="_blank">
                                <img className="w-[450px] h-auto rounded-md img-boxs" src={ImageSponsor1} />
                                <div className="w-[380px] absolute space-y-[8px] pb-[15px] pl-[15px] pr-[45px] bottom-0">
                                    <div className="text-left txt-arena-white font-bold">
                                        <h3 className="text-[16px]">Visita Movistar</h3>
                                    </div>
                                    <div className="text-left txt-arena-white">
                                        <p className="text-[14px]">
                                                                                    </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="relative scale-100 hover:scale-105 ease-in duration-150">
                            <a href="https://www.amd.com/es" target="_blank">
                                <img className="w-[450px] h-auto rounded-md img-boxs" src={ImageSponsor2} />
                                <div className="w-[380px] absolute space-y-[8px] pb-[15px] pl-[15px] pr-[45px] bottom-0">
                                    <div className="text-left txt-arena-white font-bold">
                                        <h3 className="text-[16px]">Visita la AMD ZONE</h3>
                                    </div>
                                    <div className="text-left txt-arena-white">
                                        <p className="text-[14px]">
                                            Las mejores PC's con AMD en todas nuestras sucursales. Te esperamos!
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Fade>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div className="w-full space-y-[2vw] py-[2vw]">
                    <Fade cascade damping={0.1}>
                        <div className="text-left txt-arena-white font-bold">
                            <h3 className="text-[5vw]">Nuestros patrocinadores</h3>
                        </div>
                        <div className="text-left text-gray-400">
                            <p className="text-[4w]">
                                Hacemos alianzas con los mejores partners para traerte experiencias únicas y divertidas
                            </p>
                        </div>
                    </Fade>
                </div>
                <div className="pt-[2vw] space-y-[3vw]">
                    <Fade cascade damping={0.1}>
                        <div className="relative">
                            <a href="https://betmaster.com.mx/es-mx/" target="_blank">
                                <img className="w-[90vw] h-[50vw] rounded-md img-boxs" src={ImageSponsor1} />
                                <div className="absolute space-y-[1vw] pb-[2vw] pl-[3vw] pr-[4vw] bottom-0">
                                    <div className="text-left txt-arena-white font-bold">
                                        <h3 className="text-[4.8vw]">Visita Betmaster</h3>
                                    </div>
                                    <div className="text-left txt-arena-white">
                                        <p className="text-[4vw]">
                                            Realiza apuestas en Esports, Fútbol, Vóleibol, Hockey, Baloncesto, Tenis, MMA, etc… Te esperamos!
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="relative">
                            <a href="https://www.amd.com/es" target="_blank">
                                <img className="w-[90vw] h-[50vw] rounded-md img-boxs" src={ImageSponsor2} />
                                <div className="absolute space-y-[1vw] pb-[2vw] pl-[3vw] pr-[4vw] bottom-0">
                                    <div className="text-left txt-arena-white font-bold">
                                        <h3 className="text-[4.8vw]">Visita la AMD ZONE</h3>
                                    </div>
                                    <div className="text-left txt-arena-white">
                                        <p className="text-[4vw]">
                                            Los mejores equipos, diversión y más. Disponible en el complejo ¡INTERLOMAS. Te esperamos!
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </Fade>
                </div>
            </div>
        </>
    );
}

export default HomepageSponsor;