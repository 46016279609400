import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import Select from "../../../components/select/Select";
import RealeseModel from "../../../models/Realese";
import RealesesPop from "./RealesesPop";
import RealesesTable from "./RealesesTable";

interface FilterData {
    inputType: string;
    inputName: string;
    word: string;
}

const RealesesCard = ({
        realeses,
        esrb,
        plataformas,
        stars,
        onRefresh
    } : {
        realeses: Array<RealeseModel>,
        esrb: Array<any>,
        plataformas: Array<any>,
        stars: Array<any>,
        onRefresh: any
    }) => {
    const [statusModal, setStatusModal] = useState<boolean>(false);
    const [arrRealeses, setArrRealeses] = useState<Array<RealeseModel>>([]);
    const [realesesUniv, setRealesesUniv] = useState<Array<RealeseModel>>([]);

    const filter = (type: FilterData) => {
        if (type.inputType === "input") {
            const filter1 = document.getElementById("filter-arplataform") as HTMLSelectElement | null;
            const filter2 = document.getElementById("filter-aresrb") as HTMLSelectElement | null;

            if (filter1 !== null) filter1.value = "default";
            if (filter2 !== null) filter2.value = "default";

            let palabra = new RegExp(`${type.word}.*`, "i");
            let newRealeses = realesesUniv.filter((realesesElement) => palabra.test(realesesElement.gameTitle));
            setArrRealeses(newRealeses);
        } else {
            if (type.inputName === "gamePlataform") {
                const filter1 = document.getElementById("filter-jtext") as HTMLInputElement | null;
                const filter2 = document.getElementById("filter-aresrb") as HTMLSelectElement | null;

                if (filter1 !== null) filter1.value = "";
                if (filter2 !== null) filter2.value = "default";

                const realesesFound = realesesUniv.filter((realesesElement) => (realesesElement.gamePlatform === type.word));
                setArrRealeses(realesesFound);
            } else if (type.inputName === "gameESRB") {
                const filter1 = document.getElementById("filter-jtext") as HTMLInputElement | null;
                const filter2 = document.getElementById("filter-arplataform") as HTMLSelectElement | null;

                if (filter1 !== null) filter1.value = "";
                if (filter2 !== null) filter2.value = "default";

                const realesesFound = realesesUniv.filter((realesesElement) => (realesesElement.gameESRB === type.word));
                setArrRealeses(realesesFound);
            }
        }
    }

    useEffect(() => {
        setArrRealeses(realeses);
        setRealesesUniv(realeses);
    }, [realeses]);

    return (
        <>
            {
                statusModal ?
                    <RealesesPop
                        stars={stars}
                        esrb={esrb}
                        plataforma={plataformas}
                        realese={null}
                        onResponse={() => {setStatusModal(false) }}
                        onRefresh={onRefresh}
                    />
                : null
            }

            <div className="block">
                <div className="bg-arena-white m-[20px] md:m-[25px] space-y-[25px] px-[15px] py-[15px] md:space-y-[35px] md:px-[35px] md:py-[30px] rounded">
                    <div className="text-left font-bold">
                        <h3 className="text-[20px]">Lanzamientos</h3>
                    </div>
                    <div className="flex space-x-[10px]">
                        <button className="flex items-center bg-arena-orange py-[5px] px-[15px] space-x-[40px] rounded-sm" type="button" onClick={() => { setStatusModal(true) }}>
                            <div className="text-left txt-arena-white font-bold">
                                <span className="text-[14px]">Añadir lanzamiento</span>
                            </div>
                            <div className="txt-arena-white font-bold">
                                <span className="text-[20px]"><AiOutlinePlus /></span>
                            </div>
                        </button>
                    </div>
                    <div>
                        <div className="space-y-[20px] lg:flex lg:space-x-[10px] lg:space-y-0">
                            <div className="relative">
                                <input
                                    className="bg-transparent border-b border-zinc-500 py-[8px] px-0 md:px-[10px] w-full lg:w-[200px] txt-arena-gray text-[14px]"
                                    type="text"
                                    id="filter-jtext"
                                    placeholder="Buscar juego"
                                    onChange={(wrd) => { filter({ inputType: "input", inputName: "gameTitle", word: `${wrd.target.value}` }) }}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-[8px] flex items-center">
                                    <span className="txt-arena-gray text-[20px]"><BiSearch /></span>
                                </div>
                            </div>
                            <div>
                                <Select
                                    className="bg-transparent border-b border-zinc-500 py-[10px] px-0 md:px-[10px] w-full lg:w-[200px] txt-arena-gray text-[14px]"
                                    options={plataformas}
                                    onResponse={(wrd: any) => { filter({ inputType: "select", inputName: "gamePlataform", word: `${plataformas[wrd.target.value].value}` }) }}
                                    name="filter-arplataform"
                                    id="filter-arplataform"
                                    placeholder="Filtrar por plataforma"
                                />
                            </div>
                            <div>
                                <Select
                                    className="bg-transparent border-b border-zinc-500 py-[10px] px-0 md:px-[10px] w-full lg:w-[200px] txt-arena-gray text-[14px]"
                                    options={esrb}
                                    onResponse={(wrd: any) => { filter({ inputType: "select", inputName: "gameESRB", word: `${esrb[wrd.target.value].value}` }) }}
                                    name="filter-aresrb"
                                    id="filter-aresrb"
                                    placeholder="Filtrar por ESRB"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <RealesesTable onRefresh={onRefresh} plataformas={plataformas} stars={stars} esrb={esrb} realeses={arrRealeses} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default RealesesCard;