import { useState, useEffect } from "react";
import { AiFillCloseCircle, AiFillWarning, AiOutlineClose } from "react-icons/ai";
import { BsFillCheckCircleFill } from "react-icons/bs";

interface PopData {
    icon: any;
    color: string,
    title: string;
    body: string;
}

const PopNotify = ({ type, onClose } : { type: string, onClose: any }) => {
    const [popData, setPopData] = useState<PopData>({
        icon: null,
        color: "",
        title: "",
        body: ""
    });

    // Obtiene los parametros dados por el usuario, y determina el tipo de notificación que mostrará en pantalla...
    useEffect(() => {
        if (type === "success") {
            setPopData({...popData,
                icon: <BsFillCheckCircleFill />,
                color: "text-green-600",
                title: "¡ÉXITO!",
                body: "Proceso finalizado correctamente"
            });
        } else if (type === "error") {
            setPopData({...popData,
                icon: <AiFillCloseCircle />,
                color: "text-red-600",
                title: "¡ERROR!",
                body: "Ha ocurrido un problema inesperado"
            });
        } else if (type === "warning") {
            setPopData({...popData,
                icon: <AiFillWarning />,
                color: "text-yellow-600",
                title: "¡ATENCIÓN!",
                body: "Favor de validar los datos ingresados"
            });
        }
        else if (type === "warningVideo") {
            setPopData({...popData,
                icon: <AiFillWarning />,
                color: "text-yellow-600",
                title: "¡ATENCIÓN!",
                body: "No puedes eliminar todos los videos"
            });
        }

        setInterval(() => {
            onClose(false);
        }, 5000);
    }, [type]);

    return (
        <>
            <div className="block">
                <div className="mt-[65px] mr-[10px] md:mr-[30px] justify-end flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[400px]">
                        <div className="p-[5px] md:p-[10px] border-0 rounded-lg shadow-lg flex-col w-full bg-arena-black opacity-75 outline-none focus:outline-none">
                            <div className="relative px-[8px] py-[10px] flex-auto">
                                <div className="flex justify-between items-center space-x-[15px]">
                                    <div className={popData.color}>
                                        <span className="text-[30px] md:text-[30px]">
                                            { popData.icon }
                                        </span>
                                    </div>
                                    <div className="txt-arena-white text-left">
                                        <div className="font-bold">
                                            <h3 className="text-[14px]">{ popData.title }</h3>
                                        </div>
                                        <div className="">
                                            <p className="text-[14px]">{ popData.body }</p>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="txt-arena-white text-[20px] rounded-sm"
                                            onClick={() => onClose(false)}
                                        >
                                            <AiOutlineClose />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PopNotify;