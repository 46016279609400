import { useMemo, useState, useEffect } from "react";
import Table from "../../../components/table/Table";
import styled from "styled-components";
import EventsModel from "../../../models/Events";
import EventsButtonsTable from "./EventsButtonsTable";

const Styled = styled.div`
    table {
        width: 950px;
        text-align: left;
    }

    table th {
        background-color: rgb(240, 240, 240);
        font-weight: bold;
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    table td {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    @media (max-width: 1350px) {
        table { width: 850px; }
    }

    @media (max-width: 1270px) {
        table { width: 750px; }
    }

    @media (max-width: 1180px) {
        table { width: 600px; }
    }

    @media (max-width: 1024px) {
        table { width: 800px; }
    }
`;

const EventsTable = ({ onRefresh, events, venues } : { onRefresh: any, events: Array<EventsModel>, venues: Array<any> }) => {
    const [eventsNew, setEventsNew] = useState<Array<any>>([]);

    const columns = useMemo(() => [
        {
            Header: 'Evento',
            accessor: 'eventName',
            width: '30%'
        },
        {
            Header: 'Sucursal',
            accessor: 'eventVenue',
            width: '30%'
        },
        {
            Header: 'Fecha',
            accessor: 'eventDate',
            width: '30%'
        },
        {
            Header: '',
            accessor: 'options',
            width: '10%'
        }
    ], []);

    useEffect(() => {
        const newArr = events.map((eventElement: EventsModel, i: number) => {
            return {
                companyID: eventElement.companyID,
                eventID: eventElement.eventID,
                eventName: eventElement.eventName,
                eventVenue:
                    eventElement.eventVenue.length > 1 ? `${eventElement.eventVenue.length} Sucursales` : eventElement.eventVenue,
                eventYear: eventElement.eventYear,
                eventDate: eventElement.eventDate,
                eventCreatedAt: eventElement.eventCreatedAt,
                options: <EventsButtonsTable onRefresh={onRefresh} eventElement={eventElement} venues={venues} />
            };
        });
        setEventsNew(newArr);
    }, [events]);

    return (
        <>
            <div className="block overflow-x-auto lg:overflow-x-visible">
                <Styled>
                    <Table columns={columns} data={eventsNew} />
                </Styled>
            </div>
        </>
    );
}

export default EventsTable;