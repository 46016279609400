import { useState, useEffect } from "react";
import { IDCompany } from "../../../global/global";
import { addEvents, updateEvent } from "../../../services/Events";
import Loading from "../../../components/loading/Loading";
import PopNotify from "../../../components/Pop/PopNotify";
import Select from "../../../components/select/Select";
import EventsModel from "../../../models/Events";

interface PopInfo {
    status: boolean,
    type: string
}

const EventsForm = ({ eventElement, venues, onRefresh } : { eventElement: any, venues: Array<any>, onRefresh: any }) => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });

    const [evtData, setEvtDataElement] = useState({
        companyID: { value: `${IDCompany}`, isCorrect: true },
        eventID: { value: "", isCorrect: false },
        eventName: { value: "", isCorrect: false },
        eventVenue: { value: [], isCorrect: false },
        eventYear: { value: "", isCorrect: false },
        eventDate: { value: "", isCorrect: false },
        eventCreatedAt: { value: "", isCorrect: false }
    });

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setEvtDataElement({...evtData, [evt.target.name]: { value: evt.target.value, isCorrect: true }});
    }

    const onResponseSelect = (opt: any) => {
        let arrCurrent: any = [];
        arrCurrent = venues;
        const existVenue = evtData.eventVenue.value.find(vnel => vnel === Object(arrCurrent[opt.target.value]).value);
        if (!existVenue) {
            const newArrVenue: any = evtData.eventVenue.value;
            newArrVenue.push(Object(arrCurrent[opt.target.value]).value);
            setEvtDataElement({...evtData, eventVenue: { value: newArrVenue, isCorrect: true }});
        }
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (evtData.eventName.isCorrect && evtData.eventDate.isCorrect && evtData.eventVenue.isCorrect) {
            setLoadingStatus(true);
            const newData: EventsModel = {
                companyID: `${IDCompany}`,
                eventID: eventElement ? eventElement.eventID : "",
                eventName: evtData.eventName.value,
                eventVenue: evtData.eventVenue.value,
                eventYear: eventElement ? eventElement.eventYear : `${new Date().getFullYear()}`,
                eventDate: evtData.eventDate.value,
                eventCreatedAt: eventElement ? eventElement.eventCreatedAt : ""
            }
            new Promise<void>((resolve, reject) => {
                if (eventElement !== null) {
                    updateEvent(newData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data) {
                            setPopStatus({...popStatus, status: true, type: "success"});
                            onRefresh(true);
                        } else {
                            setPopStatus({...popStatus, status: true, type: "error"});
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                } else {
                    addEvents(newData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data) {
                            onRefresh(true);
                            setPopStatus({...popStatus, status: true, type: "success"});
                            setEvtDataElement({...evtData,
                                companyID: { value: `${IDCompany}`, isCorrect: true },
                                eventID: { value: "", isCorrect: false },
                                eventName: { value: "", isCorrect: false },
                                eventVenue: { value: [], isCorrect: false },
                                eventYear: { value: "", isCorrect: false },
                                eventDate: { value: "", isCorrect: false }
                            });
                        } else {
                            setPopStatus({...popStatus, status: true, type: "error"});
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                }
            });
        } else {
            setPopStatus({...popStatus, status: true, type: "warning"});
        }
    }

    const deleteVenueElement = (strElement: string) => {
        const newVenueArr = evtData.eventVenue.value.filter(elem => elem !== strElement);
        setEvtDataElement({...evtData, eventVenue: { value: newVenueArr, isCorrect: true }});
    }

    useEffect(() => {
        if (eventElement !== null) {
            const newEventModel = {
                companyID: { value: `${IDCompany}`, isCorrect: true },
                eventID: { value: eventElement.eventID, isCorrect: true },
                eventName: { value: eventElement.eventName, isCorrect: true },
                eventVenue: { value: eventElement.eventVenue, isCorrect: true },
                eventYear: { value: eventElement.eventYear, isCorrect: true },
                eventDate: { value: eventElement.eventDate, isCorrect: true },
                eventCreatedAt: { value: eventElement.eventCreatedAt, isCorrect: true }
            }
            setEvtDataElement(newEventModel);
        }
    }, [eventElement]);

    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="block">
                <form onSubmit={handleSubmit}>
                    <div className="px-2 text-left">
                        <div className="space-y-7">
                            <div className="space-y-5">
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                        Nombre del evento
                                    </label>
                                    <input
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                        id="eventNameInput"
                                        type="text"
                                        placeholder="Nombre del evento..."
                                        name="eventName"
                                        onChange={handleChange}
                                        value={evtData.eventName.value}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                        Fecha del evento
                                    </label>
                                    <input
                                        style={{ color: 'black' }}
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px]"
                                        id="eventDateInput"
                                        type="date"
                                        name="eventDate"
                                        onChange={handleChange}
                                        value={evtData.eventDate.value}
                                    />
                                </div>
                                <div className="mb-4 space-y-2">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                        Sucursales
                                    </label>
                                    <Select
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                        options={venues}
                                        onResponse={onResponseSelect}
                                        name="gamePlatform"
                                        id="gamePlatformInput"
                                        placeholder="Sucursal..."
                                    />
                                </div>
                                <div className="mb-4 space-y-2">
                                    <div className="space-y-1 md:flex md:items-center md:space-x-[5px] md:space-y-0">
                                        {
                                            evtData.eventVenue.value.map((venueElement:string, i:number) => {
                                                return (
                                                    <div className="flex items-center bg-arena-black opacity-75 space-x-[5px] px-[5px] py-[3px]" key={i}>
                                                        <span className="txt-arena-white text-[12px]">
                                                            {venueElement}
                                                        </span>
                                                        <button
                                                            className="txt-arena-white text-[12px]"
                                                            type="button"
                                                            onClick={() => deleteVenueElement(venueElement)}
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div className="flex justify-end pt-[5px]">
                                    <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" type="submit">
                                        { eventElement ? "Guardar cambios" : "Agregar evento" }
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default EventsForm;