import { useContext } from "react";
import { GlobalContext } from "../context/global_context";
import { Link } from "react-router-dom";
// import { VscSettingsGear } from 'react-icons/vsc'
import { AiOutlineArrowRight } from 'react-icons/ai'
// import CopaTorneo from "../assets/images/tournaments/39_img_portada_torneos_2.png";
import CopaTorneo from "../assets/images/tournaments/03_icon_torneos.png";
import IconCalendar from "../assets/icons/booking/22_icon_reservas.png";
// import  from "../assets/icons/01_arena_icon_promos.svg";
import Navbar from "./navbar/Navbar";

const MyAccount = () => {

    const { globalState } = useContext(GlobalContext)
    return (
        <>
            <div className="flex flex-row">
                <div className="w-full bg-no-repeat bg-cover fixed z-40">
                    <Navbar />

                </div>
            </div>
            <div className="bg-arena-grayo h-[100vh]">
                <div className="w-[80%] m-auto">
                    <div className="flex pt-[80px] justify-between items-center">
                        <div className="text-left  h-[100%] txt-arena-cgray text-[4vw]">Bienvenido, {globalState.userData?.player.nick}</div>
                        {/* <div className="txt-arena-white flex  items-center">Editar perfil <span className="txt-arena-red ml-[5px]"><VscSettingsGear /></span></div> */}
                    </div>
                    <div className="flex">
                        <div className="w-[400px] glassmorphism py-[15px]" onClick={() => {

                        }}>

                            <div className="borderCard mx-[10px]">
                                <div className="text-left p-[25px]">
                                    <div className=" txt-arena-white text-[15px]">
                                        Tu saldo disponible:
                                    </div>
                                    <div className="txt-arena-white text-[25px] font-bold">{globalState.userData?.player.coins[0].coinAmount} <span className="text-[25px]">ARES</span></div>
                                    <div className="txt-arena-orange text-[12px] font-bold">Total: <span className="text-[12px]">{globalState.userData?.player.coins[0].coinAmount} mxn</span></div>
                                    <div className="txt-arena-white w-[60%] text-[15px] mt-[10px]">Lleva a casa grandes premios no lo dudes e inscribite ya.</div>

                                </div>
                            </div>
                            {/* <div className="txt-arena-white text-[15px] flex justify-between items-center p-[15px] font-bold my-auto">
                                <div>Cargar Ares</div>
                                <div className="ml-[10px] text-[25px]"><AiOutlineArrowRight /></div>
                            </div> */}

                        </div>
                        <div className="w-[400px] glassmorphism py-[15px] mx-[10px]" onClick={() => {

                        }}>

                            <div className="mx-[10px] h-[100%]">
                                <div className="flex flex-col justify-between align-between p-[15px] h-[100%]">
                                    {/* <div className="text-right txt-arena-orange text-[15px]">
                                        Haz participado en
                                    </div>
                                    <div className="text-right txt-arena-white text-[40px] font-bold mt-[-10px]">05</div>
                                    <div className="text-right txt-arena-orange text-[12px] font-bold mt-[-10px]">Toneos</div> */}
                                    <div>
                                        <img src={CopaTorneo} width='45px' />
                                        <div className="text-left txt-arena-white text-[15px] font-bold">Tenemos nuevos Torneos!</div>
                                    </div>
                                    <Link to="/tournaments">
                                        <div className="txt-arena-white text-[15px] flex justify-between items-end mt-[20px]">
                                            <div className="text-left w-[60%]">Lleva a casa grandes premios no lo dudes e inscribite ya.</div>
                                            <div className="ml-[10px] text-[25px]"><AiOutlineArrowRight /></div>
                                        </div>
                                    </Link>


                                </div>
                            </div>
                            {/* <div className="txt-arena-white text-[15px] flex items-center p-[15px] font-bold">Cargar Ares <span className="ml-[10px]"><AiOutlineArrowRight /></span></div> */}

                        </div>
                        <div className="w-[400px] glassmorphism py-[15px]" onClick={() => {

                        }}>

                            <div className="h-[100%]">
                                <div className="flex flex-col justify-between align-between p-[15px] h-[100%]">
                                    {/* <div className="text-right txt-arena-orange text-[15px]">
                                        Haz realizado
                                    </div>
                                    <div className="text-right txt-arena-white text-[40px] font-bold mt-[-10px]">02</div>
                                    <div className="text-right txt-arena-orange text-[12px] font-bold mt-[-10px]">Reservas</div> */}
                                    <div>
                                        <img src={IconCalendar} width='45px' />
                                        <div className="text-left txt-arena-white text-[15px] font-bold">Crea una reservación</div>
                                    </div>
                                    <Link to="/booking">
                                        <div className="txt-arena-white text-[15px] flex justify-between items-end mt-[20px]">
                                            <div className="text-left w-[60%]">Prueba cada una de las experiencias especiales que diseñamos para ti.</div>
                                            <div className="ml-[10px] text-[25px]"><AiOutlineArrowRight /></div>
                                        </div>
                                    </Link>

                                </div>
                            </div>
                            {/* <div className="txt-arena-white text-[15px] flex items-center p-[15px] font-bold">Cargar Ares <span className="ml-[10px]"><AiOutlineArrowRight /></span></div> */}

                        </div>

                    </div>
                    {/* <div className="w-full glassmorphism mt-[20px] p-[20px]">
                        <div className="flex justify-between items-center">

                            <div className="flex items-center font-bold txt-arena-white">
                                <div className="mr-[10px]"><img src={IconCalendar} width='45px' /></div>
                                <span className="txt-arena-orange font-bold">Recordatorio. </span>
                                Tienes una reserva para el próximo 27 de Enero de 2023</div>
                            <div className="txt-arena-white flex items-center">
                                <div className="mr-[25px]">VER TODAS</div>
                                <div className="btnIcon">
                                <span className="txt-arena-white">
                                    <AiOutlineArrowRight />
                                </span>
                            </div>
                            </div>
                            

                        </div>
                    </div> */}
                </div>

            </div>
        </>
    )

}

export default MyAccount