import VenuesModel from "../../../models/Venues";
import { GiSmartphone } from "react-icons/gi";
import { HiMail } from "react-icons/hi";
import { Fade } from "react-awesome-reveal"

const VenueContact = ({ info } : { info: VenuesModel }) => {
    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="w-[570px] lg:w-[1000px] space-y-[12px] text-left m-auto">
                    <div>
                        <Fade><h3 className="text-[25px] font-bold">Contacto y horarios</h3></Fade>
                    </div>
                    <div>
                        <Fade><p className="text-[18px]">{ info.venueContactPhone }</p></Fade>
                    </div>
                    <div>
                        <Fade><p className="text-[18px]">{ info.venueContactMail }</p></Fade>
                    </div>
                    <div>
                        <Fade><p className="text-[18px] font-bold">{ info.venueWorkingHours }</p></Fade>
                    </div>
                    <div className="w-[300px] m-auto">
                        <Fade>
                            <a className="btn-arena-primary flex justify-center scale-100 hover:scale-105 ease-in duration-150" type="button" href={`mailto:${info.venueContactMail}`}>
                                <div className="flex items-center space-x-[10px]">
                                    <span className="text-[22px]"><HiMail /></span>
                                    <span className="text-[18px]">ENVIAR EMAIL</span>
                                </div>
                            </a>
                        </Fade>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div className="space-y-[3vw] text-left">
                    <div>
                        <Fade><h3 className="text-[5vw] font-bold">Contacto y horarios</h3></Fade>
                    </div>
                    <div>
                        <Fade><p className="text-[4vw]">{ info.venueContactPhone }</p></Fade>
                    </div>
                    <div>
                        <Fade>
                            <a href={`mailto:${info.venueContactMail}`}>
                                <p className="text-[4vw]">{ info.venueContactMail }</p>
                            </a>
                        </Fade>
                    </div>
                    <div>
                        <Fade><p className="text-[4vw] font-bold">{ info.venueWorkingHours }</p></Fade>
                    </div>
                    <div className="py-[3vw]">
                        <Fade>
                            <a className="btn-arena-primary flex justify-center" type="button" href={`tel:${info.venueContactPhone}`}>
                                <span className="flex items-center space-x-[2vw] text-[4vw]">
                                    <GiSmartphone />
                                    <p>LLAMAR</p>
                                </span>
                            </a>
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VenueContact;