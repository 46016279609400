export const statesOptions = [
    { label: "Aguascalientes", value: "Aguascalientes" },
    { label: "Baja California", value: "Baja California" },
    { label: "Baja California Sur", value: "Baja California Sur" },
    { label: "Campeche", value: "Campeche" },
    { label: "Chiapas", value: "Chiapas" },
    { label: "Chihuahua", value: "Chihuahua" },
    { label: "Coahuila", value: "Coahuila" },
    { label: "Colima", value: "Colima" },
    { label: "Ciudad de México", value: "Ciudad de México" },
    { label: "Durango", value: "Durango" },
    { label: "Estado de México", value: "Estado de México" },
    { label: "Guanajuato", value: "Guanajuato" },
    { label: "Guerrero", value: "Guerrero" },
    { label: "Hidalgo", value: "Hidalgo" },
    { label: "Jalisco", value: "Jalisco" },
    { label: "Michoacán", value: "Michoacán" },
    { label: "Morelos", value: "Morelos" },
    { label: "Nayarit", value: "Nayarit" },
    { label: "Nuevo León", value: "Nuevo León" },
    { label: "Oaxaca", value: "Oaxaca" },
    { label: "Puebla", value: "Puebla" },
    { label: "Querétaro", value: "Querétaro" },
    { label: "Quintana Roo", value: "Quintana Roo" },
    { label: "San Luis Potosí", value: "San Luis Potosí" },
    { label: "Sinaloa", value: "Sinaloa" },
    { label: "Sonora", value: "Sonora" },
    { label: "Tabasco", value: "Tabasco" },
    { label: "Tamaulipas", value: "Tamaulipas" },
    { label: "Tlaxcala", value: "Tlaxcala" },
    { label: "Veracruz", value: "Veracruz" },
    { label: "Yucatán", value: "Yucatán" },
    { label: "Zacatecas", value: "Zacatecas" }
];

export const citiesOptions = {
    "Aguascalientes": [
        { label: "AGUASCALIENTES", value: "AGUASCALIENTES" },
        { label: "ASIENTOS", value: "ASIENTOS" },
        { label: "CALVILLO", value: "CALVILLO" },
        { label: "COSÍO", value: "COSÍO" },
        { label: "JESÚS MARÍA", value: "JESÚS MARÍA" },
        { label: "PABELLÓN DE ARTEAGA", value: "PABELLÓN DE ARTEAGA" },
        { label: "RINCÓN DE ROMOS", value: "RINCÓN DE ROMOS" },
        { label: "SAN JOSÉ DE GRACIA", value: "SAN JOSÉ DE GRACIA" },
        { label: "TEPEZALÁ", value: "TEPEZALÁ" },
        { label: "EL LLANO", value: "EL LLANO" },
        { label: "SAN FRANCISCO DE LOS ROMO", value: "SAN FRANCISCO DE LOS ROMO" }
    ],
    "Baja California": [
        { label: "ENSENADA", value: "ENSENADA" },
        { label: "MEXICALI", value: "MEXICALI" },
        { label: "TECATE", value: "TECATE" },
        { label: "TIJUANA", value: "TIJUANA" },
        { label: "PLAYAS DE ROSARITO", value: "PLAYAS DE ROSARITO" }
    ],
    "Baja California Sur": [
        { label: "COMONDÚ", value: "COMONDÚ" },
        { label: "MULEGÉ", value: "MULEGÉ" },
        { label: "LA PAZ", value: "LA PAZ" },
        { label: "LOS CABOS", value: "LOS CABOS" },
        { label: "LORETO", value: "LORETO" }
    ],
    "Campeche": [
        { label: "CALKINÍ", value: "CALKINÍ" },
        { label: "CAMPECHE", value: "CAMPECHE" },
        { label: "CARMEN", value: "CARMEN" },
        { label: "CHAMPOTÓN", value: "CHAMPOTÓN" },
        { label: "HECELCHAKÁN", value: "HECELCHAKÁN" },
        { label: "HOPELCHÉN", value: "HOPELCHÉN" },
        { label: "PALIZADA", value: "PALIZADA" },
        { label: "TENABO", value: "TENABO" },
        { label: "ESCÁRCEGA", value: "ESCÁRCEGA" },
        { label: "CALAKMUL", value: "CALAKMUL" },
        { label: "CANDELARIA", value: "CANDELARIA" },
    ],
    "Chiapas": [
        { label: "ACACOYAGUA", value: "ACACOYAGUA" },
        { label: "ACALA", value: "ACALA" },
        { label: "ACAPETAHUA", value: "ACAPETAHUA" },
        { label: "ALTAMIRANO", value: "ALTAMIRANO" },
        { label: "AMATÁN", value: "AMATÁN" },
        { label: "AMATENANGO DE LA FRONTERA", value: "AMATENANGO DE LA FRONTERA" },
        { label: "AMATENANGO DEL VALLE", value: "AMATENANGO DEL VALLE" },
        { label: "ANGEL ALBINO CORZO", value: "ANGEL ALBINO CORZO" },
        { label: "ARRIAGA", value: "ARRIAGA" },
        { label: "BEJUCAL DE OCAMPO", value: "BEJUCAL DE OCAMPO" },
        { label: "BELLA VISTA", value: "BELLA VISTA" },
        { label: "BERRIOZÁBAL", value: "BERRIOZÁBAL" },
        { label: "BOCHIL", value: "BOCHIL" },
        { label: "EL BOSQUE", value: "EL BOSQUE" },
        { label: "CACAHOATÁN", value: "CACAHOATÁN" },
        { label: "CATAZAJÁ", value: "CATAZAJÁ" },
        { label: "CINTALAPA", value: "CINTALAPA" },
        { label: "COAPILLA", value: "COAPILLA" },
        { label: "COMITÁN DE DOMÍNGUEZ", value: "COMITÁN DE DOMÍNGUEZ" },
        { label: "LA CONCORDIA", value: "LA CONCORDIA" },
        { label: "COPAINALÁ", value: "COPAINALÁ" },
        { label: "CHALCHIHUITÁN", value: "CHALCHIHUITÁN" },
        { label: "CHAMULA", value: "CHAMULA" },
        { label: "CHANAL", value: "CHANAL" },
        { label: "CHAPULTENANGO", value: "CHAPULTENANGO" },
        { label: "CHENALHÓ", value: "CHENALHÓ" },
        { label: "CHIAPA DE CORZO", value: "CHIAPA DE CORZO" },
        { label: "CHIAPILLA", value: "CHIAPILLA" },
        { label: "CHICOASÉN", value: "CHICOASÉN" },
        { label: "CHICOMUSELO", value: "CHICOMUSELO" },
        { label: "CHILÓN", value: "CHILÓN" },
        { label: "ESCUINTLA", value: "ESCUINTLA" },
        { label: "FRANCISCO LEÓN", value: "FRANCISCO LEÓN" },
        { label: "FRONTERA COMALAPA", value: "FRONTERA COMALAPA" },
        { label: "FRONTERA HIDALGO", value: "FRONTERA HIDALGO" },
        { label: "LA GRANDEZA", value: "LA GRANDEZA" },
        { label: "HUEHUETÁN", value: "HUEHUETÁN" },
        { label: "HUIXTÁN", value: "HUIXTÁN" },
        { label: "HUITIUPÁN", value: "HUITIUPÁN" },
        { label: "HUIXTLA", value: "HUIXTLA" },
        { label: "LA INDEPENDENCIA", value: "LA INDEPENDENCIA" },
        { label: "IXHUATÁN", value: "IXHUATÁN" },
        { label: "IXTACOMITÁN", value: "IXTACOMITÁN" },
        { label: "IXTAPA", value: "IXTAPA" },
        { label: "IXTAPANGAJOYA", value: "IXTAPANGAJOYA" },
        { label: "JIQUIPILAS", value: "JIQUIPILAS" },
        { label: "JITOTOL", value: "JITOTOL" },
        { label: "JUÁREZ", value: "JUÁREZ" },
        { label: "LARRÁINZAR", value: "LARRÁINZAR" },
        { label: "LA LIBERTAD", value: "LA LIBERTAD" },
        { label: "MAPASTEPEC", value: "MAPASTEPEC" },
        { label: "LAS MARGARITAS", value: "LAS MARGARITAS" },
        { label: "MAZAPA DE MADERO", value: "MAZAPA DE MADERO" },
        { label: "MAZATÁN", value: "MAZATÁN" },
        { label: "METAPA", value: "METAPA" },
        { label: "MITONTIC", value: "MITONTIC" },
        { label: "MOTOZINTLA", value: "MOTOZINTLA" },
        { label: "NICOLÁS RUÍZ", value: "NICOLÁS RUÍZ" },
        { label: "OCOSINGO", value: "OCOSINGO" },
        { label: "OCOTEPEC", value: "OCOTEPEC" },
        { label: "OCOZOCOAUTLA DE ESPINOSA", value: "OCOZOCOAUTLA DE ESPINOSA" },
        { label: "OSTUACÁN", value: "OSTUACÁN" },
        { label: "OSUMACINTA", value: "OSUMACINTA" },
        { label: "OXCHUC", value: "OXCHUC" },
        { label: "PALENQUE", value: "PALENQUE" },
        { label: "PANTELHÓ", value: "PANTELHÓ" },
        { label: "PANTEPEC", value: "PANTEPEC" },
        { label: "PICHUCALCO", value: "PICHUCALCO" },
        { label: "PIJIJIAPAN", value: "PIJIJIAPAN" },
        { label: "EL PORVENIR", value: "EL PORVENIR" },
        { label: "VILLA COMALTITLÁN", value: "VILLA COMALTITLÁN" },
        { label: "PUEBLO NUEVO SOLISTAHUACÁN", value: "PUEBLO NUEVO SOLISTAHUACÁN" },
        { label: "RAYÓN", value: "RAYÓN" },
        { label: "REFORMA", value: "REFORMA" },
        { label: "LAS ROSAS", value: "LAS ROSAS" },
        { label: "SABANILLA", value: "SABANILLA" },
        { label: "SALTO DE AGUA", value: "SALTO DE AGUA" },
        { label: "SAN CRISTÓBAL DE LAS CASAS", value: "SAN CRISTÓBAL DE LAS CASAS" },
        { label: "SAN FERNANDO", value: "SAN FERNANDO" },
        { label: "SILTEPEC", value: "SILTEPEC" },
        { label: "SIMOJOVEL", value: "SIMOJOVEL" },
        { label: "SITALÁ", value: "SITALÁ" },
        { label: "SOCOLTENANGO", value: "SOCOLTENANGO" },
        { label: "SOLOSUCHIAPA", value: "SOLOSUCHIAPA" },
        { label: "SOYALÓ", value: "SOYALÓ" },
        { label: "SUCHIAPA", value: "SUCHIAPA" },
        { label: "SUCHIATE", value: "SUCHIATE" },
        { label: "SUNUAPA", value: "SUNUAPA" },
        { label: "TAPACHULA", value: "TAPACHULA" },
        { label: "TAPALAPA", value: "TAPALAPA" },
        { label: "TAPILULA", value: "TAPILULA" },
        { label: "TECPATÁN", value: "TECPATÁN" },
        { label: "TENEJAPA", value: "TENEJAPA" },
        { label: "TEOPISCA", value: "TEOPISCA" },
        { label: "TILA", value: "TILA" },
        { label: "TONALÁ", value: "TONALÁ" },
        { label: "TOTOLAPA", value: "TOTOLAPA" },
        { label: "LA TRINITARIA", value: "TRINITARIA" },
        { label: "TUMBALÁ", value: "TUMBALÁ" },
        { label: "TUXTLA GUTIÉRREZ", value: "TUXTLA GUTIÉRREZ" },
        { label: "TUXTLA CHICO", value: "TUXTLA CHICO" },
        { label: "TUZANTÁN", value: "TUZANTÁN" },
        { label: "TZIMOL", value: "TZIMOL" },
        { label: "UNIÓN JUÁREZ", value: "UNIÓN JUÁREZ" },
        { label: "VENUSTIANO CARRANZA", value: "VENUSTIANO CARRANZA" },
        { label: "VILLA CORZO", value: "VILLA CORZO" },
        { label: "VILLAFLORES", value: "VILLAFLORES" },
        { label: "YAJALÓN", value: "YAJALÓN" },
        { label: "SAN LUCAS", value: "SAN LUCAS" },
        { label: "ZINACANTÁN", value: "ZINACANTÁN" },
        { label: "SAN JUAN CANCUC", value: "SAN JUAN CANCUC" },
        { label: "ALDAMA", value: "ALDAMA" },
        { label: "BENEMÉRITO DE LAS AMÉRICAS", value: "BENEMÉRITO DE LAS AMÉRICAS" },
        { label: "MARAVILLA TENEJAPA", value: "MARAVILLA TENEJAPA" },
        { label: "MARQUÉS DE COMILLAS", value: "MARQUÉS DE COMILLAS" },
        { label: "MONTECRISTO DE GUERRERO", value: "MONTECRISTO DE GUERRERO" },
        { label: "SAN ANDRÉS DURAZNAL", value: "SAN ANDRÉS DURAZNAL" },
        { label: "SANTIAGO EL PINAR", value: "SANTIAGO EL PINAR" },
        { label: "CANDELARIA", value: "CANDELARIA" }
    ],
    "Chihuahua": [
        { label: "AHUMADA", value: "AHUMADA" },
        { label: "ALDAMA", value: "ALDAMA" },
        { label: "ALLENDE", value: "ALLENDE" },
        { label: "AQUILES SERDÁN", value: "AQUILES SERDÁN" },
        { label: "ASCENSIÓN", value: "ASCENSIÓN" },
        { label: "BACHÍNIVA", value: "BACHÍNIVA" },
        { label: "BALLEZA", value: "BALLEZA" },
        { label: "BATOPILAS", value: "BATOPILAS" },
        { label: "BOCOYNA", value: "BOCOYNA" },
        { label: "BUENAVENTURA", value: "BUENAVENTURA" },
        { label: "CAMARGO", value: "CAMARGO" },
        { label: "CARICHÍ", value: "CARICHÍ" },
        { label: "CASAS GRANDES", value: "CASAS GRANDES" },
        { label: "CORONADO", value: "CORONADO" },
        { label: "COYAME DEL SOTOL", value: "COYAME DEL SOTOL" },
        { label: "LA CRUZ", value: "LA CRUZ" },
        { label: "CUAUHTÉMOC", value: "CUAUHTÉMOC" },
        { label: "CUSIHUIRIACHI", value: "CUSIHUIRIACHI" },
        { label: "CHIHUAHUA", value: "CHIHUAHUA" },
        { label: "CHÍNIPAS", value: "CHÍNIPAS" },
        { label: "DELICIAS", value: "DELICIAS" },
        { label: "DR. BELISARIO DOMÍNGUEZ", value: "DR. BELISARIO DOMÍNGUEZ" },
        { label: "GALEANA", value: "GALEANA" },
        { label: "SANTA ISABEL", value: "SANTA ISABEL" },
        { label: "GÓMEZ FARÍAS", value: "GÓMEZ FARÍAS" },
        { label: "GRAN MORELOS", value: "GRAN MORELOS" },
        { label: "GUACHOCHI", value: "GUACHOCHI" },
        { label: "GUADALUPE", value: "GUADALUPE" },
        { label: "GUADALUPE Y CALVO", value: "GUADALUPE Y CALVO" },
        { label: "GUAZAPARES", value: "GUAZAPARES" },
        { label: "GUERRERO", value: "GUERRERO" },
        { label: "HIDALGO DEL PARRAL", value: "HIDALGO DEL PARRAL" },
        { label: "HUEJOTITÁN", value: "HUEJOTITÁN" },
        { label: "IGNACIO ZARAGOZA", value: "IGNACIO ZARAGOZA" },
        { label: "JANOS", value: "JANOS" },
        { label: "JIMÉNEZ", value: "JIMÉNEZ" },
        { label: "JUÁREZ", value: "JUÁREZ" },
        { label: "JULIMES", value: "JULIMES" },
        { label: "LÓPEZ", value: "LÓPEZ" },
        { label: "MADERA", value: "MADERA" },
        { label: "MAGUARICHI", value: "MAGUARICHI" },
        { label: "MANUEL BENAVIDES", value: "MANUEL BENAVIDES" },
        { label: "MATACHÍ", value: "MATACHÍ" },
        { label: "MATAMOROS", value: "MATAMOROS" },
        { label: "MEOQUI", value: "MEOQUI" },
        { label: "MORELOS", value: "MORELOS" },
        { label: "MORIS", value: "MORIS" },
        { label: "NAMIQUIPA", value: "NAMIQUIPA" },
        { label: "NONOAVA", value: "NONOAVA" },
        { label: "NUEVO CASAS GRANDES", value: "NUEVO CASAS GRANDES" },
        { label: "OCAMPO", value: "OCAMPO" },
        { label: "OJINAGA", value: "OJINAGA" },
        { label: "PRAXEDIS G. GUERRERO", value: "PRAXEDIS G. GUERRERO" },
        { label: "RIVA PALACIO", value: "RIVA PALACIO" },
        { label: "ROSALES", value: "ROSALES" },
        { label: "ROSARIO", value: "ROSARIO" },
        { label: "SAN FRANCISCO DE BORJA", value: "SAN FRANCISCO DE BORJA" },
        { label: "SAN FRANCISCO DE CONCHOS", value: "SAN FRANCISCO DE CONCHOS" },
        { label: "SAN FRANCISCO DEL ORO", value: "SAN FRANCISCO DEL ORO" },
        { label: "SANTA BÁRBARA", value: "SANTA BÁRBARA" },
        { label: "SATEVÓ", value: "SATEVÓ" },
        { label: "SAUCILLO", value: "SAUCILLO" },
        { label: "TEMÓSACHIC", value: "TEMÓSACHIC" },
        { label: "EL TULE", value: "EL TULE" },
        { label: "URIQUE", value: "URIQUE" },
        { label: "URUACHI", value: "URUACHI" },
        { label: "VALLE DE ZARAGOZA", value: "VALLE DE ZARAGOZA" }
    ],
    "Coahuila": [
        { label: "ABASOLO", value: "ABASOLO" },
        { label: "ACUÑA", value: "ACUÑA" },
        { label: "ALLENDE", value: "ALLENDE" },
        { label: "ARTEAGA", value: "ARTEAGA" },
        { label: "CANDELA", value: "CANDELA" },
        { label: "CASTAÑOS", value: "CASTAÑOS" },
        { label: "CUATRO CIÉNEGAS", value: "CUATRO CIÉNEGAS" },
        { label: "ESCOBEDO", value: "ESCOBEDO" },
        { label: "FRANCISCO I. MADERO", value: "FRANCISCO I. MADERO" },
        { label: "FRONTERA", value: "FRONTERA" },
        { label: "GENERAL CEPEDA", value: "GENERAL CEPEDA" },
        { label: "GUERRERO", value: "GUERRERO" },
        { label: "HIDALGO", value: "HIDALGO" },
        { label: "JIMÉNEZ", value: "JIMÉNEZ" },
        { label: "JUÁREZ", value: "JUÁREZ" },
        { label: "LAMADRID", value: "LAMADRID" },
        { label: "MATAMOROS", value: "MATAMOROS" },
        { label: "MONCLOVA", value: "MONCLOVA" },
        { label: "MORELOS", value: "MORELOS" },
        { label: "MÚZQUIZ", value: "MÚZQUIZ" },
        { label: "NADADORES", value: "NADADORES" },
        { label: "NAVA", value: "NAVA" },
        { label: "OCAMPO", value: "OCAMPO" },
        { label: "PARRAS", value: "PARRAS" },
        { label: "PIEDRAS NEGRAS", value: "PIEDRAS NEGRAS" },
        { label: "PROGRESO", value: "PROGRESO" },
        { label: "RAMOS ARIZPE", value: "RAMOS ARIZPE" },
        { label: "SABINAS", value: "SABINAS" },
        { label: "SACRAMENTO", value: "SACRAMENTO" },
        { label: "SALTILLO", value: "SALTILLO" },
        { label: "SAN BUENAVENTURA", value: "SAN BUENAVENTURA" },
        { label: "SAN JUAN DE SABINAS", value: "SAN JUAN DE SABINAS" },
        { label: "SAN PEDRO", value: "SAN PEDRO" },
        { label: "SIERRA MOJADA", value: "SIERRA MOJADA" },
        { label: "TORREÓN", value: "TORREÓN" },
        { label: "VIESCA", value: "VIESCA" },
        { label: "VILLA UNIÓN", value: "VILLA UNIÓN" },
        { label: "ZARAGOZA", value: "ZARAGOZA" }
    ],
    "Colima": [
        { label: "ARMERÍA", value: "ARMERÍA" },
        { label: "COLIMA", value: "COLIMA" },
        { label: "COMALA", value: "COMALA" },
        { label: "COQUIMATLÁN", value: "COQUIMATLÁN" },
        { label: "CUAUHTÉMOC", value: "CUAUHTÉMOC" },
        { label: "IXTLAHUACÁN", value: "IXTLAHUACÁN" },
        { label: "MANZANILLO", value: "MANZANILLO" },
        { label: "MINATITLÁN", value: "MINATITLÁN" },
        { label: "TECOMÁN", value: "TECOMÁN" },
        { label: "VILLA DE ÁLVAREZ", value: "VILLA DE ÁLVAREZ" },
    ],
    "Ciudad de México": [
        { label: "AZCAPOTZALCO", value: "AZCAPOTZALCO" },
        { label: "COYOACÁN", value: "COYOACÁN" },
        { label: "CUAJIMALPA DE MORELOS", value: "CUAJIMALPA DE MORELOS" },
        { label: "GUSTAVO A. MADERO", value: "GUSTAVO A. MADERO" },
        { label: "IZTACALCO", value: "IZTACALCO" },
        { label: "IZTAPALAPA", value: "IZTAPALAPA" },
        { label: "LA MAGDALENA CONTRERAS", value: "LA MAGDALENA CONTRERAS" },
        { label: "MILPA ALTA", value: "MILPA ALTA" },
        { label: "ÁLVARO OBREGÓN", value: "ÁLVARO OBREGÓN" },
        { label: "TLÁHUAC", value: "TLÁHUAC" },
        { label: "TLALPAN", value: "TLALPAN" },
        { label: "XOCHIMILCO", value: "XOCHIMILCO" },
        { label: "BENITO JUÁREZ", value: "BENITO JUÁREZ" },
        { label: "CUAUHTÉMOC", value: "CUAUHTÉMOC" },
        { label: "MIGUEL HIDALGO", value: "MIGUEL HIDALGO" },
        { label: "VENUSTIANO CARRANZA", value: "VENUSTIANO CARRANZA" }
    ],
    "Durango": [
        { label: "DURANGO", value: "DURANGO" },
        { label: "CANATLÁN", value: "CANATLÁN" },
        { label: "CANELAS", value: "CANELAS" },
        { label: "CONETO DE COMONFORT", value: "CONETO DE COMONFORT" },
        { label: "CUENCAMÉ", value: "CUENCAMÉ" },
        { label: "GENERAL SIMÓN BOLÍVAR", value: "GENERAL SIMÓN BOLÍVAR" },
        { label: "GÓMEZ PALACIO", value: "GÓMEZ PALACIO" },
        { label: "GUADALUPE VICTORIA", value: "GUADALUPE VICTORIA" },
        { label: "GUANACEVÍ", value: "GUANACEVÍ" },
        { label: "HIDALGO", value: "HIDALGO" },
        { label: "INDÉ", value: "INDÉ" },
        { label: "LERDO", value: "LERDO" },
        { label: "MAPIMÍ", value: "MAPIMÍ" },
        { label: "MEZQUITAL", value: "MEZQUITAL" },
        { label: "NAZAS", value: "NAZAS" },
        { label: "NOMBRE DE DIOS", value: "NOMBRE DE DIOS" },
        { label: "OCAMPO", value: "OCAMPO" },
        { label: "EL ORO", value: "EL ORO" },
        { label: "OTÁEZ", value: "OTÁEZ" },
        { label: "PÁNUCO DE CORONADO", value: "PÁNUCO DE CORONADO" },
        { label: "PEÑÓN BLANCO", value: "PEÑÓN BLANCO" },
        { label: "POANAS", value: "POANAS" },
        { label: "PUEBLO NUEVO", value: "PUEBLO NUEVO" },
        { label: "RODEO", value: "RODEO" },
        { label: "SAN BERNARDO", value: "SAN BERNARDO" },
        { label: "SAN DIMAS", value: "SAN DIMAS" },
        { label: "SAN JUAN DE GUADALUPE", value: "SAN JUAN DE GUADALUPE" },
        { label: "SAN JUAN DEL RÍO", value: "SAN JUAN DEL RÍO" },
        { label: "SAN LUIS DEL CORDERO", value: "SAN LUIS DEL CORDERO" },
        { label: "SAN PEDRO DEL GALLO", value: "SAN PEDRO DEL GALLO" },
        { label: "SANTA CLARA", value: "SANTA CLARA" },
        { label: "SANTIAGO PAPASQUIARO", value: "SANTIAGO PAPASQUIARO" },
        { label: "SÚCHIL", value: "SÚCHIL" },
        { label: "TAMAZULA", value: "TAMAZULA" },
        { label: "TEPEHUANES", value: "TEPEHUANES" },
        { label: "TLAHUALILO", value: "TLAHUALILO" },
        { label: "TOPIA", value: "TOPIA" },
        { label: "VICENTE GUERRERO", value: "VICENTE GUERRERO" },
        { label: "NUEVO IDEAL", value: "NUEVO IDEAL" }
    ],
    "Estado de México": [
        { label: "ACAMBAY DE RUÍZ CASTAÑEDA", value: "ACAMBAY DE RUÍZ CASTAÑEDA" },
        { label: "ACOLMAN", value: "ACOLMAN" },
        { label: "ACULCO", value: "ACULCO" },
        { label: "ALMOLOYA DE ALQUISIRAS", value: "ALMOLOYA DE ALQUISIRAS" },
        { label: "ALMOLOYA DE JUÁREZ", value: "ALMOLOYA DE JUÁREZ" },
        { label: "ALMOLOYA DEL RÍO", value: "ALMOLOYA DEL RÍO" },
        { label: "AMANALCO", value: "AMANALCO" },
        { label: "AMATEPEC", value: "AMATEPEC" },
        { label: "AMECAMECA", value: "AMECAMECA" },
        { label: "APAXCO", value: "APAXCO" },
        { label: "ATENCO", value: "ATENCO" },
        { label: "ATIZAPÁN", value: "ATIZAPÁN" },
        { label: "ATIZAPÁN DE ZARAGOZA", value: "ATIZAPÁN DE ZARAGOZA" },
        { label: "ATLACOMULCO", value: "ATLACOMULCO" },
        { label: "ATLAUTLA", value: "ATLAUTLA" },
        { label: "AXAPUSCO", value: "AXAPUSCO" },
        { label: "AYAPANGO", value: "AYAPANGO" },
        { label: "CALIMAYA", value: "CALIMAYA" },
        { label: "CAPULHUAC", value: "CAPULHUAC" },
        { label: "COACALCO DE BERRIOZÁBAL", value: "COACALCO DE BERRIOZÁBAL" },
        { label: "COATEPEC HARINAS", value: "COATEPEC HARINAS" },
        { label: "COCOTITLÁN", value: "COCOTITLÁN" },
        { label: "COYOTEPEC", value: "COYOTEPEC" },
        { label: "CUAUTITLÁN", value: "CUAUTITLÁN" },
        { label: "CHALCO", value: "CHALCO" },
        { label: "CHAPA DE MOTA", value: "CHAPA DE MOTA" },
        { label: "CHAPULTEPEC", value: "CHAPULTEPEC" },
        { label: "CHIAUTLA", value: "CHIAUTLA" },
        { label: "CHICOLOAPAN", value: "CHICOLOAPAN" },
        { label: "CHICONCUAC", value: "CHICONCUAC" },
        { label: "CHIMALHUACÁN", value: "CHIMALHUACÁN" },
        { label: "DONATO GUERRA", value: "DONATO GUERRA" },
        { label: "ECATEPEC DE MORELOS", value: "ECATEPEC DE MORELOS" },
        { label: "ECATZINGO", value: "ECATZINGO" },
        { label: "HUEHUETOCA", value: "HUEHUETOCA" },
        { label: "HUEYPOXTLA", value: "HUEYPOXTLA" },
        { label: "HUIXQUILUCAN", value: "HUIXQUILUCAN" },
        { label: "ISIDRO FABELA", value: "ISIDRO FABELA" },
        { label: "IXTAPALUCA", value: "IXTAPALUCA" },
        { label: "IXTAPAN DE LA SAL", value: "IXTAPAN DE LA SAL" },
        { label: "IXTAPAN DEL ORO", value: "IXTAPAN DEL ORO" },
        { label: "IXTLAHUACA", value: "IXTLAHUACA" },
        { label: "XALATLACO", value: "XALATLACO" },
        { label: "JALTENCO", value: "JALTENCO" },
        { label: "JILOTEPEC", value: "JILOTEPEC" },
        { label: "JILOTZINGO", value: "JILOTZINGO" },
        { label: "JIQUIPILCO", value: "JIQUIPILCO" },
        { label: "JOCOTITLÁN", value: "JOCOTITLÁN" },
        { label: "JOQUICINGO", value: "JOQUICINGO" },
        { label: "JUCHITEPEC", value: "JUCHITEPEC" },
        { label: "LERMA", value: "LERMA" },
        { label: "MALINALCO", value: "MALINALCO" },
        { label: "MELCHOR OCAMPO", value: "MELCHOR OCAMPO" },
        { label: "METEPEC", value: "METEPEC" },
        { label: "MEXICALTZINGO", value: "MEXICALTZINGO" },
        { label: "MORELOS", value: "MORELOS" },
        { label: "NAUCALPAN DE JUÁREZ", value: "NAUCALPAN DE JUÁREZ" },
        { label: "NEZAHUALCÓYOTL", value: "NEZAHUALCÓYOTL" },
        { label: "NEXTLALPAN", value: "NEXTLALPAN" },
        { label: "NICOLÁS ROMERO", value: "NICOLÁS ROMERO" },
        { label: "NOPALTEPEC", value: "NOPALTEPEC" },
        { label: "OCOYOACAC", value: "OCOYOACAC" },
        { label: "OCUILAN", value: "OCUILAN" },
        { label: "EL ORO", value: "EL ORO" },
        { label: "OTUMBA", value: "OTUMBA" },
        { label: "OTZOLOAPAN", value: "OTZOLOAPAN" },
        { label: "OTZOLOTEPEC", value: "OTZOLOTEPEC" },
        { label: "OZUMBA", value: "OZUMBA" },
        { label: "PAPALOTLA", value: "PAPALOTLA" },
        { label: "LA PAZ", value: "LA PAZ" },
        { label: "POLOTITLÁN", value: "POLOTITLÁN" },
        { label: "RAYÓN", value: "RAYÓN" },
        { label: "SAN ANTONIO LA ISLA", value: "SAN ANTONIO LA ISLA" },
        { label: "SAN FELIPE DEL PROGRESO", value: "SAN FELIPE DEL PROGRESO" },
        { label: "SAN MARTÍN DE LAS PIRÁMIDES", value: "SAN MARTÍN DE LAS PIRÁMIDES" },
        { label: "SAN MATEO ATENCO", value: "SAN MATEO ATENCO" },
        { label: "SAN SIMÓN DE GUERRERO", value: "SAN SIMÓN DE GUERRERO" },
        { label: "SANTO TOMÁS", value: "SANTO TOMÁS" },
        { label: "SOYANIQUILPAN DE JUÁREZ", value: "SOYANIQUILPAN DE JUÁREZ" },
        { label: "SULTEPEC", value: "SULTEPEC" },
        { label: "TECÁMAC", value: "TECÁMAC" },
        { label: "TEJUPILCO", value: "TEJUPILCO" },
        { label: "TEMAMATLA", value: "TEMAMATLA" },
        { label: "TEMASCALAPA", value: "TEMASCALAPA" },
        { label: "TEMASCALCINGO", value: "TEMASCALCINGO" },
        { label: "TEMASCALTEPEC", value: "TEMASCALTEPEC" },
        { label: "TEMOAYA", value: "TEMOAYA" },
        { label: "TENANCINGO", value: "TENANCINGO" },
        { label: "TENANGO DEL AIRE", value: "TENANGO DEL AIRE" },
        { label: "TENANGO DEL VALLE", value: "TENANGO DEL VALLE" },
        { label: "TEOLOYUCAN", value: "TEOLOYUCAN" },
        { label: "TEOTIHUACÁN", value: "TEOTIHUACÁN" },
        { label: "TEPETLAOXTOC", value: "TEPETLAOXTOC" },
        { label: "TEPETLIXPA", value: "TEPETLIXPA" },
        { label: "TEPOTZOTLÁN", value: "TEPOTZOTLÁN" },
        { label: "TEQUIXQUIAC", value: "TEQUIXQUIAC" },
        { label: "TEXCALTITLÁN", value: "TEXCALTITLÁN" },
        { label: "TEXCALYACAC", value: "TEXCALYACAC" },
        { label: "TEXCOCO", value: "TEXCOCO" },
        { label: "TEZOYUCA", value: "TEZOYUCA" },
        { label: "TIANGUISTENCO", value: "TIANGUISTENCO" },
        { label: "TIMILPAN", value: "TIMILPAN" },
        { label: "TLALMANALCO", value: "TLALMANALCO" },
        { label: "TLALNEPANTLA DE BAZ", value: "TLALNEPANTLA DE BAZ" },
        { label: "TLATLAYA", value: "TLATLAYA" },
        { label: "TOLUCA", value: "TOLUCA" },
        { label: "TONATICO", value: "TONATICO" },
        { label: "TULTEPEC", value: "TULTEPEC" },
        { label: "TULTITLÁN", value: "TULTITLÁN" },
        { label: "VALLE DE BRAVO", value: "VALLE DE BRAVO" },
        { label: "VILLA DE ALLENDE", value: "VILLA DE ALLENDE" },
        { label: "VILLA DEL CARBÓN", value: "VILLA DEL CARBÓN" },
        { label: "VILLA GUERRERO", value: "VILLA GUERRERO" },
        { label: "VILLA VICTORIA", value: "VILLA VICTORIA" },
        { label: "XONACATLÁN", value: "XONACATLÁN" },
        { label: "ZACAZONAPAN", value: "ZACAZONAPAN" },
        { label: "ZACUALPAN", value: "ZACUALPAN" },
        { label: "ZINACANTEPEC", value: "ZINACANTEPEC" },
        { label: "ZUMPAHUACÁN", value: "ZUMPAHUACÁN" },
        { label: "ZUMPANGO", value: "ZUMPANGO" },
        { label: "CUAUTITLÁN IZCALLI", value: "CUAUTITLÁN IZCALLI" },
        { label: "VALLE DE CHALCO SOLIDARIDAD", value: "VALLE DE CHALCO SOLIDARIDAD" },
        { label: "LUVIANOS", value: "LUVIANOS" },
        { label: "SAN JOSÉ DEL RINCÓN", value: "SAN JOSÉ DEL RINCÓN" },
        { label: "TONANITLA", value: "TONANITLA" }
    ],
    "Guanajuato": [
        { label: "ABASOLO", value: "ABASOLO" },
        { label: "ACÁMBARO", value: "ACÁMBARO" },
        { label: "SAN MIGUEL DE ALLENDE", value: "SAN MIGUEL DE ALLENDE" },
        { label: "APASEO EL ALTO", value: "APASEO EL ALTO" },
        { label: "APASEO EL GRANDE", value: "APASEO EL GRANDE" },
        { label: "ATARJEA", value: "ATARJEA" },
        { label: "CELAYA", value: "CELAYA" },
        { label: "MANUEL DOBLADO", value: "MANUEL DOBLADO" },
        { label: "COMONFORT", value: "COMONFORT" },
        { label: "CORONEO", value: "CORONEO" },
        { label: "CORTAZAR", value: "CORTAZAR" },
        { label: "CUERÁMARO", value: "CUERÁMARO" },
        { label: "DOCTOR MORA", value: "DOCTOR MORA" },
        { label: "DOLORES HIDALGO", value: "DOLORES HIDALGO" },
        { label: "GUANAJUATO", value: "GUANAJUATO" },
        { label: "HUANÍMARO", value: "HUANÍMARO" },
        { label: "IRAPUATO", value: "IRAPUATO" },
        { label: "JARAL DEL PROGRESO", value: "JARAL DEL PROGRESO" },
        { label: "JERÉCUARO", value: "JERÉCUARO" },
        { label: "LEÓN", value: "LEÓN" },
        { label: "MOROLEÓN", value: "MOROLEÓN" },
        { label: "OCAMPO", value: "OCAMPO" },
        { label: "PÉNJAMO", value: "PÉNJAMO" },
        { label: "PUEBLO NUEVO", value: "PUEBLO NUEVO" },
        { label: "PURÍSIMA DEL RINCÓN", value: "PURÍSIMA DEL RINCÓN" },
        { label: "ROMITA", value: "ROMITA" },
        { label: "SALAMANCA", value: "SALAMANCA" },
        { label: "SALVATIERRA", value: "SALVATIERRA" },
        { label: "SAN DIEGO DE LA UNIÓN", value: "SAN DIEGO DE LA UNIÓN" },
        { label: "SAN FELIPE", value: "SAN FELIPE" },
        { label: "SAN FRANCISCO DEL RINCÓN", value: "SAN FRANCISCO DEL RINCÓN" },
        { label: "SAN JOSÉ ITURBIDE", value: "SAN JOSÉ ITURBIDE" },
        { label: "SAN LUIS DE LA PAZ", value: "SAN LUIS DE LA PAZ" },
        { label: "SANTA CATARINA", value: "SANTA CATARINA" },
        { label: "SANTA CRUZ DE JUVENTINO ROSAS", value: "SANTA CRUZ DE JUVENTINO ROSAS" },
        { label: "SANTIAGO MARAVATÍO", value: "SANTIAGO MARAVATÍO" },
        { label: "SILAO DE LA VICTORIA", value: "SILAO DE LA VICTORIA" },
        { label: "TARANDACUAO", value: "TARANDACUAO" },
        { label: "TARIMORO", value: "TARIMORO" },
        { label: "TIERRA BLANCA", value: "TIERRA BLANCA" },
        { label: "URIANGATO", value: "URIANGATO" },
        { label: "VALLE DE SANTIAGO", value: "VALLE DE SANTIAGO" },
        { label: "VICTORIA", value: "VICTORIA" },
        { label: "VILLAGRÁN", value: "VILLAGRÁN" },
        { label: "XICHÚ", value: "XICHÚ" },
        { label: "YURIRIA", value: "YURIRIA" }
    ],
    "Guerrero": [
        { label: "ACAPULCO DE JUÁREZ", value: "ACAPULCO DE JUÁREZ" },
        { label: "AHUACUOTZINGO", value: "AHUACUOTZINGO" },
        { label: "AJUCHITLÁN DEL PROGRESO", value: "AJUCHITLÁN DEL PROGRESO" },
        { label: "ALCOZAUCA DE GUERRERO", value: "ALCOZAUCA DE GUERRERO" },
        { label: "ALPOYECA", value: "ALPOYECA" },
        { label: "APAXTLA", value: "APAXTLA" },
        { label: "ARCELIA", value: "ARCELIA" },
        { label: "ATENANGO DEL RÍO", value: "ATENANGO DEL RÍO" },
        { label: "ATLAMAJALCINGO DEL MONTE", value: "ATLAMAJALCINGO DEL MONTE" },
        { label: "ATLIXTAC", value: "ATLIXTAC" },
        { label: "ATOYAC DE ÁLVAREZ", value: "ATOYAC DE ÁLVAREZ" },
        { label: "AYUTLA DE LOS LIBRES", value: "AYUTLA DE LOS LIBRES" },
        { label: "AZOYÚ", value: "AZOYÚ" },
        { label: "BENITO JUÁREZ", value: "BENITO JUÁREZ" },
        { label: "BUENAVISTA DE CUÉLLAR", value: "BUENAVISTA DE CUÉLLAR" },
        { label: "COAHUAYUTLA DE JOSÉ MARÍA IZAZAGA", value: "COAHUAYUTLA DE JOSÉ MARÍA IZAZAGA" },
        { label: "COCULA", value: "COCULA" },
        { label: "COPALA", value: "COPALA" },
        { label: "COPALILLO", value: "COPALILLO" },
        { label: "COPANATOYAC", value: "COPANATOYAC" },
        { label: "COYUCA DE BENÍTEZ", value: "COYUCA DE BENÍTEZ" },
        { label: "COYUCA DE CATALÁN", value: "COYUCA DE CATALÁN" },
        { label: "CUAJINICUILAPA", value: "CUAJINICUILAPA" },
        { label: "CUALÁC", value: "CUALÁC" },
        { label: "CUAUTEPEC", value: "CUAUTEPEC" },
        { label: "CUETZALA DEL PROGRESO", value: "CUETZALA DEL PROGRESO" },
        { label: "CUTZAMALA DE PINZÓN", value: "CUTZAMALA DE PINZÓN" },
        { label: "CHILAPA DE ÁLVAREZ", value: "CHILAPA DE ÁLVAREZ" },
        { label: "CHILPANCINGO DE LOS BRAVO", value: "CHILPANCINGO DE LOS BRAVO" },
        { label: "FLORENCIO VILLARREAL", value: "FLORENCIO VILLARREAL" },
        { label: "GENERAL CANUTO A. NERI", value: "GENERAL CANUTO A. NERI" },
        { label: "GENERAL HELIODORO CASTILLO", value: "GENERAL HELIODORO CASTILLO" },
        { label: "HUAMUXTITLÁN", value: "HUAMUXTITLÁN" },
        { label: "HUITZUCO DE LOS FIGUEROA", value: "HUITZUCO DE LOS FIGUEROA" },
        { label: "IGUALA DE LA INDEPENDENCIA", value: "IGUALA DE LA INDEPENDENCIA" },
        { label: "IGUALAPA", value: "IGUALAPA" },
        { label: "IXCATEOPAN DE CUAUHTÉMOC", value: "IXCATEOPAN DE CUAUHTÉMOC" },
        { label: "ZIHUATANEJO DE AZUETA", value: "ZIHUATANEJO DE AZUETA" },
        { label: "JUAN R. ESCUDERO", value: "JUAN R. ESCUDERO" },
        { label: "LEONARDO BRAVO", value: "LEONARDO BRAVO" },
        { label: "MALINALTEPEC", value: "MALINALTEPEC" },
        { label: "MÁRTIR DE CUILAPAN", value: "MÁRTIR DE CUILAPAN" },
        { label: "METLATÓNOC", value: "METLATÓNOC" },
        { label: "MOCHITLÁN", value: "MOCHITLÁN" },
        { label: "OLINALÁ", value: "OLINALÁ" },
        { label: "OMETEPEC", value: "OMETEPEC" },
        { label: "PEDRO ASCENCIO ALQUISIRAS", value: "PEDRO ASCENCIO ALQUISIRAS" },
        { label: "PETATLÁN", value: "PETATLÁN" },
        { label: "PILCAYA", value: "PILCAYA" },
        { label: "PUNGARABATO", value: "PUNGARABATO" },
        { label: "QUECHULTENANGO", value: "QUECHULTENANGO" },
        { label: "SAN LUIS ACATLÁN", value: "SAN LUIS ACATLÁN" },
        { label: "SAN MARCOS", value: "SAN MARCOS" },
        { label: "SAN MIGUEL TOTOLAPAN", value: "SAN MIGUEL TOTOLAPAN" },
        { label: "TAXCO DE ALARCÓN", value: "TAXCO DE ALARCÓN" },
        { label: "TECOANAPA", value: "TECOANAPA" },
        { label: "TÉCPAN DE GALEANA", value: "TÉCPAN DE GALEANA" },
        { label: "TELOLOAPAN", value: "TELOLOAPAN" },
        { label: "TEPECOACUILCO DE TRUJANO", value: "TEPECOACUILCO DE TRUJANO" },
        { label: "TETIPAC", value: "TETIPAC" },
        { label: "TIXTLA DE GUERRERO", value: "TIXTLA DE GUERRERO" },
        { label: "TLACOACHISTLAHUACA", value: "TLACOACHISTLAHUACA" },
        { label: "TLACOAPA", value: "TLACOAPA" },
        { label: "TLALCHAPA", value: "TLALCHAPA" },
        { label: "TLALIXTAQUILLA DE MALDONADO", value: "TLALIXTAQUILLA DE MALDONADO" },
        { label: "TLAPA DE COMONFORT", value: "TLAPA DE COMONFORT" },
        { label: "TLAPEHUALA", value: "TLAPEHUALA" },
        { label: "LA UNIÓN DE ISIDORO MONTES DE OCA", value: "LA UNIÓN DE ISIDORO MONTES DE OCA" },
        { label: "XALPATLÁHUAC", value: "XALPATLÁHUAC" },
        { label: "XOCHIHUEHUETLÁN", value: "XOCHIHUEHUETLÁN" },
        { label: "XOCHISTLAHUACA", value: "XOCHISTLAHUACA" },
        { label: "ZAPOTITLÁN TABLAS", value: "ZAPOTITLÁN TABLAS" },
        { label: "ZIRÁNDARO", value: "ZIRÁNDARO" },
        { label: "ZITLALA", value: "ZITLALA" },
        { label: "EDUARDO NERI", value: "EDUARDO NERI" },
        { label: "ACATEPEC", value: "ACATEPEC" },
        { label: "MARQUELIA", value: "MARQUELIA" },
        { label: "COCHOAPA EL GRANDE", value: "COCHOAPA EL GRANDE" },
        { label: "JOSÉ JOAQUÍN DE HERRERA", value: "JOSÉ JOAQUÍN DE HERRERA" },
        { label: "JUCHITÁN", value: "JUCHITÁN" },
        { label: "ILIATENCO", value: "ILIATENCO" }
    ],
    "Hidalgo": [
        { label: "ACATLÁN", value: "ACATLÁN" },
        { label: "ACAXOCHITLÁN", value: "ACAXOCHITLÁN" },
        { label: "ACTOPAN", value: "ACTOPAN" },
        { label: "AGUA BLANCA DE ITURBIDE", value: "AGUA BLANCA DE ITURBIDE" },
        { label: "AJACUBA", value: "AJACUBA" },
        { label: "ALFAJAYUCAN", value: "ALFAJAYUCAN" },
        { label: "ALMOLOYA", value: "ALMOLOYA" },
        { label: "APAN", value: "APAN" },
        { label: "EL ARENAL", value: "EL ARENAL" },
        { label: "ATITALAQUIA", value: "ATITALAQUIA" },
        { label: "ATLAPEXCO", value: "ATLAPEXCO" },
        { label: "ATOTONILCO EL GRANDE", value: "ATOTONILCO EL GRANDE" },
        { label: "ATOTONILCO DE TULA", value: "ATOTONILCO DE TULA" },
        { label: "CALNALI", value: "CALNALI" },
        { label: "CARDONAL", value: "CARDONAL" },
        { label: "CUAUTEPEC DE HINOJOSA", value: "CUAUTEPEC DE HINOJOSA" },
        { label: "CHAPANTONGO", value: "CHAPANTONGO" },
        { label: "CHAPULHUACÁN", value: "CHAPULHUACÁN" },
        { label: "CHILCUAUTLA", value: "CHILCUAUTLA" },
        { label: "ELOXOCHITLÁN", value: "ELOXOCHITLÁN" },
        { label: "EMILIANO ZAPATA", value: "EMILIANO ZAPATA" },
        { label: "EPAZOYUCAN", value: "EPAZOYUCAN" },
        { label: "FRANCISCO I. MADERO", value: "FRANCISCO I. MADERO" },
        { label: "HUASCA DE OCAMPO", value: "HUASCA DE OCAMPO" },
        { label: "HUAUTLA", value: "HUAUTLA" },
        { label: "HUAZALINGO", value: "HUAZALINGO" },
        { label: "HUEHUETLA", value: "HUEHUETLA" },
        { label: "HUEJUTLA DE REYES", value: "HUEJUTLA DE REYES" },
        { label: "HUICHAPAN", value: "HUICHAPAN" },
        { label: "IXMIQUILPAN", value: "IXMIQUILPAN" },
        { label: "JACALA DE LEDEZMA", value: "JACALA DE LEDEZMA" },
        { label: "JALTOCÁN", value: "JALTOCÁN" },
        { label: "JUÁREZ HIDALGO", value: "JUÁREZ HIDALGO" },
        { label: "LOLOTLA", value: "LOLOTLA" },
        { label: "METEPEC", value: "METEPEC" },
        { label: "SAN AGUSTÍN METZQUITITLÁN", value: "SAN AGUSTÍN METZQUITITLÁN" },
        { label: "METZTITLÁN", value: "METZTITLÁN" },
        { label: "MINERAL DEL CHICO", value: "MINERAL DEL CHICO" },
        { label: "MINERAL DEL MONTE", value: "MINERAL DEL MONTE" },
        { label: "LA MISIÓN", value: "LA MISIÓN" },
        { label: "MIXQUIAHUALA DE JUÁREZ", value: "MIXQUIAHUALA DE JUÁREZ" },
        { label: "MOLANGO DE ESCAMILLA", value: "MOLANGO DE ESCAMILLA" },
        { label: "NICOLÁS FLORES", value: "NICOLÁS FLORES" },
        { label: "NOPALA DE VILLAGRÁN", value: "NOPALA DE VILLAGRÁN" },
        { label: "OMITLÁN DE JUÁREZ", value: "OMITLÁN DE JUÁREZ" },
        { label: "SAN FELIPE ORIZATLÁN", value: "SAN FELIPE ORIZATLÁN" },
        { label: "PACULA", value: "PACULA" },
        { label: "PACHUCA DE SOTO", value: "PACHUCA DE SOTO" },
        { label: "PISAFLORES", value: "PISAFLORES" },
        { label: "PROGRESO DE OBREGÓN", value: "PROGRESO DE OBREGÓN" },
        { label: "MINERAL DE LA REFORMA", value: "MINERAL DE LA REFORMA" },
        { label: "SAN AGUSTÍN TLAXIACA", value: "SAN AGUSTÍN TLAXIACA" },
        { label: "SAN BARTOLO TUTOTEPEC", value: "SAN BARTOLO TUTOTEPEC" },
        { label: "SAN SALVADOR", value: "SAN SALVADOR" },
        { label: "SANTIAGO DE ANAYA", value: "SANTIAGO DE ANAYA" },
        { label: "SANTIAGO TULANTEPEC", value: "SANTIAGO TULANTEPEC" },
        { label: "SINGUILUCAN", value: "SINGUILUCAN" },
        { label: "TASQUILLO", value: "TASQUILLO" },
        { label: "TECOZAUTLA", value: "TECOZAUTLA" },
        { label: "TENANGO DE DORIA", value: "TENANGO DE DORIA" },
        { label: "TEPEAPULCO", value: "TEPEAPULCO" },
        { label: "TEPEHUACÁN DE GUERRERO", value: "TEPEHUACÁN DE GUERRERO" },
        { label: "TEPEJI DEL RÍO DE OCAMPO", value: "TEPEJI DEL RÍO DE OCAMPO" },
        { label: "TEPETITLÁN", value: "TEPETITLÁN" },
        { label: "TETEPANGO", value: "TETEPANGO" },
        { label: "VILLA DE TEZONTEPEC", value: "VILLA DE TEZONTEPEC" },
        { label: "TEZONTEPEC DE ALDAMA", value: "TEZONTEPEC DE ALDAMA" },
        { label: "TIANGUISTENGO", value: "TIANGUISTENGO" },
        { label: "TIZAYUCA", value: "TIZAYUCA" },
        { label: "TLAHUELILPAN", value: "TLAHUELILPAN" },
        { label: "TLAHUILTEPA", value: "TLAHUILTEPA" },
        { label: "TLANALAPA", value: "TLANALAPA" },
        { label: "TLANCHINOL", value: "TLANCHINOL" },
        { label: "TLAXCOAPAN", value: "TLAXCOAPAN" },
        { label: "TOLCAYUCA", value: "TOLCAYUCA" },
        { label: "TULA DE ALLENDE", value: "TULA DE ALLENDE" },
        { label: "TULANCINGO DE BRAVO", value: "TULANCINGO DE BRAVO" },
        { label: "XOCHIATIPAN", value: "XOCHIATIPAN" },
        { label: "XOCHICOATLÁN", value: "XOCHICOATLÁN" },
        { label: "YAHUALICA", value: "YAHUALICA" },
        { label: "ZACUALTIPÁN DE ÁNGELES", value: "ZACUALTIPÁN DE ÁNGELES" },
        { label: "ZAPOTLÁN DE JUÁREZ", value: "ZAPOTLÁN DE JUÁREZ" },
        { label: "ZEMPOALA", value: "ZEMPOALA" },
        { label: "ZIMAPÁN", value: "ZIMAPÁN" }
    ],
    "Jalisco": [
        { label: "ACATIC", value: "ACATIC" },
        { label: "ACATLÁN DE JUÁREZ", value: "ACATLÁN DE JUÁREZ" },
        { label: "AHUALULCO DE MERCADO", value: "AHUALULCO DE MERCADO" },
        { label: "AMACUECA", value: "AMACUECA" },
        { label: "AMATITÁN", value: "AMATITÁN" },
        { label: "AMECA", value: "AMECA" },
        { label: "SAN JUANITO DE ESCOBEDO", value: "SAN JUANITO DE ESCOBEDO" },
        { label: "ARANDAS", value: "ARANDAS" },
        { label: "EL ARENAL", value: "EL ARENAL" },
        { label: "ATEMAJAC DE BRIZUELA", value: "ATEMAJAC DE BRIZUELA" },
        { label: "ATENGO", value: "ATENGO" },
        { label: "ATENGUILLO", value: "ATENGUILLO" },
        { label: "ATOTONILCO EL ALTO", value: "ATOTONILCO EL ALTO" },
        { label: "ATOYAC", value: "ATOYAC" },
        { label: "AUTLÁN DE NAVARRO", value: "AUTLÁN DE NAVARRO" },
        { label: "AYOTLÁN", value: "AYOTLÁN" },
        { label: "AYUTLA", value: "AYUTLA" },
        { label: "LA BARCA", value: "LA BARCA" },
        { label: "BOLAÑOS", value: "BOLAÑOS" },
        { label: "CABO CORRIENTES", value: "CABO CORRIENTES" },
        { label: "CASIMIRO CASTILLO", value: "CASIMIRO CASTILLO" },
        { label: "CIHUATLÁN", value: "CIHUATLÁN" },
        { label: "ZAPOTLÁN EL GRANDE", value: "ZAPOTLÁN EL GRANDE" },
        { label: "COCULA", value: "COCULA" },
        { label: "COLOTLÁN", value: "COLOTLÁN" },
        { label: "CONCEPCIÓN DE BUENOS AIRES", value: "CONCEPCIÓN DE BUENOS AIRES" },
        { label: "CUAUTITLÁN DE GARCÍA BARRAGÁN", value: "CUAUTITLÁN DE GARCÍA BARRAGÁN" },
        { label: "CUAUTLA", value: "CUAUTLA" },
        { label: "CUQUÍO", value: "CUQUÍO" },
        { label: "CHAPALA", value: "CHAPALA" },
        { label: "CHIMALTITÁN", value: "CHIMALTITÁN" },
        { label: "CHIQUILISTLÁN", value: "CHIQUILISTLÁN" },
        { label: "DEGOLLADO", value: "DEGOLLADO" },
        { label: "EJUTLA", value: "EJUTLA" },
        { label: "ENCARNACIÓN DE DÍAZ", value: "ENCARNACIÓN DE DÍAZ" },
        { label: "ETZATLÁN", value: "ETZATLÁN" },
        { label: "EL GRULLO", value: "EL GRULLO" },
        { label: "GUACHINANGO", value: "GUACHINANGO" },
        { label: "GUADALAJARA", value: "GUADALAJARA" },
        { label: "HOSTOTIPAQUILLO", value: "HOSTOTIPAQUILLO" },
        { label: "HUEJÚCAR", value: "HUEJÚCAR" },
        { label: "HUEJUQUILLA EL ALTO", value: "HUEJUQUILLA EL ALTO" },
        { label: "LA HUERTA", value: "LA HUERTA" },
        { label: "IXTLAHUACÁN DE LOS MEMBRILLOS", value: "IXTLAHUACÁN DE LOS MEMBRILLOS" },
        { label: "IXTLAHUACÁN DEL RÍO", value: "IXTLAHUACÁN DEL RÍO" },
        { label: "JALOSTOTITLÁN", value: "JALOSTOTITLÁN" },
        { label: "JAMAY", value: "JAMAY" },
        { label: "JESÚS MARÍA", value: "JESÚS MARÍA" },
        { label: "JILOTLÁN DE LOS DOLORES", value: "JILOTLÁN DE LOS DOLORES" },
        { label: "JOCOTEPEC", value: "JOCOTEPEC" },
        { label: "JUANACATLÁN", value: "JUANACATLÁN" },
        { label: "JUCHITLÁN", value: "JUCHITLÁN" },
        { label: "LAGOS DE MORENO", value: "LAGOS DE MORENO" },
        { label: "EL LIMÓN", value: "EL LIMÓN" },
        { label: "MAGDALENA", value: "MAGDALENA" },
        { label: "SANTA MARÍA DEL ORO", value: "SANTA MARÍA DEL ORO" },
        { label: "LA MANZANILLA DE LA PAZ", value: "LA MANZANILLA DE LA PAZ" },
        { label: "MASCOTA", value: "MASCOTA" },
        { label: "MAZAMITLA", value: "MAZAMITLA" },
        { label: "MEXTICACÁN", value: "MEXTICACÁN" },
        { label: "MEZQUITIC", value: "MEZQUITIC" },
        { label: "MIXTLÁN", value: "MIXTLÁN" },
        { label: "OCOTLÁN", value: "OCOTLÁN" },
        { label: "OJUELOS DE JALISCO", value: "OJUELOS DE JALISCO" },
        { label: "PIHUAMO", value: "PIHUAMO" },
        { label: "PONCITLÁN", value: "PONCITLÁN" },
        { label: "PUERTO", value: "PUERTO" },
        { label: "VALLARTA", value: "VALLARTA" },
        { label: "VILLA PURIFICACIÓN", value: "VILLA PURIFICACIÓN" },
        { label: "QUITUPAN", value: "QUITUPAN" },
        { label: "EL SALTO", value: "EL SALTO" },
        { label: "SAN CRISTÓBAL DE LA BARRANCA", value: "SAN CRISTÓBAL DE LA BARRANCA" },
        { label: "SAN DIEGO DE ALEJANDRÍA", value: "SAN DIEGO DE ALEJANDRÍA" },
        { label: "SAN JUAN DE LOS LAGOS", value: "SAN JUAN DE LOS LAGOS" },
        { label: "SAN JULIÁN", value: "SAN JULIÁN" },
        { label: "SAN MARCOS", value: "SAN MARCOS" },
        { label: "SAN MARTÍN DE BOLAÑOS", value: "SAN MARTÍN DE BOLAÑOS" },
        { label: "SAN MARTÍN HIDALGO", value: "SAN MARTÍN HIDALGO" },
        { label: "SAN MIGUEL EL ALTO", value: "SAN MIGUEL EL ALTO" },
        { label: "GÓMEZ FARÍAS", value: "GÓMEZ FARÍAS" },
        { label: "SAN SEBASTIÁN DEL OESTE", value: "SAN SEBASTIÁN DEL OESTE" },
        { label: "SANTA MARÍA DE LOS ÁNGELES", value: "SANTA MARÍA DE LOS ÁNGELES" },
        { label: "SAYULA", value: "SAYULA" },
        { label: "TALA", value: "TALA" },
        { label: "TALPA DE ALLENDE", value: "TALPA DE ALLENDE" },
        { label: "TAMAZULA DE GORDIANO", value: "TAMAZULA DE GORDIANO" },
        { label: "TAPALPA", value: "TAPALPA" },
        { label: "TECALITLÁN", value: "TECALITLÁN" },
        { label: "TECOLOTLÁN", value: "TECOLOTLÁN" },
        { label: "TECHALUTA DE MONTENEGRO", value: "TECHALUTA DE MONTENEGRO" },
        { label: "TENAMAXTLÁN", value: "TENAMAXTLÁN" },
        { label: "TEOCALTICHE", value: "TEOCALTICHE" },
        { label: "TEOCUITATLÁN DE CORONA", value: "TEOCUITATLÁN DE CORONA" },
        { label: "TEPATITLÁN DE MORELOS", value: "TEPATITLÁN DE MORELOS" },
        { label: "TEQUILA", value: "TEQUILA" },
        { label: "TEUCHITLÁN", value: "TEUCHITLÁN" },
        { label: "TIZAPÁN EL ALTO", value: "TIZAPÁN EL ALTO" },
        { label: "TLAJOMULCO DE ZÚÑIGA", value: "TLAJOMULCO DE ZÚÑIGA" },
        { label: "SAN PEDRO TLAQUEPAQUE", value: "SAN PEDRO TLAQUEPAQUE" },
        { label: "TOLIMÁN", value: "TOLIMÁN" },
        { label: "TOMATLÁN", value: "TOMATLÁN" },
        { label: "TONALÁ", value: "TONALÁ" },
        { label: "TONAYA", value: "TONAYA" },
        { label: "TONILA", value: "TONILA" },
        { label: "TOTATICHE", value: "TOTATICHE" },
        { label: "TOTOTLÁN", value: "TOTOTLÁN" },
        { label: "TUXCACUESCO", value: "TUXCACUESCO" },
        { label: "TUXCUECA", value: "TUXCUECA" },
        { label: "TUXPAN", value: "TUXPAN" },
        { label: "UNIÓN DE SAN ANTONIO", value: "UNIÓN DE SAN ANTONIO" },
        { label: "UNIÓN DE TULA", value: "UNIÓN DE TULA" },
        { label: "VALLE DE GUADALUPE", value: "VALLE DE GUADALUPE" },
        { label: "VALLE DE JUÁREZ", value: "VALLE DE JUÁREZ" },
        { label: "SAN GABRIEL", value: "SAN GABRIEL" },
        { label: "VILLA CORONA", value: "VILLA CORONA" },
        { label: "VILLA GUERRERO", value: "VILLA GUERRERO" },
        { label: "VILLA HIDALGO", value: "VILLA HIDALGO" },
        { label: "CAÑADAS DE OBREGÓN", value: "CAÑADAS DE OBREGÓN" },
        { label: "YAHUALICA DE GONZÁLEZ GALLO", value: "YAHUALICA DE GONZÁLEZ GALLO" },
        { label: "ZACOALCO DE TORRES", value: "ZACOALCO DE TORRES" },
        { label: "ZAPOPAN", value: "ZAPOPAN" },
        { label: "ZAPOTILTIC", value: "ZAPOTILTIC" },
        { label: "ZAPOTITLÁN DE VADILLO", value: "ZAPOTITLÁN DE VADILLO" },
        { label: "ZAPOTLÁN DEL REY", value: "ZAPOTLÁN DEL REY" },
        { label: "ZAPOTLANEJO", value: "ZAPOTLANEJO" },
        { label: "SAN IGNACIO CERRO GORDO", value: "SAN IGNACIO CERRO GORDO" }
    ],
    "Michoacán": [
        { label: "ACUITZIO", value: "ACUITZIO" },
        { label: "AGUILILLA", value: "AGUILILLA" },
        { label: "ÁLVARO OBREGÓN", value: "ÁLVARO OBREGÓN" },
        { label: "ANGAMACUTIRO", value: "ANGAMACUTIRO" },
        { label: "ANGANGUEO", value: "ANGANGUEO" },
        { label: "APATZINGÁN", value: "APATZINGÁN" },
        { label: "APORO", value: "APORO" },
        { label: "AQUILA", value: "AQUILA" },
        { label: "ARIO", value: "ARIO" },
        { label: "ARTEAGA", value: "ARTEAGA" },
        { label: "BRISEÑAS", value: "BRISEÑAS" },
        { label: "BUENAVISTA", value: "BUENAVISTA" },
        { label: "CARÁCUARO", value: "CARÁCUARO" },
        { label: "COAHUAYANA", value: "COAHUAYANA" },
        { label: "COALCOMÁN DE VÁZQUEZ PALLARES", value: "COALCOMÁN DE VÁZQUEZ PALLARES" },
        { label: "COENEO", value: "COENEO" },
        { label: "CONTEPEC", value: "CONTEPEC" },
        { label: "COPÁNDARO", value: "COPÁNDARO" },
        { label: "COTIJA", value: "COTIJA" },
        { label: "CUITZEO", value: "CUITZEO" },
        { label: "CHARAPAN", value: "CHARAPAN" },
        { label: "CHARO", value: "CHARO" },
        { label: "CHAVINDA", value: "CHAVINDA" },
        { label: "CHERÁN", value: "CHERÁN" },
        { label: "CHILCHOTA", value: "CHILCHOTA" },
        { label: "CHINICUILA", value: "CHINICUILA" },
        { label: "CHUCÁNDIRO", value: "CHUCÁNDIRO" },
        { label: "CHURINTZIO", value: "CHURINTZIO" },
        { label: "CHURUMUCO", value: "CHURUMUCO" },
        { label: "ECUANDUREO", value: "ECUANDUREO" },
        { label: "EPITACIO HUERTA", value: "EPITACIO HUERTA" },
        { label: "ERONGARÍCUARO", value: "ERONGARÍCUARO" },
        { label: "GABRIEL ZAMORA", value: "GABRIEL ZAMORA" },
        { label: "HIDALGO", value: "HIDALGO" },
        { label: "LA HUACANA", value: "LA HUACANA" },
        { label: "HUANDACAREO", value: "HUANDACAREO" },
        { label: "HUANIQUEO", value: "HUANIQUEO" },
        { label: "HUETAMO", value: "HUETAMO" },
        { label: "HUIRAMBA", value: "HUIRAMBA" },
        { label: "INDAPARAPEO", value: "INDAPARAPEO" },
        { label: "IRIMBO", value: "IRIMBO" },
        { label: "IXTLÁN", value: "IXTLÁN" },
        { label: "JACONA", value: "JACONA" },
        { label: "JIMÉNEZ", value: "JIMÉNEZ" },
        { label: "JIQUILPAN", value: "JIQUILPAN" },
        { label: "JUÁREZ", value: "JUÁREZ" },
        { label: "JUNGAPEO", value: "JUNGAPEO" },
        { label: "LAGUNILLAS", value: "LAGUNILLAS" },
        { label: "MADERO", value: "MADERO" },
        { label: "MARAVATÍO", value: "MARAVATÍO" },
        { label: "MARCOS CASTELLANOS", value: "MARCOS CASTELLANOS" },
        { label: "LÁZARO CÁRDENAS", value: "LÁZARO CÁRDENAS" },
        { label: "MORELIA", value: "MORELIA" },
        { label: "MORELOS", value: "MORELOS" },
        { label: "MÚGICA", value: "MÚGICA" },
        { label: "NAHUATZEN", value: "NAHUATZEN" },
        { label: "NOCUPÉTARO", value: "NOCUPÉTARO" },
        { label: "NUEVO PARANGARICUTIRO", value: "NUEVO PARANGARICUTIRO" },
        { label: "NUEVO URECHO", value: "NUEVO URECHO" },
        { label: "NUMARÁN", value: "NUMARÁN" },
        { label: "OCAMPO", value: "OCAMPO" },
        { label: "PAJACUARÁN", value: "PAJACUARÁN" },
        { label: "PANINDÍCUARO", value: "PANINDÍCUARO" },
        { label: "PARÁCUARO", value: "PARÁCUARO" },
        { label: "PARACHO", value: "PARACHO" },
        { label: "PÁTZCUARO", value: "PÁTZCUARO" },
        { label: "PENJAMILLO", value: "PENJAMILLO" },
        { label: "PERIBÁN", value: "PERIBÁN" },
        { label: "LA PIEDAD", value: "LA PIEDAD" },
        { label: "PURÉPERO", value: "PURÉPERO" },
        { label: "PURUÁNDIRO", value: "PURUÁNDIRO" },
        { label: "QUERÉNDARO", value: "QUERÉNDARO" },
        { label: "QUIROGA", value: "QUIROGA" },
        { label: "COJUMATLÁN DE RÉGULES", value: "COJUMATLÁN DE RÉGULES" },
        { label: "LOS REYES", value: "LOS REYES" },
        { label: "SAHUAYO", value: "SAHUAYO" },
        { label: "SAN LUCAS", value: "SAN LUCAS" },
        { label: "SANTA ANA MAYA", value: "SANTA ANA MAYA" },
        { label: "SALVADOR ESCALANTE", value: "SALVADOR ESCALANTE" },
        { label: "SENGUIO", value: "SENGUIO" },
        { label: "SUSUPUATO", value: "SUSUPUATO" },
        { label: "TACÁMBARO", value: "TACÁMBARO" },
        { label: "TANCÍTARO", value: "TANCÍTARO" },
        { label: "TANGAMANDAPIO", value: "TANGAMANDAPIO" },
        { label: "TANGANCÍCUARO", value: "TANGANCÍCUARO" },
        { label: "TANHUATO", value: "TANHUATO" },
        { label: "TARETAN", value: "TARETAN" },
        { label: "TARÍMBARO", value: "TARÍMBARO" },
        { label: "TEPALCATEPEC", value: "TEPALCATEPEC" },
        { label: "TINGAMBATO", value: "TINGAMBATO" },
        { label: "TINGÜINDÍN", value: "TINGÜINDÍN" },
        { label: "TIQUICHEO DE NICOLÁS ROMERO", value: "TIQUICHEO DE NICOLÁS ROMERO" },
        { label: "TLALPUJAHUA", value: "TLALPUJAHUA" },
        { label: "TLAZAZALCA", value: "TLAZAZALCA" },
        { label: "TOCUMBO", value: "TOCUMBO" },
        { label: "TUMBISCATÍO", value: "TUMBISCATÍO" },
        { label: "TURICATO", value: "TURICATO" },
        { label: "TUXPAN", value: "TUXPAN" },
        { label: "TUZANTLA", value: "TUZANTLA" },
        { label: "TZINTZUNTZAN", value: "TZINTZUNTZAN" },
        { label: "TZITZIO", value: "TZITZIO" },
        { label: "URUAPAN", value: "URUAPAN" },
        { label: "VENUSTIANO CARRANZA", value: "VENUSTIANO CARRANZA" },
        { label: "VILLAMAR", value: "" },
        { label: "VISTA HERMOSA", value: "" },
        { label: "YURÉCUARO", value: "" },
        { label: "ZACAPU", value: "" },
        { label: "ZAMORA", value: "" },
        { label: "ZINÁPARO", value: "" },
        { label: "ZINAPÉCUARO", value: "" },
        { label: "ZIRACUARETIRO", value: "" },
        { label: "ZITÁCUARO", value: "" },
        { label: "JOSÉ SIXTO VERDUZCO", value: "" }
    ],
    "Morelos": [
        { label: "AMACUZAC", value: "AMACUZAC" },
        { label: "ATLATLAHUCAN", value: "ATLATLAHUCAN" },
        { label: "AXOCHIAPAN", value: "AXOCHIAPAN" },
        { label: "AYALA", value: "AYALA" },
        { label: "COATLÁN DEL RÍO", value: "COATLÁN DEL RÍO" },
        { label: "CUAUTLA", value: "CUAUTLA" },
        { label: "CUERNAVACA", value: "CUERNAVACA" },
        { label: "EMILIANO ZAPATA", value: "EMILIANO ZAPATA" },
        { label: "HUITZILAC", value: "HUITZILAC" },
        { label: "JANTETELCO", value: "JANTETELCO" },
        { label: "JIUTEPEC", value: "JIUTEPEC" },
        { label: "JOJUTLA", value: "JOJUTLA" },
        { label: "JONACATEPEC DE LEANDRO VALLE", value: "JONACATEPEC DE LEANDRO VALLE" },
        { label: "MAZATEPEC", value: "MAZATEPEC" },
        { label: "MIACATLÁN", value: "MIACATLÁN" },
        { label: "OCUITUCO", value: "OCUITUCO" },
        { label: "PUENTE DE IXTLA", value: "PUENTE DE IXTLA" },
        { label: "TEMIXCO", value: "TEMIXCO" },
        { label: "TEPALCINGO", value: "TEPALCINGO" },
        { label: "TEPOZTLÁN", value: "TEPOZTLÁN" },
        { label: "TETECALA", value: "TETECALA" },
        { label: "TETELA DEL VOLCÁN", value: "TETELA DEL VOLCÁN" },
        { label: "TLALNEPANTLA", value: "TLALNEPANTLA" },
        { label: "TLALTIZAPÁN DE ZAPATA", value: "TLALTIZAPÁN DE ZAPATA" },
        { label: "TLAQUILTENANGO", value: "TLAQUILTENANGO" },
        { label: "TLAYACAPAN", value: "TLAYACAPAN" },
        { label: "TOTOLAPAN", value: "TOTOLAPAN" },
        { label: "XOCHITEPEC", value: "XOCHITEPEC" },
        { label: "YAUTEPEC", value: "YAUTEPEC" },
        { label: "YECAPIXTLA", value: "YECAPIXTLA" },
        { label: "ZACATEPEC", value: "ZACATEPEC" },
        { label: "ZACUALPAN DE AMILPAS", value: "ZACUALPAN DE AMILPAS" },
        { label: "TEMOAC", value: "TEMOAC" }
    ],
    "Nayarit": [
        { label: "ACAPONETA", value: "ACAPONETA" },
        { label: "AHUACATLÁN", value: "AHUACATLÁN" },
        { label: "AMATLÁN DE CAÑAS", value: "AMATLÁN DE CAÑAS" },
        { label: "COMPOSTELA", value: "COMPOSTELA" },
        { label: "HUAJICORI", value: "HUAJICORI" },
        { label: "IXTLÁN DEL RÍO", value: "IXTLÁN DEL RÍO" },
        { label: "JALA", value: "JALA" },
        { label: "XALISCO", value: "XALISCO" },
        { label: "DEL NAYAR", value: "DEL NAYAR" },
        { label: "ROSAMORADA", value: "ROSAMORADA" },
        { label: "RUÍZ", value: "RUÍZ" },
        { label: "SAN BLAS", value: "SAN BLAS" },
        { label: "SAN PEDRO LAGUNILLAS", value: "SAN PEDRO LAGUNILLAS" },
        { label: "SANTA MARÍA DEL ORO", value: "SANTA MARÍA DEL ORO" },
        { label: "SANTIAGO IXCUINTLA", value: "SANTIAGO IXCUINTLA" },
        { label: "TECUALA", value: "TECUALA" },
        { label: "TEPIC", value: "TEPIC" },
        { label: "TUXPAN", value: "TUXPAN" },
        { label: "LA YESCA", value: "LA YESCA" },
        { label: "BAHÍA DE BANDERAS", value: "BAHÍA DE BANDERAS" }
    ],
    "Nuevo León": [
        { label: "ABASOLO", value: "ABASOLO" },
        { label: "AGUALEGUAS", value: "AGUALEGUAS" },
        { label: "LOS ALDAMAS", value: "LOS ALDAMAS" },
        { label: "ALLENDE", value: "ALLENDE" },
        { label: "ANÁHUAC", value: "ANÁHUAC" },
        { label: "APODACA", value: "APODACA" },
        { label: "ARAMBERRI", value: "ARAMBERRI" },
        { label: "BUSTAMANTE", value: "BUSTAMANTE" },
        { label: "CADEREYTA JIMÉNEZ", value: "CADEREYTA JIMÉNEZ" },
        { label: "EL CARMEN", value: "EL CARMEN" },
        { label: "CERRALVO", value: "CERRALVO" },
        { label: "CIÉNEGA DE FLORES", value: "CIÉNEGA DE FLORES" },
        { label: "CHINA", value: "CHINA" },
        { label: "DOCTOR ARROYO", value: "DOCTOR ARROYO" },
        { label: "DOCTOR COSS", value: "DOCTOR COSS" },
        { label: "DOCTOR GONZÁLEZ", value: "DOCTOR GONZÁLEZ" },
        { label: "GALEANA", value: "GALEANA" },
        { label: "GARCÍA", value: "GARCÍA" },
        { label: "SAN PEDRO GARZA GARCÍA", value: "SAN PEDRO GARZA GARCÍA" },
        { label: "GENERAL BRAVO", value: "GENERAL BRAVO" },
        { label: "GENERAL ESCOBEDO", value: "GENERAL ESCOBEDO" },
        { label: "GENERAL TERÁN", value: "GENERAL TERÁN" },
        { label: "GENERAL TREVIÑO", value: "GENERAL TREVIÑO" },
        { label: "GENERAL ZARAGOZA", value: "GENERAL ZARAGOZA" },
        { label: "GENERAL ZUAZUA", value: "GENERAL ZUAZUA" },
        { label: "GUADALUPE", value: "GUADALUPE" },
        { label: "LOS HERRERAS", value: "LOS HERRERAS" },
        { label: "HIGUERAS", value: "HIGUERAS" },
        { label: "HUALAHUISES", value: "HUALAHUISES" },
        { label: "ITURBIDE", value: "ITURBIDE" },
        { label: "JUÁREZ", value: "JUÁREZ" },
        { label: "LAMPAZOS DE NARANJO", value: "LAMPAZOS DE NARANJO" },
        { label: "LINARES", value: "LINARES" },
        { label: "MARÍN", value: "MARÍN" },
        { label: "MELCHOR OCAMPO", value: "MELCHOR OCAMPO" },
        { label: "MIER Y NORIEGA", value: "MIER Y NORIEGA" },
        { label: "MINA", value: "MINA" },
        { label: "MONTEMORELOS", value: "MONTEMORELOS" },
        { label: "MONTERREY", value: "MONTERREY" },
        { label: "PARÁS", value: "PARÁS" },
        { label: "PESQUERÍA", value: "PESQUERÍA" },
        { label: "LOS RAMONES", value: "LOS RAMONES" },
        { label: "RAYONES", value: "RAYONES" },
        { label: "SABINAS HIDALGO", value: "SABINAS HIDALGO" },
        { label: "SALINAS VICTORIA", value: "SALINAS VICTORIA" },
        { label: "SAN NICOLÁS DE LOS GARZA", value: "SAN NICOLÁS DE LOS GARZA" },
        { label: "HIDALGO", value: "HIDALGO" },
        { label: "SANTA CATARINA", value: "SANTA CATARINA" },
        { label: "SANTIAGO", value: "SANTIAGO" },
        { label: "VALLECILLO", value: "VALLECILLO" },
        { label: "VILLALDAMA", value: "VILLALDAMA" }
    ],
    "Oaxaca": [
        { label: "ABEJONES", value: "ABEJONES" },
        { label: "ACATLÁN DE PÉREZ FIGUEROA", value: "ACATLÁN DE PÉREZ FIGUEROA" },
        { label: "ASUNCIÓN CACALOTEPEC", value: "ASUNCIÓN CACALOTEPEC" },
        { label: "ASUNCIÓN CUYOTEPEJI", value: "ASUNCIÓN CUYOTEPEJI" },
        { label: "ASUNCIÓN IXTALTEPEC", value: "ASUNCIÓN IXTALTEPEC" },
        { label: "ASUNCIÓN NOCHIXTLÁN", value: "ASUNCIÓN NOCHIXTLÁN" },
        { label: "ASUNCIÓN OCOTLÁN", value: "ASUNCIÓN OCOTLÁN" },
        { label: "ASUNCIÓN TLACOLULITA", value: "ASUNCIÓN TLACOLULITA" },
        { label: "AYOTZINTEPEC", value: "AYOTZINTEPEC" },
        { label: "EL BARRIO DE LA SOLEDAD", value: "EL BARRIO DE LA SOLEDAD" },
        { label: "CALIHUALÁ", value: "CALIHUALÁ" },
        { label: "CANDELARIA LOXICHA", value: "CANDELARIA LOXICHA" },
        { label: "CIÉNEGA DE ZIMATLÁN", value: "CIÉNEGA DE ZIMATLÁN" },
        { label: "CIUDAD IXTEPEC", value: "CIUDAD IXTEPEC" },
        { label: "COATECAS ALTAS", value: "COATECAS ALTAS" },
        { label: "COICOYÁN DE LAS FLORES", value: "COICOYÁN DE LAS FLORES" },
        { label: "LA COMPAÑÍA", value: "LA COMPAÑÍA" },
        { label: "CONCEPCIÓN BUENAVISTA", value: "CONCEPCIÓN BUENAVISTA" },
        { label: "CONCEPCIÓN PÁPALO", value: "CONCEPCIÓN PÁPALO" },
        { label: "CONSTANCIA DEL ROSARIO", value: "CONSTANCIA DEL ROSARIO" },
        { label: "COSOLAPA", value: "COSOLAPA" },
        { label: "COSOLTEPEC", value: "COSOLTEPEC" },
        { label: "CUILÁPAM DE GUERRERO", value: "CUILÁPAM DE GUERRERO" },
        { label: "CUYAMECALCO VILLA DE ZARAGOZA", value: "CUYAMECALCO VILLA DE ZARAGOZA" },
        { label: "CHAHUITES", value: "CHAHUITES" },
        { label: "CHALCATONGO DE HIDALGO", value: "CHALCATONGO DE HIDALGO" },
        { label: "CHIQUIHUITLÁN DE BENITO JUÁREZ", value: "CHIQUIHUITLÁN DE BENITO JUÁREZ" },
        { label: "HEROICA CIUDAD DE EJUTLA DE CRESPO", value: "HEROICA CIUDAD DE EJUTLA DE CRESPO" },
        { label: "ELOXOCHITLÁN DE FLORES MAGÓN", value: "ELOXOCHITLÁN DE FLORES MAGÓN" },
        { label: "EL ESPINAL", value: "EL ESPINAL" },
        { label: "TAMAZULÁPAM DEL ESPÍRITU SANTO", value: "TAMAZULÁPAM DEL ESPÍRITU SANTO" },
        { label: "FRESNILLO DE TRUJANO", value: "FRESNILLO DE TRUJANO" },
        { label: "GUADALUPE ETLA", value: "GUADALUPE ETLA" },
        { label: "GUADALUPE DE RAMÍREZ", value: "GUADALUPE DE RAMÍREZ" },
        { label: "GUELATAO DE JUÁREZ", value: "GUELATAO DE JUÁREZ" },
        { label: "GUEVEA DE HUMBOLDT", value: "GUEVEA DE HUMBOLDT" },
        { label: "MESONES HIDALGO", value: "MESONES HIDALGO" },
        { label: "VILLA HIDALGO", value: "VILLA HIDALGO" },
        { label: "HEROICA CIUDAD DE HUAJUAPAN DE LEÓN", value: "HEROICA CIUDAD DE HUAJUAPAN DE LEÓN" },
        { label: "HUAUTEPEC", value: "HUAUTEPEC" },
        { label: "HUAUTLA DE JIMÉNEZ", value: "HUAUTLA DE JIMÉNEZ" },
        { label: "IXTLÁN DE JUÁREZ", value: "IXTLÁN DE JUÁREZ" },
        { label: "HEROICA CIUDAD DE JUCHITÁN DE ZARAGOZA", value: "HEROICA CIUDAD DE JUCHITÁN DE ZARAGOZA" },
        { label: "LOMA BONITA", value: "LOMA BONITA" },
        { label: "MAGDALENA APASCO", value: "MAGDALENA APASCO" },
        { label: "MAGDALENA JALTEPEC", value: "MAGDALENA JALTEPEC" },
        { label: "SANTA MAGDALENA JICOTLÁN", value: "SANTA MAGDALENA JICOTLÁN" },
        { label: "MAGDALENA MIXTEPEC", value: "MAGDALENA MIXTEPEC" },
        { label: "MAGDALENA OCOTLÁN", value: "MAGDALENA OCOTLÁN" },
        { label: "MAGDALENA PEÑASCO", value: "MAGDALENA PEÑASCO" },
        { label: "MAGDALENA TEITIPAC", value: "MAGDALENA TEITIPAC" },
        { label: "MAGDALENA TEQUISISTLÁN", value: "MAGDALENA TEQUISISTLÁN" },
        { label: "MAGDALENA TLACOTEPEC", value: "MAGDALENA TLACOTEPEC" },
        { label: "MAGDALENA ZAHUATLÁN", value: "MAGDALENA ZAHUATLÁN" },
        { label: "MARISCALA DE JUÁREZ", value: "MARISCALA DE JUÁREZ" },
        { label: "MÁRTIRES DE TACUBAYA", value: "MÁRTIRES DE TACUBAYA" },
        { label: "MATÍAS ROMERO AVENDAÑO", value: "MATÍAS ROMERO AVENDAÑO" },
        { label: "MAZATLÁN VILLA DE FLORES", value: "MAZATLÁN VILLA DE FLORES" },
        { label: "MIAHUATLÁN DE PORFIRIO DÍAZ", value: "MIAHUATLÁN DE PORFIRIO DÍAZ" },
        { label: "MIXISTLÁN DE LA REFORMA", value: "MIXISTLÁN DE LA REFORMA" },
        { label: "MONJAS", value: "MONJAS" },
        { label: "NATIVIDAD", value: "NATIVIDAD" },
        { label: "NAZARENO ETLA", value: "NAZARENO ETLA" },
        { label: "NEJAPA DE MADERO", value: "NEJAPA DE MADERO" },
        { label: "IXPANTEPEC NIEVES", value: "IXPANTEPEC NIEVES" },
        { label: "SANTIAGO NILTEPEC", value: "SANTIAGO NILTEPEC" },
        { label: "OAXACA DE JUÁREZ", value: "OAXACA DE JUÁREZ" },
        { label: "OCOTLÁN DE MORELOS", value: "OCOTLÁN DE MORELOS" },
        { label: "LA PE", value: "LA PE" },
        { label: "PINOTEPA DE DON LUIS", value: "PINOTEPA DE DON LUIS" },
        { label: "PLUMA HIDALGO", value: "PLUMA HIDALGO" },
        { label: "SAN JOSÉ DEL PROGRESO", value: "SAN JOSÉ DEL PROGRESO" },
        { label: "PUTLA VILLA DE GUERRERO", value: "PUTLA VILLA DE GUERRERO" },
        { label: "SANTA CATARINA QUIOQUITANI", value: "SANTA CATARINA QUIOQUITANI" },
        { label: "REFORMA DE PINEDA", value: "REFORMA DE PINEDA" },
        { label: "LA REFORMA", value: "LA REFORMA" },
        { label: "REYES ETLA", value: "REYES ETLA" },
        { label: "ROJAS DE CUAUHTÉMOC", value: "ROJAS DE CUAUHTÉMOC" },
        { label: "SALINA CRUZ", value: "SALINA CRUZ" },
        { label: "SAN AGUSTÍN AMATENGO", value: "SAN AGUSTÍN AMATENGO" },
        { label: "SAN AGUSTÍN ATENANGO", value: "SAN AGUSTÍN ATENANGO" },
        { label: "SAN AGUSTÍN CHAYUCO", value: "SAN AGUSTÍN CHAYUCO" },
        { label: "SAN AGUSTÍN DE LAS JUNTAS", value: "SAN AGUSTÍN DE LAS JUNTAS" },
        { label: "SAN AGUSTÍN ETLA", value: "SAN AGUSTÍN ETLA" },
        { label: "SAN AGUSTÍN LOXICHA", value: "SAN AGUSTÍN LOXICHA" },
        { label: "SAN AGUSTÍN TLACOTEPEC", value: "SAN AGUSTÍN TLACOTEPEC" },
        { label: "SAN AGUSTÍN YATARENI", value: "SAN AGUSTÍN YATARENI" },
        { label: "SAN ANDRÉS CABECERA NUEVA", value: "SAN ANDRÉS CABECERA NUEVA" },
        { label: "SAN ANDRÉS DINICUITI", value: "SAN ANDRÉS DINICUITI" },
        { label: "SAN ANDRÉS HUAXPALTEPEC", value: "SAN ANDRÉS HUAXPALTEPEC" },
        { label: "SAN ANDRÉS HUAYÁPAM", value: "SAN ANDRÉS HUAYÁPAM" },
        { label: "SAN ANDRÉS IXTLAHUACA", value: "SAN ANDRÉS IXTLAHUACA" },
        { label: "SAN ANDRÉS LAGUNAS", value: "SAN ANDRÉS LAGUNAS" },
        { label: "SAN ANDRÉS NUXIÑO", value: "SAN ANDRÉS NUXIÑO" },
        { label: "SAN ANDRÉS PAXTLÁN", value: "SAN ANDRÉS PAXTLÁN" },
        { label: "SAN ANDRÉS SINAXTLA", value: "SAN ANDRÉS SINAXTLA" },
        { label: "SAN ANDRÉS SOLAGA", value: "SAN ANDRÉS SOLAGA" },
        { label: "SAN ANDRÉS TEOTILÁLPAM", value: "SAN ANDRÉS TEOTILÁLPAM" },
        { label: "SAN ANDRÉS TEPETLAPA", value: "SAN ANDRÉS TEPETLAPA" },
        { label: "SAN ANDRÉS YAÁ", value: "SAN ANDRÉS YAÁ" },
        { label: "SAN ANDRÉS ZABACHE", value: "SAN ANDRÉS ZABACHE" },
        { label: "SAN ANDRÉS ZAUTLA", value: "SAN ANDRÉS ZAUTLA" },
        { label: "SAN ANTONINO CASTILLO VELASCO", value: "SAN ANTONINO CASTILLO VELASCO" },
        { label: "SAN ANTONINO EL ALTO", value: "SAN ANTONINO EL ALTO" },
        { label: "SAN ANTONINO MONTE VERDE", value: "SAN ANTONINO MONTE VERDE" },
        { label: "SAN ANTONIO ACUTLA", value: "SAN ANTONIO ACUTLA" },
        { label: "SAN ANTONIO DE LA CAL", value: "SAN ANTONIO DE LA CAL" },
        { label: "SAN ANTONIO HUITEPEC", value: "SAN ANTONIO HUITEPEC" },
        { label: "SAN ANTONIO NANAHUATÍPAM", value: "SAN ANTONIO NANAHUATÍPAM" },
        { label: "SAN ANTONIO SINICAHUA", value: "SAN ANTONIO SINICAHUA" },
        { label: "SAN ANTONIO TEPETLAPA", value: "SAN ANTONIO TEPETLAPA" },
        { label: "SAN BALTAZAR CHICHICÁPAM", value: "SAN BALTAZAR CHICHICÁPAM" },
        { label: "SAN BALTAZAR LOXICHA", value: "SAN BALTAZAR LOXICHA" },
        { label: "SAN BALTAZAR YATZACHI EL BAJO", value: "SAN BALTAZAR YATZACHI EL BAJO" },
        { label: "SAN BARTOLO COYOTEPEC", value: "SAN BARTOLO COYOTEPEC" },
        { label: "SAN BARTOLOMÉ AYAUTLA", value: "SAN BARTOLOMÉ AYAUTLA" },
        { label: "SAN BARTOLOMÉ LOXICHA", value: "SAN BARTOLOMÉ LOXICHA" },
        { label: "SAN BARTOLOMÉ QUIALANA", value: "SAN BARTOLOMÉ QUIALANA" },
        { label: "SAN BARTOLOMÉ YUCUAÑE", value: "SAN BARTOLOMÉ YUCUAÑE" },
        { label: "SAN BARTOLOMÉ ZOOGOCHO", value: "SAN BARTOLOMÉ ZOOGOCHO" },
        { label: "SAN BARTOLO SOYALTEPEC", value: "SAN BARTOLO SOYALTEPEC" },
        { label: "SAN BARTOLO YAUTEPEC", value: "SAN BARTOLO YAUTEPEC" },
        { label: "SAN BERNARDO MIXTEPEC", value: "SAN BERNARDO MIXTEPEC" },
        { label: "SAN BLAS ATEMPA", value: "SAN BLAS ATEMPA" },
        { label: "SAN CARLOS YAUTEPEC", value: "SAN CARLOS YAUTEPEC" },
        { label: "SAN CRISTÓBAL AMATLÁN", value: "SAN CRISTÓBAL AMATLÁN" },
        { label: "SAN CRISTÓBAL AMOLTEPEC", value: "SAN CRISTÓBAL AMOLTEPEC" },
        { label: "SAN CRISTÓBAL LACHIRIOAG", value: "SAN CRISTÓBAL LACHIRIOAG" },
        { label: "SAN CRISTÓBAL SUCHIXTLAHUACA", value: "SAN CRISTÓBAL SUCHIXTLAHUACA" },
        { label: "SAN DIONISIO DEL MAR", value: "SAN DIONISIO DEL MAR" },
        { label: "SAN DIONISIO OCOTEPEC", value: "SAN DIONISIO OCOTEPEC" },
        { label: "SAN DIONISIO OCOTLÁN", value: "SAN DIONISIO OCOTLÁN" },
        { label: "SAN ESTEBAN ATATLAHUCA", value: "SAN ESTEBAN ATATLAHUCA" },
        { label: "SAN FELIPE JALAPA DE DÍAZ", value: "SAN FELIPE JALAPA DE DÍAZ" },
        { label: "SAN FELIPE TEJALÁPAM", value: "SAN FELIPE TEJALÁPAM" },
        { label: "SAN FELIPE USILA", value: "SAN FELIPE USILA" },
        { label: "SAN FRANCISCO CAHUACUÁ", value: "SAN FRANCISCO CAHUACUÁ" },
        { label: "SAN FRANCISCO CAJONOS", value: "SAN FRANCISCO CAJONOS" },
        { label: "SAN FRANCISCO CHAPULAPA", value: "SAN FRANCISCO CHAPULAPA" },
        { label: "SAN FRANCISCO CHINDÚA", value: "SAN FRANCISCO CHINDÚA" },
        { label: "SAN FRANCISCO DEL MAR", value: "SAN FRANCISCO DEL MAR" },
        { label: "SAN FRANCISCO HUEHUETLÁN", value: "SAN FRANCISCO HUEHUETLÁN" },
        { label: "SAN FRANCISCO IXHUATÁN", value: "SAN FRANCISCO IXHUATÁN" },
        { label: "SAN FRANCISCO JALTEPETONGO", value: "SAN FRANCISCO JALTEPETONGO" },
        { label: "SAN FRANCISCO LACHIGOLÓ", value: "SAN FRANCISCO LACHIGOLÓ" },
        { label: "SAN FRANCISCO LOGUECHE", value: "SAN FRANCISCO LOGUECHE" },
        { label: "SAN FRANCISCO NUXAÑO", value: "SAN FRANCISCO NUXAÑO" },
        { label: "SAN FRANCISCO OZOLOTEPEC", value: "SAN FRANCISCO OZOLOTEPEC" },
        { label: "SAN FRANCISCO SOLA", value: "SAN FRANCISCO SOLA" },
        { label: "SAN FRANCISCO TELIXTLAHUACA", value: "SAN FRANCISCO TELIXTLAHUACA" },
        { label: "SAN FRANCISCO TEOPAN", value: "SAN FRANCISCO TEOPAN" },
        { label: "SAN FRANCISCO TLAPANCINGO", value: "SAN FRANCISCO TLAPANCINGO" },
        { label: "SAN GABRIEL MIXTEPEC", value: "SAN GABRIEL MIXTEPEC" },
        { label: "SAN ILDEFONSO AMATLÁN", value: "SAN ILDEFONSO AMATLÁN" },
        { label: "SAN ILDEFONSO SOLA", value: "SAN ILDEFONSO SOLA" },
        { label: "SAN ILDEFONSO VILLA ALTA", value: "SAN ILDEFONSO VILLA ALTA" },
        { label: "SAN JACINTO AMILPAS", value: "SAN JACINTO AMILPAS" },
        { label: "SAN JACINTO TLACOTEPEC", value: "SAN JACINTO TLACOTEPEC" },
        { label: "SAN JERÓNIMO COATLÁN", value: "SAN JERÓNIMO COATLÁN" },
        { label: "SAN JERÓNIMO SILACAYOAPILLA", value: "SAN JERÓNIMO SILACAYOAPILLA" },
        { label: "SAN JERÓNIMO SOSOLA", value: "SAN JERÓNIMO SOSOLA" },
        { label: "SAN JERÓNIMO TAVICHE", value: "SAN JERÓNIMO TAVICHE" },
        { label: "SAN JERÓNIMO TECÓATL", value: "SAN JERÓNIMO TECÓATL" },
        { label: "SAN JORGE NUCHITA", value: "SAN JORGE NUCHITA" },
        { label: "SAN JOSÉ AYUQUILA", value: "SAN JOSÉ AYUQUILA" },
        { label: "SAN JOSÉ CHILTEPEC", value: "SAN JOSÉ CHILTEPEC" },
        { label: "SAN JOSÉ DEL PEÑASCO", value: "SAN JOSÉ DEL PEÑASCO" },
        { label: "SAN JOSÉ ESTANCIA GRANDE", value: "SAN JOSÉ ESTANCIA GRANDE" },
        { label: "SAN JOSÉ INDEPENDENCIA", value: "SAN JOSÉ INDEPENDENCIA" },
        { label: "SAN JOSÉ LACHIGUIRI", value: "SAN JOSÉ LACHIGUIRI" },
        { label: "SAN JOSÉ TENANGO", value: "SAN JOSÉ TENANGO" },
        { label: "SAN JUAN ACHIUTLA", value: "SAN JUAN ACHIUTLA" },
        { label: "SAN JUAN ATEPEC", value: "SAN JUAN ATEPEC" },
        { label: "ÁNIMAS TRUJANO", value: "ÁNIMAS TRUJANO" },
        { label: "SAN JUAN BAUTISTA ATATLAHUCA", value: "SAN JUAN BAUTISTA ATATLAHUCA" },
        { label: "SAN JUAN BAUTISTA COIXTLAHUACA", value: "SAN JUAN BAUTISTA COIXTLAHUACA" },
        { label: "SAN JUAN BAUTISTA CUICATLÁN", value: "SAN JUAN BAUTISTA CUICATLÁN" },
        { label: "SAN JUAN BAUTISTA GUELACHE", value: "SAN JUAN BAUTISTA GUELACHE" },
        { label: "SAN JUAN BAUTISTA JAYACATLÁN", value: "SAN JUAN BAUTISTA JAYACATLÁN" },
        { label: "SAN JUAN BAUTISTA LO DE SOTO", value: "SAN JUAN BAUTISTA LO DE SOTO" },
        { label: "SAN JUAN BAUTISTA SUCHITEPEC", value: "SAN JUAN BAUTISTA SUCHITEPEC" },
        { label: "SAN JUAN BAUTISTA TLACOATZINTEPEC", value: "SAN JUAN BAUTISTA TLACOATZINTEPEC" },
        { label: "SAN JUAN BAUTISTA TLACHICHILCO", value: "SAN JUAN BAUTISTA TLACHICHILCO" },
        { label: "SAN JUAN BAUTISTA TUXTEPEC", value: "SAN JUAN BAUTISTA TUXTEPEC" },
        { label: "SAN JUAN CACAHUATEPEC", value: "SAN JUAN CACAHUATEPEC" },
        { label: "SAN JUAN CIENEGUILLA", value: "SAN JUAN CIENEGUILLA" },
        { label: "SAN JUAN COATZÓSPAM", value: "SAN JUAN COATZÓSPAM" },
        { label: "SAN JUAN COLORADO", value: "SAN JUAN COLORADO" },
        { label: "SAN JUAN COMALTEPEC", value: "SAN JUAN COMALTEPEC" },
        { label: "SAN JUAN COTZOCÓN", value: "SAN JUAN COTZOCÓN" },
        { label: "SAN JUAN CHICOMEZÚCHIL", value: "SAN JUAN CHICOMEZÚCHIL" },
        { label: "SAN JUAN CHILATECA", value: "SAN JUAN CHILATECA" },
        { label: "SAN JUAN DEL ESTADO", value: "SAN JUAN DEL ESTADO" },
        { label: "SAN JUAN DEL RÍO", value: "SAN JUAN DEL RÍO" },
        { label: "SAN JUAN DIUXI", value: "SAN JUAN DIUXI" },
        { label: "SAN JUAN EVANGELISTA ANALCO", value: "SAN JUAN EVANGELISTA ANALCO" },
        { label: "SAN JUAN GUELAVÍA", value: "SAN JUAN GUELAVÍA" },
        { label: "SAN JUAN GUICHICOVI", value: "SAN JUAN GUICHICOVI" },
        { label: "SAN JUAN IHUALTEPEC", value: "SAN JUAN IHUALTEPEC" },
        { label: "SAN JUAN JUQUILA MIXES", value: "SAN JUAN JUQUILA MIXES" },
        { label: "SAN JUAN JUQUILA VIJANOS", value: "SAN JUAN JUQUILA VIJANOS" },
        { label: "SAN JUAN LACHAO", value: "SAN JUAN LACHAO" },
        { label: "SAN JUAN LACHIGALLA", value: "SAN JUAN LACHIGALLA" },
        { label: "SAN JUAN LAJARCIA", value: "SAN JUAN LAJARCIA" },
        { label: "SAN JUAN LALANA", value: "SAN JUAN LALANA" },
        { label: "SAN JUAN DE LOS CUÉS", value: "SAN JUAN DE LOS CUÉS" },
        { label: "SAN JUAN MAZATLÁN", value: "SAN JUAN MAZATLÁN" },
        { label: "SAN JUAN MIXTEPEC", value: "SAN JUAN MIXTEPEC" },
        { label: "SAN JUAN MIXTEPEC", value: "SAN JUAN MIXTEPEC" },
        { label: "SAN JUAN ÑUMÍ", value: "SAN JUAN ÑUMÍ" },
        { label: "SAN JUAN OZOLOTEPEC", value: "SAN JUAN OZOLOTEPEC" },
        { label: "SAN JUAN PETLAPA", value: "SAN JUAN PETLAPA" },
        { label: "SAN JUAN QUIAHIJE", value: "SAN JUAN QUIAHIJE" },
        { label: "SAN JUAN QUIOTEPEC", value: "SAN JUAN QUIOTEPEC" },
        { label: "SAN JUAN SAYULTEPEC", value: "SAN JUAN SAYULTEPEC" },
        { label: "SAN JUAN TABAÁ", value: "SAN JUAN TABAÁ" },
        { label: "SAN JUAN TAMAZOLA", value: "SAN JUAN TAMAZOLA" },
        { label: "SAN JUAN TEITA", value: "SAN JUAN TEITA" },
        { label: "SAN JUAN TEITIPAC", value: "SAN JUAN TEITIPAC" },
        { label: "SAN JUAN TEPEUXILA", value: "SAN JUAN TEPEUXILA" },
        { label: "SAN JUAN TEPOSCOLULA", value: "SAN JUAN TEPOSCOLULA" },
        { label: "SAN JUAN YAEÉ", value: "SAN JUAN YAEÉ" },
        { label: "SAN JUAN YATZONA", value: "SAN JUAN YATZONA" },
        { label: "SAN JUAN YUCUITA", value: "SAN JUAN YUCUITA" },
        { label: "SAN LORENZO", value: "SAN LORENZO" },
        { label: "SAN LORENZO ALBARRADAS", value: "SAN LORENZO ALBARRADAS" },
        { label: "SAN LORENZO CACAOTEPEC", value: "SAN LORENZO CACAOTEPEC" },
        { label: "SAN LORENZO CUAUNECUILTITLA", value: "SAN LORENZO CUAUNECUILTITLA" },
        { label: "SAN LORENZO TEXMELÚCAN", value: "SAN LORENZO TEXMELÚCAN" },
        { label: "SAN LORENZO VICTORIA", value: "SAN LORENZO VICTORIA" },
        { label: "SAN LUCAS CAMOTLÁN", value: "SAN LUCAS CAMOTLÁN" },
        { label: "SAN LUCAS OJITLÁN", value: "SAN LUCAS OJITLÁN" },
        { label: "SAN LUCAS QUIAVINÍ", value: "SAN LUCAS QUIAVINÍ" },
        { label: "SAN LUCAS ZOQUIÁPAM", value: "SAN LUCAS ZOQUIÁPAM" },
        { label: "SAN LUIS AMATLÁN", value: "SAN LUIS AMATLÁN" },
        { label: "SAN MARCIAL OZOLOTEPEC", value: "SAN MARCIAL OZOLOTEPEC" },
        { label: "SAN MARCOS ARTEAGA", value: "SAN MARCOS ARTEAGA" },
        { label: "SAN MARTÍN DE LOS CANSECOS", value: "SAN MARTÍN DE LOS CANSECOS" },
        { label: "SAN MARTÍN HUAMELÚLPAM", value: "SAN MARTÍN HUAMELÚLPAM" },
        { label: "SAN MARTÍN ITUNYOSO", value: "SAN MARTÍN ITUNYOSO" },
        { label: "SAN MARTÍN LACHILÁ", value: "SAN MARTÍN LACHILÁ" },
        { label: "SAN MARTÍN PERAS", value: "SAN MARTÍN PERAS" },
        { label: "SAN MARTÍN TILCAJETE", value: "SAN MARTÍN TILCAJETE" },
        { label: "SAN MARTÍN TOXPALAN", value: "SAN MARTÍN TOXPALAN" },
        { label: "SAN MARTÍN ZACATEPEC", value: "SAN MARTÍN ZACATEPEC" },
        { label: "SAN MATEO CAJONOS", value: "SAN MATEO CAJONOS" },
        { label: "CAPULÁLPAM DE MÉNDEZ", value: "CAPULÁLPAM DE MÉNDEZ" },
        { label: "SAN MATEO DEL MAR", value: "SAN MATEO DEL MAR" },
        { label: "SAN MATEO YOLOXOCHITLÁN", value: "SAN MATEO YOLOXOCHITLÁN" },
        { label: "SAN MATEO ETLATONGO", value: "SAN MATEO ETLATONGO" },
        { label: "SAN MATEO NEJÁPAM", value: "SAN MATEO NEJÁPAM" },
        { label: "SAN MATEO PEÑASCO", value: "SAN MATEO PEÑASCO" },
        { label: "SAN MATEO PIÑAS", value: "SAN MATEO PIÑAS" },
        { label: "SAN MATEO RÍO HONDO", value: "SAN MATEO RÍO HONDO" },
        { label: "SAN MATEO SINDIHUI", value: "SAN MATEO SINDIHUI" },
        { label: "SAN MATEO TLAPILTEPEC", value: "SAN MATEO TLAPILTEPEC" },
        { label: "SAN MELCHOR BETAZA", value: "SAN MELCHOR BETAZA" },
        { label: "SAN MIGUEL ACHIUTLA", value: "SAN MIGUEL ACHIUTLA" },
        { label: "SAN MIGUEL AHUEHUETITLÁN", value: "SAN MIGUEL AHUEHUETITLÁN" },
        { label: "SAN MIGUEL ALOÁPAM", value: "SAN MIGUEL ALOÁPAM" },
        { label: "SAN MIGUEL AMATITLÁN", value: "SAN MIGUEL AMATITLÁN" },
        { label: "SAN MIGUEL AMATLÁN", value: "SAN MIGUEL AMATLÁN" },
        { label: "SAN MIGUEL COATLÁN", value: "SAN MIGUEL COATLÁN" },
        { label: "SAN MIGUEL CHICAHUA", value: "SAN MIGUEL CHICAHUA" },
        { label: "SAN MIGUEL CHIMALAPA", value: "SAN MIGUEL CHIMALAPA" },
        { label: "SAN MIGUEL DEL PUERTO", value: "SAN MIGUEL DEL PUERTO" },
        { label: "SAN MIGUEL DEL RÍO", value: "SAN MIGUEL DEL RÍO" },
        { label: "SAN MIGUEL EJUTLA", value: "SAN MIGUEL EJUTLA" },
        { label: "SAN MIGUEL EL GRANDE", value: "SAN MIGUEL EL GRANDE" },
        { label: "SAN MIGUEL HUAUTLA", value: "SAN MIGUEL HUAUTLA" },
        { label: "SAN MIGUEL MIXTEPEC", value: "SAN MIGUEL MIXTEPEC" },
        { label: "SAN MIGUEL PANIXTLAHUACA", value: "SAN MIGUEL PANIXTLAHUACA" },
        { label: "SAN MIGUEL PERAS", value: "SAN MIGUEL PERAS" },
        { label: "SAN MIGUEL PIEDRAS", value: "SAN MIGUEL PIEDRAS" },
        { label: "SAN MIGUEL QUETZALTEPEC", value: "SAN MIGUEL QUETZALTEPEC" },
        { label: "SAN MIGUEL SANTA FLOR", value: "SAN MIGUEL SANTA FLOR" },
        { label: "VILLA SOLA DE VEGA", value: "VILLA SOLA DE VEGA" },
        { label: "SAN MIGUEL SOYALTEPEC", value: "SAN MIGUEL SOYALTEPEC" },
        { label: "SAN MIGUEL SUCHIXTEPEC", value: "SAN MIGUEL SUCHIXTEPEC" },
        { label: "VILLA TALEA DE CASTRO", value: "VILLA TALEA DE CASTRO" },
        { label: "SAN MIGUEL TECOMATLÁN", value: "SAN MIGUEL TECOMATLÁN" },
        { label: "SAN MIGUEL TENANGO", value: "SAN MIGUEL TENANGO" },
        { label: "SAN MIGUEL TEQUIXTEPEC", value: "SAN MIGUEL TEQUIXTEPEC" },
        { label: "SAN MIGUEL TILQUIÁPAM", value: "SAN MIGUEL TILQUIÁPAM" },
        { label: "SAN MIGUEL TLACAMAMA", value: "SAN MIGUEL TLACAMAMA" },
        { label: "SAN MIGUEL TLACOTEPEC", value: "SAN MIGUEL TLACOTEPEC" },
        { label: "SAN MIGUEL TULANCINGO", value: "SAN MIGUEL TULANCINGO" },
        { label: "SAN MIGUEL YOTAO", value: "SAN MIGUEL YOTAO" },
        { label: "SAN NICOLÁS", value: "SAN NICOLÁS" },
        { label: "SAN NICOLÁS HIDALGO", value: "SAN NICOLÁS HIDALGO" },
        { label: "SAN PABLO COATLÁN", value: "SAN PABLO COATLÁN" },
        { label: "SAN PABLO CUATRO VENADOS", value: "SAN PABLO CUATRO VENADOS" },
        { label: "SAN PABLO ETLA", value: "SAN PABLO ETLA" },
        { label: "SAN PABLO HUITZO", value: "SAN PABLO HUITZO" },
        { label: "SAN PABLO HUIXTEPEC", value: "SAN PABLO HUIXTEPEC" },
        { label: "SAN PABLO MACUILTIANGUIS", value: "SAN PABLO MACUILTIANGUIS" },
        { label: "SAN PABLO TIJALTEPEC", value: "SAN PABLO TIJALTEPEC" },
        { label: "SAN PABLO VILLA DE MITLA", value: "SAN PABLO VILLA DE MITLA" },
        { label: "SAN PABLO YAGANIZA", value: "SAN PABLO YAGANIZA" },
        { label: "SAN PEDRO AMUZGOS", value: "SAN PEDRO AMUZGOS" },
        { label: "SAN PEDRO APÓSTOL", value: "SAN PEDRO APÓSTOL" },
        { label: "SAN PEDRO ATOYAC", value: "SAN PEDRO ATOYAC" },
        { label: "SAN PEDRO CAJONOS", value: "SAN PEDRO CAJONOS" },
        { label: "SAN PEDRO COXCALTEPEC CÁNTAROS", value: "SAN PEDRO COXCALTEPEC CÁNTAROS" },
        { label: "SAN PEDRO COMITANCILLO", value: "SAN PEDRO COMITANCILLO" },
        { label: "SAN PEDRO EL ALTO", value: "SAN PEDRO EL ALTO" },
        { label: "SAN PEDRO HUAMELULA", value: "SAN PEDRO HUAMELULA" },
        { label: "SAN PEDRO HUILOTEPEC", value: "SAN PEDRO HUILOTEPEC" },
        { label: "SAN PEDRO IXCATLÁN", value: "SAN PEDRO IXCATLÁN" },
        { label: "SAN PEDRO IXTLAHUACA", value: "SAN PEDRO IXTLAHUACA" },
        { label: "SAN PEDRO JALTEPETONGO", value: "SAN PEDRO JALTEPETONGO" },
        { label: "SAN PEDRO JICAYÁN", value: "SAN PEDRO JICAYÁN" },
        { label: "SAN PEDRO JOCOTIPAC", value: "SAN PEDRO JOCOTIPAC" },
        { label: "SAN PEDRO JUCHATENGO", value: "SAN PEDRO JUCHATENGO" },
        { label: "SAN PEDRO MÁRTIR", value: "SAN PEDRO MÁRTIR" },
        { label: "SAN PEDRO MÁRTIR QUIECHAPA", value: "SAN PEDRO MÁRTIR QUIECHAPA" },
        { label: "SAN PEDRO MÁRTIR YUCUXACO", value: "SAN PEDRO MÁRTIR YUCUXACO" },
        { label: "SAN PEDRO MIXTEPEC", value: "SAN PEDRO MIXTEPEC" },
        { label: "SAN PEDRO MIXTEPEC", value: "SAN PEDRO MIXTEPEC" },
        { label: "SAN PEDRO MOLINOS", value: "SAN PEDRO MOLINOS" },
        { label: "SAN PEDRO NOPALA", value: "SAN PEDRO NOPALA" },
        { label: "SAN PEDRO OCOPETATILLO", value: "SAN PEDRO OCOPETATILLO" },
        { label: "SAN PEDRO OCOTEPEC", value: "SAN PEDRO OCOTEPEC" },
        { label: "SAN PEDRO POCHUTLA", value: "SAN PEDRO POCHUTLA" },
        { label: "SAN PEDRO QUIATONI", value: "SAN PEDRO QUIATONI" },
        { label: "SAN PEDRO SOCHIÁPAM", value: "SAN PEDRO SOCHIÁPAM" },
        { label: "SAN PEDRO TAPANATEPEC", value: "SAN PEDRO TAPANATEPEC" },
        { label: "SAN PEDRO TAVICHE", value: "SAN PEDRO TAVICHE" },
        { label: "SAN PEDRO TEOZACOALCO", value: "SAN PEDRO TEOZACOALCO" },
        { label: "SAN PEDRO TEUTILA", value: "SAN PEDRO TEUTILA" },
        { label: "SAN PEDRO TIDAÁ", value: "SAN PEDRO TIDAÁ" },
        { label: "SAN PEDRO TOPILTEPEC", value: "SAN PEDRO TOPILTEPEC" },
        { label: "SAN PEDRO TOTOLÁPAM", value: "SAN PEDRO TOTOLÁPAM" },
        { label: "VILLA DE TUTUTEPEC", value: "VILLA DE TUTUTEPEC" },
        { label: "SAN PEDRO YANERI", value: "SAN PEDRO YANERI" },
        { label: "SAN PEDRO YÓLOX", value: "SAN PEDRO YÓLOX" },
        { label: "SAN PEDRO Y SAN PABLO AYUTLA", value: "SAN PEDRO Y SAN PABLO AYUTLA" },
        { label: "VILLA DE ETLA", value: "VILLA DE ETLA" },
        { label: "SAN PEDRO Y SAN PABLO TEPOSCOLULA", value: "SAN PEDRO Y SAN PABLO TEPOSCOLULA" },
        { label: "SAN PEDRO Y SAN PABLO TEQUIXTEPEC", value: "SAN PEDRO Y SAN PABLO TEQUIXTEPEC" },
        { label: "SAN PEDRO YUCUNAMA", value: "SAN PEDRO YUCUNAMA" },
        { label: "SAN RAYMUNDO JALPAN", value: "SAN RAYMUNDO JALPAN" },
        { label: "SAN SEBASTIÁN ABASOLO", value: "SAN SEBASTIÁN ABASOLO" },
        { label: "SAN SEBASTIÁN COATLÁN", value: "SAN SEBASTIÁN COATLÁN" },
        { label: "SAN SEBASTIÁN IXCAPA", value: "SAN SEBASTIÁN IXCAPA" },
        { label: "SAN SEBASTIÁN NICANANDUTA", value: "SAN SEBASTIÁN NICANANDUTA" },
        { label: "SAN SEBASTIÁN RÍO HONDO", value: "SAN SEBASTIÁN RÍO HONDO" },
        { label: "SAN SEBASTIÁN TECOMAXTLAHUACA", value: "SAN SEBASTIÁN TECOMAXTLAHUACA" },
        { label: "SAN SEBASTIÁN TEITIPAC", value: "SAN SEBASTIÁN TEITIPAC" },
        { label: "SAN SEBASTIÁN TUTLA", value: "SAN SEBASTIÁN TUTLA" },
        { label: "SAN SIMÓN ALMOLONGAS", value: "SAN SIMÓN ALMOLONGAS" },
        { label: "SAN SIMÓN ZAHUATLÁN", value: "SAN SIMÓN ZAHUATLÁN" },
        { label: "SANTA ANA", value: "SANTA ANA" },
        { label: "SANTA ANA ATEIXTLAHUACA", value: "SANTA ANA ATEIXTLAHUACA" },
        { label: "SANTA ANA CUAUHTÉMOC", value: "SANTA ANA CUAUHTÉMOC" },
        { label: "SANTA ANA DEL VALLE", value: "SANTA ANA DEL VALLE" },
        { label: "SANTA ANA TAVELA", value: "SANTA ANA TAVELA" },
        { label: "SANTA ANA TLAPACOYAN", value: "SANTA ANA TLAPACOYAN" },
        { label: "SANTA ANA YARENI", value: "SANTA ANA YARENI" },
        { label: "SANTA ANA ZEGACHE", value: "SANTA ANA ZEGACHE" },
        { label: "SANTA CATALINA QUIERÍ", value: "SANTA CATALINA QUIERÍ" },
        { label: "SANTA CATARINA CUIXTLA", value: "SANTA CATARINA CUIXTLA" },
        { label: "SANTA CATARINA IXTEPEJI", value: "SANTA CATARINA IXTEPEJI" },
        { label: "SANTA CATARINA JUQUILA", value: "SANTA CATARINA JUQUILA" },
        { label: "SANTA CATARINA LACHATAO", value: "SANTA CATARINA LACHATAO" },
        { label: "SANTA CATARINA LOXICHA", value: "SANTA CATARINA LOXICHA" },
        { label: "SANTA CATARINA MECHOACÁN", value: "SANTA CATARINA MECHOACÁN" },
        { label: "SANTA CATARINA MINAS", value: "SANTA CATARINA MINAS" },
        { label: "SANTA CATARINA QUIANÉ", value: "SANTA CATARINA QUIANÉ" },
        { label: "SANTA CATARINA TAYATA", value: "SANTA CATARINA TAYATA" },
        { label: "SANTA CATARINA TICUÁ", value: "SANTA CATARINA TICUÁ" },
        { label: "SANTA CATARINA YOSONOTÚ", value: "SANTA CATARINA YOSONOTÚ" },
        { label: "SANTA CATARINA ZAPOQUILA", value: "SANTA CATARINA ZAPOQUILA" },
        { label: "SANTA CRUZ ACATEPEC", value: "SANTA CRUZ ACATEPEC" },
        { label: "SANTA CRUZ AMILPAS", value: "SANTA CRUZ AMILPAS" },
        { label: "SANTA CRUZ DE BRAVO", value: "SANTA CRUZ DE BRAVO" },
        { label: "SANTA CRUZ ITUNDUJIA", value: "SANTA CRUZ ITUNDUJIA" },
        { label: "SANTA CRUZ MIXTEPEC", value: "SANTA CRUZ MIXTEPEC" },
        { label: "SANTA CRUZ NUNDACO", value: "SANTA CRUZ NUNDACO" },
        { label: "SANTA CRUZ PAPALUTLA", value: "SANTA CRUZ PAPALUTLA" },
        { label: "SANTA CRUZ TACACHE DE MINA", value: "SANTA CRUZ TACACHE DE MINA" },
        { label: "SANTA CRUZ TACAHUA", value: "SANTA CRUZ TACAHUA" },
        { label: "SANTA CRUZ TAYATA", value: "SANTA CRUZ TAYATA" },
        { label: "SANTA CRUZ XITLA", value: "SANTA CRUZ XITLA" },
        { label: "SANTA CRUZ XOXOCOTLÁN", value: "SANTA CRUZ XOXOCOTLÁN" },
        { label: "SANTA CRUZ ZENZONTEPEC", value: "SANTA CRUZ ZENZONTEPEC" },
        { label: "SANTA GERTRUDIS", value: "SANTA GERTRUDIS" },
        { label: "SANTA INÉS DEL MONTE", value: "SANTA INÉS DEL MONTE" },
        { label: "SANTA INÉS YATZECHE", value: "SANTA INÉS YATZECHE" },
        { label: "SANTA LUCÍA DEL CAMINO", value: "SANTA LUCÍA DEL CAMINO" },
        { label: "SANTA LUCÍA MIAHUATLÁN", value: "SANTA LUCÍA MIAHUATLÁN" },
        { label: "SANTA LUCÍA MONTEVERDE", value: "SANTA LUCÍA MONTEVERDE" },
        { label: "SANTA LUCÍA OCOTLÁN", value: "SANTA LUCÍA OCOTLÁN" },
        { label: "SANTA MARÍA ALOTEPEC", value: "SANTA MARÍA ALOTEPEC" },
        { label: "SANTA MARÍA APAZCO", value: "SANTA MARÍA APAZCO" },
        { label: "SANTA MARÍA LA ASUNCIÓN", value: "SANTA MARÍA LA ASUNCIÓN" },
        { label: "HEROICA CIUDAD DE TLAXIACO", value: "HEROICA CIUDAD DE TLAXIACO" },
        { label: "AYOQUEZCO DE ALDAMA", value: "AYOQUEZCO DE ALDAMA" },
        { label: "SANTA MARÍA ATZOMPA", value: "SANTA MARÍA ATZOMPA" },
        { label: "SANTA MARÍA CAMOTLÁN", value: "SANTA MARÍA CAMOTLÁN" },
        { label: "SANTA MARÍA COLOTEPEC", value: "SANTA MARÍA COLOTEPEC" },
        { label: "SANTA MARÍA CORTIJO", value: "SANTA MARÍA CORTIJO" },
        { label: "SANTA MARÍA COYOTEPEC", value: "SANTA MARÍA COYOTEPEC" },
        { label: "SANTA MARÍA CHACHOÁPAM", value: "SANTA MARÍA CHACHOÁPAM" },
        { label: "VILLA DE CHILAPA DE DÍAZ", value: "VILLA DE CHILAPA DE DÍAZ" },
        { label: "SANTA MARÍA CHILCHOTLA", value: "SANTA MARÍA CHILCHOTLA" },
        { label: "SANTA MARÍA CHIMALAPA", value: "SANTA MARÍA CHIMALAPA" },
        { label: "SANTA MARÍA DEL ROSARIO", value: "SANTA MARÍA DEL ROSARIO" },
        { label: "SANTA MARÍA DEL TULE", value: "SANTA MARÍA DEL TULE" },
        { label: "SANTA MARÍA ECATEPEC", value: "SANTA MARÍA ECATEPEC" },
        { label: "SANTA MARÍA GUELACÉ", value: "SANTA MARÍA GUELACÉ" },
        { label: "SANTA MARÍA GUIENAGATI", value: "SANTA MARÍA GUIENAGATI" },
        { label: "SANTA MARÍA HUATULCO", value: "SANTA MARÍA HUATULCO" },
        { label: "SANTA MARÍA HUAZOLOTITLÁN", value: "SANTA MARÍA HUAZOLOTITLÁN" },
        { label: "SANTA MARÍA IPALAPA", value: "SANTA MARÍA IPALAPA" },
        { label: "SANTA MARÍA IXCATLÁN", value: "SANTA MARÍA IXCATLÁN" },
        { label: "SANTA MARÍA JACATEPEC", value: "SANTA MARÍA JACATEPEC" },
        { label: "SANTA MARÍA JALAPA DEL MARQUÉS", value: "SANTA MARÍA JALAPA DEL MARQUÉS" },
        { label: "SANTA MARÍA JALTIANGUIS", value: "SANTA MARÍA JALTIANGUIS" },
        { label: "SANTA MARÍA LACHIXÍO", value: "SANTA MARÍA LACHIXÍO" },
        { label: "SANTA MARÍA MIXTEQUILLA", value: "SANTA MARÍA MIXTEQUILLA" },
        { label: "SANTA MARÍA NATIVITAS", value: "SANTA MARÍA NATIVITAS" },
        { label: "SANTA MARÍA NDUAYACO", value: "SANTA MARÍA NDUAYACO" },
        { label: "SANTA MARÍA OZOLOTEPEC", value: "SANTA MARÍA OZOLOTEPEC" },
        { label: "SANTA MARÍA PÁPALO", value: "SANTA MARÍA PÁPALO" },
        { label: "SANTA MARÍA PEÑOLES", value: "SANTA MARÍA PEÑOLES" },
        { label: "SANTA MARÍA PETAPA", value: "SANTA MARÍA PETAPA" },
        { label: "SANTA MARÍA QUIEGOLANI", value: "SANTA MARÍA QUIEGOLANI" },
        { label: "SANTA MARÍA SOLA", value: "SANTA MARÍA SOLA" },
        { label: "SANTA MARÍA TATALTEPEC", value: "SANTA MARÍA TATALTEPEC" },
        { label: "SANTA MARÍA TECOMAVACA", value: "SANTA MARÍA TECOMAVACA" },
        { label: "SANTA MARÍA TEMAXCALAPA", value: "SANTA MARÍA TEMAXCALAPA" },
        { label: "SANTA MARÍA TEMAXCALTEPEC", value: "SANTA MARÍA TEMAXCALTEPEC" },
        { label: "SANTA MARÍA TEOPOXCO", value: "SANTA MARÍA TEOPOXCO" },
        { label: "SANTA MARÍA TEPANTLALI", value: "SANTA MARÍA TEPANTLALI" },
        { label: "SANTA MARÍA TEXCATITLÁN", value: "SANTA MARÍA TEXCATITLÁN" },
        { label: "SANTA MARÍA TLAHUITOLTEPEC", value: "SANTA MARÍA TLAHUITOLTEPEC" },
        { label: "SANTA MARÍA TLALIXTAC", value: "SANTA MARÍA TLALIXTAC" },
        { label: "SANTA MARÍA TONAMECA", value: "SANTA MARÍA TONAMECA" },
        { label: "SANTA MARÍA TOTOLAPILLA", value: "SANTA MARÍA TOTOLAPILLA" },
        { label: "SANTA MARÍA XADANI", value: "SANTA MARÍA XADANI" },
        { label: "SANTA MARÍA YALINA", value: "SANTA MARÍA YALINA" },
        { label: "SANTA MARÍA YAVESÍA", value: "SANTA MARÍA YAVESÍA" },
        { label: "SANTA MARÍA YOLOTEPEC", value: "SANTA MARÍA YOLOTEPEC" },
        { label: "SANTA MARÍA YOSOYÚA", value: "SANTA MARÍA YOSOYÚA" },
        { label: "SANTA MARÍA YUCUHITI", value: "SANTA MARÍA YUCUHITI" },
        { label: "SANTA MARÍA ZACATEPEC", value: "SANTA MARÍA ZACATEPEC" },
        { label: "SANTA MARÍA ZANIZA", value: "SANTA MARÍA ZANIZA" },
        { label: "SANTA MARÍA ZOQUITLÁN", value: "SANTA MARÍA ZOQUITLÁN" },
        { label: "SANTIAGO AMOLTEPEC", value: "SANTIAGO AMOLTEPEC" },
        { label: "SANTIAGO APOALA", value: "SANTIAGO APOALA" },
        { label: "SANTIAGO APÓSTOL", value: "SANTIAGO APÓSTOL" },
        { label: "SANTIAGO ASTATA", value: "SANTIAGO ASTATA" },
        { label: "SANTIAGO ATITLÁN", value: "SANTIAGO ATITLÁN" },
        { label: "SANTIAGO AYUQUILILLA", value: "SANTIAGO AYUQUILILLA" },
        { label: "SANTIAGO CACALOXTEPEC", value: "SANTIAGO CACALOXTEPEC" },
        { label: "SANTIAGO CAMOTLÁN", value: "SANTIAGO CAMOTLÁN" },
        { label: "SANTIAGO COMALTEPEC", value: "SANTIAGO COMALTEPEC" },
        { label: "SANTIAGO CHAZUMBA", value: "SANTIAGO CHAZUMBA" },
        { label: "SANTIAGO CHOÁPAM", value: "SANTIAGO CHOÁPAM" },
        { label: "SANTIAGO DEL RÍO", value: "SANTIAGO DEL RÍO" },
        { label: "SANTIAGO HUAJOLOTITLÁN", value: "SANTIAGO HUAJOLOTITLÁN" },
        { label: "SANTIAGO HUAUCLILLA", value: "SANTIAGO HUAUCLILLA" },
        { label: "SANTIAGO IHUITLÁN PLUMAS", value: "SANTIAGO IHUITLÁN PLUMAS" },
        { label: "SANTIAGO IXCUINTEPEC", value: "SANTIAGO IXCUINTEPEC" },
        { label: "SANTIAGO IXTAYUTLA", value: "SANTIAGO IXTAYUTLA" },
        { label: "SANTIAGO JAMILTEPEC", value: "SANTIAGO JAMILTEPEC" },
        { label: "SANTIAGO JOCOTEPEC", value: "SANTIAGO JOCOTEPEC" },
        { label: "SANTIAGO JUXTLAHUACA", value: "SANTIAGO JUXTLAHUACA" },
        { label: "SANTIAGO LACHIGUIRI", value: "SANTIAGO LACHIGUIRI" },
        { label: "SANTIAGO LALOPA", value: "SANTIAGO LALOPA" },
        { label: "SANTIAGO LAOLLAGA", value: "SANTIAGO LAOLLAGA" },
        { label: "SANTIAGO LAXOPA", value: "SANTIAGO LAXOPA" },
        { label: "SANTIAGO LLANO GRANDE", value: "SANTIAGO LLANO GRANDE" },
        { label: "SANTIAGO MATATLÁN", value: "SANTIAGO MATATLÁN" },
        { label: "SANTIAGO MILTEPEC", value: "SANTIAGO MILTEPEC" },
        { label: "SANTIAGO MINAS", value: "SANTIAGO MINAS" },
        { label: "SANTIAGO NACALTEPEC", value: "SANTIAGO NACALTEPEC" },
        { label: "SANTIAGO NEJAPILLA", value: "SANTIAGO NEJAPILLA" },
        { label: "SANTIAGO NUNDICHE", value: "SANTIAGO NUNDICHE" },
        { label: "SANTIAGO NUYOÓ", value: "SANTIAGO NUYOÓ" },
        { label: "SANTIAGO PINOTEPA NACIONAL", value: "SANTIAGO PINOTEPA NACIONAL" },
        { label: "SANTIAGO SUCHILQUITONGO", value: "SANTIAGO SUCHILQUITONGO" },
        { label: "SANTIAGO TAMAZOLA", value: "SANTIAGO TAMAZOLA" },
        { label: "SANTIAGO TAPEXTLA", value: "SANTIAGO TAPEXTLA" },
        { label: "VILLA TEJÚPAM DE LA UNIÓN", value: "VILLA TEJÚPAM DE LA UNIÓN" },
        { label: "SANTIAGO TENANGO", value: "SANTIAGO TENANGO" },
        { label: "SANTIAGO TEPETLAPA", value: "SANTIAGO TEPETLAPA" },
        { label: "SANTIAGO TETEPEC", value: "SANTIAGO TETEPEC" },
        { label: "SANTIAGO TEXCALCINGO", value: "SANTIAGO TEXCALCINGO" },
        { label: "SANTIAGO TEXTITLÁN", value: "SANTIAGO TEXTITLÁN" },
        { label: "SANTIAGO TILANTONGO", value: "SANTIAGO TILANTONGO" },
        { label: "SANTIAGO TILLO", value: "SANTIAGO TILLO" },
        { label: "SANTIAGO TLAZOYALTEPEC", value: "SANTIAGO TLAZOYALTEPEC" },
        { label: "SANTIAGO XANICA", value: "SANTIAGO XANICA" },
        { label: "SANTIAGO XIACUÍ", value: "SANTIAGO XIACUÍ" },
        { label: "SANTIAGO YAITEPEC", value: "SANTIAGO YAITEPEC" },
        { label: "SANTIAGO YAVEO", value: "SANTIAGO YAVEO" },
        { label: "SANTIAGO YOLOMÉCATL", value: "SANTIAGO YOLOMÉCATL" },
        { label: "SANTIAGO YOSONDÚA", value: "SANTIAGO YOSONDÚA" },
        { label: "SANTIAGO YUCUYACHI", value: "SANTIAGO YUCUYACHI" },
        { label: "SANTIAGO ZACATEPEC", value: "SANTIAGO ZACATEPEC" },
        { label: "SANTIAGO ZOOCHILA", value: "SANTIAGO ZOOCHILA" },
        { label: "NUEVO ZOQUIÁPAM", value: "NUEVO ZOQUIÁPAM" },
        { label: "SANTO DOMINGO INGENIO", value: "SANTO DOMINGO INGENIO" },
        { label: "SANTO DOMINGO ALBARRADAS", value: "SANTO DOMINGO ALBARRADAS" },
        { label: "SANTO DOMINGO ARMENTA", value: "SANTO DOMINGO ARMENTA" },
        { label: "SANTO DOMINGO CHIHUITÁN", value: "SANTO DOMINGO CHIHUITÁN" },
        { label: "SANTO DOMINGO DE MORELOS", value: "SANTO DOMINGO DE MORELOS" },
        { label: "SANTO DOMINGO IXCATLÁN", value: "SANTO DOMINGO IXCATLÁN" },
        { label: "SANTO DOMINGO NUXAÁ", value: "SANTO DOMINGO NUXAÁ" },
        { label: "SANTO DOMINGO OZOLOTEPEC", value: "SANTO DOMINGO OZOLOTEPEC" },
        { label: "SANTO DOMINGO PETAPA", value: "SANTO DOMINGO PETAPA" },
        { label: "SANTO DOMINGO ROAYAGA", value: "SANTO DOMINGO ROAYAGA" },
        { label: "SANTO DOMINGO TEHUANTEPEC", value: "SANTO DOMINGO TEHUANTEPEC" },
        { label: "SANTO DOMINGO TEOJOMULCO", value: "SANTO DOMINGO TEOJOMULCO" },
        { label: "SANTO DOMINGO TEPUXTEPEC", value: "SANTO DOMINGO TEPUXTEPEC" },
        { label: "SANTO DOMINGO TLATAYÁPAM", value: "SANTO DOMINGO TLATAYÁPAM" },
        { label: "SANTO DOMINGO TOMALTEPEC", value: "SANTO DOMINGO TOMALTEPEC" },
        { label: "SANTO DOMINGO TONALÁ", value: "SANTO DOMINGO TONALÁ" },
        { label: "SANTO DOMINGO TONALTEPEC", value: "SANTO DOMINGO TONALTEPEC" },
        { label: "SANTO DOMINGO XAGACÍA", value: "SANTO DOMINGO XAGACÍA" },
        { label: "SANTO DOMINGO YANHUITLÁN", value: "SANTO DOMINGO YANHUITLÁN" },
        { label: "SANTO DOMINGO YODOHINO", value: "SANTO DOMINGO YODOHINO" },
        { label: "SANTO DOMINGO ZANATEPEC", value: "SANTO DOMINGO ZANATEPEC" },
        { label: "SANTOS REYES NOPALA", value: "SANTOS REYES NOPALA" },
        { label: "SANTOS REYES PÁPALO", value: "SANTOS REYES PÁPALO" },
        { label: "SANTOS REYES TEPEJILLO", value: "SANTOS REYES TEPEJILLO" },
        { label: "SANTOS REYES YUCUNÁ", value: "SANTOS REYES YUCUNÁ" },
        { label: "SANTO TOMÁS JALIEZA", value: "SANTO TOMÁS JALIEZA" },
        { label: "SANTO TOMÁS MAZALTEPEC", value: "SANTO TOMÁS MAZALTEPEC" },
        { label: "SANTO TOMÁS OCOTEPEC", value: "SANTO TOMÁS OCOTEPEC" },
        { label: "SANTO TOMÁS TAMAZULAPAN", value: "SANTO TOMÁS TAMAZULAPAN" },
        { label: "SAN VICENTE COATLÁN", value: "SAN VICENTE COATLÁN" },
        { label: "SAN VICENTE LACHIXÍO", value: "SAN VICENTE LACHIXÍO" },
        { label: "SAN VICENTE NUÑÚ", value: "SAN VICENTE NUÑÚ" },
        { label: "SILACAYOÁPAM", value: "SILACAYOÁPAM" },
        { label: "SITIO DE XITLAPEHUA", value: "SITIO DE XITLAPEHUA" },
        { label: "SOLEDAD ETLA", value: "SOLEDAD ETLA" },
        { label: "VILLA DE TAMAZULÁPAM DEL PROGRESO", value: "VILLA DE TAMAZULÁPAM DEL PROGRESO" },
        { label: "TANETZE DE ZARAGOZA", value: "TANETZE DE ZARAGOZA" },
        { label: "TANICHE", value: "TANICHE" },
        { label: "TATALTEPEC DE VALDÉS", value: "TATALTEPEC DE VALDÉS" },
        { label: "TEOCOCUILCO DE MARCOS PÉREZ", value: "TEOCOCUILCO DE MARCOS PÉREZ" },
        { label: "TEOTITLÁN DE FLORES MAGÓN", value: "TEOTITLÁN DE FLORES MAGÓN" },
        { label: "TEOTITLÁN DEL VALLE", value: "TEOTITLÁN DEL VALLE" },
        { label: "TEOTONGO", value: "TEOTONGO" },
        { label: "TEPELMEME VILLA DE MORELOS", value: "TEPELMEME VILLA DE MORELOS" },
        { label: "VILLA TEZOATLÁN DE SEGURA Y LUNA", value: "VILLA TEZOATLÁN DE SEGURA Y LUNA" },
        { label: "SAN JERÓNIMO TLACOCHAHUAYA", value: "SAN JERÓNIMO TLACOCHAHUAYA" },
        { label: "TLACOLULA DE MATAMOROS", value: "TLACOLULA DE MATAMOROS" },
        { label: "TLACOTEPEC PLUMAS", value: "TLACOTEPEC PLUMAS" },
        { label: "TLALIXTAC DE CABRERA", value: "TLALIXTAC DE CABRERA" },
        { label: "TOTONTEPEC VILLA DE MORELOS", value: "TOTONTEPEC VILLA DE MORELOS" },
        { label: "TRINIDAD ZAACHILA", value: "TRINIDAD ZAACHILA" },
        { label: "LA TRINIDAD VISTA HERMOSA", value: "LA TRINIDAD VISTA HERMOSA" },
        { label: "UNIÓN HIDALGO", value: "UNIÓN HIDALGO" },
        { label: "VALERIO TRUJANO", value: "VALERIO TRUJANO" },
        { label: "SAN JUAN BAUTISTA VALLE NACIONAL", value: "SAN JUAN BAUTISTA VALLE NACIONAL" },
        { label: "VILLA DÍAZ ORDAZ", value: "VILLA DÍAZ ORDAZ" },
        { label: "YAXE", value: "YAXE" },
        { label: "MAGDALENA YODOCONO DE PORFIRIO DÍAZ", value: "MAGDALENA YODOCONO DE PORFIRIO DÍAZ" },
        { label: "YOGANA", value: "YOGANA" },
        { label: "YUTANDUCHI DE GUERRERO", value: "YUTANDUCHI DE GUERRERO" },
        { label: "VILLA DE ZAACHILA", value: "VILLA DE ZAACHILA" },
        { label: "SAN MATEO YUCUTINDOO", value: "SAN MATEO YUCUTINDOO" },
        { label: "ZAPOTITLÁN LAGUNAS", value: "ZAPOTITLÁN LAGUNAS" },
        { label: "ZAPOTITLÁN PALMAS", value: "ZAPOTITLÁN PALMAS" },
        { label: "SANTA INÉS DE ZARAGOZA", value: "SANTA INÉS DE ZARAGOZA" },
        { label: "ZIMATLÁN DE ÁLVAREZ", value: "ZIMATLÁN DE ÁLVAREZ" },
    ],
    "Puebla": [
        { label: "ACAJETE", value: "ACAJETE" },
        { label: "ACATENO", value: "ACATENO" },
        { label: "ACATLÁN", value: "ACATLÁN" },
        { label: "ACATZINGO", value: "ACATZINGO" },
        { label: "ACTEOPAN", value: "ACTEOPAN" },
        { label: "AHUACATLÁN", value: "AHUACATLÁN" },
        { label: "AHUATLÁN", value: "AHUATLÁN" },
        { label: "AHUAZOTEPEC", value: "AHUAZOTEPEC" },
        { label: "AHUEHUETITLA", value: "AHUEHUETITLA" },
        { label: "AJALPAN", value: "AJALPAN" },
        { label: "ALBINO ZERTUCHE", value: "ALBINO ZERTUCHE" },
        { label: "ALJOJUCA", value: "ALJOJUCA" },
        { label: "ALTEPEXI", value: "ALTEPEXI" },
        { label: "AMIXTLÁN", value: "AMIXTLÁN" },
        { label: "AMOZOC", value: "AMOZOC" },
        { label: "AQUIXTLA", value: "AQUIXTLA" },
        { label: "ATEMPAN", value: "ATEMPAN" },
        { label: "ATEXCAL", value: "ATEXCAL" },
        { label: "ATLIXCO", value: "ATLIXCO" },
        { label: "ATOYATEMPAN", value: "ATOYATEMPAN" },
        { label: "ATZALA", value: "ATZALA" },
        { label: "ATZITZIHUACÁN", value: "ATZITZIHUACÁN" },
        { label: "ATZITZINTLA", value: "ATZITZINTLA" },
        { label: "AXUTLA", value: "AXUTLA" },
        { label: "AYOTOXCO DE GUERRERO", value: "AYOTOXCO DE GUERRERO" },
        { label: "CALPAN", value: "CALPAN" },
        { label: "CALTEPEC", value: "CALTEPEC" },
        { label: "CAMOCUAUTLA", value: "CAMOCUAUTLA" },
        { label: "CAXHUACAN", value: "CAXHUACAN" },
        { label: "COATEPEC", value: "COATEPEC" },
        { label: "COATZINGO", value: "COATZINGO" },
        { label: "COHETZALA", value: "COHETZALA" },
        { label: "COHUECAN", value: "COHUECAN" },
        { label: "CORONANGO", value: "CORONANGO" },
        { label: "COXCATLÁN", value: "COXCATLÁN" },
        { label: "COYOMEAPAN", value: "COYOMEAPAN" },
        { label: "COYOTEPEC", value: "COYOTEPEC" },
        { label: "CUAPIAXTLA DE MADERO", value: "CUAPIAXTLA DE MADERO" },
        { label: "CUAUTEMPAN", value: "CUAUTEMPAN" },
        { label: "CUAUTINCHÁN", value: "CUAUTINCHÁN" },
        { label: "CUAUTLANCINGO", value: "CUAUTLANCINGO" },
        { label: "CUAYUCA DE ANDRADE", value: "CUAYUCA DE ANDRADE" },
        { label: "CUETZALAN DEL PROGRESO", value: "CUETZALAN DEL PROGRESO" },
        { label: "CUYOACO", value: "CUYOACO" },
        { label: "CHALCHICOMULA DE SESMA", value: "CHALCHICOMULA DE SESMA" },
        { label: "CHAPULCO", value: "CHAPULCO" },
        { label: "CHIAUTLA", value: "CHIAUTLA" },
        { label: "CHIAUTZINGO", value: "CHIAUTZINGO" },
        { label: "CHICONCUAUTLA", value: "CHICONCUAUTLA" },
        { label: "CHICHIQUILA", value: "CHICHIQUILA" },
        { label: "CHIETLA", value: "CHIETLA" },
        { label: "CHIGMECATITLÁN", value: "CHIGMECATITLÁN" },
        { label: "CHIGNAHUAPAN", value: "CHIGNAHUAPAN" },
        { label: "CHIGNAUTLA", value: "CHIGNAUTLA" },
        { label: "CHILA", value: "CHILA" },
        { label: "CHILA DE LA SAL", value: "CHILA DE LA SAL" },
        { label: "HONEY", value: "HONEY" },
        { label: "CHILCHOTLA", value: "CHILCHOTLA" },
        { label: "CHINANTLA", value: "CHINANTLA" },
        { label: "DOMINGO ARENAS", value: "DOMINGO ARENAS" },
        { label: "ELOXOCHITLÁN", value: "ELOXOCHITLÁN" },
        { label: "EPATLÁN", value: "EPATLÁN" },
        { label: "ESPERANZA", value: "ESPERANZA" },
        { label: "FRANCISCO Z. MENA", value: "FRANCISCO Z. MENA" },
        { label: "GENERAL FELIPE ÁNGELES", value: "GENERAL FELIPE ÁNGELES" },
        { label: "GUADALUPE", value: "GUADALUPE" },
        { label: "GUADALUPE VICTORIA", value: "GUADALUPE VICTORIA" },
        { label: "HERMENEGILDO GALEANA", value: "HERMENEGILDO GALEANA" },
        { label: "HUAQUECHULA", value: "HUAQUECHULA" },
        { label: "HUATLATLAUCA", value: "HUATLATLAUCA" },
        { label: "HUAUCHINANGO", value: "HUAUCHINANGO" },
        { label: "HUEHUETLA", value: "HUEHUETLA" },
        { label: "HUEHUETLÁN EL CHICO", value: "HUEHUETLÁN EL CHICO" },
        { label: "HUEJOTZINGO", value: "HUEJOTZINGO" },
        { label: "HUEYAPAN", value: "HUEYAPAN" },
        { label: "HUEYTAMALCO", value: "HUEYTAMALCO" },
        { label: "HUEYTLALPAN", value: "HUEYTLALPAN" },
        { label: "HUITZILAN DE SERDÁN", value: "HUITZILAN DE SERDÁN" },
        { label: "HUITZILTEPEC", value: "HUITZILTEPEC" },
        { label: "ATLEQUIZAYAN", value: "ATLEQUIZAYAN" },
        { label: "IXCAMILPA DE GUERRERO", value: "IXCAMILPA DE GUERRERO" },
        { label: "IXCAQUIXTLA", value: "IXCAQUIXTLA" },
        { label: "IXTACAMAXTITLÁN", value: "IXTACAMAXTITLÁN" },
        { label: "IXTEPEC", value: "IXTEPEC" },
        { label: "IZÚCAR DE MATAMOROS", value: "IZÚCAR DE MATAMOROS" },
        { label: "JALPAN", value: "JALPAN" },
        { label: "JOLALPAN", value: "JOLALPAN" },
        { label: "JONOTLA", value: "JONOTLA" },
        { label: "JOPALA", value: "JOPALA" },
        { label: "JUAN C. BONILLA", value: "JUAN C. BONILLA" },
        { label: "JUAN GALINDO", value: "JUAN GALINDO" },
        { label: "JUAN N. MÉNDEZ", value: "JUAN N. MÉNDEZ" },
        { label: "LAFRAGUA", value: "LAFRAGUA" },
        { label: "LIBRES", value: "LIBRES" },
        { label: "LA MAGDALENA TLATLAUQUITEPEC", value: "LA MAGDALENA TLATLAUQUITEPEC" },
        { label: "MAZAPILTEPEC DE JUÁREZ", value: "MAZAPILTEPEC DE JUÁREZ" },
        { label: "MIXTLA", value: "MIXTLA" },
        { label: "MOLCAXAC", value: "MOLCAXAC" },
        { label: "CAÑADA MORELOS", value: "CAÑADA MORELOS" },
        { label: "NAUPAN", value: "NAUPAN" },
        { label: "NAUZONTLA", value: "NAUZONTLA" },
        { label: "NEALTICAN", value: "NEALTICAN" },
        { label: "NICOLÁS BRAVO", value: "NICOLÁS BRAVO" },
        { label: "NOPALUCAN", value: "NOPALUCAN" },
        { label: "OCOTEPEC", value: "OCOTEPEC" },
        { label: "OCOYUCAN", value: "OCOYUCAN" },
        { label: "OLINTLA", value: "OLINTLA" },
        { label: "ORIENTAL", value: "ORIENTAL" },
        { label: "PAHUATLÁN", value: "PAHUATLÁN" },
        { label: "PALMAR DE BRAVO", value: "PALMAR DE BRAVO" },
        { label: "PANTEPEC", value: "PANTEPEC" },
        { label: "PETLALCINGO", value: "PETLALCINGO" },
        { label: "PIAXTLA", value: "PIAXTLA" },
        { label: "PUEBLA", value: "PUEBLA" },
        { label: "QUECHOLAC", value: "QUECHOLAC" },
        { label: "QUIMIXTLÁN", value: "QUIMIXTLÁN" },
        { label: "RAFAEL LARA GRAJALES", value: "RAFAEL LARA GRAJALES" },
        { label: "LOS REYES DE JUÁREZ", value: "LOS REYES DE JUÁREZ" },
        { label: "SAN ANDRÉS CHOLULA", value: "SAN ANDRÉS CHOLULA" },
        { label: "SAN ANTONIO CAÑADA", value: "SAN ANTONIO CAÑADA" },
        { label: "SAN DIEGO LA MESA TOCHIMILTZINGO", value: "SAN DIEGO LA MESA TOCHIMILTZINGO" },
        { label: "SAN FELIPE TEOTLALCINGO", value: "SAN FELIPE TEOTLALCINGO" },
        { label: "SAN FELIPE TEPATLÁN", value: "SAN FELIPE TEPATLÁN" },
        { label: "SAN GABRIEL CHILAC", value: "SAN GABRIEL CHILAC" },
        { label: "SAN GREGORIO ATZOMPA", value: "SAN GREGORIO ATZOMPA" },
        { label: "SAN JERÓNIMO TECUANIPAN", value: "SAN JERÓNIMO TECUANIPAN" },
        { label: "SAN JERÓNIMO XAYACATLÁN", value: "SAN JERÓNIMO XAYACATLÁN" },
        { label: "SAN JOSÉ CHIAPA", value: "SAN JOSÉ CHIAPA" },
        { label: "SAN JOSÉ MIAHUATLÁN", value: "SAN JOSÉ MIAHUATLÁN" },
        { label: "SAN JUAN ATENCO", value: "SAN JUAN ATENCO" },
        { label: "SAN JUAN ATZOMPA", value: "SAN JUAN ATZOMPA" },
        { label: "SAN MARTÍN TEXMELUCAN", value: "SAN MARTÍN TEXMELUCAN" },
        { label: "SAN MARTÍN TOTOLTEPEC", value: "SAN MARTÍN TOTOLTEPEC" },
        { label: "SAN MATÍAS TLALANCALECA", value: "SAN MATÍAS TLALANCALECA" },
        { label: "SAN MIGUEL IXITLÁN", value: "SAN MIGUEL IXITLÁN" },
        { label: "SAN MIGUEL XOXTLA", value: "SAN MIGUEL XOXTLA" },
        { label: "SAN NICOLÁS BUENOS AIRES", value: "SAN NICOLÁS BUENOS AIRES" },
        { label: "SAN NICOLÁS DE LOS RANCHOS", value: "SAN NICOLÁS DE LOS RANCHOS" },
        { label: "SAN PABLO ANICANO", value: "SAN PABLO ANICANO" },
        { label: "SAN PEDRO CHOLULA", value: "SAN PEDRO CHOLULA" },
        { label: "SAN PEDRO YELOIXTLAHUACA", value: "SAN PEDRO YELOIXTLAHUACA" },
        { label: "SAN SALVADOR EL SECO", value: "SAN SALVADOR EL SECO" },
        { label: "SAN SALVADOR EL VERDE", value: "SAN SALVADOR EL VERDE" },
        { label: "SAN SALVADOR HUIXCOLOTLA", value: "SAN SALVADOR HUIXCOLOTLA" },
        { label: "SAN SEBASTIÁN TLACOTEPEC", value: "SAN SEBASTIÁN TLACOTEPEC" },
        { label: "SANTA CATARINA TLALTEMPAN", value: "SANTA CATARINA TLALTEMPAN" },
        { label: "SANTA INÉS AHUATEMPAN", value: "SANTA INÉS AHUATEMPAN" },
        { label: "SANTA ISABEL CHOLULA", value: "SANTA ISABEL CHOLULA" },
        { label: "SANTIAGO MIAHUATLÁN", value: "SANTIAGO MIAHUATLÁN" },
        { label: "HUEHUETLÁN EL GRANDE", value: "HUEHUETLÁN EL GRANDE" },
        { label: "SANTO TOMÁS HUEYOTLIPAN", value: "SANTO TOMÁS HUEYOTLIPAN" },
        { label: "SOLTEPEC", value: "SOLTEPEC" },
        { label: "TECALI DE HERRERA", value: "TECALI DE HERRERA" },
        { label: "TECAMACHALCO", value: "TECAMACHALCO" },
        { label: "TECOMATLÁN", value: "TECOMATLÁN" },
        { label: "TEHUACÁN", value: "TEHUACÁN" },
        { label: "TEHUITZINGO", value: "TEHUITZINGO" },
        { label: "TENAMPULCO", value: "TENAMPULCO" },
        { label: "TEOPANTLÁN", value: "TEOPANTLÁN" },
        { label: "TEOTLALCO", value: "TEOTLALCO" },
        { label: "TEPANCO DE LÓPEZ", value: "TEPANCO DE LÓPEZ" },
        { label: "TEPANGO DE RODRÍGUEZ", value: "TEPANGO DE RODRÍGUEZ" },
        { label: "TEPATLAXCO DE HIDALGO", value: "TEPATLAXCO DE HIDALGO" },
        { label: "TEPEACA", value: "TEPEACA" },
        { label: "TEPEMAXALCO", value: "TEPEMAXALCO" },
        { label: "TEPEOJUMA", value: "TEPEOJUMA" },
        { label: "TEPETZINTLA", value: "TEPETZINTLA" },
        { label: "TEPEXCO", value: "TEPEXCO" },
        { label: "TEPEXI DE RODRÍGUEZ", value: "TEPEXI DE RODRÍGUEZ" },
        { label: "TEPEYAHUALCO", value: "TEPEYAHUALCO" },
        { label: "TEPEYAHUALCO DE CUAUHTÉMOC", value: "TEPEYAHUALCO DE CUAUHTÉMOC" },
        { label: "TETELA DE OCAMPO", value: "TETELA DE OCAMPO" },
        { label: "TETELES DE AVILA CASTILLO", value: "TETELES DE AVILA CASTILLO" },
        { label: "TEZIUTLÁN", value: "TEZIUTLÁN" },
        { label: "TIANGUISMANALCO", value: "TIANGUISMANALCO" },
        { label: "TILAPA", value: "TILAPA" },
        { label: "TLACOTEPEC DE BENITO JUÁREZ", value: "TLACOTEPEC DE BENITO JUÁREZ" },
        { label: "TLACUILOTEPEC", value: "TLACUILOTEPEC" },
        { label: "TLACHICHUCA", value: "TLACHICHUCA" },
        { label: "TLAHUAPAN", value: "TLAHUAPAN" },
        { label: "TLALTENANGO", value: "TLALTENANGO" },
        { label: "TLANEPANTLA", value: "TLANEPANTLA" },
        { label: "TLAOLA", value: "TLAOLA" },
        { label: "TLAPACOYA", value: "TLAPACOYA" },
        { label: "TLAPANALÁ", value: "TLAPANALÁ" },
        { label: "TLATLAUQUITEPEC", value: "TLATLAUQUITEPEC" },
        { label: "TLAXCO", value: "TLAXCO" },
        { label: "TOCHIMILCO", value: "TOCHIMILCO" },
        { label: "TOCHTEPEC", value: "TOCHTEPEC" },
        { label: "TOTOLTEPEC DE GUERRERO", value: "TOTOLTEPEC DE GUERRERO" },
        { label: "TULCINGO", value: "TULCINGO" },
        { label: "TUZAMAPAN DE GALEANA", value: "TUZAMAPAN DE GALEANA" },
        { label: "TZICATLACOYAN", value: "TZICATLACOYAN" },
        { label: "VENUSTIANO CARRANZA", value: "VENUSTIANO CARRANZA" },
        { label: "VICENTE GUERRERO", value: "VICENTE GUERRERO" },
        { label: "XAYACATLÁN DE BRAVO", value: "XAYACATLÁN DE BRAVO" },
        { label: "XICOTEPEC", value: "XICOTEPEC" },
        { label: "XICOTLÁN", value: "XICOTLÁN" },
        { label: "XIUTETELCO", value: "XIUTETELCO" },
        { label: "XOCHIAPULCO", value: "XOCHIAPULCO" },
        { label: "XOCHILTEPEC", value: "XOCHILTEPEC" },
        { label: "XOCHITLÁN DE VICENTE SUÁREZ", value: "XOCHITLÁN DE VICENTE SUÁREZ" },
        { label: "XOCHITLÁN TODOS SANTOS", value: "XOCHITLÁN TODOS SANTOS" },
        { label: "YAONÁHUAC", value: "YAONÁHUAC" },
        { label: "YEHUALTEPEC", value: "YEHUALTEPEC" },
        { label: "ZACAPALA", value: "ZACAPALA" },
        { label: "ZACAPOAXTLA", value: "ZACAPOAXTLA" },
        { label: "ZACATLÁN", value: "ZACATLÁN" },
        { label: "ZAPOTITLÁN", value: "ZAPOTITLÁN" },
        { label: "ZAPOTITLÁN DE MÉNDEZ", value: "ZAPOTITLÁN DE MÉNDEZ" },
        { label: "ZARAGOZA", value: "ZARAGOZA" },
        { label: "ZAUTLA", value: "ZAUTLA" },
        { label: "ZIHUATEUTLA", value: "ZIHUATEUTLA" },
        { label: "ZINACATEPEC", value: "ZINACATEPEC" },
        { label: "ZONGOZOTLA", value: "ZONGOZOTLA" },
        { label: "ZOQUIAPAN", value: "ZOQUIAPAN" },
        { label: "ZOQUITLÁN", value: "ZOQUITLÁN" },
    ],
    "Querétaro": [
        { label: "AMEALCO DE BONFIL", value: "AMEALCO DE BONFIL" },
        { label: "PINAL DE AMOLES", value: "PINAL DE AMOLES" },
        { label: "ARROYO SECO", value: "ARROYO SECO" },
        { label: "CADEREYTA DE MONTES", value: "CADEREYTA DE MONTES" },
        { label: "COLÓN", value: "COLÓN" },
        { label: "CORREGIDORA", value: "CORREGIDORA" },
        { label: "EZEQUIEL MONTES", value: "EZEQUIEL MONTES" },
        { label: "HUIMILPAN", value: "HUIMILPAN" },
        { label: "JALPAN DE SERRA", value: "JALPAN DE SERRA" },
        { label: "LANDA DE MATAMOROS", value: "LANDA DE MATAMOROS" },
        { label: "EL MARQUÉS", value: "EL MARQUÉS" },
        { label: "PEDRO ESCOBEDO", value: "PEDRO ESCOBEDO" },
        { label: "PEÑAMILLER", value: "PEÑAMILLER" },
        { label: "QUERÉTARO", value: "QUERÉTARO" },
        { label: "SAN JOAQUÍN", value: "SAN JOAQUÍN" },
        { label: "SAN JUAN DEL RÍO", value: "SAN JUAN DEL RÍO" },
        { label: "TEQUISQUIAPAN", value: "TEQUISQUIAPAN" },
        { label: "TOLIMÁN", value: "TOLIMÁN" },
    ],
    "Quintana Roo": [
        { label: "COZUMEL", value: "COZUMEL" },
        { label: "FELIPE CARRILLO PUERTO", value: "FELIPE CARRILLO PUERTO" },
        { label: "ISLA MUJERES", value: "ISLA MUJERES" },
        { label: "OTHÓN P. BLANCO", value: "OTHÓN P. BLANCO" },
        { label: "BENITO JUÁREZ", value: "BENITO JUÁREZ" },
        { label: "JOSÉ MARÍA MORELOS", value: "JOSÉ MARÍA MORELOS" },
        { label: "LÁZARO CÁRDENAS", value: "LÁZARO CÁRDENAS" },
        { label: "SOLIDARIDAD", value: "SOLIDARIDAD" },
        { label: "TULUM", value: "TULUM" },
        { label: "BACALAR", value: "BACALAR" },
        { label: "PUERTO MORELOS", value: "PUERTO MORELOS" },
        { label: "SAN LUIS POTOSÍ", value: "SAN LUIS POTOSÍ" },
        { label: "AHUALULCO", value: "AHUALULCO" },
        { label: "ALAQUINES", value: "ALAQUINES" },
        { label: "AQUISMÓN", value: "AQUISMÓN" },
        { label: "ARMADILLO DE LOS INFANTE", value: "ARMADILLO DE LOS INFANTE" },
        { label: "CÁRDENAS", value: "CÁRDENAS" },
        { label: "CATORCE", value: "CATORCE" },
        { label: "CEDRAL", value: "CEDRAL" },
        { label: "CERRITOS", value: "CERRITOS" },
        { label: "CERRO DE SAN PEDRO", value: "CERRO DE SAN PEDRO" },
        { label: "CIUDAD DEL MAÍZ", value: "CIUDAD DEL MAÍZ" },
        { label: "CIUDAD FERNÁNDEZ", value: "CIUDAD FERNÁNDEZ" },
        { label: "TANCANHUITZ", value: "TANCANHUITZ" },
        { label: "CIUDAD VALLES", value: "CIUDAD VALLES" },
        { label: "COXCATLÁN", value: "COXCATLÁN" },
        { label: "CHARCAS", value: "CHARCAS" },
        { label: "EBANO", value: "EBANO" },
        { label: "GUADALCÁZAR", value: "GUADALCÁZAR" },
        { label: "HUEHUETLÁN", value: "HUEHUETLÁN" },
        { label: "LAGUNILLAS", value: "LAGUNILLAS" },
        { label: "MATEHUALA", value: "MATEHUALA" },
        { label: "MEXQUITIC DE CARMONA", value: "MEXQUITIC DE CARMONA" },
        { label: "MOCTEZUMA", value: "MOCTEZUMA" },
        { label: "RAYÓN", value: "RAYÓN" },
        { label: "RIOVERDE", value: "RIOVERDE" },
        { label: "SALINAS", value: "SALINAS" },
        { label: "SAN ANTONIO", value: "SAN ANTONIO" },
        { label: "SAN CIRO DE ACOSTA", value: "SAN CIRO DE ACOSTA" },
    ],
    "San Luis Potosí": [
        { label: "SAN MARTÍN CHALCHICUAUTLA", value: "SAN MARTÍN CHALCHICUAUTLA" },
        { label: "SAN NICOLÁS TOLENTINO", value: "SAN NICOLÁS TOLENTINO" },
        { label: "SANTA CATARINA", value: "SANTA CATARINA" },
        { label: "SANTA MARÍA DEL RÍO", value: "SANTA MARÍA DEL RÍO" },
        { label: "SANTO DOMINGO", value: "SANTO DOMINGO" },
        { label: "SAN VICENTE TANCUAYALAB", value: "SAN VICENTE TANCUAYALAB" },
        { label: "SOLEDAD DE GRACIANO SÁNCHEZ", value: "SOLEDAD DE GRACIANO SÁNCHEZ" },
        { label: "TAMASOPO", value: "TAMASOPO" },
        { label: "TAMAZUNCHALE", value: "TAMAZUNCHALE" },
        { label: "TAMPACÁN", value: "TAMPACÁN" },
        { label: "TAMPAMOLÓN CORONA", value: "TAMPAMOLÓN CORONA" },
        { label: "TAMUÍN", value: "TAMUÍN" },
        { label: "TANLAJÁS", value: "TANLAJÁS" },
        { label: "TANQUIÁN DE ESCOBEDO", value: "TANQUIÁN DE ESCOBEDO" },
        { label: "TIERRA NUEVA", value: "TIERRA NUEVA" },
        { label: "VANEGAS", value: "VANEGAS" },
        { label: "VENADO", value: "VENADO" },
        { label: "VILLA DE ARRIAGA", value: "VILLA DE ARRIAGA" },
        { label: "VILLA DE GUADALUPE", value: "VILLA DE GUADALUPE" },
        { label: "VILLA DE LA PAZ", value: "VILLA DE LA PAZ" },
        { label: "VILLA DE RAMOS", value: "VILLA DE RAMOS" },
        { label: "VILLA DE REYES", value: "VILLA DE REYES" },
        { label: "VILLA HIDALGO", value: "VILLA HIDALGO" },
        { label: "VILLA JUÁREZ", value: "VILLA JUÁREZ" },
        { label: "AXTLA DE TERRAZAS", value: "AXTLA DE TERRAZAS" },
        { label: "XILITLA", value: "XILITLA" },
        { label: "ZARAGOZA", value: "ZARAGOZA" },
        { label: "VILLA DE ARISTA", value: "VILLA DE ARISTA" },
        { label: "MATLAPA", value: "MATLAPA" },
        { label: "EL NARANJO", value: "EL NARANJO" },
    ],
    "Sinaloa": [
        { label: "AHOME", value: "AHOME" },
        { label: "ANGOSTURA", value: "ANGOSTURA" },
        { label: "BADIRAGUATO", value: "BADIRAGUATO" },
        { label: "CONCORDIA", value: "CONCORDIA" },
        { label: "COSALÁ", value: "COSALÁ" },
        { label: "CULIACÁN", value: "CULIACÁN" },
        { label: "CHOIX", value: "CHOIX" },
        { label: "ELOTA", value: "ELOTA" },
        { label: "ESCUINAPA", value: "ESCUINAPA" },
        { label: "EL FUERTE", value: "EL FUERTE" },
        { label: "GUASAVE", value: "GUASAVE" },
        { label: "MAZATLÁN", value: "MAZATLÁN" },
        { label: "MOCORITO", value: "MOCORITO" },
        { label: "ROSARIO", value: "ROSARIO" },
        { label: "SALVADOR ALVARADO", value: "SALVADOR ALVARADO" },
        { label: "SAN IGNACIO", value: "SAN IGNACIO" },
        { label: "SINALOA", value: "SINALOA" },
        { label: "NAVOLATO", value: "NAVOLATO" },
    ],
    "Sonora": [
        { label: "ACONCHI", value: "ACONCHI" },
        { label: "AGUA PRIETA", value: "AGUA PRIETA" },
        { label: "ALAMOS", value: "ALAMOS" },
        { label: "ALTAR", value: "ALTAR" },
        { label: "ARIVECHI", value: "ARIVECHI" },
        { label: "ARIZPE", value: "ARIZPE" },
        { label: "ATIL", value: "ATIL" },
        { label: "BACADÉHUACHI", value: "BACADÉHUACHI" },
        { label: "BACANORA", value: "BACANORA" },
        { label: "BACERAC", value: "BACERAC" },
        { label: "BACOACHI", value: "BACOACHI" },
        { label: "BÁCUM", value: "BÁCUM" },
        { label: "BANÁMICHI", value: "BANÁMICHI" },
        { label: "BAVIÁCORA", value: "BAVIÁCORA" },
        { label: "BAVISPE", value: "BAVISPE" },
        { label: "BENJAMÍN HILL", value: "BENJAMÍN HILL" },
        { label: "CABORCA", value: "CABORCA" },
        { label: "CAJEME", value: "CAJEME" },
        { label: "CANANEA", value: "CANANEA" },
        { label: "CARBÓ", value: "CARBÓ" },
        { label: "LA COLORADA", value: "LA COLORADA" },
        { label: "CUCURPE", value: "CUCURPE" },
        { label: "CUMPAS", value: "CUMPAS" },
        { label: "DIVISADEROS", value: "DIVISADEROS" },
        { label: "EMPALME", value: "EMPALME" },
        { label: "ETCHOJOA", value: "ETCHOJOA" },
        { label: "FRONTERAS", value: "FRONTERAS" },
        { label: "GRANADOS", value: "GRANADOS" },
        { label: "GUAYMAS", value: "GUAYMAS" },
        { label: "HERMOSILLO", value: "HERMOSILLO" },
        { label: "HUACHINERA", value: "HUACHINERA" },
        { label: "HUÁSABAS", value: "HUÁSABAS" },
        { label: "HUATABAMPO", value: "HUATABAMPO" },
        { label: "HUÉPAC", value: "HUÉPAC" },
        { label: "IMURIS", value: "IMURIS" },
        { label: "MAGDALENA", value: "MAGDALENA" },
        { label: "MAZATÁN", value: "MAZATÁN" },
        { label: "MOCTEZUMA", value: "MOCTEZUMA" },
        { label: "NACO", value: "NACO" },
        { label: "NÁCORI CHICO", value: "NÁCORI CHICO" },
        { label: "NACOZARI DE GARCÍA", value: "NACOZARI DE GARCÍA" },
        { label: "NAVOJOA", value: "NAVOJOA" },
        { label: "NOGALES", value: "NOGALES" },
        { label: "ONAVAS", value: "ONAVAS" },
        { label: "OPODEPE", value: "OPODEPE" },
        { label: "OQUITOA", value: "OQUITOA" },
        { label: "PITIQUITO", value: "PITIQUITO" },
        { label: "PUERTO PEÑASCO", value: "PUERTO PEÑASCO" },
        { label: "QUIRIEGO", value: "QUIRIEGO" },
        { label: "RAYÓN", value: "RAYÓN" },
        { label: "ROSARIO", value: "ROSARIO" },
        { label: "SAHUARIPA", value: "SAHUARIPA" },
        { label: "SAN FELIPE DE JESÚS", value: "SAN FELIPE DE JESÚS" },
        { label: "SAN JAVIER", value: "SAN JAVIER" },
        { label: "SAN LUIS RÍO COLORADO", value: "SAN LUIS RÍO COLORADO" },
        { label: "SAN MIGUEL DE HORCASITAS", value: "SAN MIGUEL DE HORCASITAS" },
        { label: "SAN PEDRO DE LA CUEVA", value: "SAN PEDRO DE LA CUEVA" },
        { label: "SANTA ANA", value: "SANTA ANA" },
        { label: "SANTA CRUZ", value: "SANTA CRUZ" },
        { label: "SÁRIC", value: "SÁRIC" },
        { label: "SOYOPA", value: "SOYOPA" },
        { label: "SUAQUI GRANDE", value: "SUAQUI GRANDE" },
        { label: "TEPACHE", value: "TEPACHE" },
        { label: "TRINCHERAS", value: "TRINCHERAS" },
        { label: "TUBUTAMA", value: "TUBUTAMA" },
        { label: "URES", value: "URES" },
        { label: "VILLA HIDALGO", value: "VILLA HIDALGO" },
        { label: "VILLA PESQUEIRA", value: "VILLA PESQUEIRA" },
        { label: "YÉCORA", value: "YÉCORA" },
        { label: "GENERAL PLUTARCO ELÍAS CALLES", value: "GENERAL PLUTARCO ELÍAS CALLES" },
        { label: "BENITO JUÁREZ", value: "BENITO JUÁREZ" },
        { label: "SAN IGNACIO RÍO MUERTO", value: "SAN IGNACIO RÍO MUERTO" },
    ],
    "Tabasco": [
        { label: "BALANCÁN", value: "BALANCÁN" },
        { label: "CÁRDENAS", value: "CÁRDENAS" },
        { label: "CENTLA", value: "CENTLA" },
        { label: "CENTRO", value: "CENTRO" },
        { label: "COMALCALCO", value: "COMALCALCO" },
        { label: "CUNDUACÁN", value: "CUNDUACÁN" },
        { label: "EMILIANO ZAPATA", value: "EMILIANO ZAPATA" },
        { label: "HUIMANGUILLO", value: "HUIMANGUILLO" },
        { label: "JALAPA", value: "JALAPA" },
        { label: "JALPA DE MÉNDEZ", value: "JALPA DE MÉNDEZ" },
        { label: "JONUTA", value: "JONUTA" },
        { label: "MACUSPANA", value: "MACUSPANA" },
        { label: "NACAJUCA", value: "NACAJUCA" },
        { label: "PARAÍSO", value: "PARAÍSO" },
        { label: "TACOTALPA", value: "TACOTALPA" },
        { label: "TEAPA", value: "TEAPA" },
        { label: "TENOSIQUE", value: "TENOSIQUE" },
    ],
    "Tamaulipas": [
        { label: "ABASOLO", value: "ABASOLO" },
        { label: "ALDAMA", value: "ALDAMA" },
        { label: "ALTAMIRA", value: "ALTAMIRA" },
        { label: "ANTIGUO MORELOS", value: "ANTIGUO MORELOS" },
        { label: "BURGOS", value: "BURGOS" },
        { label: "BUSTAMANTE", value: "BUSTAMANTE" },
        { label: "CAMARGO", value: "CAMARGO" },
        { label: "CASAS", value: "CASAS" },
        { label: "CIUDAD MADERO", value: "CIUDAD MADERO" },
        { label: "CRUILLAS", value: "CRUILLAS" },
        { label: "GÓMEZ FARÍAS", value: "GÓMEZ FARÍAS" },
        { label: "GONZÁLEZ", value: "GONZÁLEZ" },
        { label: "GÜÉMEZ", value: "GÜÉMEZ" },
        { label: "GUERRERO", value: "GUERRERO" },
        { label: "GUSTAVO DÍAZ ORDAZ", value: "GUSTAVO DÍAZ ORDAZ" },
        { label: "HIDALGO", value: "HIDALGO" },
        { label: "JAUMAVE", value: "JAUMAVE" },
        { label: "JIMÉNEZ", value: "JIMÉNEZ" },
        { label: "LLERA", value: "LLERA" },
        { label: "MAINERO", value: "MAINERO" },
        { label: "EL MANTE", value: "EL MANTE" },
        { label: "MATAMOROS", value: "MATAMOROS" },
        { label: "MÉNDEZ", value: "MÉNDEZ" },
        { label: "MIER", value: "MIER" },
        { label: "MIGUEL ALEMÁN", value: "MIGUEL ALEMÁN" },
        { label: "MIQUIHUANA", value: "MIQUIHUANA" },
        { label: "NUEVO LAREDO", value: "NUEVO LAREDO" },
        { label: "NUEVO MORELOS", value: "NUEVO MORELOS" },
        { label: "OCAMPO", value: "OCAMPO" },
        { label: "PADILLA", value: "PADILLA" },
        { label: "PALMILLAS", value: "PALMILLAS" },
        { label: "REYNOSA", value: "REYNOSA" },
        { label: "RÍO BRAVO", value: "RÍO BRAVO" },
        { label: "SAN CARLOS", value: "SAN CARLOS" },
        { label: "SAN FERNANDO", value: "SAN FERNANDO" },
        { label: "SAN NICOLÁS", value: "SAN NICOLÁS" },
        { label: "SOTO LA MARINA", value: "SOTO LA MARINA" },
        { label: "TAMPICO", value: "TAMPICO" },
        { label: "TULA", value: "TULA" },
        { label: "VALLE HERMOSO", value: "VALLE HERMOSO" },
        { label: "VICTORIA", value: "VICTORIA" },
        { label: "VILLAGRÁN", value: "VILLAGRÁN" },
        { label: "XICOTÉNCATL", value: "XICOTÉNCATL" },
    ],
    "Tlaxcala": [
        { label: "AMAXAC DE GUERRERO", value: "AMAXAC DE GUERRERO" },
        { label: "APETATITLÁN DE ANTONIO CARVAJAL", value: "APETATITLÁN DE ANTONIO CARVAJAL" },
        { label: "ATLANGATEPEC", value: "ATLANGATEPEC" },
        { label: "ATLTZAYANCA", value: "ATLTZAYANCA" },
        { label: "APIZACO", value: "APIZACO" },
        { label: "CALPULALPAN", value: "CALPULALPAN" },
        { label: "EL CARMEN TEQUEXQUITLA", value: "EL CARMEN TEQUEXQUITLA" },
        { label: "CUAPIAXTLA", value: "CUAPIAXTLA" },
        { label: "CUAXOMULCO", value: "CUAXOMULCO" },
        { label: "CHIAUTEMPAN", value: "CHIAUTEMPAN" },
        { label: "MUÑOZ DE DOMINGO ARENAS", value: "MUÑOZ DE DOMINGO ARENAS" },
        { label: "ESPAÑITA", value: "ESPAÑITA" },
        { label: "HUAMANTLA", value: "HUAMANTLA" },
        { label: "HUEYOTLIPAN", value: "HUEYOTLIPAN" },
        { label: "IXTACUIXTLA DE MARIANO MATAMOROS", value: "IXTACUIXTLA DE MARIANO MATAMOROS" },
        { label: "IXTENCO", value: "IXTENCO" },
        { label: "MAZATECOCHCO DE JOSÉ MARÍA MORELOS", value: "MAZATECOCHCO DE JOSÉ MARÍA MORELOS" },
        { label: "CONTLA DE JUAN CUAMATZI", value: "CONTLA DE JUAN CUAMATZI" },
        { label: "TEPETITLA DE LARDIZÁBAL", value: "TEPETITLA DE LARDIZÁBAL" },
        { label: "SANCTÓRUM DE LÁZARO CÁRDENAS", value: "SANCTÓRUM DE LÁZARO CÁRDENAS" },
        { label: "NANACAMILPA DE MARIANO ARISTA", value: "NANACAMILPA DE MARIANO ARISTA" },
        { label: "ACUAMANALA DE MIGUEL HIDALGO", value: "ACUAMANALA DE MIGUEL HIDALGO" },
        { label: "NATÍVITAS", value: "NATÍVITAS" },
        { label: "PANOTLA", value: "PANOTLA" },
        { label: "SAN PABLO DEL MONTE", value: "SAN PABLO DEL MONTE" },
        { label: "SANTA CRUZ TLAXCALA", value: "SANTA CRUZ TLAXCALA" },
        { label: "TENANCINGO", value: "TENANCINGO" },
        { label: "TEOLOCHOLCO", value: "TEOLOCHOLCO" },
        { label: "TEPEYANCO", value: "TEPEYANCO" },
        { label: "TERRENATE", value: "TERRENATE" },
        { label: "TETLA DE LA SOLIDARIDAD", value: "TETLA DE LA SOLIDARIDAD" },
        { label: "TETLATLAHUCA", value: "TETLATLAHUCA" },
        { label: "TLAXCALA", value: "TLAXCALA" },
        { label: "TLAXCO", value: "TLAXCO" },
        { label: "TOCATLÁN", value: "TOCATLÁN" },
        { label: "TOTOLAC", value: "TOTOLAC" },
        { label: "ZILTLALTÉPEC DE TRINIDAD SÁNCHEZ SANTOS", value: "ZILTLALTÉPEC DE TRINIDAD SÁNCHEZ SANTOS" },
        { label: "TZOMPANTEPEC", value: "TZOMPANTEPEC" },
        { label: "XALOZTOC", value: "XALOZTOC" },
        { label: "XALTOCAN", value: "XALTOCAN" },
        { label: "PAPALOTLA DE XICOHTÉNCATL", value: "PAPALOTLA DE XICOHTÉNCATL" },
        { label: "XICOHTZINCO", value: "XICOHTZINCO" },
        { label: "YAUHQUEMEHCAN", value: "YAUHQUEMEHCAN" },
        { label: "ZACATELCO", value: "ZACATELCO" },
        { label: "BENITO JUÁREZ", value: "BENITO JUÁREZ" },
        { label: "EMILIANO ZAPATA", value: "EMILIANO ZAPATA" },
        { label: "LÁZARO CÁRDENAS", value: "LÁZARO CÁRDENAS" },
        { label: "LA MAGDALENA TLALTELULCO", value: "LA MAGDALENA TLALTELULCO" },
        { label: "SAN DAMIÁN TEXÓLOC", value: "SAN DAMIÁN TEXÓLOC" },
        { label: "SAN FRANCISCO TETLANOHCAN", value: "SAN FRANCISCO TETLANOHCAN" },
        { label: "SAN JERÓNIMO ZACUALPAN", value: "SAN JERÓNIMO ZACUALPAN" },
        { label: "SAN JOSÉ TEACALCO", value: "SAN JOSÉ TEACALCO" },
        { label: "SAN JUAN HUACTZINCO", value: "SAN JUAN HUACTZINCO" },
        { label: "SAN LORENZO AXOCOMANITLA", value: "SAN LORENZO AXOCOMANITLA" },
        { label: "SAN LUCAS TECOPILCO", value: "SAN LUCAS TECOPILCO" },
        { label: "SANTA ANA NOPALUCAN", value: "SANTA ANA NOPALUCAN" },
        { label: "SANTA APOLONIA TEACALCO", value: "SANTA APOLONIA TEACALCO" },
        { label: "SANTA CATARINA AYOMETLA", value: "SANTA CATARINA AYOMETLA" },
        { label: "SANTA CRUZ QUILEHTLA", value: "SANTA CRUZ QUILEHTLA" },
        { label: "SANTA ISABEL XILOXOXTLA", value: "SANTA ISABEL XILOXOXTLA" },
    ],
    "Veracruz": [
        { label: "ACAJETE", value: "ACAJETE" },
        { label: "ACATLÁN", value: "ACATLÁN" },
        { label: "ACAYUCAN", value: "ACAYUCAN" },
        { label: "ACTOPAN", value: "ACTOPAN" },
        { label: "ACULA", value: "ACULA" },
        { label: "ACULTZINGO", value: "ACULTZINGO" },
        { label: "CAMARÓN DE TEJEDA", value: "CAMARÓN DE TEJEDA" },
        { label: "ALPATLÁHUAC", value: "ALPATLÁHUAC" },
        { label: "ALTO LUCERO DE GUTIÉRREZ BARRIOS", value: "ALTO LUCERO DE GUTIÉRREZ BARRIOS" },
        { label: "ALTOTONGA", value: "ALTOTONGA" },
        { label: "ALVARADO", value: "ALVARADO" },
        { label: "AMATITLÁN", value: "AMATITLÁN" },
        { label: "NARANJOS AMATLÁN", value: "NARANJOS AMATLÁN" },
        { label: "AMATLÁN DE LOS REYES", value: "AMATLÁN DE LOS REYES" },
        { label: "ANGEL R. CABADA", value: "ANGEL R. CABADA" },
        { label: "LA ANTIGUA", value: "LA ANTIGUA" },
        { label: "APAZAPAN", value: "APAZAPAN" },
        { label: "AQUILA", value: "AQUILA" },
        { label: "ASTACINGA", value: "ASTACINGA" },
        { label: "ATLAHUILCO", value: "ATLAHUILCO" },
        { label: "ATOYAC", value: "ATOYAC" },
        { label: "ATZACAN", value: "ATZACAN" },
        { label: "ATZALAN", value: "ATZALAN" },
        { label: "TLALTETELA", value: "TLALTETELA" },
        { label: "AYAHUALULCO", value: "AYAHUALULCO" },
        { label: "BANDERILLA", value: "BANDERILLA" },
        { label: "BENITO JUÁREZ", value: "BENITO JUÁREZ" },
        { label: "BOCA DEL RÍO", value: "BOCA DEL RÍO" },
        { label: "CALCAHUALCO", value: "CALCAHUALCO" },
        { label: "CAMERINO Z. MENDOZA", value: "CAMERINO Z. MENDOZA" },
        { label: "CARRILLO PUERTO", value: "CARRILLO PUERTO" },
        { label: "CATEMACO", value: "CATEMACO" },
        { label: "CAZONES DE HERRERA", value: "CAZONES DE HERRERA" },
        { label: "CERRO AZUL", value: "CERRO AZUL" },
        { label: "CITLALTÉPETL", value: "CITLALTÉPETL" },
        { label: "COACOATZINTLA", value: "COACOATZINTLA" },
        { label: "COAHUITLÁN", value: "COAHUITLÁN" },
        { label: "COATEPEC", value: "COATEPEC" },
        { label: "COATZACOALCOS", value: "COATZACOALCOS" },
        { label: "COATZINTLA", value: "COATZINTLA" },
        { label: "COETZALA", value: "COETZALA" },
        { label: "COLIPA", value: "COLIPA" },
        { label: "COMAPA", value: "COMAPA" },
        { label: "CÓRDOBA", value: "CÓRDOBA" },
        { label: "COSAMALOAPAN DE CARPIO", value: "COSAMALOAPAN DE CARPIO" },
        { label: "COSAUTLÁN DE CARVAJAL", value: "COSAUTLÁN DE CARVAJAL" },
        { label: "COSCOMATEPEC", value: "COSCOMATEPEC" },
        { label: "COSOLEACAQUE", value: "COSOLEACAQUE" },
        { label: "COTAXTLA", value: "COTAXTLA" },
        { label: "COXQUIHUI", value: "COXQUIHUI" },
        { label: "COYUTLA", value: "COYUTLA" },
        { label: "CUICHAPA", value: "CUICHAPA" },
        { label: "CUITLÁHUAC", value: "CUITLÁHUAC" },
        { label: "CHACALTIANGUIS", value: "CHACALTIANGUIS" },
        { label: "CHALMA", value: "CHALMA" },
        { label: "CHICONAMEL", value: "CHICONAMEL" },
        { label: "CHICONQUIACO", value: "CHICONQUIACO" },
        { label: "CHICONTEPEC", value: "CHICONTEPEC" },
        { label: "CHINAMECA", value: "CHINAMECA" },
        { label: "CHINAMPA DE GOROSTIZA", value: "CHINAMPA DE GOROSTIZA" },
        { label: "LAS CHOAPAS", value: "LAS CHOAPAS" },
        { label: "CHOCAMÁN", value: "CHOCAMÁN" },
        { label: "CHONTLA", value: "CHONTLA" },
        { label: "CHUMATLÁN", value: "CHUMATLÁN" },
        { label: "EMILIANO ZAPATA", value: "EMILIANO ZAPATA" },
        { label: "ESPINAL", value: "ESPINAL" },
        { label: "FILOMENO MATA", value: "FILOMENO MATA" },
        { label: "FORTÍN", value: "FORTÍN" },
        { label: "GUTIÉRREZ ZAMORA", value: "GUTIÉRREZ ZAMORA" },
        { label: "HIDALGOTITLÁN", value: "HIDALGOTITLÁN" },
        { label: "HUATUSCO", value: "HUATUSCO" },
        { label: "HUAYACOCOTLA", value: "HUAYACOCOTLA" },
        { label: "HUEYAPAN DE OCAMPO", value: "HUEYAPAN DE OCAMPO" },
        { label: "HUILOAPAN DE CUAUHTÉMOC", value: "HUILOAPAN DE CUAUHTÉMOC" },
        { label: "IGNACIO DE LA LLAVE", value: "IGNACIO DE LA LLAVE" },
        { label: "ILAMATLÁN", value: "ILAMATLÁN" },
        { label: "ISLA", value: "ISLA" },
        { label: "IXCATEPEC", value: "IXCATEPEC" },
        { label: "IXHUACÁN DE LOS REYES", value: "IXHUACÁN DE LOS REYES" },
        { label: "IXHUATLÁN DEL CAFÉ", value: "IXHUATLÁN DEL CAFÉ" },
        { label: "IXHUATLANCILLO", value: "IXHUATLANCILLO" },
        { label: "IXHUATLÁN DEL SURESTE", value: "IXHUATLÁN DEL SURESTE" },
        { label: "IXHUATLÁN DE MADERO", value: "IXHUATLÁN DE MADERO" },
        { label: "IXMATLAHUACAN", value: "IXMATLAHUACAN" },
        { label: "IXTACZOQUITLÁN", value: "IXTACZOQUITLÁN" },
        { label: "JALACINGO", value: "JALACINGO" },
        { label: "XALAPA", value: "XALAPA" },
        { label: "JALCOMULCO", value: "JALCOMULCO" },
        { label: "JÁLTIPAN", value: "JÁLTIPAN" },
        { label: "JAMAPA", value: "JAMAPA" },
        { label: "JESÚS CARRANZA", value: "JESÚS CARRANZA" },
        { label: "XICO", value: "XICO" },
        { label: "JILOTEPEC", value: "JILOTEPEC" },
        { label: "JUAN RODRÍGUEZ CLARA", value: "JUAN RODRÍGUEZ CLARA" },
        { label: "JUCHIQUE DE FERRER", value: "JUCHIQUE DE FERRER" },
        { label: "LANDERO Y COSS", value: "LANDERO Y COSS" },
        { label: "LERDO DE TEJADA", value: "LERDO DE TEJADA" },
        { label: "MAGDALENA", value: "MAGDALENA" },
        { label: "MALTRATA", value: "MALTRATA" },
        { label: "MANLIO FABIO ALTAMIRANO", value: "MANLIO FABIO ALTAMIRANO" },
        { label: "MARIANO ESCOBEDO", value: "MARIANO ESCOBEDO" },
        { label: "MARTÍNEZ DE LA TORRE", value: "MARTÍNEZ DE LA TORRE" },
        { label: "MECATLÁN", value: "MECATLÁN" },
        { label: "MECAYAPAN", value: "MECAYAPAN" },
        { label: "MEDELLÍN DE BRAVO", value: "MEDELLÍN DE BRAVO" },
        { label: "MIAHUATLÁN", value: "MIAHUATLÁN" },
        { label: "LAS MINAS", value: "LAS MINAS" },
        { label: "MINATITLÁN", value: "MINATITLÁN" },
        { label: "MISANTLA", value: "MISANTLA" },
        { label: "MIXTLA DE ALTAMIRANO", value: "MIXTLA DE ALTAMIRANO" },
        { label: "MOLOACÁN", value: "MOLOACÁN" },
        { label: "NAOLINCO", value: "NAOLINCO" },
        { label: "NARANJAL", value: "NARANJAL" },
        { label: "NAUTLA", value: "NAUTLA" },
        { label: "NOGALES", value: "NOGALES" },
        { label: "OLUTA", value: "OLUTA" },
        { label: "OMEALCA", value: "OMEALCA" },
        { label: "ORIZABA", value: "ORIZABA" },
        { label: "OTATITLÁN", value: "OTATITLÁN" },
        { label: "OTEAPAN", value: "OTEAPAN" },
        { label: "OZULUAMA DE MASCAREÑAS", value: "OZULUAMA DE MASCAREÑAS" },
        { label: "PAJAPAN", value: "PAJAPAN" },
        { label: "PÁNUCO", value: "PÁNUCO" },
        { label: "PAPANTLA", value: "PAPANTLA" },
        { label: "PASO DEL MACHO", value: "PASO DEL MACHO" },
        { label: "PASO DE OVEJAS", value: "PASO DE OVEJAS" },
        { label: "LA PERLA", value: "LA PERLA" },
        { label: "PEROTE", value: "PEROTE" },
        { label: "PLATÓN SÁNCHEZ", value: "PLATÓN SÁNCHEZ" },
        { label: "PLAYA VICENTE", value: "PLAYA VICENTE" },
        { label: "POZA RICA DE HIDALGO", value: "POZA RICA DE HIDALGO" },
        { label: "LAS VIGAS DE RAMÍREZ", value: "LAS VIGAS DE RAMÍREZ" },
        { label: "PUEBLO VIEJO", value: "PUEBLO VIEJO" },
        { label: "PUENTE NACIONAL", value: "PUENTE NACIONAL" },
        { label: "RAFAEL DELGADO", value: "RAFAEL DELGADO" },
        { label: "RAFAEL LUCIO", value: "RAFAEL LUCIO" },
        { label: "LOS REYES", value: "LOS REYES" },
        { label: "RÍO BLANCO", value: "RÍO BLANCO" },
        { label: "SALTABARRANCA", value: "SALTABARRANCA" },
        { label: "SAN ANDRÉS TENEJAPAN", value: "SAN ANDRÉS TENEJAPAN" },
        { label: "SAN ANDRÉS TUXTLA", value: "SAN ANDRÉS TUXTLA" },
        { label: "SAN JUAN EVANGELISTA", value: "SAN JUAN EVANGELISTA" },
        { label: "SANTIAGO TUXTLA", value: "SANTIAGO TUXTLA" },
        { label: "SAYULA DE ALEMÁN", value: "SAYULA DE ALEMÁN" },
        { label: "SOCONUSCO", value: "SOCONUSCO" },
        { label: "SOCHIAPA", value: "SOCHIAPA" },
        { label: "SOLEDAD ATZOMPA", value: "SOLEDAD ATZOMPA" },
        { label: "SOLEDAD DE DOBLADO", value: "SOLEDAD DE DOBLADO" },
        { label: "SOTEAPAN", value: "SOTEAPAN" },
        { label: "TAMALÍN", value: "TAMALÍN" },
        { label: "TAMIAHUA", value: "TAMIAHUA" },
        { label: "TAMPICO ALTO", value: "TAMPICO ALTO" },
        { label: "TANCOCO", value: "TANCOCO" },
        { label: "TANTIMA", value: "TANTIMA" },
        { label: "TANTOYUCA", value: "TANTOYUCA" },
        { label: "TATATILA", value: "TATATILA" },
        { label: "CASTILLO DE TEAYO", value: "CASTILLO DE TEAYO" },
        { label: "TECOLUTLA", value: "TECOLUTLA" },
        { label: "TEHUIPANGO", value: "TEHUIPANGO" },
        { label: "ÁLAMO TEMAPACHE", value: "ÁLAMO TEMAPACHE" },
        { label: "TEMPOAL", value: "TEMPOAL" },
        { label: "TENAMPA", value: "TENAMPA" },
        { label: "TENOCHTITLÁN", value: "TENOCHTITLÁN" },
        { label: "TEOCELO", value: "TEOCELO" },
        { label: "TEPATLAXCO", value: "TEPATLAXCO" },
        { label: "TEPETLÁN", value: "TEPETLÁN" },
        { label: "TEPETZINTLA", value: "TEPETZINTLA" },
        { label: "TEQUILA", value: "TEQUILA" },
        { label: "JOSÉ AZUETA", value: "JOSÉ AZUETA" },
        { label: "TEXCATEPEC", value: "TEXCATEPEC" },
        { label: "TEXHUACÁN", value: "TEXHUACÁN" },
        { label: "TEXISTEPEC", value: "TEXISTEPEC" },
        { label: "TEZONAPA", value: "TEZONAPA" },
        { label: "TIERRA BLANCA", value: "TIERRA BLANCA" },
        { label: "TIHUATLÁN", value: "TIHUATLÁN" },
        { label: "TLACOJALPAN", value: "TLACOJALPAN" },
        { label: "TLACOLULAN", value: "TLACOLULAN" },
        { label: "TLACOTALPAN", value: "TLACOTALPAN" },
        { label: "TLACOTEPEC DE MEJÍA", value: "TLACOTEPEC DE MEJÍA" },
        { label: "TLACHICHILCO", value: "TLACHICHILCO" },
        { label: "TLALIXCOYAN", value: "TLALIXCOYAN" },
        { label: "TLALNELHUAYOCAN", value: "TLALNELHUAYOCAN" },
        { label: "TLAPACOYAN", value: "TLAPACOYAN" },
        { label: "TLAQUILPA", value: "TLAQUILPA" },
        { label: "TLILAPAN", value: "TLILAPAN" },
        { label: "TOMATLÁN", value: "TOMATLÁN" },
        { label: "TONAYÁN", value: "TONAYÁN" },
        { label: "TOTUTLA", value: "TOTUTLA" },
        { label: "TUXPAN", value: "TUXPAN" },
        { label: "TUXTILLA", value: "TUXTILLA" },
        { label: "URSULO GALVÁN", value: "URSULO GALVÁN" },
        { label: "VEGA DE ALATORRE", value: "VEGA DE ALATORRE" },
        { label: "VERACRUZ", value: "VERACRUZ" },
        { label: "VILLA ALDAMA", value: "VILLA ALDAMA" },
        { label: "XOXOCOTLA", value: "XOXOCOTLA" },
        { label: "YANGA", value: "YANGA" },
        { label: "YECUATLA", value: "YECUATLA" },
        { label: "ZACUALPAN", value: "ZACUALPAN" },
        { label: "ZARAGOZA", value: "ZARAGOZA" },
        { label: "ZENTLA", value: "ZENTLA" },
        { label: "ZONGOLICA", value: "ZONGOLICA" },
        { label: "ZONTECOMATLÁN DE LÓPEZ Y FUENTES", value: "ZONTECOMATLÁN DE LÓPEZ Y FUENTES" },
        { label: "ZOZOCOLCO DE HIDALGO", value: "ZOZOCOLCO DE HIDALGO" },
        { label: "AGUA DULCE", value: "AGUA DULCE" },
        { label: "EL HIGO", value: "EL HIGO" },
        { label: "NANCHITAL DE LÁZARO CÁRDENAS DEL RÍO", value: "NANCHITAL DE LÁZARO CÁRDENAS DEL RÍO" },
        { label: "TRES VALLES", value: "TRES VALLES" },
        { label: "CARLOS A. CARRILLO", value: "CARLOS A. CARRILLO" },
        { label: "TATAHUICAPAN DE JUÁREZ", value: "TATAHUICAPAN DE JUÁREZ" },
        { label: "UXPANAPA", value: "UXPANAPA" },
        { label: "SAN RAFAEL", value: "SAN RAFAEL" },
        { label: "SANTIAGO SOCHIAPAN", value: "SANTIAGO SOCHIAPAN" },
    ],
    "Yucatán": [
        { label: "ABALÁ", value: "ABALÁ" },
        { label: "ACANCEH", value: "ACANCEH" },
        { label: "AKIL", value: "AKIL" },
        { label: "BACA", value: "BACA" },
        { label: "BOKOBÁ", value: "BOKOBÁ" },
        { label: "BUCTZOTZ", value: "BUCTZOTZ" },
        { label: "CACALCHÉN", value: "CACALCHÉN" },
        { label: "CALOTMUL", value: "CALOTMUL" },
        { label: "CANSAHCAB", value: "CANSAHCAB" },
        { label: "CANTAMAYEC", value: "CANTAMAYEC" },
        { label: "CELESTÚN", value: "CELESTÚN" },
        { label: "CENOTILLO", value: "CENOTILLO" },
        { label: "CONKAL", value: "CONKAL" },
        { label: "CUNCUNUL", value: "CUNCUNUL" },
        { label: "CUZAMÁ", value: "CUZAMÁ" },
        { label: "CHACSINKÍN", value: "CHACSINKÍN" },
        { label: "CHANKOM", value: "CHANKOM" },
        { label: "CHAPAB", value: "CHAPAB" },
        { label: "CHEMAX", value: "CHEMAX" },
        { label: "CHICXULUB PUEBLO", value: "CHICXULUB PUEBLO" },
        { label: "CHICHIMILÁ", value: "CHICHIMILÁ" },
        { label: "CHIKINDZONOT", value: "CHIKINDZONOT" },
        { label: "CHOCHOLÁ", value: "CHOCHOLÁ" },
        { label: "CHUMAYEL", value: "CHUMAYEL" },
        { label: "DZÁN", value: "DZÁN" },
        { label: "DZEMUL", value: "DZEMUL" },
        { label: "DZIDZANTÚN", value: "DZIDZANTÚN" },
        { label: "DZILAM DE BRAVO", value: "DZILAM DE BRAVO" },
        { label: "DZILAM GONZÁLEZ", value: "DZILAM GONZÁLEZ" },
        { label: "DZITÁS", value: "DZITÁS" },
        { label: "DZONCAUICH", value: "DZONCAUICH" },
        { label: "ESPITA", value: "ESPITA" },
        { label: "HALACHÓ", value: "HALACHÓ" },
        { label: "HOCABÁ", value: "HOCABÁ" },
        { label: "HOCTÚN", value: "HOCTÚN" },
        { label: "HOMÚN", value: "HOMÚN" },
        { label: "HUHÍ", value: "HUHÍ" },
        { label: "HUNUCMÁ", value: "HUNUCMÁ" },
        { label: "IXIL", value: "IXIL" },
        { label: "IZAMAL", value: "IZAMAL" },
        { label: "KANASÍN", value: "KANASÍN" },
        { label: "KANTUNIL", value: "KANTUNIL" },
        { label: "KAUA", value: "KAUA" },
        { label: "KINCHIL", value: "KINCHIL" },
        { label: "KOPOMÁ", value: "KOPOMÁ" },
        { label: "MAMA", value: "MAMA" },
        { label: "MANÍ", value: "MANÍ" },
        { label: "MAXCANÚ", value: "MAXCANÚ" },
        { label: "MAYAPÁN", value: "MAYAPÁN" },
        { label: "MÉRIDA", value: "MÉRIDA" },
        { label: "MOCOCHÁ", value: "MOCOCHÁ" },
        { label: "MOTUL", value: "MOTUL" },
        { label: "MUNA", value: "MUNA" },
        { label: "MUXUPIP", value: "MUXUPIP" },
        { label: "OPICHÉN", value: "OPICHÉN" },
        { label: "OXKUTZCAB", value: "OXKUTZCAB" },
        { label: "PANABÁ", value: "PANABÁ" },
        { label: "PETO", value: "PETO" },
        { label: "PROGRESO", value: "PROGRESO" },
        { label: "QUINTANA ROO", value: "QUINTANA ROO" },
        { label: "RÍO LAGARTOS", value: "RÍO LAGARTOS" },
        { label: "SACALUM", value: "SACALUM" },
        { label: "SAMAHIL", value: "SAMAHIL" },
        { label: "SANAHCAT", value: "SANAHCAT" },
        { label: "SAN FELIPE", value: "SAN FELIPE" },
        { label: "SANTA ELENA", value: "SANTA ELENA" },
        { label: "SEYÉ", value: "SEYÉ" },
        { label: "SINANCHÉ", value: "SINANCHÉ" },
        { label: "SOTUTA", value: "SOTUTA" },
        { label: "SUCILÁ", value: "SUCILÁ" },
        { label: "SUDZAL", value: "SUDZAL" },
        { label: "SUMA", value: "SUMA" },
        { label: "TAHDZIÚ", value: "TAHDZIÚ" },
        { label: "TAHMEK", value: "TAHMEK" },
        { label: "TEABO", value: "TEABO" },
        { label: "TECOH", value: "TECOH" },
        { label: "TEKAL DE VENEGAS", value: "TEKAL DE VENEGAS" },
        { label: "TEKANTÓ", value: "TEKANTÓ" },
        { label: "TEKAX", value: "TEKAX" },
        { label: "TEKIT", value: "TEKIT" },
        { label: "TEKOM", value: "TEKOM" },
        { label: "TELCHAC PUEBLO", value: "TELCHAC PUEBLO" },
        { label: "TELCHAC PUERTO", value: "TELCHAC PUERTO" },
        { label: "TEMAX", value: "TEMAX" },
        { label: "TEMOZÓN", value: "TEMOZÓN" },
        { label: "TEPAKÁN", value: "TEPAKÁN" },
        { label: "TETIZ", value: "TETIZ" },
        { label: "TEYA", value: "TEYA" },
        { label: "TICUL", value: "TICUL" },
        { label: "TIMUCUY", value: "TIMUCUY" },
        { label: "TINUM", value: "TINUM" },
        { label: "TIXCACALCUPUL", value: "TIXCACALCUPUL" },
        { label: "TIXKOKOB", value: "TIXKOKOB" },
        { label: "TIXMEHUAC", value: "TIXMEHUAC" },
        { label: "TIXPÉHUAL", value: "TIXPÉHUAL" },
        { label: "TIZIMÍN", value: "TIZIMÍN" },
        { label: "TUNKÁS", value: "TUNKÁS" },
        { label: "TZUCACAB", value: "TZUCACAB" },
        { label: "UAYMA", value: "UAYMA" },
        { label: "UCÚ", value: "UCÚ" },
        { label: "UMÁN", value: "UMÁN" },
        { label: "VALLADOLID", value: "VALLADOLID" },
        { label: "XOCCHEL", value: "XOCCHEL" },
        { label: "YAXCABÁ", value: "YAXCABÁ" },
        { label: "YAXKUKUL", value: "YAXKUKUL" },
        { label: "YOBAÍN", value: "YOBAÍN" },
    ],
    "Zacatecas": [
        { label: "APOZOL", value: "APOZOL" },
        { label: "APULCO", value: "APULCO" },
        { label: "ATOLINGA", value: "ATOLINGA" },
        { label: "BENITO JUÁREZ", value: "BENITO JUÁREZ" },
        { label: "CALERA", value: "CALERA" },
        { label: "CAÑITAS DE FELIPE PESCADOR", value: "CAÑITAS DE FELIPE PESCADOR" },
        { label: "CONCEPCIÓN DEL ORO", value: "CONCEPCIÓN DEL ORO" },
        { label: "CUAUHTÉMOC", value: "CUAUHTÉMOC" },
        { label: "CHALCHIHUITES", value: "CHALCHIHUITES" },
        { label: "FRESNILLO", value: "FRESNILLO" },
        { label: "TRINIDAD GARCÍA DE LA CADENA", value: "TRINIDAD GARCÍA DE LA CADENA" },
        { label: "GENARO CODINA", value: "GENARO CODINA" },
        { label: "GENERAL ENRIQUE ESTRADA", value: "GENERAL ENRIQUE ESTRADA" },
        { label: "GENERAL FRANCISCO R. MURGUÍA", value: "GENERAL FRANCISCO R. MURGUÍA" },
        { label: "EL PLATEADO DE JOAQUÍN AMARO", value: "EL PLATEADO DE JOAQUÍN AMARO" },
        { label: "GENERAL PÁNFILO NATERA", value: "GENERAL PÁNFILO NATERA" },
        { label: "GUADALUPE", value: "GUADALUPE" },
        { label: "HUANUSCO", value: "HUANUSCO" },
        { label: "JALPA", value: "JALPA" },
        { label: "JEREZ", value: "JEREZ" },
        { label: "JIMÉNEZ DEL TEUL", value: "JIMÉNEZ DEL TEUL" },
        { label: "JUAN ALDAMA", value: "JUAN ALDAMA" },
        { label: "JUCHIPILA", value: "JUCHIPILA" },
        { label: "LORETO", value: "LORETO" },
        { label: "LUIS MOYA", value: "LUIS MOYA" },
        { label: "MAZAPIL", value: "MAZAPIL" },
        { label: "MELCHOR OCAMPO", value: "MELCHOR OCAMPO" },
        { label: "MEZQUITAL DEL ORO", value: "MEZQUITAL DEL ORO" },
        { label: "MIGUEL AUZA", value: "MIGUEL AUZA" },
        { label: "MOMAX", value: "MOMAX" },
        { label: "MONTE ESCOBEDO", value: "MONTE ESCOBEDO" },
        { label: "MORELOS", value: "MORELOS" },
        { label: "MOYAHUA DE ESTRADA", value: "MOYAHUA DE ESTRADA" },
        { label: "NOCHISTLÁN DE MEJÍA", value: "NOCHISTLÁN DE MEJÍA" },
        { label: "NORIA DE ÁNGELES", value: "NORIA DE ÁNGELES" },
        { label: "OJOCALIENTE", value: "OJOCALIENTE" },
        { label: "PÁNUCO", value: "PÁNUCO" },
        { label: "PINOS", value: "PINOS" },
        { label: "RÍO GRANDE", value: "RÍO GRANDE" },
        { label: "SAIN ALTO", value: "SAIN ALTO" },
        { label: "EL SALVADOR", value: "EL SALVADOR" },
        { label: "SOMBRERETE", value: "SOMBRERETE" },
        { label: "SUSTICACÁN", value: "SUSTICACÁN" },
        { label: "TABASCO", value: "TABASCO" },
        { label: "TEPECHITLÁN", value: "TEPECHITLÁN" },
        { label: "TEPETONGO", value: "TEPETONGO" },
        { label: "TEÚL DE GONZÁLEZ ORTEGA", value: "TEÚL DE GONZÁLEZ ORTEGA" },
        { label: "TLALTENANGO DE SÁNCHEZ ROMÁN", value: "TLALTENANGO DE SÁNCHEZ ROMÁN" },
        { label: "VALPARAÍSO", value: "VALPARAÍSO" },
        { label: "VETAGRANDE", value: "VETAGRANDE" },
        { label: "VILLA DE COS", value: "VILLA DE COS" },
        { label: "VILLA GARCÍA", value: "VILLA GARCÍA" },
        { label: "VILLA GONZÁLEZ ORTEGA", value: "VILLA GONZÁLEZ ORTEGA" },
        { label: "VILLA HIDALGO", value: "VILLA HIDALGO" },
        { label: "VILLANUEVA", value: "VILLANUEVA" },
        { label: "ZACATECAS", value: "ZACATECAS" },
        { label: "TRANCOSO", value: "TRANCOSO" },
        { label: "SANTA MARÍA DE LA PAZ", value: "SANTA MARÍA DE LA PAZ" },
    ]
};