import { functions, httpsCallable } from "../firebase";
import { IDCompany } from "../global/global";
import VenuesModel from "../models/Venues";

export const getVenues = () => {
    const getActiveVenues = httpsCallable(functions, "readAllActiveVenues");
    const res = getActiveVenues({ companyID: `${IDCompany}` });
    return res;
}

export const addVenue = (data: VenuesModel) => {
    const saveVenue = httpsCallable(functions, "createNewVenue");
    const res = saveVenue(data);
    return res;
}

export const updateVenue = (data: Object) => {
    const editVenue = httpsCallable(functions, "updateVenueById");
    const res = editVenue(data);
    return res;
}

export const deleteVenue = (data: Object) => {
    const removeVenue = httpsCallable(functions, "deleteVenueById");
    const res = removeVenue(data);
    return res;
}

export const activeVenueById = (data: Object) => {
    const activeVenue = httpsCallable(functions, "activateVenueById");
    const res = activeVenue(data);
    return res;
}

export const desactiveVenueById = (data: Object) => {
    const desactiveVenue = httpsCallable(functions, "deactivateVenueById");
    const res = desactiveVenue(data);
    return res;
}