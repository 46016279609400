import { useMemo, useState, useEffect } from "react";
import Table from "../../../components/table/Table";
import styled from "styled-components";
import NewsModel from "../../../models/News";
import NewsButtonsTable from "./NewsButtonsTable";

const Styled = styled.div`
    table {
        width: 950px;
        text-align: left;
    }

    table th {
        background-color: rgb(240, 240, 240);
        font-weight: bold;
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    table td {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    @media (max-width: 1350px) {
        table { width: 850px; }
    }

    @media (max-width: 1270px) {
        table { width: 750px; }
    }

    @media (max-width: 1180px) {
        table { width: 600px; }
    }

    @media (max-width: 1024px) {
        table { width: 800px; }
    }
`;

const NewsTable = ({ news, onRefresh } : { news: Array<NewsModel>, onRefresh: any }) => {
    const [newNews, setNewNews] = useState<Array<any>>([]);

    const columns = useMemo(() => [
        {
            Header: 'Episodio',
            accessor: 'noteEpisode',
            width: '30%'
        },
        {
            Header: 'Creador',
            accessor: 'noteCreatedBy',
            width: '30%'
        },
        {
            Header: 'Fecha',
            accessor: 'noteCreatedAt',
            width: '30%'
        },
        {
            Header: '',
            accessor: 'options',
            width: '10%'
        }
    ], []);

    useEffect(() => {
        const newArr = news.map((newElement: NewsModel, i: number) => {
            return {
                companyID: newElement.companyID,
                noteID: newElement.noteID,
                noteTitle: newElement.noteTitle,
                noteEpisode: newElement.noteEpisode,
                noteBody: newElement.noteBody,
                noteCreatedBy: newElement.noteCreatedBy,
                noteCreatedAt: newElement.noteCreatedAt,
                noteImageURL: newElement.noteImageURL,
                noteVideoURL: newElement.noteVideoURL,
                options: <NewsButtonsTable onRefresh={onRefresh} news={newElement} />
            };
        });
        setNewNews(newArr);
    }, [news]);

    return (
        <div className="block overflow-x-auto lg:overflow-x-visible">
            <Styled>
                <Table columns={columns} data={newNews} />
            </Styled>
        </div>
    );
}

export default NewsTable;