import { FaBrush } from "react-icons/fa";
import Select from "../../components/select/Select";

const RealesesFilter = ({ plataform, stars, onResponse } : { plataform: Array<any>, stars: Array<any>, onResponse: any }) => {
    return (
        <>
            <div className="hidden md:block">
                <div className="flex justify-between items-center">
                    <div>
                        <input
                            className="w-[190px] lg:w-[250px] bg-transparent p-[6px] border border-zinc-400 text-[14px] text-zinc-400 rounded-md"
                            type="text"
                            id="gameTitle-filter"
                            name="gameTitle-filter"
                            placeholder="Titulo del juego..."
                            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => onResponse(evt.target.value, "input") }
                        />
                    </div>
                    <div>
                        <Select
                            className="w-[190px] lg:w-[250px] bg-transparent p-[9px] border border-zinc-400 text-[14px] text-zinc-400 rounded-md"
                            id="gamePlataform-filter"
                            name="gamePlataform-filter"
                            options={plataform}
                            onResponse={(opt: any) => onResponse(plataform[opt.target.value], "select")}
                            placeholder="Plataforma..." />
                    </div>
                    <div>
                        <Select
                            className="w-[190px] lg:w-[250px] bg-transparent p-[9px] border border-zinc-400 text-[14px] text-zinc-400 rounded-md"
                            id="gameStars-filter"
                            name="gameStars-filter"
                            options={stars}
                            onResponse={(opt: any) => onResponse(stars[opt.target.value], "select") }
                            placeholder="Calificación..." />
                    </div>
                    <div>
                        <button
                            className="bg-transparent px-[8px] py-[8px] border border-zinc-400 text-[18px] text-zinc-400 rounded-md"
                            type="button"
                            onClick={() => {
                                const inputText = document.getElementById("gameTitle-filter") as HTMLInputElement | null;
                                const plataformSelect = document.getElementById("gamePlataform-filter") as HTMLSelectElement | null;
                                const starsSelect = document.getElementById("gameStars-filter") as HTMLSelectElement | null;
                                if (inputText !== null && plataformSelect !== null && starsSelect !== null) {
                                    inputText.value = "";
                                    plataformSelect.value = "default";
                                    starsSelect.value = "default";
                                }
                                onResponse("", "clean");
                            }}
                        >
                            <FaBrush />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RealesesFilter;