import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import IconBg from "../../../assets/icons/06_hexagon.svg";
import Footer from "../../../components/footer/Footer";
import Navbar from "../../../components/navbar/Navbar";
import PDFViewer from "../../../components/pdf-viewer/PDFViewer";
import VenuesModel from "../../../models/Venues";
import VenueContact from "./VenueContact";
import VenuesInfo from "./VenuesInfo";
import VenuesImages from "./VenuesImages";
import VenuesMapPop from "./VenuesMapPop";
import Rellax from "rellax";
import { GlobalContext } from "../../../context/global_context";
import { getVenues } from "../../../services/Venues";
import Loading from "../../../components/loading/Loading";

const VenuesDetail = () => {
    const location = useLocation();
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const { globalState } = useContext(GlobalContext)
    const [statusMapModal, setStatusMapModal] = useState<boolean>(false);
    const [statusModal, setStatusModal] = useState({
        status: false,
        indexImage: 0
    });

    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    const [info, setInfo] = useState<VenuesModel>({
        companyID: "",
        venueID: "",
        venueName: "",
        venueAddress: {
            street: "",
            number: "",
            interiorNumber: "",
            neighborhood: "",
            city: "",
            state: "",
            country: "",
            zipCode: ""
        },
        venueContactMail: "",
        venueMapURL: "",
        venueWorkingHours: "",
        venueContactPhone: "",
        venueImagesURL: [""],
        venueVideosURL: [""]
    });

    useEffect(() => {
        new Rellax(".animatev0", { speed: 3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev1", { speed: -1, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev2", { speed: -2, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev3", { speed: -3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev4", { speed: -4, center: false, round: true, vertical: true, horizontal: false });
        if (globalState.name_location !== '') {
    
            new Promise<void>((resolve, reject) => {
                setLoadingStatus(true)
                getVenues().then(res => {
                    if (res && res.data) {
                        const datares: any = res.data;
                        let newArr = new Array();
                        let newArrStates: Array<any> = [
                            { label: "Mostrar todos", value: "clean" }
                        ];
                        for (let i = 0; i < datares.length; i++) {
                            const element = Object(datares[i]);
                            const stateFound = newArrStates.find((eleState) => eleState.value === element.venueAddress.state);
                            if (!stateFound) {
                                newArrStates.push({ label: element.venueAddress.state, value: element.venueAddress.state });
                            }
                            newArr.push(element);
                        }
       
                        const venue = newArr.filter((item: any) => {
                            return item.venueName === globalState.name_location
                        })
                        setInfo(venue[0])
                        setStatusMapModal(true)
                    }
                });
                setTimeout(function () {
                    setLoadingStatus(false)
                    resolve();
                }, 1000);
            });
        } else {
      
            if (location && location.state) {
                let _venue = location.state as any;
                setInfo(_venue);
            }
        }
    }, []);


    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({ ...popupTP, status: false, documentStr: "" }) }}
                    />
                    : null
            }

            {
                statusModal.status ?
                    <VenuesImages
                        imageVenues={info.venueImagesURL}
                        imageIndex={statusModal.indexImage}
                        onCloseModal={() =>
                            setStatusModal({ ...statusModal, status: false, indexImage: 0 })
                        }
                    />
                    :
                    null
            }

            {
                statusMapModal ?
                    <VenuesMapPop
                        mapStr={info.venueMapURL}
                        onCloseModal={() =>
                            setStatusMapModal(false)
                        }
                    />
                    :
                    null
            }

            <div id="animatev0" style={{ scrollBehavior: 'auto' }} className="bg-arena-gray">
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-40">
                        <Navbar />
                    </div>
                </div>
                <div className="relative w-[90%] pt-[20vw] md:w-[650px] lg:w-[950px] pt-[85px] m-auto z-10">
                    <img className="animatev2 absolute w-[50vw] top-[200vw] left-[-35vw] md:w-[250px] md:top-[80px] md:left-[420px] lg:top-[200px] lg:left-[720px] z-[-1]" src={IconBg} />
                    <img className="animatev3 absolute w-[50vw] top-[80vw] left-[40vw] md:w-[250px] md:top-[600px] md:left-[-80px] lg:top-[500px] lg:left-[-200px] z-[-1]" src={IconBg} />

                    <VenuesInfo
                        info={info}
                        onModalMap={(sts: boolean) => { setStatusMapModal(sts) }}
                        onModalCarrousel={(sts: boolean, idx: number) => {
                            setStatusModal({ ...statusModal, status: sts, indexImage: idx })
                        }}
                    />
                </div>
                <div className="news-arena-style bg-arena-white relative mt-[85px]">
                    <div className="w-[90%] m-auto pt-[25vw] md:pt-[95px] md:w-[75%]">
                        <VenueContact info={info} />
                    </div>
                    <div className="relative pt-1 md:pt-[10px]">
                        <Fade>
                            <Footer
                                statusPop={(opt: any) => {
                                    setPopupTP({ ...popupTP, status: opt.status, documentStr: opt.documentStr })
                                }}
                            />
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VenuesDetail;