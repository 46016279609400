import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import PopupCarrousel from "./PopupCarrousel";

const HomepagePop = ({ images, video, urlExterna, closeModal }: { images: Array<string>, video: string, urlExterna: any, closeModal: any }) => {
    // console.log(urlExterna)
    const [showVideo, setShowVideo] = useState<boolean>(false);
    // console.log(video)
    console.log(images)
    useEffect(() => {
        if (video !== "") setShowVideo(true);
    }, [images, video]);

    return (
        <>
            <div className="block ">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none w-[85vw] md:w-[730px] lg:w-[950px] m-auto">
                    <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none popUpInitial">
                        <div className="flex items-center justify-between p-[5px] border-b border-solid border-gray-500 rounded-t">
                            <span className="pl-4 txt-arena-white font-bold">¡Bienvenidos a Arena Movistar!</span>
                            <button
                                className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                onClick={() => closeModal(false)}
                            >
                                X
                            </button>
                        </div>
                        <div className="relative p-[10px] flex-auto ">
                            {urlExterna ?
                                <>
                                    {
                                        showVideo ?
                                            <a href={urlExterna} className="cursor-pointer" target={"_blank"}>
                                                <div className="bg-arena-black w-[80vw] h-[45vw] m-auto md:w-auto md:h-[400px] lg:h-[550px] rounded-md">
                                                    <ReactPlayer
                                                        url={video}
                                                        className='react-player'
                                                        width='100%'
                                                        height='100%'
                                                        muted={true}
                                                        playing
                                                    />
                                                </div>
                                            </a>
                                            :
                                            <a className="cursor-pointer" href={urlExterna} target={"_blank"}>
                                                <PopupCarrousel images={images} />
                                            </a>
                                    }
                                </>
                                :
                                <>
                                    {
                                        showVideo ?
                                            <div className="bg-arena-black w-[80vw] h-[45vw] m-auto md:w-auto md:h-[400px] lg:h-[550px] rounded-md">
                                                <ReactPlayer
                                                    url={video}
                                                    className='react-player'
                                                    width='100%'
                                                    height='100%'
                                                    muted={true}
                                                    playing
                                                />
                                            </div>
                                            :
                                            <PopupCarrousel images={images} />
                                    }
                                </>
                            }

                        </div>
                    </div>
                </div>

                <div onClick={() => closeModal(false)} className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </div>
        </>
    );
}

export default HomepagePop;