import { useMemo, useState, useEffect } from "react";
import Table from "../../../components/table/Table";
import styled from "styled-components";
import TournamentDropModel from "../../../models/TournamentDrop";
import DropsButtonsTable from "./DropsButtonsTable";

const Styled = styled.div`
    table {
        width: 950px;
        text-align: left;
    }

    table th {
        background-color: rgb(240, 240, 240);
        font-weight: bold;
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    table td {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    @media (max-width: 1350px) {
        table { width: 850px; }
    }

    @media (max-width: 1270px) {
        table { width: 750px; }
    }

    @media (max-width: 1180px) {
        table { width: 600px; }
    }

    @media (max-width: 1024px) {
        table { width: 800px; }
    }
`;

const DropsTable = ({
    data,
    type,
    mode,
    plataform,
    venues,
    onRefresh
} : {
    data: Array<TournamentDropModel>,
    type: Array<any>,
    mode: Array<any>,
    plataform: Array<any>,
    venues: Array<any>,
    onRefresh: any
}) => {
    const [newDrops, setNewDrops] = useState<Array<any>>([]);
    const columns = useMemo(() => [
        {
            Header: 'Titulo',
            accessor: 'dropName',
            width: '30%'
        },
        {
            Header: 'Tipo',
            accessor: 'dropType',
            width: '15%'
        },
        {
            Header: 'Fecha',
            accessor: 'dropDate',
            width: '45%'
        },
        {
            Header: '',
            accessor: 'options',
            width: '10%'
        }
    ], []);

    useEffect(() => {
        const newArr = data.map((dtElement: TournamentDropModel, i: number) => {
            return {
                companyID: dtElement.companyID,
                dropName: dtElement.dropName,
                dropGameTitle: dtElement.dropGameTitle,
                dropType: dtElement.dropType,
                dropReward: dtElement.dropReward,
                dropGroupMode: dtElement.dropGroupMode,
                dropDescription: dtElement.dropDescription,
                drpoTerms: dtElement.drpoTerms,
                dropConsole: dtElement.dropConsole,
                dropDate: dtElement.dropDate,
                dropLocation: dtElement.dropLocation,
                dropCreatedBy: dtElement.dropCreatedBy,
                dropImageURL: dtElement.dropImageURL,
                dropVideoURL: dtElement.dropVideoURL,
                options:
                    <DropsButtonsTable
                        type={type}
                        mode={mode}
                        plataform={plataform}
                        venues={venues}
                        drop={dtElement}
                        onRefresh={onRefresh}
                    />
            };
        });
        setNewDrops(newArr);
    }, [data]);

    return (
        <div className="block overflow-x-auto lg:overflow-x-visible">
            <Styled>
                <Table columns={columns} data={newDrops} />
            </Styled>
        </div>
    );
}

export default DropsTable;