import { functions, httpsCallable } from "../firebase";
import { IDCompany } from "../global/global";
import RealeseModel from "../models/Realese";

export const getRealeses = () => {
    const getAllRealeses = httpsCallable(functions, "readAllGames");
    const res = getAllRealeses({ companyID: `${IDCompany}` });
    return res;
}

export const saveRealeses = (data: RealeseModel) => {
    const saveRealese = httpsCallable(functions, "createGame");
    const res = saveRealese(data);
    return res;
}

export const updateRealeses = (data: Object) => {
    const updateRealese = httpsCallable(functions, "updateGame");
    const res = updateRealese(data);
    return res;
}

export const deleteRealese = (data: Object) => {
    const removeRealese = httpsCallable(functions, "deleteGame");
    const res = removeRealese(data);
    return res;
}