import { useEffect, useState } from "react";
import { HiPhotograph } from "react-icons/hi";
import { uploadImages } from "../../../firebase";
import { IDCompany } from "../../../global/global";
import { addNews, updateNews } from "../../../services/News";
import NewsModel from "../../../models/News";
import PopNotify from "../../../components/Pop/PopNotify";
import Loading from "../../../components/loading/Loading";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsPlusCircleFill } from "react-icons/bs";

interface StepData {
    step: string;
    status: boolean;
}

interface ImageData {
    name: string;
    file: any;
}

interface PopInfo {
    status: boolean,
    type: string
}

const NewsForm = ({ newElement, onRefresh } : { newElement: any, onRefresh: any }) => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [imageUpload, setImageUpload] = useState<ImageData>({
        name: "",
        file: null
    });

    const [steps, setSteps] = useState<Array<StepData>>([
        { step: "Step 1", status: true },
        { step: "Step 2", status: false }
    ]);

    const [news, setNews] = useState({
        companyID: { value: `${IDCompany}`, isCorrect: true },
        noteID: { value: "", isCorrect: false },
        noteTitle: { value: "", isCorrect: false },
        noteEpisode: { value: "", isCorrect: false },
        noteBody: { value: "", isCorrect: false },
        noteCreatedBy: { value: "", isCorrect: false },
        noteCreatedAt: { value: "", isCorrect: false },
        noteImageURL: { value: [], isCorrect: false },
        noteVideoURL: { value: [], isCorrect: false }
    });

    const deleteImage = () => {
        setImageUpload({...imageUpload, name: "", file: null});
    }

    const handleUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const file = evt.currentTarget.files;
        if (file !== null) {
            setImageUpload({...imageUpload, name: file[0].name, file: file[0]});
        }
    }

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setNews({...news, [evt.target.name]: { value: evt.target.value, isCorrect: true }});
    }

    const nextStep = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        if ((evt.currentTarget.name === "Step 2" &&
            (
                !news.noteTitle.isCorrect || !news.noteCreatedBy.isCorrect || !news.noteBody.isCorrect
            ))
        ) {
            return 0;
        }

        const newStep = steps.map((stepElement, i) => {
            if (stepElement.step === evt.currentTarget.name) {
                return { ...stepElement, status: true };
            } else {
                return { ...stepElement, status: false };
            }
        });
        setSteps(newStep);
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (
            news.noteTitle.isCorrect && news.noteCreatedBy.isCorrect && news.noteBody.isCorrect &&
            news.noteVideoURL.isCorrect
        ) {
            setLoadingStatus(true);
            let imageUrlStr: string = "";
            if (imageUpload.file) {
                imageUrlStr = await uploadImages(imageUpload.file, "news");
            } else if (newElement && newElement.noteImageURL.length > 0 && !imageUpload.file) {
                imageUrlStr = newElement.noteImageURL[0];
            }
            const newData: NewsModel = {
                companyID: `${IDCompany}`,
                noteID: newElement ? newElement.noteID : "",
                noteTitle: news.noteTitle.value,
                noteEpisode: newElement ? newElement.noteEpisode : "",
                noteBody: news.noteBody.value,
                noteCreatedBy: news.noteCreatedBy.value,
                noteCreatedAt: newElement ? newElement.noteCreatedAt : "",
                noteImageURL: [imageUrlStr],
                noteVideoURL: [`${news.noteVideoURL.value}`]
            }
            new Promise<void>((resolve, reject) => {
                if (newElement !== null) {
                    updateNews(newData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data) {
                            setPopStatus({...popStatus, status: true, type: "success"});
                            onRefresh(true);
                        } else {
                            setPopStatus({...popStatus, status: true, type: "error"});
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                } else {
                    addNews(newData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data) {
                            onRefresh(true);
                            setPopStatus({...popStatus, status: true, type: "success"});
                            setImageUpload({...imageUpload, name: "", file: null});
                            setNews({...news,
                                companyID: { value: `${IDCompany}`, isCorrect: true },
                                noteID: { value: "", isCorrect: false },
                                noteTitle: { value: "", isCorrect: false },
                                noteEpisode: { value: "", isCorrect: false },
                                noteBody: { value: "", isCorrect: false },
                                noteCreatedBy: { value: "", isCorrect: false },
                                noteCreatedAt: { value: "", isCorrect: false },
                                noteImageURL: { value: [], isCorrect: false },
                                noteVideoURL: { value: [], isCorrect: false }
                            });
                        } else {
                            setPopStatus({...popStatus, status: true, type: "error"});
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                }
            });
        } else {
            setPopStatus({...popStatus, status: true, type: "warning"});
        }
    }

    useEffect(() => {
        if (newElement !== null) {
            const newNewModel = {
                companyID: { value: `${IDCompany}`, isCorrect: true },
                noteID: { value: newElement.noteID, isCorrect: true },
                noteTitle: { value: newElement.noteTitle, isCorrect: true },
                noteEpisode: { value: newElement.noteEpisode, isCorrect: true },
                noteBody: { value: newElement.noteBody, isCorrect: true },
                noteCreatedBy: { value: newElement.noteCreatedBy, isCorrect: true },
                noteCreatedAt: { value: newElement.noteCreatedAt, isCorrect: true },
                noteImageURL: { value: newElement.noteImageURL, isCorrect: true },
                noteVideoURL: { value: newElement.noteVideoURL, isCorrect: true }
            }
            setNews(newNewModel);
            setImageUpload({...imageUpload, name: newElement.noteImageURL, file: null});
        }
    }, [newElement]);

    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="block">
                <form onSubmit={handleSubmit}>
                    <div className="px-2 text-left">
                        {/* Primera parte del formulario */}
                        {
                            steps[0].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[620px] bg-zinc-300 border border-zinc-300"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-zinc-300 rounded-full">
                                                <span className="text-[18px] text-zinc-300 font-bold">2</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-zinc-300 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="space-y-5">
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Titulo de la noticia
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="noteTitleInput"
                                                type="text"
                                                placeholder="Titulo..."
                                                name="noteTitle"
                                                onChange={handleChange}
                                                value={news.noteTitle.value}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Autor
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="noteCreatedByInput"
                                                type="text"
                                                placeholder="Titulo..."
                                                name="noteCreatedBy"
                                                onChange={handleChange}
                                                value={news.noteCreatedBy.value}
                                            />
                                        </div>
                                        <div className="mb-4 space-y-2">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Descripción <span className="text-zinc-300">{' (Máximo 600 carácteres)'}</span>
                                            </label>
                                            <textarea
                                                className="appearance-none border border-zinc-500 bg-transparent w-full p-[10px] text-[14px] txt-arena-black rounded-sm"
                                                id="noteBodyInput"
                                                placeholder="Descripción..."
                                                name="noteBody"
                                                onChange={handleChange}
                                                value={news.noteBody.value}
                                                rows={6}
                                                maxLength={600}
                                            >
                                            </textarea>
                                        </div>
                                        <div className="flex justify-end pt-[5px]">
                                            <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" name="Step 2" type="button" onClick={nextStep}>
                                                Siguiente
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }

                        {/* Segunda parte del formulario */}
                        {
                            steps[1].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[620px] bg-orange-600 border border-orange-600"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">2</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-orange-600 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="space-y-5">
                                        <div className="mb-4 space-y-2">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Imagen <span className="text-zinc-400">{'Resolución recomendada 800x530'}</span>
                                            </label>
                                            <div className="flex items-center justify-center w-full">
                                                <label className="w-full h-12 border-4 border-dashed">
                                                    <div className="flex space-x-2 p-2">
                                                        <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                        <div>
                                                            { imageUpload.name ?
                                                                <div className="flex">
                                                                    <span
                                                                        className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                                    >
                                                                        {imageUpload.name}
                                                                    </span>
                                                                    <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteImage() }>
                                                                        <AiFillCloseCircle />
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="flex">
                                                                    <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                        Cargar imagen...
                                                                    </span>
                                                                    <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <input type="file" className="opacity-0" name="noteImageURL" onChange={handleUpload} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                URL video noticia
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="noteVideoURLInput"
                                                type="text"
                                                placeholder="URL Video..."
                                                name="noteVideoURL"
                                                onChange={handleChange}
                                                value={news.noteVideoURL.value}
                                            />
                                        </div>
                                        <div className="flex justify-end pt-[5px]">
                                            <button className="bg-arena-orange px-[60px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" type="submit">
                                                { newElement ? "Guardar cambios" : "Agregar noticia" }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </form>
            </div>
        </>

    );
}

export default NewsForm;