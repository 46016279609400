import { useState, useEffect } from "react";
import { getNews } from "../../../services/News";
import NavbarAdmin from "../../../components/navbar-admin/NavbarAdmin";
import SidebarAdmin from "../../../components/sidebar-admin/SidebarAdmin";
import NewsCard from "./NewsCard";
import NewsModel from "../../../models/News";
import Loading from "../../../components/loading/Loading";

const AdminNews = () => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [news, setNews] = useState<Array<NewsModel>>([]);

    const getUsers = () => {
        new Promise<void>((resolve, reject) => {
            // Get all news
            getNews().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }

                    // Se ordena las noticias por episodio
                    newArr.sort((a: NewsModel, b:NewsModel) => {
                        return Number(String(a.noteEpisode).replace("Episodio ", "")).valueOf() - Number(String(b.noteEpisode).replace("Episodio ", "")).valueOf();
                    });
                    
                    setNews(newArr);
                }
            });

            // Resolve promise
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div className="flex flex-row w-full h-screen">
            { loadingStatus ? <Loading /> : null }
            <div className="w-[65px] md:w-[250px] lg:w-[370px]">
                <SidebarAdmin />
            </div>
            <div className="w-full bg-zinc-300 flex-grow flex-shrink flex-auto overflow-y-scroll">
                <div>
                    <NavbarAdmin />
                </div>
                <div>
                    <NewsCard onRefresh={getUsers} news={news} />
                </div>
            </div>
        </div>
    );
}

export default AdminNews;