import { functions, httpsCallable } from "../firebase";
import { IDCompany } from "../global/global";
import EventsModel from "../models/Events";

export const getEvents = () => {
    const getAllEvents = httpsCallable(functions, "readAllEvents");
    const res = getAllEvents({ companyID: `${IDCompany}` });
    return res;
}

export const getEventById = (data: Object) => {
    const getAEventsById = httpsCallable(functions, "readEventById");
    const res = getAEventsById(data);
    return res;
}

export const addEvents = (data: EventsModel) => {
    const saveEvent = httpsCallable(functions, "createEvent");
    const res = saveEvent(data);
    return res;
}

export const updateEvent = (data: Object) => {
    const editEvent = httpsCallable(functions, "updateEvent");
    const res = editEvent(data);
    return res;
}

export const deleteEvent = (data: Object) => {
    const removeEvent = httpsCallable(functions, "deleteEvent");
    const res = removeEvent(data);
    return res;
}