import NavbarAdmin from "../../components/navbar-admin/NavbarAdmin";
import SidebarAdmin from "../../components/sidebar-admin/SidebarAdmin";

const Admin = () => {
    return (
        <div className="flex">
            <div className="w-[18vw] md:w-[250px] lg:w-[370px]">
                <SidebarAdmin />
            </div>
            <div className="w-full">
                <NavbarAdmin />
            </div>
        </div>
    );
}

export default Admin;