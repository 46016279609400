import { useMemo, useState, useEffect } from "react";
import Table from "../../../components/table/Table";
import styled from "styled-components";
import VenuesModel from "../../../models/Venues";
import VenuesButtonsTable from "./VenuesButtonsTable";

const Styled = styled.div`
    table {
        width: 950px;
        text-align: left;
    }

    table th {
        background-color: rgb(240, 240, 240);
        font-weight: bold;
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    table td {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    @media (max-width: 1350px) {
        table { width: 850px; }
    }

    @media (max-width: 1270px) {
        table { width: 750px; }
    }

    @media (max-width: 1180px) {
        table { width: 600px; }
    }

    @media (max-width: 1024px) {
        table { width: 800px; }
    }
`;

const arrActiveBg: any = {
    true: "bg-green-500",
    false: "bg-zinc-400"
};

const VenuesTable = ({ venues, states, cities, onRefresh } : { venues: Array<VenuesModel>, states: Array<any>, cities: any, onRefresh: any }) => {
    const [newVenues, setNewVenues] = useState<Array<any>>([]);
    const columns = useMemo(() => [
        {
            Header: 'Sucursal',
            accessor: 'venueName',
            width: '33%'
        },
        {
            Header: 'Ciudad',
            accessor: 'venueAddress.city',
            width: '33%'
        },
        {
            Header: 'Estatus',
            accessor: 'venueIsActive',
            width: '22%'
        },
        {
            Header: '',
            accessor: 'options',
            width: '12%'
        }
    ], []);

    useEffect(() => {
        const newArr = venues.map((venElement: any, i: number) => {
            const venueActive: string = venElement.venueIsActive ? "ACTIVA" : "ARCHIVADA";
            return {
                companyID: venElement.companyID,
                venueName: venElement.venueName,
                venueAddress : {
                    street: venElement.venueAddress.street,
                    number: venElement.venueAddress.number,
                    interiorNumber: venElement.venueAddress.interiorNumber,
                    neighborhood: venElement.venueAddress.neighborhood,
                    city: venElement.venueAddress.city,
                    state: venElement.venueAddress.state,
                    country: venElement.venueAddress.country,
                    zipCode: venElement.venueAddress.zipCode
                },
                venueContactMail: venElement.venueContactMail,
                venueMapURL: venElement.venueMapURL,
                venueImagesURL: venElement.venueImagesURL,
                venueVideosURL: venElement.venueVideosURL,
                venueIsActive:
                    <div className={"w-[100px] p-[3px] text-center txt-arena-white text-[9px] font-bold rounded-md " + arrActiveBg[`${venElement.venueIsActive}`]}>
                        {venueActive}
                    </div>,
                options: <VenuesButtonsTable onRefresh={onRefresh} states={states} cities={cities} venue={venElement} />
            };
        });
        setNewVenues(newArr);
    }, [venues])

    return (
        <div className="block overflow-x-auto lg:overflow-x-visible">
            <Styled>
                <Table columns={columns} data={newVenues} />
            </Styled>
        </div>
    );
}

export default VenuesTable;