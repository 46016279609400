import { useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { IDCompany } from "../../../global/global";
import { activeVenueById, deleteVenue, desactiveVenueById } from "../../../services/Venues";
import { FaRegEdit, FaUserCheck, FaUserSlash } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import Pop from "../../../components/Pop/Pop";
import VenuesModel from "../../../models/Venues";
import VenuesPop from "./VenuesPop";
import PopNotify from "../../../components/Pop/PopNotify";
import Loading from "../../../components/loading/Loading";

interface PopInfo {
    status: boolean,
    type: string
}

const VenuesButtonsTable = ({ venue, states, cities, onRefresh } : { venue: VenuesModel, states: Array<any>, cities: any, onRefresh: any }) => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [closeStatus, setCloseStatus] = useState<boolean>(false);
    const [updateStatus, setUpdateStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    
    const removeRealese = (res: boolean) => {
        setCloseStatus(false);
        if (res) {
            setLoadingStatus(true);
            new Promise<void>((resolve, reject) => {
                deleteVenue({ companyID: `${IDCompany}`, venueID: venue.venueID }).then(res => {
                    setLoadingStatus(false);
                    if (res && res.data) {
                        setPopStatus({...popStatus, status: true, type: "success"});
                        onRefresh(true);
                    }
                });
    
                // Resolve promise
                setTimeout(function() {
                    resolve();
                }, 1000);
            });
        }
    }

    const activeVenue = () => {
        setCloseStatus(false);
        setLoadingStatus(true);
        new Promise<void>((resolve, reject) => {
            activeVenueById({ companyID: `${IDCompany}`, venueID: venue.venueID }).then(res => {
                setLoadingStatus(false);
                if (res && res.data) {
                    setPopStatus({...popStatus, status: true, type: "success"});
                    onRefresh(true);
                }
            });

            // Resolve promise
            setTimeout(function() {
                resolve();
            }, 1000);
        });
    }

    const desactiveVenue = () => {
        setCloseStatus(false);
        setLoadingStatus(true);
        new Promise<void>((resolve, reject) => {
            desactiveVenueById({ companyID: `${IDCompany}`, venueID: venue.venueID }).then(res => {
                setLoadingStatus(false);
                if (res && res.data) {
                    setPopStatus({...popStatus, status: true, type: "success"});
                    onRefresh(true);
                }
            });

            // Resolve promise
            setTimeout(function() {
                resolve();
            }, 1000);
        });
    }

    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                closeStatus ?
                    <Pop title={`¿Está seguro que desea eliminar ${venue.venueName}?`} message="" onClose={() => {setCloseStatus(false) }} onResponse={removeRealese} />
                : null
            }

            {
                updateStatus ?
                    <VenuesPop onRefresh={onRefresh} states={states} cities={cities} venElement={venue} onResponse={() => {setUpdateStatus(false)} } />
                : null
            }

            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="relative">
                <div className="hidden lg:block">
                    <div className="dropdown">
                        <button className="dropbtn"><HiDotsVertical /></button>
                        <div className="dropdown-content">
                            <a onClick={() => setUpdateStatus(true) } href="#">Editar sucursal</a>
                            <a onClick={() => setCloseStatus(true) } href="#">Eliminar sucursal</a>
                            <a onClick={activeVenue } href="#">Habilitar sucursal</a>
                            <a onClick={desactiveVenue } href="#">Deshabilitar sucursal</a>
                        </div>
                    </div>
                </div>
                <div className="lg:hidden flex space-x-1">
                    <a className="p-2 bg-zinc-200 txt-arena-gray text-[16px] rounded-md" onClick={() => setUpdateStatus(true) } href="#"><FaRegEdit /></a>
                    <a className="p-2 bg-zinc-200 txt-arena-gray text-[16px] rounded-md" onClick={() => setCloseStatus(true) } href="#"><AiFillDelete /></a>
                    <a className="p-2 bg-zinc-200 txt-arena-gray text-[16px] rounded-md" onClick={activeVenue} href="#"><FaUserCheck /></a>
                    <a className="p-2 bg-zinc-200 txt-arena-gray text-[16px] rounded-md" onClick={desactiveVenue} href="#"><FaUserSlash /></a>
                </div>
            </div>
        </>
    );
}

export default VenuesButtonsTable;