import NavbarAdmin from "../../../components/navbar-admin/NavbarAdmin";
import SidebarAdmin from "../../../components/sidebar-admin/SidebarAdmin";
import VenuesCard from "./VenuesCard";
import VenuesModel from "../../../models/Venues";
import Loading from "../../../components/loading/Loading";
import { statesOptions, citiesOptions } from "../../../utils/Utils";
import { useState, useEffect } from "react";
import { getVenues } from "../../../services/Venues";

const AdminVenues = () => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [venues, setVenues] = useState<Array<VenuesModel>>([]);
    const statusOptions = [
        { label: "ACTIVA", value: true },
        { label: "ARCHIVADA", value: false }
    ];

    const getVenuesData = () => {
        new Promise<void>((resolve, reject) => {
            // Get all venues
            getVenues().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setVenues(newArr);
                }
            });

            // Resolve promise
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    }

    useEffect(() => {
        getVenuesData();
    }, []);


    return (
        <div className="flex flex-row w-full h-screen">
            { loadingStatus ? <Loading /> : null }
            <div className="w-[65px] md:w-[250px] lg:w-[370px]">
                <SidebarAdmin />
            </div>
            <div className="w-full bg-zinc-300 flex-grow flex-shrink flex-auto overflow-y-scroll">
                <div>
                    <NavbarAdmin />
                </div>
                <div>
                    <VenuesCard onRefresh={getVenuesData} venues={venues} status={statusOptions} states={statesOptions} cities={citiesOptions} />
                </div>
            </div>
        </div>
    );
}

export default AdminVenues;