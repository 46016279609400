import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconMenu1 from "../../assets/icons/02_arena_icon_home.svg";
import IconMenu2 from "../../assets/icons/01_arena_icon_promos.svg";
import IconMenu3 from "../../assets/icons/03_arena_icon_cumple.svg";
import IconMenu4 from "../../assets/icons/04_arena_icon_lanzamientos.svg";
import IconMenu5 from "../../assets/icons/03_arena_icon_arenanews.svg";
import Pop from "../Pop/Pop";

interface LoginData {
  status: boolean;
  user: any;
}

interface JSONData {
  token: string;
  username: string;
}

const SidebarButtons = ({ status, onClose } : { status: boolean, onClose: any }) => {
  const [closeStatus, setCloseStatus] = useState<boolean>(false);
    const navigate = useNavigate();
    const [userLogger, setUserLogger] = useState<LoginData>({
        status: false,
        user: null
    });

    const signOutAccount = async () => {
        setUserLogger({...userLogger, status: false, user: "" });
        setCloseStatus(false);
        localStorage.clear();
        navigate("/");
    }

    useEffect(() => {
        const userInfo: JSONData = JSON.parse(localStorage.getItem('user-data') || '{}');
        if (userInfo && Object.keys(userInfo).length > 0) {
            setUserLogger({...userLogger, status: true, user: String(userInfo.username).toUpperCase() });
        } else {
            setUserLogger({...userLogger, status: false, user: "" });
        }
    }, []);

    return (
      <>
        {
          closeStatus ?
            <Pop title="¿Esta seguro que desea cerrar sesión?" message="" onClose={() => {setCloseStatus(false) }} onResponse={signOutAccount} />
          : null
        }

        {/* Desktop */}
        <div className="hidden md:block">
          <div className="w-full block">
            <div
              className={`top-0 left-0 w-[400px] lg:w-[450px] bg-arena-cgray pt-20 txt-arena-cgray fixed h-full z-40 ease-in-out duration-300 ${
                status ? "translate-x-0" : "-translate-x-[100vw]"
              }`}
            >
              <Link to="/">
                <h3 className="group flex items-center space-x-2 sb-text py-5 pl-10 txt-arena-cgray text-left text-[20px] font-semibold border-b border-zinc-600">
                  <img className="hidden group-hover:block" src={IconMenu1} />
                  <span>Home</span>
                </h3>
              </Link>
              <Link to="/tournaments">
                <h3 className="group flex items-center space-x-2 sb-text py-5 pl-10 txt-arena-cgray text-left text-[20px] font-semibold border-b border-zinc-600">
                  <img className="hidden group-hover:block" src={IconMenu2} />
                  <span>Torneos y Promociones</span>
                </h3>
              </Link>
              {/* <Link to="/birthday">
                <h3 className="group flex items-center space-x-2 sb-text py-5 pl-10 txt-arena-cgray text-left text-[20px] font-semibold border-b border-zinc-600">
                  <img className="hidden group-hover:block" src={IconMenu3} />
                  <span>Cumpleaños</span>
                </h3>
              </Link> */}
              <Link to="/realeses">
                <h3 className="group flex items-center space-x-2 sb-text py-5 pl-10 txt-arena-cgray text-left text-[20px] font-semibold border-b border-zinc-600">
                  <img className="hidden group-hover:block" src={IconMenu4} />
                  <span>Lanzamientos</span>
                </h3>  
              </Link>
              <Link to="/news">
                <h3 className="group flex items-center space-x-2 sb-text py-5 pl-10 txt-arena-cgray text-left text-[20px] font-semibold border-b border-zinc-600">
                  <img className="hidden group-hover:block" src={IconMenu5} />
                  <span>Arena News</span>
                </h3>
              </Link>
              <a href="https://www.sixkarma.mx/" target="_blank">
                <h3 className="group flex items-center space-x-2 sb-text py-5 pl-10 txt-arena-cgray text-left text-[20px] font-semibold border-b border-zinc-600">
                  <span>Six Karma</span>
                </h3>
              </a>
            </div>
          </div>

          <div onClick={() => onClose(false) } className={`opacity-50 fixed inset-0 z-0 bg-black ${ status ? "block" : "hidden" }`}></div>
        </div>

        {/* Mobile */}
        <div className="md:hidden">
          <div
            className={`top-0 left-0 w-[100vw] bg-arena-gray pt-20 txt-arena-cgray fixed h-full z-40 ease-in-out duration-300 ${
              status ? "translate-x-0" : "-translate-x-[100vw]"
            }`}
          >
            <Link to="/"><h3 className="py-2 pl-8 text-left font-semibold text-2xl txt-arena-cgray">Home</h3></Link>
            {/* <Link to="/booking"><h3 className="py-2 pl-8 text-left font-semibold text-2xl txt-arena-cgray">Reserva</h3></Link> */}
            <Link to="/tournaments"><h3 className="py-2 pl-8 text-left font-semibold text-2xl txt-arena-cgray">Campeonatos {'&'} Drops</h3></Link>
            {/* <Link to="/birthday"><h3 className="py-2 pl-8 text-left font-semibold text-2xl txt-arena-cgray">Cumpleaños</h3></Link> */}
            <Link to="/realeses"><h3 className="py-2 pl-8 text-left font-semibold text-2xl txt-arena-cgray">Lanzamientos</h3></Link>
            <Link to="/news"><h3 className="py-2 pl-8 text-left font-semibold text-2xl txt-arena-cgray">Arena News</h3></Link>
            <Link to="/venues"><h3 className="py-2 pl-8 text-left font-semibold text-2xl txt-arena-cgray">Sucursales</h3></Link>
            <a href="https://www.sixkarma.mx/" target="_blank"><h3 className="py-2 pl-8 text-left font-semibold text-2xl txt-arena-cgray">Six Karma</h3></a>
            <div className="pt-3">
              <div className="row py-3 px-10">
                <Link to="/register">
                  <button className="btn-arena-primary" type="button">REGISTRATE</button>
                </Link>
              </div>
              <div className="row py-3 px-10">
                {
                  userLogger.status ?
                    <button className="btn-arena-secondary" type="button" onClick={() => setCloseStatus(true)}>CERRAR SESIÓN</button>
                  :
                    <Link to="/login">
                      <button className="btn-arena-secondary" type="button">INICAR SESIÓN</button>
                    </Link>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default SidebarButtons;