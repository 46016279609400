import { useState, useEffect } from "react";
import { IDCompany } from "../../global/global";
import { sendBirthdayMail } from "../../services/Birthday";
import { validateMail, validatePhone, validateString } from "../../utils/Validators";
import { FaQuestionCircle } from "react-icons/fa";
import { getCurrentStringDate } from "../../utils/Formats";
import { Fade } from "react-awesome-reveal";
import BirthdayModel from "../../models/Birthday";
import Select from "../../components/select/Select";

const BirthdayForm = ({
    duration,
    venues,
    events,
    onWarning,
    onSuccess,
    onStatus,
    onClosePop,
    statusPop,
    onLoading
} : {
    duration: Array<Object>,
    venues: Array<Object>,
    events: Array<any>,
    onWarning: any,
    onSuccess: any,
    onStatus: any,
    onClosePop: any,
    statusPop: any,
    onLoading: any
}) => {
    const [terms, setTerms] = useState<boolean>(false);
    const [currentDate, setCurrentDate] = useState<string>("");
    const [birthday, setBirthday] = useState({
        companyID: { value: `${IDCompany}`, isCorrect: false },
        userName: { value: "", isCorrect: false },
        userMail: { value: "", isCorrect: false },
        userPhone: { value: "", isCorrect: false },
        numberOfPeople: { value: 0, isCorrect: false },
        partyType: { value: "Cumpleaños", isCorrect: true },
        venueID: { value: "", isCorrect: false },
        partyMessage: { value: "", isCorrect: false },
        partyDate: { value: "", isCorrect: false },
        partyHour: { value: "", isCorrect: false },
        partyTime: { value: "", isCorrect: false }
    });

    const onResponseSelect = (opt: any) => {
        let arrCurrent: Array<Object> = [];
        if (opt.target.name === "partyTime") {
            arrCurrent = duration;
        } else if (opt.target.name === "venueID") {
            arrCurrent = venues;
        }
        setBirthday({...birthday, [opt.target.name]: { value: Object(arrCurrent[opt.target.value]).value, isCorrect: true }});
    }

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (evt.target.name === "partyDate") {
            if (birthday.venueID.isCorrect) {
                if (new Date().getFullYear() <= (new Date(evt.target.value).getFullYear()+1)) {
                    let currentVenue:string = "";
                    
                    let selectVenue:any = null;
                    if (window.innerWidth <= 640) {
                        selectVenue = document.getElementById("select-arenaMC") as HTMLSelectElement | null;
                    } else {
                        selectVenue = document.getElementById("select-arenaDC") as HTMLSelectElement | null;
                    }
                    if (selectVenue !== null) currentVenue = `${Object(venues[Number(selectVenue.value)]).label}`;

                    const eventFound = events.find((evtEle: any) => {
                        if (evtEle.eventDate === evt.target.value) {
                            const venueExist = evtEle.eventVenue.find((vneEle: string) => vneEle === currentVenue);
                            if (venueExist) return evtEle
                        }
                    });
                    
                    if (eventFound) {
                        onWarning("No se puede reservar en esa fecha ya que existe actualmente un evento de Arena Movistar");
                        
                        let inputDate:any = null;
                        if (window.innerWidth <= 640) {
                            inputDate = document.getElementById("inputdate-arenaMC") as HTMLSelectElement | null;
                        } else {
                            inputDate = document.getElementById("inputdate-arenaDC") as HTMLSelectElement | null;
                        }
                        if (inputDate !== null) inputDate.value = "";
                    } else {
                        document.getElementsByName(evt.target.name)[0].style.borderColor="rgb(243 244 246)";
                        setBirthday({...birthday, [evt.target.name]: { value: evt.target.value, isCorrect: true }});
                    }
                }
            } else {
                onWarning("Favor de seleccionar primero la Sucursal");
                let inputDate:any = null;
                if (window.innerWidth <= 640) {
                    inputDate = document.getElementById("inputdate-arenaMC") as HTMLSelectElement | null;
                } else {
                    inputDate = document.getElementById("inputdate-arenaDC") as HTMLSelectElement | null;
                }
                if (inputDate !== null) inputDate.value = "";
            }
        } else {
            let validateInput: boolean = true;

            if (evt.target.name === "userName" || evt.target.name === "partyType") {
                validateInput = validateString(evt.target.value);
            } else if (evt.target.name === "userMail") {
                validateInput = validateMail(evt.target.value);
            } else if (evt.target.name === "userPhone") {
                validateInput = validatePhone(evt.target.value);
            }else {
                validateInput = true;
            }

            if (!validateInput) {
                document.getElementsByName(evt.target.name)[0].style.borderColor="red";
                setBirthday({...birthday, [evt.target.name]: { value: evt.target.value, isCorrect: false }});
            } else {
                document.getElementsByName(evt.target.name)[0].style.borderColor="rgb(243 244 246)";
                setBirthday({...birthday, [evt.target.name]: { value: evt.target.value, isCorrect: true }});
            }
        }
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (terms) {
            if (
                birthday.userName.isCorrect && birthday.userMail.isCorrect && birthday.userPhone.isCorrect && 
                birthday.numberOfPeople.isCorrect && birthday.partyType.isCorrect && birthday.partyMessage.isCorrect && 
                birthday.partyDate.isCorrect && birthday.partyHour && birthday.partyTime.value && birthday.venueID.isCorrect
            ) {
                onLoading(true);
                const birthdayData: BirthdayModel = {
                    companyID: birthday.companyID.value,
                    venueID: birthday.venueID.value,
                    userName: birthday.userName.value,
                    userMail: birthday.userMail.value,
                    userPhone: birthday.userPhone.value,
                    numberOfPeople: birthday.numberOfPeople.value,
                    partyType: birthday.partyType.value,
                    partyMessage: birthday.partyMessage.value,
                    partyDate: birthday.partyDate.value,
                    partyHour: birthday.partyHour.value,
                    partyTime: birthday.partyTime.value
                }
                new Promise<void>((resolve, reject) => {
                    sendBirthdayMail(birthdayData).then(res => {
                        onLoading(false);
                        if (res && res.data) {
                            setBirthday({...birthday, 
                                companyID: { value: `${IDCompany}`, isCorrect: false },
                                userName: { value: "", isCorrect: false },
                                userMail: { value: "", isCorrect: false },
                                userPhone: { value: "", isCorrect: false },
                                numberOfPeople: { value: 0, isCorrect: false },
                                partyType: { value: "Cumpleaños", isCorrect: true },
                                venueID: { value: "", isCorrect: false },
                                partyMessage: { value: "", isCorrect: false },
                                partyDate: { value: "", isCorrect: false },
                                partyHour: { value: "", isCorrect: false },
                                partyTime: { value: "", isCorrect: false }
                            });
                            setTerms(false);
                            let venueArena = document.getElementById("select-arena") as HTMLSelectElement | null;
                            if (venueArena !== null) venueArena.value = "";

                            const partyTimeInput = document.getElementsByName("partyTime") as any;
                            if (partyTimeInput !== null) for (let i = 0; i < partyTimeInput.length; i++) partyTimeInput[i].value = "";

                            const venueIDInput = document.getElementsByName("venueID") as any;
                            if (venueIDInput !== null) for (let i = 0; i < venueIDInput.length; i++) venueIDInput[i].value = "";

                            const termsInput = document.getElementsByName("terms") as any;
                            if (termsInput !== null) for (let i = 0; i < termsInput.length; i++) termsInput[i]['checked'] = false;

                            onStatus("success");
                        } else {
                            onStatus("error");
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                });
            } else {
                onStatus("warning");
            }
        } else {
            onWarning("Favor de llenar todo el formulario");
        }
    }

    useEffect(() => {
        setCurrentDate(getCurrentStringDate());
    }, []);

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block w-full justify-center">
                <form onSubmit={handleSubmit}>
                    <div className="space-y-[25px] px-[35px] text-left">
                        <Fade cascade damping={0.1}>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="nameLabel">
                                    Tu nombre
                                    <span title="Eg. Luis López" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="nameInput"
                                    type="text"
                                    name="userName"
                                    onChange={handleChange}
                                    value={birthday.userName.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="emailLabel">
                                    Tu correo electrónico
                                    <span title="Eg. luislopez@ejemplo.com" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="emailInput"
                                    type="text"
                                    name="userMail"
                                    onChange={handleChange}
                                    value={birthday.userMail.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="phoneLabel">
                                    Teléfono
                                    <span title="Eg. 55 0000 0000" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="phoneInput"
                                    type="text"
                                    name="userPhone"
                                    onChange={handleChange}
                                    value={birthday.userPhone.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="peopleLabel">
                                    Número de personas
                                    <span title="Eg. 15 personas" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="peopleInput"
                                    type="text"
                                    name="numberOfPeople"
                                    onChange={handleChange}
                                    value={birthday.numberOfPeople.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="venueLabel">
                                    Sucursal
                                    <span title="Selecciona la sucursal del cumpleaños" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <Select
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="select-arenaDC"
                                    options={venues}
                                    onResponse={onResponseSelect}
                                    name="venueID"
                                    placeholder="Selecciona la sucursal..."
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="messageLabel">
                                    Mensaje
                                    <span title="Eg. Es un cumpleaños para adolescentes" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>    
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="messageInput"
                                    type="text"
                                    name="partyMessage"
                                    onChange={handleChange}
                                    value={birthday.partyMessage.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="dateLabel">
                                    Día
                                    <span title="Día del evento dd/mm/aaaa" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="inputdate-arenaDC"
                                    type="date"
                                    min={currentDate}
                                    name="partyDate"
                                    onChange={handleChange}
                                    value={birthday.partyDate.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="hourLabel">
                                    Hora
                                    <span title="Horario 12:00PM - 09:00PM" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="hourInput"
                                    type="time"
                                    name="partyHour"
                                    onChange={handleChange}
                                    value={birthday.partyHour.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="timeLabel">
                                    Duración
                                    <span title="Selecciona el tiempo de duración del evento" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <Select
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    options={duration}
                                    onResponse={onResponseSelect}
                                    name="partyTime"
                                    id="select-arena"
                                    placeholder="Selecciona la duración..."
                                />
                            </div>
                            <div className="flex space-x-[12px] py-[12px] m-auto w-[70%] text-[14px] txt-arena-white">
                                <input type="checkbox" name="terms" onChange={() => { setTerms(!terms) }} />
                                <p>
                                    {'Acepto el '}
                                    <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "privacy" }) }}>
                                        aviso de privacidad
                                    </button>
                                    {' y, '}
                                    <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "terms_conditions" }) }}>
                                        términos y condiciones
                                    </button>
                                </p>
                            </div>
                            <div>
                                <div className="pb-[12px] px-[35px] m-auto scale-100 hover:scale-110 ease-in duration-150">
                                    <button className="btn-arena-primary" type="submit">
                                        <span className="tracking-[2px]">ENVIAR</span>
                                    </button>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </form>
            </div>

            {/* Mobile */}
            <form onSubmit={handleSubmit} className="md:hidden">
                <div className="space-y-[5.2vw] px-[3.2vw] text-left">
                    <Fade cascade damping={0.1}>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="nameLabel">Tu nombre</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="nameInput"
                                type="text"
                                name="userName"
                                onChange={handleChange}
                                value={birthday.userName.value}
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="emailLabel">Tu correo electrónico</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="emailInput"
                                type="text"
                                name="userMail"
                                onChange={handleChange}
                                value={birthday.userMail.value}
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="phoneLabel">Teléfono</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="phoneInput"
                                type="text"
                                name="userPhone"
                                onChange={handleChange}
                                value={birthday.userPhone.value}
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="peopleLabel">Número de personas</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="peopleInput"
                                type="text"
                                name="numberOfPeople"
                                onChange={handleChange}
                                value={birthday.numberOfPeople.value}
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="venueLabel">
                                Sucursal
                            </label>
                            <Select
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="select-arenaMC"
                                options={venues}
                                onResponse={onResponseSelect}
                                name="venueID"
                                placeholder="Selecciona la sucursal..."
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="messageLabel">Mensaje</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="messageInput"
                                type="text"
                                name="partyMessage"
                                onChange={handleChange}
                                value={birthday.partyMessage.value}
                            />
                        </div>
                        <div>
                            <label className="block txt-arena-orange text-[3.5vw] font-bold" htmlFor="dateLabel">Día</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="inputdate-arenaMC"
                                type="date"
                                min={currentDate}
                                name="partyDate"
                                onChange={handleChange}
                                value={birthday.partyDate.value}
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="hourLabel">
                                Hora
                            </label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="hourInput"
                                type="time"
                                name="partyHour"
                                onChange={handleChange}
                                value={birthday.partyHour.value}
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="timeLabel">
                                Duración
                            </label>
                            <Select
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                options={duration}
                                onResponse={onResponseSelect}
                                name="partyTime"
                                id="select-arena"
                                placeholder="Ingresa la duración..."
                            />
                        </div>
                        <div className="flex space-x-[1vw] py-[2vw] m-auto w-[60vw] text-[3.3vw] txt-arena-white">
                            <input type="checkbox" name="terms" onChange={() => { setTerms(!terms) }} />
                            <p>
                                {'Acepto el '}
                                <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "privacy" }) }}>
                                    aviso de privacidad
                                </button>
                                {' y, '}
                                <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "terms_conditions" }) }}>
                                    términos y condiciones
                                </button>
                            </p>
                        </div>
                        <div>
                            <div className="pb-[10vw] px-[5vw] m-auto">
                                <button className="btn-arena-primary" type="submit">ENVIAR</button>
                            </div>
                        </div>
                    </Fade>
                </div>
            </form>
        </>
    );
}

export default BirthdayForm;