import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import IconBg from "../../../assets/icons/06_hexagon.svg";
import Footer from "../../../components/footer/Footer";
import Navbar from "../../../components/navbar/Navbar";
import PDFViewer from "../../../components/pdf-viewer/PDFViewer";
import NewsModel from "../../../models/News";
import NewsHeader from "./NewsHeader2";
import NewsMore2 from "./NewsMore2";
import Rellax from "rellax";

const NewsDetail = () => {
    const location = useLocation();
    const [arrNews, setArrNews] = useState<Array<NewsModel>>([]);

    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    const [newElement, setNewElement] = useState<NewsModel>({
        companyID: "",
        noteID: "",
        noteTitle: "",
        noteEpisode: "",
        noteCreatedAt: "",
        noteBody: "",
        noteCreatedBy: "",
        noteImageURL: [],
        noteVideoURL: []
    });

    useEffect(() => {
        new Rellax(".animatev0", { speed: 3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev1", { speed: -1, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev2", { speed: -2, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev3", { speed: -3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev4", { speed: -4, center: false, round: true, vertical: true, horizontal: false });

        if (location && location.state) {
            let _news=location.state as any;
            setNewElement(_news['array'][_news['index']]);
            setArrNews(_news['array']);
        }
    }, []);

    return (
        <>
            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({...popupTP, status: false, documentStr: ""}) }}
                    />
                : null
            }

            <div id="animatev0" style={{ scrollBehavior: 'auto' }} className="relative bg-arena-black">
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-40">
                        <Navbar />
                    </div>
                </div>
                <div className="pt-[50px] relative w-[100%] md:w-[650px] lg:w-[1000px] m-auto">
                    <NewsHeader newElement={newElement} />
                </div>
                <div className="news-arena-style bg-arena-white relative mt-[25px]">
                    <div className="w-[75%] m-auto pt-[22vw] md:pt-[100px] lg:pt-[120px]">
                        <NewsMore2
                            onResponse={(newProp: NewsModel) => {
                                setNewElement(newProp);
                                window.scrollTo(0, 0);
                            }}
                            news={arrNews}
                        />
                    </div>
                    <div className="md:pt-[100px]">
                        <img className="hidden md:block absolute w-[250px] top-[470px] left-[-50px] lg:top-[520px] lg:left-[50px] z-10" src={IconBg} />
                        <Fade>
                            <Footer
                                statusPop={(opt: any) => {
                                    setPopupTP({...popupTP, status: opt.status, documentStr: opt.documentStr })
                                }}
                            />
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewsDetail;