import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NewsModel from "../../models/News";

interface TabData {
    status: boolean;
    name: string;
    info: NewsModel;
}

const NewsCarrousel = ({ news } : { news: Array<NewsModel> }) => {
    const [tab, setTab] = useState<Array<TabData>>([]);

    const onClickTab = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();

        const newTab = tab.map((tabElement, i) => {
            if (tabElement.name === evt.currentTarget.name) {
                return { ...tabElement, status: true };
            } else {
                return { ...tabElement, status: false };
            }
        });

        setTab(newTab);
    }

    useEffect(() => {
        const newNews = news.map((newElement: NewsModel, i: number) => {
            let statusTab: boolean = false;

            if (i === 0) statusTab = true;

            return {
                status: statusTab,
                name: `tab${(i+1)}`,
                info: newElement
            }
        });
        setTab(newNews);
    }, [news])

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div>
                    {
                        tab.map((newElement: TabData, i: number) => {
                            if (newElement.status && `${newElement.info.noteCreatedAt}`!== "NaN") {
                                return (
                                    <div key={i}>
                                        <div className="w-[600px] h-[320px] lg:w-[800px] lg:h-[520px] overflow-hidden rounded-md m-auto">
                                            <Link to="/news/detail" state={{ index: i, array: news }}>
                                                <img
                                                    className="w-[600px] h-[320px] lg:w-[800px] lg:h-[520px] duration-500 ease-in-out hover:scale-110 z-30 m-auto grayscale-75 brightness-75"
                                                    src={newElement && newElement.info.noteImageURL ? newElement.info.noteImageURL[0] : ""}
                                                />
                                                <div className="relative flex justify-center items-center">
                                                    <div className="absolute w-[450px] top-[-250px] lg:top-[-320px] z-30 m-auto">
                                                        <p className="py-[15px] txt-arena-orange text-[18px] font-bold">{newElement.info.noteEpisode}</p>
                                                        <p className="txt-arena-white text-[25px] font-bold">
                                                            {newElement.info.noteTitle}
                                                        </p>
                                                        <div className="space-x-[8px]">
                                                            <button className="w-2 h-2 rounded-full bg-arena-white btnCarrousel" type="button" name="tab1" onClick={onClickTab}></button>
                                                            <button className="w-2 h-2 rounded-full bg-arena-white btnCarrousel" type="button" name="tab2" onClick={onClickTab}></button>
                                                            <button className="w-2 h-2 rounded-full bg-arena-white btnCarrousel" type="button" name="tab3" onClick={onClickTab}></button>
                                                            <button className="w-2 h-2 rounded-full bg-arena-white btnCarrousel" type="button" name="tab4" onClick={onClickTab}></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div className="body-carrousel-news">
                    <div className="slider-carrousel-news">
                        <div className="slides-carrousel-news">
                            {
                                news.map((newElement: NewsModel, i: number) => {
                                    if (i < 4 && `${newElement.noteCreatedAt}`!== "NaN") {
                                        return (
                                            <div key={i}>
                                                <Link to="/news/detail" state={{ index: i, array: news }}>
                                                    <img
                                                        className="w-[100vw] h-[80vw] z-30 m-auto grayscale-75 brightness-75"
                                                        src={newElement && newElement.noteImageURL ? newElement.noteImageURL[0] : ""}
                                                    />
                                                </Link>
                                                <div className="absolute top-[35vw] left-[8vw] w-[80vw]">
                                                    <p className="py-[1vw] txt-arena-orange text-[4.5vw] font-bold">{newElement.noteEpisode}</p>
                                                    <p className="txt-arena-white text-[4.5vw] font-bold">
                                                        {newElement.noteTitle}
                                                    </p>
                                                </div>
                                            </div>
                                        );
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
                {/* <div>
                    {
                        tab.map((newElement: TabData, i: number) => {
                            if (newElement.status) {
                                return (
                                    <div key={i}>
                                        <Link to="/news/detail" state={{ index: i, array: news }}>
                                            <img className="w-[100vw] h-[80vw] z-30 m-auto grayscale-75 brightness-75" src={newElement.info.noteImageURL[0]} />
                                        </Link>
                                        <div className="absolute top-[35vw] left-[8vw] w-[80vw]">
                                            <p className="py-[1vw] txt-arena-orange text-[4.5vw] font-bold">{newElement.info.noteEpisode}</p>
                                            <p className="txt-arena-white text-[4.5vw] font-bold">
                                                {newElement.info.noteTitle}
                                            </p>
                                            <div className="space-x-[1vw]">
                                                <button className="w-2 h-2 rounded-full bg-arena-white btnCarrousel" type="button" name="tab1" onClick={onClickTab}></button>
                                                <button className="w-2 h-2 rounded-full bg-arena-white btnCarrousel" type="button" name="tab2" onClick={onClickTab}></button>
                                                <button className="w-2 h-2 rounded-full bg-arena-white btnCarrousel" type="button" name="tab3" onClick={onClickTab}></button>
                                                <button className="w-2 h-2 rounded-full bg-arena-white btnCarrousel" type="button" name="tab4" onClick={onClickTab}></button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })
                    }
                </div> */}
            </div>
        </>
    );
}

export default NewsCarrousel;