import { useContext, useEffect, useState } from "react";
import { getVenues } from "../../services/Venues";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { Fade } from "react-awesome-reveal";
import IconBg from "../../assets/icons/06_hexagon.svg";
import ReactPlayer from "react-player";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import VenuesModel from "../../models/Venues";
import VenuesBody from "./VenuesBody";
import VenuesHeader from "./VenuesHeader";
import Loading from "../../components/loading/Loading";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import Rellax from "rellax";
import "./Venues.css";
import { GlobalContext } from "../../context/global_context";

const VideoArena = require("../../assets/video/arena_the_place_to_play.mp4");

const Venues = () => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [venues, setVenues] = useState<Array<VenuesModel>>([]);
    const { globalState,setGlobalState} = useContext(GlobalContext)
    const [statesOptions, setStatesOptions] = useState<Array<any>>([]);
    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    useEffect(() => {
        new Rellax(".animatev0", { speed: 3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev1", { speed: -1, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev2", { speed: -2, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev3", { speed: -3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev4", { speed: -4, center: false, round: true, vertical: true, horizontal: false });

        new Promise<void>((resolve, reject) => {
            getVenues().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    let newArrStates: Array<any> = [
                        { label: "Mostrar todos", value: "clean" }
                    ];
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        const stateFound = newArrStates.find((eleState) => eleState.value === element.venueAddress.state);
                        if (!stateFound) {
                            newArrStates.push({ label: element.venueAddress.state, value: element.venueAddress.state });
                        }
                        newArr.push(element);
                    }
                    setGlobalState({...globalState, venues:newArr})
                    setVenues(newArr);
                    setStatesOptions(newArrStates);
                }
            });
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    }, []);

    return (
        <>
            { loadingStatus ? <Loading /> : null }

            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({...popupTP, status: false, documentStr: ""}) }}
                    />
                : null
            }

            <div id="animatev0" style={{ scrollBehavior: 'auto' }} className="bg-arena-gray">
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-40">
                        <Navbar />
                    </div>
                </div>
                <div className="relative w-[100%] pt-0 m-auto md:w-[650px] lg:w-[900px] m-auto">
                    <div className="absolute md:relative w-[100vw] h-[55vw] left-0 top-[50px] m-auto z-0 md:w-[500px] md:h-[280px] md:top-[70px] lg:w-[760px] lg:h-[428px] lg:top-[70px]">
                        <ReactPlayer
                            url={VideoArena}
                            className='react-player'
                            playing
                            width='100%'
                            height='100%'
                            controls={false}
                            loop={true}
                            muted={true}
                        />
                    </div>
                    <div className="pt-[60vw] md:pt-0">
                        <img className="animatev2 md:hidden absolute w-[50vw] top-[80vw] left-[-35vw] z-0" src={IconBg} />
                        <img className="animatev1 md:hidden absolute w-[50vw] top-[55vw] left-[60vw] z-0" src={IconBg} />
                        <img className="animatev2 hidden md:block absolute w-[250px] top-[300px] left-[-100px] lg:top-[450px] lg:left-[50px] z-0" src={IconBg} />
                        <img className="animatev1 hidden md:block absolute w-[250px] top-[390px] left-[440px] lg:top-[540px] lg:left-[630px] z-0" src={IconBg} />

                        <VenuesHeader />
                    </div>
                    <div className="m-auto">
                        <VenuesBody stateOptions={statesOptions} venues={venues} />
                    </div>
                </div>
                <div className="relative pt-10 md:pt-[120px]">
                    <img className="hidden md:block absolute w-[250px] top-[-30px] left-[-100px] lg:top-[0px] lg:left-[-10px] z-0 z-10" src={IconBg} />
                    
                    <Fade>
                        <Footer
                            statusPop={(opt: any) => {
                                setPopupTP({...popupTP, status: opt.status, documentStr: opt.documentStr })
                            }}
                        />
                    </Fade>
                </div>
            </div>
        </>
    );
}

export default Venues;