import { useState, useEffect } from "react";
import { getDrops, getTournaments } from "../../../services/TournamentDrop";
import { getVenues } from "../../../services/Venues";
import NavbarAdmin from "../../../components/navbar-admin/NavbarAdmin";
import SidebarAdmin from "../../../components/sidebar-admin/SidebarAdmin";
import TournamentDropModel from "../../../models/TournamentDrop";
import DropsCard from "./DropsCard";
import VenuesModel from "../../../models/Venues";
import Loading from "../../../components/loading/Loading";

const AdminDrops = () => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [drops, setDrops] = useState<Array<TournamentDropModel>>([]);
    const [tournaments, setTournaments] = useState<Array<TournamentDropModel>>([]);
    const [venuesOptions, setVenuesOptions] = useState<Array<VenuesModel>>([]);

    const typeOptions = [
        { label: "Torneo", value: "Torneo" },
        { label: "Dinámica", value: "Dinámica" },
        { label: "Drop", value: "Drop" },
    ];

    const modeOptions = [
        { label: "NO APLICA", value: "NO APLICA" },
        { label: "EQUIPO", value: "EQUIPO" },
        { label: "INDIVIDUAL", value: "INDIVIDUAL" }
    ];

    const consoleOptions = [
        { label: "No aplica", value: "No aplica" },
        { label: "Multiplataforma", value: "Multiplataforma" },
        { label: "Nintendo Switch", value: "Nintendo Switch" },
        { label: "Windows PC", value: "Windows PC" },
        { label: "Steam", value: "Steam" },
        { label: "Playstation 4/Pro", value: "Playstation 4/Pro" },
        { label: "Playstation 5", value: "Playstation 5" },
        { label: "Xbox One", value: "Xbox One" },
        { label: "Xbox Series X/S", value: "Xbox Series X/S" },
        { label: "Android", value: "Android" },
        { label: "iOS", value: "iOS" }
    ];

    const getDropsTournamentsData = () => {
        new Promise<void>((resolve, reject) => {
            // Get all drops
            getDrops().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setDrops(newArr);
                }
            });

            // Get all tournaments
            getTournaments().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setTournaments(newArr);
                }
            });

            // Get all venues
            getVenues().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push({ label: element.venueName, value: element.venueName });
                    }

                    newArr.sort((a: any, b:any) => {
                        return String(a.label).localeCompare(String(b.label));
                    });

                    newArr.unshift({ label: "Todas las sucursales", value: "Todas las sucursales" });
                    newArr.unshift({ label: "No aplica", value: "No aplica" });

                    setVenuesOptions(newArr);
                }
            });

            // Resolve promise
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    }

    useEffect(() => {
        getDropsTournamentsData();
    }, []);

    return (
        <div className="flex flex-row w-full h-screen">
            { loadingStatus ? <Loading /> : null }
            <div className="w-[65px] md:w-[250px] lg:w-[370px]">
                <SidebarAdmin />
            </div>
            <div className="w-full bg-zinc-300 flex-grow flex-shrink flex-auto overflow-y-scroll">
                <div>
                    <NavbarAdmin />
                </div>
                <div>
                    <DropsCard
                        onRefresh={getDropsTournamentsData}
                        drops={drops}
                        tournaments={tournaments}
                        type={typeOptions}
                        mode={modeOptions}
                        plataform={consoleOptions}
                        venues={venuesOptions}
                    />
                </div>
            </div>
        </div>
    );
}

export default AdminDrops;