import { useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import VenuesModel from "../../models/Venues";

const VenuesCard = ({ venue } : { venue: VenuesModel }) => {
    const [venueElement, setVenueElement] = useState<VenuesModel>({
        companyID: "",
        venueID: "",
        venueName: "",
        venueAddress : {
            street: "",
            number: "",
            interiorNumber: "",
            neighborhood: "",
            city: "",
            state: "",
            country: "",
            zipCode: ""
        },
        venueContactMail: "",
        venueWorkingHours: "",
        venueContactPhone: "",
        venueMapURL: "",
        venueImagesURL: [""],
        venueVideosURL: [""]
    });

    useEffect(() => {
        if (venue) {
            setVenueElement(venue);
        }
    }, [venue]);

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <Link to="/venues/detail" state={venueElement}>
                    <div className="relative w-[500px] h-[280px] lg:w-[370px] lg:h-[190px] scale-100 hover:scale-105 ease-in duration-150">
                        <img className="relative w-full h-full img-boxs" src={venueElement.venueImagesURL[0]} />
                        <div className="glassmorphism w-full py-[10px] mt-[-50px] text-left txt-arena-white z-40">
                            <div className="flex w-[90%] m-auto">
                                <span className="w-[90%] text-[20px] font-bold">{venueElement.venueName}</span>
                                <span className="w-[10%] text-[32px]"><AiOutlineArrowRight /></span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <Link to="/venues/detail" state={venueElement}>
                    <div className="relative w-[85vw] h-[50vw]">
                        <img className="relative w-full h-full img-boxs" src={venueElement.venueImagesURL[0]} />
                        <div className="absolute glassmorphism w-[85vw] py-[3vw] bottom-0 text-left txt-arena-white z-40">
                            <div className="flex items-center w-[90%] m-auto">
                                <span className="w-[90%] text-[5vw] font-bold">{venueElement.venueName}</span>
                                <span className="w-[10%] text-[7vw]"><AiOutlineArrowRight /></span>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    );
}

export default VenuesCard;