import NewsModel from "../../../models/News";
import ReactPlayer from "react-player";
import IconTwitter from "../../../assets/icons/13_twitter_arena.svg";
import IconInstagram from "../../../assets/icons/14_instagram_arena.svg";
import IconFacebook from "../../../assets/icons/15_facebook_arena.svg";
import { BiCalendar } from "react-icons/bi";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Fade } from "react-awesome-reveal";

const NewsHeader2 = ({ newElement } : { newElement: NewsModel }) => {
    const [showVP, setShowVP] = useState<boolean>(false);
    const [showIN, setShowIN] = useState<boolean>(false);
    const [imgURL, setImgURL] = useState<string>("");
    const [vidURL, setVidURL] = useState<string>("");

    useEffect(() => {
        if (window.innerWidth >= 300 && window.innerWidth <= 460) {
            if (newElement && newElement.noteVideoURL && newElement.noteVideoURL.length > 0) {
                setShowVP(true);
                setShowIN(false);
                setImgURL("");
                setVidURL(newElement.noteVideoURL[0]);
            } else {
                setShowVP(true);
                setShowIN(true);
                setImgURL(newElement.noteImageURL[0]);
                setVidURL("");
            }
        } else {
            if (newElement && newElement.noteVideoURL && newElement.noteVideoURL.length > 0) {
                setShowVP(false);
                setShowIN(false);
                setImgURL("");
                setVidURL(newElement.noteVideoURL[0]);
            } else {
                setShowVP(false);
                setShowIN(true);
                setImgURL(newElement.noteImageURL[0]);
                setVidURL("");
            }
        }
    }, [newElement]);

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="w-[600px] h-[337px] lg:w-[800px] lg:h-[450px] m-auto">
                    {
                        showIN ? 
                            <img src={imgURL} /> :
                        showVP ?
                            null :
                        <ReactPlayer
                            url={vidURL}
                            className='react-player'
                            width='100%'
                            height='100%'
                            controls={true}
                            loop={true}
                            muted={false}
                        />
                    }
                </div>
                <div className="space-y-[20px] w-[600px] lg:w-[800px] m-auto">
                    <Fade cascade damping={0.1}>
                        <div className="pt-[20px]">
                            <Link to="/news">
                                <div className="flex items-center space-x-[25px] text-left mb-[35px] txt-arena-orange">
                                    <span className="text-[40px]"><AiOutlineArrowLeft /></span>
                                    <p className="text-[25px]">Noticias / {newElement.noteEpisode}</p>
                                </div>
                            </Link>
                        </div>
                        <div className="flex justify-start space-x-[10px]">
                            <a href="https://facebook.com/ArenaThePlaceToPlay" target="_blank">
                                <img className="w-[40px] scale-100 hover:scale-110 ease-in duration-150" src={IconFacebook} />
                            </a>
                            <a href="https://instagram.com/arenatheplacetoplay" target="_blank">
                                <img className="w-[40px] scale-100 hover:scale-110 ease-in duration-150" src={IconInstagram} />
                            </a>
                            <a href="https://twitter.com/arenaplacetplay" target="_blank">
                                <img className="w-[40px] scale-100 hover:scale-110 ease-in duration-150" src={IconTwitter} />
                            </a>
                        </div>
                        <div className="txt-arena-orange text-left font-bold">
                            <p className="text-[20px]">{ newElement.noteEpisode }</p>
                        </div>
                        <div className="txt-arena-white text-left font-bold">
                            <h3 className="text-[20px]">{ newElement.noteTitle }</h3>
                        </div>
                        <div className="txt-arena-white text-left">
                            <div className="flex items-center space-x-[12px]">
                                <span className="text-[20px]"><BiCalendar /></span>
                                <p className="text-[14px]">Hace {newElement.noteCreatedAt} día(s)</p>
                            </div>
                        </div>
                        <div className="txt-arena-white text-left">
                            <p className="text-[16px]">{ newElement.noteBody }</p>
                        </div>
                    </Fade>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div className="bg-arena-black py-[10vw]">
                    <div className="w-[100vw] h-[56vw]">
                        <Fade>
                            {
                                showIN ? <img src={imgURL} /> :
                                showVP ?
                                    <ReactPlayer
                                        url={vidURL}
                                        className='react-player'
                                        width='100%'
                                        height='100%'
                                        controls={true}
                                        loop={true}
                                        muted={false}
                                    />
                                : null
                            }
                        </Fade>
                    </div>
                </div>
                <div className="mt-[3vw] space-y-[4vw] px-[8vw]">
                    <Fade cascade damping={0.1}>
                        <div className="py-[2vw]">
                            <Link to="/news">
                                <div className="flex items-center space-x-[3vw] text-left font-bold mb-[2vw] txt-arena-orange">
                                    <span className="text-[6vw]"><AiOutlineArrowLeft /></span>
                                    <p className="text-[5vw]">Noticias / {newElement.noteEpisode}</p>
                                </div>
                            </Link>
                        </div>
                        <div className="flex justify-start space-x-[3vw] py-[2vw]">
                            <a href="https://facebook.com/ArenaThePlaceToPlay" target="_blank"><img className="w-[11vw]" src={IconFacebook} /></a>
                            <a href="https://instagram.com/arenatheplacetoplay" target="_blank"><img className="w-[11vw]" src={IconInstagram} /></a>
                            <a href="https://twitter.com/arenaplacetplay" target="_blank"><img className="w-[11vw]" src={IconTwitter} /></a>
                        </div>
                        <div className="txt-arena-orange text-left font-bold">
                            <p className="text-[5.5vw]">{ newElement.noteEpisode }</p>
                        </div>
                        <div className="txt-arena-white text-left font-bold">
                            <h3 className="text-[6vw]">{ newElement.noteTitle }</h3>
                        </div>
                        <div className="pt-[2vw] space-y-[3vw]">
                            <div className="text-gray-400 text-left">
                                <div className="flex items-center space-x-[2vw]">
                                    <span className="text-[5vw]"><BiCalendar /></span>
                                    <p className="text-[3vw]">Hace {newElement.noteCreatedAt} día(s)</p>
                                </div>
                            </div>
                            <div className="text-gray-400 text-left">
                                <p className="text-[4.5vw]">{ newElement.noteBody }</p>
                            </div>
                        </div>
                    </Fade>
                </div>
            </div>
        </>
    );
}

export default NewsHeader2;