import { functions, httpsCallable } from "../firebase";
import { IDCompany } from "../global/global";
import TournamentDropModel from "../models/TournamentDrop";

export const getDrops = () => {
    const getDropsDinamics = httpsCallable(functions, "readAllDropsAndDinamicas");
    const res = getDropsDinamics({ companyID: `${IDCompany}` });
    return res;
}

export const getTournaments = () => {
    const getAllTournaments = httpsCallable(functions, "readAllTournaments");
    const res = getAllTournaments({ companyID: `${IDCompany}` });
    return res;
}

export const getAllDropsTournaments = () => {
    const getAllDT = httpsCallable(functions, "readAllDrops");
    const res = getAllDT({ companyID: `${IDCompany}` });
    return res;
}

export const addDropTournament = (data: TournamentDropModel) => {
    const saveDropsTournament = httpsCallable(functions, "createDrop");
    const res = saveDropsTournament(data);
    return res;
}

export const updateDropTournament = (data: Object) => {
    const putDropTournament = httpsCallable(functions, "updateDrop");
    const res = putDropTournament(data);
    return res;
}

export const deleteDropTournmanet = (data: Object) => {
    const removeDropTournament = httpsCallable(functions, "deleteDrop");
    const res = removeDropTournament(data);
    return res;
}