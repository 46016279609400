import { useState, useContext } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { IoIosKey } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword } from "../../firebase";
import IconLogin from "../../assets/icons/04_icon_comunidad.png";
import PopNotify from "../../components/Pop/PopNotify";
import LoginResetPop from "./LoginResetPop";
import axios from "axios";
import PopWarning from "../../components/Pop/PopWarning";
import PopSuccess from "../../components/Pop/PopSuccess";
import { GlobalContext } from "../../context/global_context";


interface PopInfo {
    status: boolean,
    type: string
}

const LoginForm = () => {
    const navigate = useNavigate();
    const [popWarningStatus, setPopWarningStatus] = useState<PopInfo>({ status: false, type: "" });
    const [popSuccessStatus, setPopSuccessStatus] = useState<PopInfo>({ status: false, type: "" });
    const [resetPopStatus, setResetPopStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [loginData, setLoginData] = useState({
        email: "",
        password: ""
    });
    
    const { globalState,setGlobalState } = useContext(GlobalContext)

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setLoginData({...loginData, [evt.currentTarget.name]: evt.currentTarget.value});
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        try {
            if (loginData.email && loginData.password) {
                axios.post("https://offsite.gamersarena.com.mx/players/login", loginData).then(async (res: any) => {
                    if (res.status === 200) {
                        setGlobalState({...globalState, userData:res.data})
                        localStorage.setItem("user-data", JSON.stringify({ token: res.data.token, username: res.data.player.nick }));
                        navigate("/");
                    } else {
                        setPopWarningStatus({...popWarningStatus, status: true, type: "¡Ha ocurrido un problema, favor de volver a intentarlo!"});
                    }
                }).catch(async (error) => {
                    if (error.response.data.error.message) {
                        if (error.response.data.error.message === "No se encontró al jugador solicitado") {
                            await signInWithEmailAndPassword(auth, loginData.email, loginData.password);
                            navigate("/admin/popup");
                        }
                    } else {
                        setPopWarningStatus({...popWarningStatus, status: true, type: "¡Ha ocurrido un problema, favor de volver a intentarlo!"});
                    }
                });
            } else {
                setPopWarningStatus({...popWarningStatus, status: true, type: "Favor de llenar todos los campos del formulario"});
            }
        } catch (error) {
            setPopStatus({...popStatus, status: true, type: "warning"});
        }
    }

    return (
        <>
            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            {
                resetPopStatus ?
                    <LoginResetPop onCloseModal={() => setResetPopStatus(false)} />
                : null
            }

            {
                popWarningStatus.status ?
                    <PopWarning body={popWarningStatus.type} onClose={() => { setPopWarningStatus({...popWarningStatus, status: false, type: ""}) }} />
                : null
            }

            {
                popSuccessStatus.status ?
                    <PopSuccess body={popSuccessStatus.type} onClose={() => { setPopSuccessStatus({...popSuccessStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="block">
                <form onSubmit={handleSubmit}>
                    <div className="w-[90vw] md:w-[600px] my-[50px] p-[20px] space-y-[20px] m-auto">
                        <div>
                            <img className="w-[70px]" src={IconLogin} />
                        </div>
                        <div className="text-left txt-arena-white">
                            <div className="font-bold">
                                <h3 className="text-[20px]">Inicia sesión</h3>
                            </div>
                            <div>
                                <p className="text-[16px]">Arena donde los gamers vienen a jugar</p>
                            </div>
                        </div>
                        <div className="space-y-[10px]">
                            <div className="text-left txt-arena-orange font-bold">
                                <h3 className="text-[14px]">Correo electrónico</h3>
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    name="email"
                                    className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[16px] py-[10px]"
                                    placeholder="Ingresa tu correo electrónico"
                                    onChange={handleChange}
                                />
                                <div className="flex absolute inset-y-0 right-0 items-center pr-[20px] pointer-events-none txt-arena-white">
                                    <span className="text-[25px]"><FaUserAlt /></span>
                                </div>
                            </div>
                        </div>
                        <div className="space-y-[10px]">
                            <div className="text-left txt-arena-orange font-bold">
                                <h3 className="text-[14px]">Contraseña</h3>
                            </div>
                            <div className="relative">
                                <input
                                    type="password"
                                    name="password"
                                    className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[16px] py-[10px]"
                                    placeholder="Ingresa tu contraseña"
                                    onChange={handleChange}
                                />
                                <div className="flex absolute inset-y-0 right-0 items-center pr-[20px] pointer-events-none txt-arena-white">
                                    <span className="text-[25px]"><IoIosKey /></span>
                                </div>
                            </div>
                        </div>
                        <div className="pt-[20px] scale-100 hover:scale-110 ease-in duration-150">
                            <button className="btn-arena-primary" type="submit">INGRESAR</button>
                        </div>
                        <div
                            className="flex justify-center items-center space-x-[10px] pt-[10px] txt-arena-orange font-bold scale-100 hover:scale-110 ease-in duration-150"
                            onClick={() => setResetPopStatus(true)}
                        >
                            <span className="text-[20px]"><AiOutlineInfoCircle /></span>
                            <span className="text-[14px]">Olvidé mi contraseña</span>
                        </div>
                        <div className="txt-arena-orange font-bold scale-100 hover:scale-110 ease-in duration-150">
                            <Link to="/register">
                                <span className="text-[14px]">¿No tiene una cuenta? ¡Registrarse!</span>
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default LoginForm;