import IconLocation from "../../assets/icons/venues/04_icon_ubicaciones.png";
import { Fade } from "react-awesome-reveal";

const VenuesHeader = () => {
    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="relative">
                    <div className="flex justify-center items-center mt-[35px] m-auto z-30">
                        <div className="absolute">
                            <img className="relative w-[85px] top-[-120px] right-[150px] z-30" src={IconLocation} />
                        </div>
                        <div className="glassmorphism w-[450px] py-[40px] px-[30px] space-y-[15px] text-left txt-arena-white">
                            <Fade cascade damping={0.1}>
                                <div className="pt-[12px]">
                                    <h3 className="text-[20px] font-bold">Hay una arena cerca de ti</h3>
                                </div>
                                <p className="text-[16px]">
                                    Encuentra tu sucursal más cercana y comienza a vivir la experiencia de jugar a otro nivel. 
                                    En Arena The Place to Play Movistar te esperamos con los brazos abiertos, ¡Sube tu nivel!
                                </p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div className="relative">
                    <div className="flex justify-center items-center m-auto z-40">
                        <div className="glassmorphism w-[90vw] py-[5vw] px-[5vw] space-y-[1vw] text-left txt-arena-white">
                            <div className="relative pb-[10vw]">
                                <img className="absolute w-[20vw] top-[-10vw] z-50" src={IconLocation} />
                            </div>
                            <div className="space-y-[4vw]">
                                <Fade cascade damping={0.1}>
                                    <div>
                                        <h3 className="text-[5vw] font-bold">Hay una arena cerca de ti</h3>
                                    </div>
                                    <p className="text-[4.3vw]">
                                        Encuentra tu sucursal más cercana y comienza a vivir la experiencia de jugar a otro nivel. 
                                        En Arena The Place to Play Movistar te esperamos con los brazos abiertos, ¡Sube tu nivel!
                                    </p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VenuesHeader;