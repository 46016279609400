import { functions, httpsCallable } from "../firebase";
import UserModel from "../models/User";

export const getUsers = () => {
    const getActiveUsers = httpsCallable(functions, "readAllActiveUsers");
    const res = getActiveUsers();
    return res;
}

export const addUser = (data: UserModel) => {
    const createUser = httpsCallable(functions, "createNewUser");
    const res = createUser(data);
    return res;
}

export const updateUser = (data: Object) => {
    const editUser = httpsCallable(functions, "updateUserById");
    const res = editUser(data);
    return res;
}

export const deleteUser = (data: Object) => {
    const removeUser = httpsCallable(functions, "deleteUsersById");
    const res = removeUser(data);
    return res;
}

export const activeuserById = (data: Object) => {
    const activeUser = httpsCallable(functions, "activateUsersById");
    const res = activeUser(data);
    return res;
}

export const desactiveuserById = (data: Object) => {
    const desactiveUser = httpsCallable(functions, "deactivateUsersById");
    const res = desactiveUser(data);
    return res;
}