import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import VenuesModel from "../../../models/Venues";
import { GlobalContext } from "../../../context/global_context";
import { useContext } from 'react'

const VenuesInfo = ({ info, onModalMap, onModalCarrousel }: { info: VenuesModel, onModalMap: any, onModalCarrousel: any }) => {
    const { globalState,setGlobalState } = useContext(GlobalContext)
    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="space-y-[20px]">
                    <div className="lg:flex lg:justify-center lg:items-center lg:space-x-[20px]">
                        <div className="w-[570px] lg:w-[480px] space-y-[20px] md:m-auto lg:m-0">
                            <Fade cascade damping={0.1}>
                                <div className="px-[20px] text-left txt-arena-white">
                                    <h3 className="text-[25px] font-bold">{info.venueName}</h3>
                                </div>
                                <div>
                                    <img className="w-full h-[300px] lg:h-[280px] rounded-lg img-boxs" src={info.venueImagesURL[0]} />
                                </div>
                            </Fade>
                        </div>
                        <div className="w-[570px] lg:w-[480px] space-y-[20px] text-left txt-arena-white md:m-auto lg:m-0">
                            <Link to="/venues" onClick={() => {
                                setGlobalState({...globalState, name_location:''})
                            }}>
                                <Fade>
                                    <div className="flex items-center space-x-[12px] text-left mt-[30px] lg:mt-0 mb-[35px] txt-arena-orange">
                                        <span className="text-[30px]"><AiOutlineArrowLeft /></span>
                                        <p className="text-[20px]">Sucursales / {info.venueName}</p>
                                    </div>
                                </Fade>
                            </Link>
                            <div className="glassmorphism space-y-[12px] p-[25px]">
                                <Fade>
                                    <h3 className="text-[18px] font-bold">Dirección</h3>
                                </Fade>
                                <Fade>
                                    <p className="text-[18px]">
                                        {info.venueAddress.street} {info.venueAddress.number}, {info.venueAddress.neighborhood},
                                        CP {info.venueAddress.zipCode}
                                    </p>
                                </Fade>
                                <Fade>
                                    <p className="text-[18px] font-bold">
                                        {info.venueAddress.city}, {info.venueAddress.country}
                                    </p>
                                </Fade>
                            </div>
                            <div className="glassmorphism p-[25px]" onClick={() => onModalMap(true)}>
                                <Fade>
                                    <div className="flex m-auto">
                                        <span className="w-[90%] text-[18px] font-bold">Ver Mapa</span>
                                        <span className="w-[10%] text-[30px]"><AiOutlineArrowRight /></span>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                    <div className="md:w-[650px] lg:w-[950px] space-y-[12px] m-auto">
                        <div className="p-[25px] text-left txt-arena-white font-bold">
                            <Fade>
                                <h3 className="text-[25px]">Instalaciones</h3>
                            </Fade>
                        </div>
                        <div className="lg:grid lg:grid-cols-2 lg:gap-4 space-y-[20px] lg:space-y-0">
                            {
                                info.venueImagesURL.map((element: string, i: number) => {
                                    if (element !== "") {
                                        return (
                                            <div key={i}>
                                                <button onClick={() => onModalCarrousel(true, i)}>
                                                    <Fade>
                                                        <img className="w-full h-[300px] lg:w-[500px] lg:h-[260px] rounded-lg img-boxs" src={element} />
                                                    </Fade>
                                                </button>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <Fade cascade damping={0.1}>
                    <div>
                        <Link to="/venues">
                            <div className="flex items-center space-x-[2vw] text-left mb-[3vw] txt-arena-orange">
                                <span className="text-[5vw]"><AiOutlineArrowLeft /></span>
                                <p className="text-[4vw]">Regresar a Sucursales</p>
                            </div>
                        </Link>
                        <div className="px-[2vw] text-left txt-arena-white">
                            <h3 className="text-[6vw] font-bold">{info.venueName}</h3>
                        </div>
                    </div>
                    <div className="space-y-[5vw]">
                        <div>
                            <img className="w-[90vw] h-[50vw] rounded-lg img-boxs" src={info.venueImagesURL[0]} />
                        </div>
                        <div className="w-[85vw] space-y-[4vw] text-left txt-arena-white">
                            <div className="glassmorphism space-y-[3vw] p-[5vw]">
                                <h3 className="text-[4.5vw] font-bold">Dirección</h3>
                                <p className="text-[4vw]">
                                    {info.venueAddress.street} {info.venueAddress.number}, {info.venueAddress.neighborhood},
                                    CP {info.venueAddress.zipCode}
                                </p>
                                <p className="text-[4.5vw] font-bold">
                                    {info.venueAddress.city}, {info.venueAddress.country}
                                </p>
                            </div>
                            <div className="glassmorphism p-[5vw]" onClick={() => onModalMap(true)}>
                                <div className="flex m-auto">
                                    <span className="w-[90%] text-[5vw] font-bold">Ver Mapa</span>
                                    <span className="w-[10%] text-[7vw]"><AiOutlineArrowRight /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fade>
                <div className="mt-[5vw] space-y-[3vw]">
                    <div className="p-[2vw] text-left txt-arena-white font-bold">
                        <h3 className="text-[4vw]">Instalaciones</h3>
                    </div>
                    <div>
                        {
                            info.venueImagesURL.map((element: string, i: number) => {
                                if (element !== "") {
                                    return (
                                        <div className="mb-[4vw]" key={i}>
                                            <Fade>
                                                <img className="w-[85vw] h-[50vw] rounded-lg img-boxs" src={element} />
                                            </Fade>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default VenuesInfo;