import { useEffect, useState } from "react";
import { HiPhotograph } from "react-icons/hi";
import { uploadImages } from "../../../firebase";
import { IDCompany } from "../../../global/global";
import { addDropTournament, updateDropTournament } from "../../../services/TournamentDrop";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsPlusCircleFill } from "react-icons/bs";
import PopNotify from "../../../components/Pop/PopNotify";
import Select from "../../../components/select/Select";
import TournamentDropModel from "../../../models/TournamentDrop";
import Loading from "../../../components/loading/Loading";

interface StepData {
    step: string;
    status: boolean;
}

interface ImageData {
    name: string;
    file: any;
}

interface PopInfo {
    status: boolean,
    type: string
}

const DropForm = ({
    dtElement,
    type,
    mode,
    plataform,
    venues,
    onRefresh
} : {
    dtElement: TournamentDropModel,
    type: Array<any>,
    mode: Array<any>,
    plataform: Array<any>,
    venues: Array<any>,
    onRefresh: any
}) => {
    const [urlExt, setUrlExt] = useState<boolean>(true);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [imageUpload, setImageUpload] = useState<ImageData>({
        name: "",
        file: null
    });

    // Estado que controla los pasos del formulario...
    const [steps, setSteps] = useState<Array<StepData>>([
        { step: "Step 1", status: true },
        { step: "Step 2", status: false },
        { step: "Step 3", status: false }
    ]);

    // Modelo de Drops & Torneos
    const [dropTournament, setDropTournament] = useState({
        companyID: { value: `${IDCompany}`, isCorrect: true },
        dropID: { value: "", isCorrect: false },
        dropName: { value: "", isCorrect: false },
        dropGameTitle: { value: "", isCorrect: false },
        dropType: { value: "", isCorrect: false },
        dropReward: { value: "", isCorrect: false },
        dropGroupMode: { value: "", isCorrect: false },
        dropDescription: { value: "", isCorrect: false },
        drpoTerms: { value: "", isCorrect: false },
        dropConsole: { value: "", isCorrect: false },
        dropDate: { value: "", isCorrect: false },
        dropLocation: { value: "", isCorrect: false },
        dropCreatedBy: { value: "", isCorrect: false },
        dropImageURL: { value: "", isCorrect: false },
        dropVideoURL: { value: "", isCorrect: false },
        dropExternalURL: { value: "", isCorrect: false }
    });

    const deleteImage = () => {
        setImageUpload({...imageUpload, name: "", file: null});
    }

    // Almacena imagen ingresada por el usuario en el formulario
    const handleUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const file = evt.currentTarget.files;
        if (file !== null) {
            setImageUpload({...imageUpload, name: file[0].name, file: file[0]});
        }
    }

    // Almacena los datos (texto) ingresados por el usuario en el formulario
    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setDropTournament({...dropTournament, [evt.target.name]: { value: evt.target.value, isCorrect: true }});
    }

    // Almacena la opcion (selector) ingresada por el usuario en el formulario
    const onResponseSelect = (opt: any) => {
        let arrCurrent: Array<Object> = [];
        
        if (opt.target.name === "dropType") {
            arrCurrent = type;
        } else if (opt.target.name === "dropLocation") {
            arrCurrent = venues;
        } else if (opt.target.name === "dropConsole") {
            arrCurrent = plataform;
        } else if (opt.target.name === "dropGroupMode") {
            arrCurrent = mode;
        }
        setDropTournament({...dropTournament, [opt.target.name]: { value: Object(arrCurrent[opt.target.value]).value, isCorrect: true }});
    }

    // Verifica los datos de la fase actual del formulario, y si cumple los requisitos, pasa al siguiente paso
    const nextStep = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        if ((evt.currentTarget.name === "Step 2" &&
            (
                !dropTournament.dropName.isCorrect || !dropTournament.dropType.isCorrect || !dropTournament.dropLocation.isCorrect
            ))
            ||
            (evt.currentTarget.name === "Step 3" &&
            (
                !dropTournament.dropReward.isCorrect || !dropTournament.dropDate.isCorrect ||
                !dropTournament.dropDescription.isCorrect || !dropTournament.drpoTerms.isCorrect
            ))
        ) {
            return 0;
        }

        const newStep = steps.map((stepElement, i) => {
            if (stepElement.step === evt.currentTarget.name) {
                return { ...stepElement, status: true };
            } else {
                return { ...stepElement, status: false };
            }
        });
        setSteps(newStep);
    }

    // Verifica los campos del formulario y hace la petición requerida por el usuario (agregar/editar) al servidor
    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (
            dropTournament.dropName.isCorrect && dropTournament.dropType.isCorrect && dropTournament.dropLocation.isCorrect &&
            dropTournament.dropReward.isCorrect && dropTournament.dropDate.isCorrect && dropTournament.dropDescription.isCorrect &&
            dropTournament.drpoTerms.isCorrect
        ) {
            setLoadingStatus(true);

            // Obtiene la cadena correspondiente a la URL de la imagen almacenada en Firebase
            let imageUrlStr: string = "";
            if (imageUpload.file) {
                imageUrlStr = await uploadImages(imageUpload.file, "drops");
            } else if (dtElement && !imageUpload.file) {
                imageUrlStr = dtElement.dropImageURL;
            }

            // Modelo de Torneos & Drops
            const dtData: TournamentDropModel = {
                companyID: dropTournament.companyID.value,
                dropID: dtElement ? dropTournament.dropID.value : "",
                dropName: dropTournament.dropName.value,
                dropGameTitle: dropTournament.dropGameTitle.value,
                dropType: dropTournament.dropType.value,
                dropReward: dropTournament.dropReward.value,
                dropGroupMode: dropTournament.dropGroupMode.value,
                dropDescription: dropTournament.dropDescription.value,
                drpoTerms: dropTournament.drpoTerms.value,
                dropConsole: dropTournament.dropConsole.value,
                dropDate: dropTournament.dropDate.value,
                dropLocation: dropTournament.dropLocation.value,
                dropCreatedBy: dropTournament.dropCreatedBy.value,
                dropImageURL: imageUrlStr,
                dropVideoURL: dropTournament.dropVideoURL.value,
                dropExternalURL: dropTournament.dropExternalURL.value
            }

            new Promise<void>((resolve, reject) => {
                if (dtElement !== null) {
                    // Editar Torneo o Drop
                    updateDropTournament(dtData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data) {
                            setPopStatus({...popStatus, status: true, type: "success"});
                            onRefresh(true);
                        } else {
                            setPopStatus({...popStatus, status: true, type: "error"});
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                } else {
                    // Agregar Torneo o Drops
                    addDropTournament(dtData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data) {
                            onRefresh(true);
                            setPopStatus({...popStatus, status: true, type: "success"});
                            setImageUpload({...imageUpload, name: "", file: null});
                            setDropTournament({...dropTournament,
                                companyID: { value: `${IDCompany}`, isCorrect: true },
                                dropID: { value: "", isCorrect: false },
                                dropName: { value: "", isCorrect: false },
                                dropGameTitle: { value: "", isCorrect: false },
                                dropType: { value: "", isCorrect: false },
                                dropReward: { value: "", isCorrect: false },
                                dropGroupMode: { value: "", isCorrect: false },
                                dropDescription: { value: "", isCorrect: false },
                                drpoTerms: { value: "", isCorrect: false },
                                dropConsole: { value: "", isCorrect: false },
                                dropDate: { value: "", isCorrect: false },
                                dropLocation: { value: "", isCorrect: false },
                                dropCreatedBy: { value: "", isCorrect: false },
                                dropImageURL: { value: "", isCorrect: false },
                                dropVideoURL: { value: "", isCorrect: false },
                                dropExternalURL: { value: "", isCorrect: false }
                            });

                            // Se identifican los campos
                            let dropTypeInput = document.getElementById("dropTypeInput") as HTMLSelectElement | null;
                            let dropLocationInput = document.getElementById("dropLocationInput") as HTMLSelectElement | null;
                            let dropConsoleInput = document.getElementById("dropConsoleInput") as HTMLSelectElement | null;
                            let dropGroupModeInput = document.getElementById("dropGroupModeInput") as HTMLSelectElement | null;

                            // Se ingresan los valores
                            if (dropTypeInput !== null) dropTypeInput.value = "";
                            if (dropLocationInput !== null) dropLocationInput.value = "";
                            if (dropConsoleInput !== null) dropConsoleInput.value = "";
                            if (dropGroupModeInput !== null) dropGroupModeInput.value = "";
                        } else {
                            setPopStatus({...popStatus, status: true, type: "error"});
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                }
            });
        } else {
            setPopStatus({...popStatus, status: true, type: "warning"});
        }
    }

    useEffect(() => {
        if (dtElement !== null) {
            const newDT = {
                companyID: { value: `${IDCompany}`, isCorrect: true },
                dropID: { value: dtElement.dropID, isCorrect: true },
                dropName: { value: dtElement.dropName, isCorrect: true },
                dropGameTitle: { value: dtElement.dropGameTitle, isCorrect: true },
                dropType: { value: dtElement.dropType, isCorrect: true },
                dropReward: { value: dtElement.dropReward, isCorrect: true },
                dropGroupMode: { value: dtElement.dropGroupMode, isCorrect: true },
                dropDescription: { value: dtElement.dropDescription, isCorrect: true },
                drpoTerms: { value: dtElement.drpoTerms, isCorrect: true },
                dropConsole: { value: dtElement.dropConsole, isCorrect: true },
                dropDate: { value: dtElement.dropDate, isCorrect: true },
                dropLocation: { value: dtElement.dropLocation, isCorrect: true },
                dropCreatedBy: { value: dtElement.dropCreatedBy, isCorrect: true },
                dropImageURL: { value: dtElement.dropImageURL, isCorrect: true },
                dropVideoURL: { value: dtElement.dropVideoURL, isCorrect: true },
                dropExternalURL: { value: dtElement.dropExternalURL, isCorrect: true }
            }
            setDropTournament(newDT);
            setImageUpload({...imageUpload, name: dtElement.dropImageURL, file: null});

            // Se identifican los campos
            let dropTypeInput = document.getElementById("dropTypeInput") as HTMLSelectElement | null;
            let dropLocationInput = document.getElementById("dropLocationInput") as HTMLSelectElement | null;
            let dropConsoleInput = document.getElementById("dropConsoleInput") as HTMLSelectElement | null;
            let dropGroupModeInput = document.getElementById("dropGroupModeInput") as HTMLSelectElement | null;

            // Se ingresan los valores
            if (dropTypeInput !== null) dropTypeInput.value = `${type.findIndex((el, i) => el.value === dtElement.dropType)}`;
            if (dropLocationInput !== null) dropLocationInput.value = `${venues.findIndex((el, i) => el.value === dtElement.dropLocation)}`;
            if (dropConsoleInput !== null) dropConsoleInput.value = `${plataform.findIndex((el, i) => el.value === dtElement.dropConsole)}`;
            if (dropGroupModeInput !== null) dropGroupModeInput.value = `${mode.findIndex((el, i) => el.value === dtElement.dropGroupMode)}`;
        }
    }, [dtElement]);

    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="block">
                <form onSubmit={handleSubmit}>
                    <div className="px-2 text-left">
                        {/* Primera parte del formulario */}
                        {
                            steps[0].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[290px] bg-zinc-300 border border-zinc-300"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-zinc-300 rounded-full">
                                                <span className="text-[18px] text-zinc-300 font-bold">2</span>
                                            </div>
                                            <div className="w-[290px] bg-zinc-300 border border-zinc-300"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-zinc-300 rounded-full">
                                                <span className="text-[18px] text-zinc-300 font-bold">3</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-zinc-300 font-bold">Términos</span>
                                            <span className="text-[12px] text-zinc-300 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="space-y-5">
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Nombre
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="dropNameInput"
                                                type="text"
                                                placeholder="Nombre..."
                                                name="dropName"
                                                onChange={handleChange}
                                                value={dropTournament.dropName.value}
                                            />
                                        </div>
                                        <div className="md:grid md:grid-cols-2 md:gap-4">
                                            <div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="locationLabel">
                                                        Tipo
                                                    </label>
                                                    <Select className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black" options={type} onResponse={onResponseSelect} name="dropType" id="dropTypeInput" placeholder="Tipo..." />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="locationLabel">
                                                        Sucursal
                                                    </label>
                                                    <Select className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black" options={venues} onResponse={onResponseSelect} name="dropLocation" id="dropLocationInput" placeholder="Sucursal..." />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Título del juego <span className="text-zinc-300">{' (Solo en torneo...)'}</span>
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="dropGameTitleInput"
                                                type="text"
                                                placeholder="Titulo de juego..."
                                                name="dropGameTitle"
                                                onChange={handleChange}
                                                value={dropTournament.dropGameTitle.value}
                                            />
                                        </div>
                                        <div className="md:grid md:grid-cols-2 md:gap-4">
                                            <div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="locationLabel">
                                                        Consola <span className="text-zinc-300">{' (Solo en torneo...)'}</span>
                                                    </label>
                                                    <Select className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black" options={plataform} onResponse={onResponseSelect} name="dropConsole" id="dropConsoleInput" placeholder="Consola..." />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="locationLabel">
                                                        Modo <span className="text-zinc-300">{' (Solo en torneo...)'}</span>
                                                    </label>
                                                    <Select className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black" options={mode} onResponse={onResponseSelect} name="dropGroupMode" id="dropGroupModeInput" placeholder="Modo..." />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-end pt-[5px]">
                                            <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" name="Step 2" type="button" onClick={nextStep}>
                                                Siguiente
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }

                        {/* Segunda parte del formulario */}
                        {
                            steps[1].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[290px] bg-orange-600 border border-orange-600"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">2</span>
                                            </div>
                                            <div className="w-[290px] bg-zinc-300 border border-zinc-300"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-zinc-300 rounded-full">
                                                <span className="text-[18px] text-zinc-300 font-bold">3</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-orange-600 font-bold">Descripción</span>
                                            <span className="text-[12px] text-zinc-300 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="space-y-5">
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Premios
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="dropRewardInput"
                                                type="text"
                                                placeholder="Premios..."
                                                name="dropReward"
                                                onChange={handleChange}
                                                value={dropTournament.dropReward.value}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Vigencia
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="dropDateInput"
                                                type="text"
                                                placeholder="Vigencia..."
                                                name="dropDate"
                                                onChange={handleChange}
                                                value={dropTournament.dropDate.value}
                                            />
                                        </div>
                                        <div className="mb-4 space-y-2">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Descripción
                                            </label>
                                            <textarea
                                                className="appearance-none border border-zinc-500 bg-transparent w-full p-[10px] text-[14px] txt-arena-black rounded-sm"
                                                id="dropDescriptionInput"
                                                placeholder="Descripción..."
                                                name="dropDescription"
                                                onChange={handleChange}
                                                value={dropTournament.dropDescription.value}
                                                rows={5}
                                                maxLength={400}
                                            >
                                            </textarea>
                                        </div>
                                        <div className="mb-4 space-y-2">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Términos y condiciones
                                            </label>
                                            <textarea
                                                className="appearance-none border border-zinc-500 bg-transparent w-full p-[10px] text-[14px] txt-arena-black rounded-sm"
                                                id="drpoTermsInput"
                                                placeholder="Términos y condiciones..."
                                                name="drpoTerms"
                                                onChange={handleChange}
                                                value={dropTournament.drpoTerms.value}
                                                rows={5}
                                                maxLength={400}
                                            >
                                            </textarea>
                                        </div>
                                        <div className="flex justify-end pt-[5px]">
                                            <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" name="Step 3" type="button" onClick={nextStep}>
                                                Siguiente
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }

                        {/* Tercera parte del formulario */}
                        {
                            steps[2].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[290px] bg-orange-600 border border-orange-600"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">2</span>
                                            </div>
                                            <div className="w-[290px] bg-orange-600 border border-orange-600"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">3</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-orange-600 font-bold">Descripción</span>
                                            <span className="text-[12px] text-orange-600 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="space-y-5">
                                        <div className="mb-4 space-y-2">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Imagen <span className="text-zinc-400">{'Resolución recomendada (Drops: 300x300 - Torneos: 300x300)'}</span>
                                            </label>
                                            <div className="flex items-center justify-center w-full">
                                                <label className="w-full h-12 border-4 border-dashed">
                                                    <div className="flex space-x-2 p-2">
                                                        <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                        <div>
                                                            { imageUpload.name ?
                                                                <div className="flex">
                                                                    <span
                                                                        className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                                    >
                                                                        {imageUpload.name}
                                                                    </span>
                                                                    <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteImage() }>
                                                                        <AiFillCloseCircle />
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="flex">
                                                                    <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                        Cargar imagen...
                                                                    </span>
                                                                    <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <input type="file" className="opacity-0" name="noteImageURL" onChange={handleUpload} />
                                                </label>
                                            </div>
                                        </div>
                                        {/* <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                URL video destacado
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="dropVideoURLInput"
                                                type="text"
                                                placeholder="URL Video..."
                                                name="dropVideoURL"
                                                onChange={handleChange}
                                                value={dropTournament.dropVideoURL.value}
                                            />
                                        </div> */}
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                <input type="checkbox" name="urlExt" onChange={() => { setUrlExt(!urlExt) }} /> URL página externa
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black disabled:bg-gray-200"
                                                id="dropExternalURLInput"
                                                type="text"
                                                placeholder="URL externo..."
                                                name="dropExternalURL"
                                                onChange={handleChange}
                                                disabled={urlExt}
                                                value={dropTournament.dropExternalURL.value}
                                            />
                                        </div>
                                        <div className="flex justify-end pt-[5px]">
                                            <button className="bg-arena-orange px-[60px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" type="submit">
                                                { dtElement ? "Guardar cambios" : "Agregar drop / torneo / dinámica" }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </form>
            </div>
        </>
    );
}

export default DropForm;